import projects from "../img/school.png";
import { Link } from "react-router-dom";
import rr from "../img/rr.svg";

function ProjectDirectory({ data }) {
  return (
    <section className="projdirectory">
      <div className="container">
        {data?.project?.length > 0 && (
          <div className="row">
            <div className="col">
              <h2>Projects</h2>
            </div>
          </div>
        )}

        <div className="row mt-4">
          {data?.project?.map((item, idx) => (
            <div key={idx} className="col-md-4 mb-3">
              <div className="proj bg-white p-3">
                <img
                  src={item?.pictures || projects}
                  alt="projects"
                  className="img-fluid w-100"
                  style={{ height: 250, objectFit: "cover" }}
                />
                <h6 className="my-3">
                  <Link to="">{item?.name}</Link>
                </h6>
                <p>{item?.description}</p>
                <img src={rr} alt="review" className="img-fluid" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ProjectDirectory;
