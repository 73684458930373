import HeaderLogin from "../component/navbarLogin";
import Email from "../component/sentEmail";


function EmailSent() {
  return (
    <div>
      <HeaderLogin/>
      <Email/>
    </div>
  );
}

export default EmailSent;
