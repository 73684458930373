import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from './Button'
import { Form as FormikForm, Formik } from 'formik'
import { resetPasswordSchema } from '../utlis/validation'
import Input from './Input'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { toast } from './toast'
import { useMutation, useQuery } from 'react-query'
import { resetPassword } from '../handlers/authhandler'

function ResetP() {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const token = searchParams.get('token')

	const { mutate: resetPasswordrMutation, isLoading: isLoading } = useMutation(
		resetPassword,
		{
			onSuccess: async (res) => {
				toast.success('Password reset successful')
				navigate('/login')
			},
			onError: (e) => {
				toast.error(e)
			},
		}
	)

	const handleSubmit = (values) => {
		if (token) {
			const data = {
				...values,
				otp: token,
			}
			resetPasswordrMutation(data)
		} else {
			toast.error('Invalid token')
		}
	}
	return (
		<section className="register-section">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-5">
						<h2 className="text-center">Setup a new password</h2>
						<h6 className="mt-3 text-center">
							Enter a new password for your account
						</h6>
						<Formik
							initialValues={{
								password: '',
								password_confirmation: '',
							}}
							onSubmit={(values) => handleSubmit(values)}
							validationSchema={resetPasswordSchema}
						>
							{({ values, handleChange, handleSubmit, handleBlur }) => (
								<FormikForm className="mt-5" onSubmit={handleSubmit}>
									<Form.Group className="mb-4">
										<Input
											type="password"
											name="password"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.password}
											label="New Password"
											bottomText="Must contain 8+ characters with at least 1 number"
										/>
									</Form.Group>
									<Form.Group className="mb-4">
										<Input
											type="password"
											name="password_confirmation"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.password_confirmation}
											label="Confirm Password"
										/>
									</Form.Group>
									<Button
										className="btn btn-success w-100 login-btn mb-3"
										loading={isLoading}
										type="submit"
									>
										Confirm new password
									</Button>
								</FormikForm>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ResetP
