import CreateCampaign from "./dashboardParts/createCampaign";
import CSOSidebar from "./dashboardParts/csoSidebar";


function CreateDonations() {
  return (
    <div style={{backgroundColor: "#fff"}}>
      <CSOSidebar/>
      <CreateCampaign/>
    </div>
  );
}

export default CreateDonations;
