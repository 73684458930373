import AboutusContent from "../component/aboutusContent";
import Footer from "../component/footer";
import NavB from "../component/header";


function Aboutus() {
  return (
    <div>
      <NavB/>
      <AboutusContent/>
      <Footer/>
    </div>
  );
}

export default Aboutus;
