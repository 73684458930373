import axiosClient from "../utlis/axiosClient";

export const getApprovedDirectory = async ({
  searchText,
  state,
  page = 1,
  page_size = 10,
  area,
  rank,
  years,
}) => {
  let query = "";
  if (state) {
    query = `${query}&state=${state}`;
  }
  if (searchText) {
    query = `${query}&organization_search=${searchText}`;
  }
  if (area) {
    query = `${query}&areas=${area}`;
  }
  if (rank) {
    query = `${query}&rank=${rank}`;
  }
  if (years) {
    query = `${query}&year=${years}`;
  }
  try {
    const url = `/v1/web_views/directories?page=${page}&page_size=${page_size}${query}`;
    const response = await axiosClient.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const getSingleDirectory = async (id) => {
  try {
    const response = await axiosClient.get(`/v1/web_views/directories/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const fetchPublicProjects = async (page = 1, page_size = 9) => {
  try {
    const response = await axiosClient.get(
      `/v1/web_views/project_search/fetch_all_projects?page=${page}&page_size=${page_size}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const fetchPublicListingProjects = async (orgId) => {
  try {
    const response = await axiosClient.get(
      `/v1/web_views/project_search/${orgId}/fetch_project_by_orgs`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const fetchPublicSingleProject = async (projectId) => {
  try {
    const response = await axiosClient.get(
      `/v1/web_views/project_search/fetch_project/${projectId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const getOrganizations = async (searchText) => {
  try {
    const url = `/v1/web_views/directories?organization_search=${searchText}`;
    const response = await axiosClient.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const getAdmins = async (searchText) => {
  try {
    const url = `/v1/list-admins?name=${searchText}`;
    const response = await axiosClient.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const getSingleAdmin = async (id) => {
  try {
    const response = await axiosClient.get(`/v1/list-admins/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};
