import Form from 'react-bootstrap/Form'
import search from '../img/scope.svg'

// import Select from '../component/Select'

function EmptyDirectoryContent() {
  return (
    <section className="directorycontent">
      <div className="container">
        <div className="row">
          <div className="col-md-3 mb-3">
            <div className="filterby p-4 bg-white">
              <h4>Filter By</h4>
              <h6
                className="float-end"
                style={{ marginTop: '-38px', cursor: 'pointer' }}
                // onClick={handleClear}
              >
                <i className="bi bi-arrow-repeat me-2"></i> Reset
              </h6>
              <Form className="mt-4">
                <Form.Group className="mb-3">
                  <Form.Label>Location</Form.Label>
                  <Form.Select
                    // value={state}
                    // onChange={(e) => handleUpdateState(e.target.value)}
                  >
                    <option value="">Select State</option>
                    <option value="Abia">Abia</option>
                    <option value="Adamawa">Adamawa</option>
                    <option value="Akwa Ibom">Akwa Ibom</option>
                    <option value="Anambra">Anambra</option>
                    <option value="Bauchi">Bauchi</option>
                    <option value="Bayelsa">Bayelsa</option>
                    <option value="Benue">Benue</option>
                    <option value="Borno">Borno</option>
                    <option value="Cross River">Cross River</option>
                    <option value="Delta">Delta</option>
                    <option value="Ebonyi">Ebonyi</option>
                    <option value="Edo">Edo</option>
                    <option value="Ekiti">Ekiti</option>
                    <option value="Enugu">Enugu</option>
                    <option value="FCT">Federal Capital Territory</option>
                    <option value="Gombe">Gombe</option>
                    <option value="Imo">Imo</option>
                    <option value="Jigawa">Jigawa</option>
                    <option value="Kaduna">Kaduna</option>
                    <option value="Kano">Kano</option>
                    <option value="Katsina">Katsina</option>
                    <option value="Kebbi">Kebbi</option>
                    <option value="Kogi">Kogi</option>
                    <option value="Kwara">Kwara</option>
                    <option value="Lagos">Lagos</option>
                    <option value="Nasarawa">Nasarawa</option>
                    <option value="Niger">Niger</option>
                    <option value="Ogun">Ogun</option>
                    <option value="Ondo">Ondo</option>
                    <option value="Osun">Osun</option>
                    <option value="Oyo">Oyo</option>
                    <option value="Plateau">Plateau</option>
                    <option value="Rivers">Rivers</option>
                    <option value="Sokoto">Sokoto</option>
                    <option value="Taraba">Taraba</option>
                    <option value="Yobe">Yobe</option>
                    <option value="Zamfara">Zamfara</option>
                  </Form.Select>
                </Form.Group>
                <div className="tarea p-3 mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Thematic Area</Form.Label>
                    <Form.Select
                      // value={area}
                      // onChange={(e) => handleUpdateArea(e.target.value)}
                    >
                      <option value="">Select Area</option>
                      <option value="Education">Education</option>
                      <option value="Disability">Disability</option>
                      <option value="Poverty">Poverty</option>
                      <option value="Health">Health</option>
                      <option value="Economic/Community Development">
                        Economic/Community Development
                      </option>
                      <option value="Democracy and Good Governance">
                        Democracy and Good Governance
                      </option>
                      <option value="Orphanages and Shelters">
                        Orphanages and Shelters
                      </option>
                      <option value="Civil Rights and Social Action">
                        Civil Rights and Social Action
                      </option>
                      <option value="Peace and Conflict Resolution">
                        Peace and Conflict Resolution
                      </option>
                      <option value="Corporative and Microfinance">
                        Corporative and Microfinance
                      </option>
                      <option value="Corporative and Microfinance">
                        Corporative and Microfinance
                      </option>
                      <option value="Crime and Law Related">
                        Crime and Law Related
                      </option>
                      <option value="Youth and Youth Development">
                        Youth and Youth Development
                      </option>
                      <option value="Information Communication and Technology">
                        Information Communication and Technology
                      </option>
                      <option value="Trade">Trade</option>
                      <option value="Migration">Migration</option>
                      <option value="Gender and Social Inclusion">
                        Gender and Social Inclusion
                      </option>
                      <option value="Philanthropy">Philanthropy</option>
                      <option value="Research and Development">
                        Research and Development
                      </option>
                      <option value="Environment">Environment</option>
                      <option value="Water, Sanitation and Hygiene.">
                        Water, Sanitation and Hygiene.
                      </option>
                      <option value="Religion Related">Religion Related</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="tarea p-3 mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Ranking</Form.Label>
                    <Form.Select
                      // value={rank}
                      // onChange={(e) => handleUpdateRank(e.target.value)}
                    >
                      <option value="">Select Rank</option>
                      <option value="bronze">Bronze</option>
                      <option value="silver">Silver</option>
                      <option value="gold">Gold</option>
                      <option value="diamond">Diamond</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="tarea p-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Years of Operation</Form.Label>
                    <Form.Select
                      // value={years}
                      // onChange={(e) => handleUpdateYears(e.target.value)}
                    >
                      <option value="">Select Years</option>
                      <option value="0,5">0 - 5 Years</option>
                      <option value="5,20">5 - 20 Years</option>
                      <option value="20,50">20 - 60 Years</option>
                      <option value="60,100">60 - 100 Years</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                
              </Form>
            </div>
          </div>
          <div className='col-md-9 text-center'>
            <div className='no-res my-5'>
              <img src={search} alt="search" className='img-fluid mb-3' />
              <h6>No results found. Please adjust your search or filter criteria and try again.</h6>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default EmptyDirectoryContent
