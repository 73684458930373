import logo from "../img/logo-white.png";
import { Link } from "react-router-dom";

function Footer() {
  return(
    <section className="">
      <footer className="footer-bottom">
        <div className="container">
          <div className="row bottom-line" >
            <div className="col-md-5">
              <div className="row">
                <div className="col-sm mb-3">
                  <img src={logo} alt="logo" className="img-fluid mb-2" style={{width: "130px"}} />
                  <ul className="list-unstyled links">
                    <li><Link to="/">Terms</Link></li>
                    <li><Link to="/privacy">Privacy</Link></li>
                    <li><Link to="/about-us">About us</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-7 mb-3">
              <div className="bg-disclaimer">
                <h6 className="mb-2 text-white"><i className="bi bi-info-circle me-2"></i>Disclaimer</h6>
                <p>This project was developed under the Strengthening Civic Advocacy and Local Engagement (SCALE) project implemented by Palladium and made possible by the U.S. Agency for International Development on behalf of the American people. The contents are the sole responsibility of SCALE and do not reflect the views of the United States government.</p>
              </div>
            </div>
          </div>
          <div className="row copyright justify-content-center">
            <div className="col-md-10 text-center">
              <h6>© 2023 SCALE. All rights reserved.</h6>
            </div>
          </div>
        </div>
      </footer>
    </section>
  )
}

export default Footer;