import CSOSidebar from "./dashboardParts/csoSidebar";
import EmptyDonations from "./dashboardParts/emptyDonations";


function CSODonations() {
  return (
    <div style={{backgroundColor: "#fff"}}>
      <CSOSidebar/>
      <EmptyDonations/>
    </div>
  );
}

export default CSODonations;
