
import { Link } from "react-router-dom";
import emailsent from "../img/email.svg";

function Email() {
  return (
    <section className="register-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5 text-center">
            <img src={emailsent} alt="envelope" className="img-fluid" />
            <h2 className="mt-4 mb-3">Email Sent</h2>
            <h6 className="mb-5">Your email address has received a secure link for password reset. To reset your password, click the link.</h6>
            <Link to="/login" className="btn btn-success w-75 login-btn mb-3">Back to Login</Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Email;
