import { Link } from "react-router-dom";

function DirectoryBottom() {
  return (
    <section className="directorybottom">
      <div className="container">
        <div className="row">
          <div className="col text-center dbottom">
            <h2>Do you want your Organization to be listed here?</h2>
            <h6 className="mb-5 mt-3">Join over 4,000+ organizations already growing with Scale.</h6>
            <div className="flex-row justify-content-center desktop">
              <Link to="/signup" className="btn btn-success login-btn me-3 px-5">Get Started</Link>
              <button className="btn btn-success white-btn px-5">Learn More</button>
            </div>
            <div className="mobile">
              <Link to="/signup" className="btn btn-success login-btn me-3 px-5 mb-3 w-100">Get Started</Link>
              <button className="btn btn-success white-btn px-5 w-100">Learn More</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DirectoryBottom; 