import Adminsidebar from './dashboardParts/adminsidebar'
import ApprovedUpdate from './dashboardParts/approvedUpdate'

function ApprovedStatus() {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Adminsidebar/>
      <ApprovedUpdate/>
    </div>
  )
}

export default ApprovedStatus
