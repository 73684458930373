import React, { useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import update from '../../img/update.svg'
import dump from '../../img/dump.svg'
import dayjs from 'dayjs'
import { toast } from '../../component/toast'
import { useMutation, useQueryClient } from 'react-query'
import { updateResourceStatus } from '../../handlers/resourceHandler'
import Button from '../../component/Button'

function StatusUpdate({ id, data }) {
	const navigate = useNavigate()
	const closeApproveButtonRef = React.useRef()
	const closeRejectedButtonRef = React.useRef()
	const queryClient = useQueryClient()
	const [message, setMessage] = React.useState('')
	const [withMessage, setWithMessage] = React.useState(false)
	const {
		mutate: updateResourceStatusMutation,
		isLoading: isLoading,
	} = useMutation(updateResourceStatus, {
		onSuccess: async (res) => {
			closeApproveButtonRef?.current?.click()
			closeRejectedButtonRef?.current?.click()
			toast.success('Resource updated successfully')
			await queryClient.invalidateQueries(['admin-resources'])
			navigate('/admin/resources')
		},
		onError: (e) => {
			toast.error(e)
		},
	})

	const handleApprove = () => {
		updateResourceStatusMutation({
			id: id,
			data: {
				status: 'approved',
				with_message: false,
			},
		})
	}

	const handleReject = () => {
		updateResourceStatusMutation({
			id: id,
			data: {
				status: 'rejected',
				message: message,
				with_message: withMessage,
			},
		})
	}

	return (
		<section>
			<div className="content overview">
				<div className="row justify-content-between">
					<div className="col-md-8 mt-4 sbm">
						<Link to="/admin/resources">
							<i className="bi bi-arrow-left me-2"></i> Back to Resource Center
						</Link>
					</div>
					<div className="col-md-4 text-end mt-3">
						<div className="d-flex flex-row">
							<Button
								type="button"
								data-bs-toggle="modal"
								data-bs-target="#reject-resources"
								className="btn btn-success w-50 sbm-btn mb-3 mx-3"
							>
								Reject Resource
							</Button>
							<Button
								className="btn btn-success w-50 login-btn mb-3"
								type="button"
								data-bs-toggle="modal"
								data-bs-target="#approve-resources"
							>
								Accept Resource
							</Button>
						</div>
					</div>
				</div>
				<div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
					<hr />
				</div>
				<div className="row mt-5 justify-content-center">
					<div className="col-sm-7">
						<div className="single-tex">
							<h2>{data?.title || '-'}</h2>
							<div className="d-flex my-2">
								<span className="badgers me-3">
									<i className="bi bi-fonts me-2"></i>Text
								</span>
								{data?.status === 'approved' ? (
									<span className="mx-3 badgers">
										<i className="bi bi-check me-2"></i>Approved
									</span>
								) : data?.status === 'pending' ? (
									<span className="mx-3 pendg">
										<i className="bi bi-circle-fill me-2"></i>
										Pending
									</span>
								) : null}
								<h6 className="mt-1">By {data?.author || '-'}</h6>
							</div>
							<h3>{dayjs(data?.created_at).format('MMMM DD, YYYY')}</h3>
							<p className="mt-4">{data?.content || '-'}</p>
						</div>
					</div>
				</div>
			</div>

			{/* Approve Resource */}
			<div
				className="modal fade"
				id="approve-resources"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content px-3">
						<div className="modal-header">
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								ref={closeApproveButtonRef}
							></button>
						</div>
						<div className="modal-body">
							<div className="row listing-modal">
								<div className="col">
									<img
										src={update}
										alt="update"
										className="img-fluid mb-3"
										style={{ marginTop: '-30px' }}
									/>
									<h4>Approve Resource </h4>
									<p>Are you sure you want to approve this resource?</p>
									<div className="d-flex flex-row">
										<Button
											type="button"
											data-bs-dismiss="modal"
											aria-label="Close"
											className="btn btn-success bg-white w-100 cancel-btn mt-3 me-3"
										>
											Cancel
										</Button>
										<Button
											type={'button'}
											className="btn btn-success w-100 login-btn mt-3"
											onClick={handleApprove}
											loading={isLoading}
										>
											Confirm
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Reject Resource */}
			<div
				className="modal fade"
				id="reject-resources"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content px-3">
						<div className="modal-header">
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								ref={closeRejectedButtonRef}
							></button>
						</div>
						<div className="modal-body">
							<div className="row listing-modal">
								<div className="col">
									<img
										src={dump}
										alt="update"
										className="img-fluid mb-3"
										style={{ marginTop: '-30px' }}
									/>
									<h4>Reject Resource </h4>
									<p>Are you sure you want to reject this resource?</p>
									<div className="mb-3">
										<label className="form-label">Send a Message</label>
										<textarea
											className="form-control"
											placeholder="Enter a message"
											rows="7"
											name="message"
											value={message}
											onChange={(e) => setMessage(e.target.value)}
										></textarea>
									</div>
									<div className="mb-3">
										<div className="form-check">
											<input
												className="form-check-input"
												value={withMessage}
												onChange={(e) => setWithMessage(e.target.checked)}
												type="checkbox"
											/>
											<label
												className="form-check-label"
												htmlFor="flexCheckDefault"
											>
												Send notification email
											</label>
										</div>
									</div>
									<div className="d-flex flex-row">
										<Button
											type={'button'}
											className="btn btn-success border-g bg-white w-100 cancel-btn mt-3 me-3"
											onClick={() => closeRejectedButtonRef.current.click()}
										>
											Cancel
										</Button>
										<Button
											type={'button'}
											className="btn btn-success w-100 del-btn mt-3"
											onClick={handleReject}
											loading={isLoading}
										>
											Confirm
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default StatusUpdate
