import axiosClient from '../utlis/axiosClient'

export const getAllProject = async ({ page = 1, page_size = 9, name = '' }) => {
	try {
		const response = await axiosClient.get(
			`/v1/project?page=${page}&page_size=${page_size}&project_search=${name}`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		return response.data.data
	} catch (error) {
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const getSingleProject = async (id) => {
	try {
		const response = await axiosClient.get(`/v1/project/fetch_project/${id}`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return response.data.data
	} catch (error) {
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const createProject = async (data) => {
	try {
		const response = await axiosClient.post(`/v1/project`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return response.data.data
	} catch (error) {
		console.log({ error })
		if (error?.response?.data?.errors?.name) {
			throw error?.response?.data?.errors.name[0]
		}
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const updateProject = async ({ id, data }) => {
	try {
		const response = await axiosClient.put(
			`/v1/project/${id}/update_project`,
			data,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		return response.data.data
	} catch (error) {
		console.log({ error })
		if (error?.response?.data?.errors?.name) {
			throw error?.response?.data?.errors.name[0]
		}
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const archiveProject = async ({ id, data }) => {
	try {
		const response = await axiosClient.post(`/v1/project/${id}`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return response.data.data
	} catch (error) {
		console.log({ error })
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const fetchArchiveProject = async () => {
	try {
		const response = await axiosClient.get(
			`/v1/project/publish_status/archived`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		return response.data.data.projects
	} catch (error) {
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}
