import React from "react";
import { Link } from "react-router-dom";
import upd from "../../img/uas.svg";
import { Button } from "react-bootstrap";

function SingleComplianceView() {
  return (
    <section>
      <div className="content overview">
        <div className="row justify-content-between">
          <div className="col-md-8 mt-3 mb-3 sbm">
            <Link to="/admin/view-submission">
              {" "}
              <i className="bi bi-arrow-left me-2"></i>Back
            </Link>
          </div>
          <div className="col-md-3 text-end">
            <button
              className="btn btn-success login-btn py-2 px-4 w-50"
              data-bs-toggle="modal"
              data-bs-target="#update-comp"
            >
              {" "}
              Update Status
            </button>
          </div>
        </div>
        <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
          <hr />
        </div>
        <div className="mt-5">
          <div className="row justify-content-center">
            <div className="col-sm-6">
              <div className="comq">
                <h2>
                  What have you been involved in to target the Sustainable
                  Development Goals?
                </h2>
                <div className="d-flex d-flex my-3">
                  <span className="tan me-3">
                    <i className="bi bi-fonts me-2"></i>Text Answer
                  </span>
                  <span className="badgers">
                    <i className="bi bi-check me-2"></i>Approved
                  </span>
                </div>
                <h6>
                  By Responsible Citizens and Human Development Initiative
                  (RECHI)
                </h6>
                <h5>September 12, 2022</h5>
                <p className="mt-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Laudantium nobis similique quidem eius! Itaque dignissimos
                  mollitia exercitationem similique cum consectetur eius
                  necessitatibus pariatur. Pariatur ipsa officia ab a
                  dignissimos quas?
                </p>
                <p className="mt-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Laudantium nobis similique quidem eius! Itaque dignissimos
                  mollitia exercitationem similique cum consectetur eius
                  necessitatibus pariatur. Pariatur ipsa officia ab a
                  dignissimos quas?
                </p>
                <p className="mt-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Laudantium nobis similique quidem eius! Itaque dignissimos
                  mollitia exercitationem similique cum consectetur eius
                  necessitatibus pariatur. Pariatur ipsa officia ab a
                  dignissimos quas?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Update Status */}

      <div
        className="modal fade"
        id="update-comp"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <div className="col">
                  <img
                    src={upd}
                    alt="update"
                    className="img-fluid mb-3"
                    style={{ marginTop: "-30px" }}
                  />
                  <h4>Update Submission Status </h4>
                  <div className="form-check my-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Pending
                    </label>
                  </div>
                  <div className="form-check mb-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                    />
                    <label className="form-check-label" for="flexRadioDefault2">
                      Rejected
                    </label>
                  </div>
                  <div className="form-check mb-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                    />
                    <label className="form-check-label" for="flexRadioDefault2">
                      Approved
                    </label>
                  </div>
                  <div className="d-flex flex-row">
                    <Button
                      type={"button"}
                      className="btn btn-success border-g bg-white w-100 cancel-btn mt-3 me-3"
                    >
                      Cancel
                    </Button>
                    <Button
                      type={"button"}
                      className="btn btn-success w-100 login-btn mt-3"
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SingleComplianceView;
