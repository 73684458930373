import React, { useState } from "react";
import avatar from "../../img/user-img.svg";
import add from "../../img/added.svg";
import placeholderImg from "../../img/placeholder.jpg";
import DocIcon from "../../img/doc-icon.svg";
import { Link, useNavigate } from "react-router-dom";
// import one from "../../img/ups.svg";
import HeaderDropdownMenu from "../../component/HeaderDropdownMenu";
import dayjs from "dayjs";
import FileSaver from "file-saver";
import Button from "../../component/Button";
import { FileUploader } from "react-drag-drop-files";
import { useMutation, useQueryClient, useQuery } from "react-query";
import {
  createCompliance,
  getAllCompliance,
  submitComplianceAnswer,
  getComplianceAnswer,
} from "../../handlers/complianceHandlers";
import { toast } from "../../component/toast";
import { Form as FormikForm, Formik } from "formik";
import ReactPaginate from "react-paginate";
import { Oval } from "react-loader-spinner";
import ReactS3Client from "react-aws-s3-typescript";
import { s3Config } from "../../utlis/s3config";

const fileTypes = ["JPG", "PNG", "GIF", "PDF"];

const Compliance = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currPage, setCurrPage] = React.useState(1);
  const [answerCurrPage, setAnswerCurrPage] = React.useState(1);
  const { data } = useQuery([`compliances-question`, currPage], () =>
    getAllCompliance(currPage)
  );
  const { data: answerData } = useQuery(
    [`compliances-answers`, answerCurrPage],
    () => getComplianceAnswer(answerCurrPage)
  );
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [fileObj, setFileObj] = React.useState({
    name: "",
    loading: false,
  });
  const closeAnswerModalRef = React.useRef();

  const handlePaginateChange = async (page) => {
    setCurrPage(page.selected + 1);
  };

  const handleAnswerPaginateChange = async (page) => {
    setAnswerCurrPage(page.selected + 1);
  };

  const { mutate: submitComplianceAnswerMutation, isLoading: isLoading } =
    useMutation(submitComplianceAnswer, {
      onSuccess: async (res) => {
        toast.success("Answered successfully");
        await queryClient.invalidateQueries(["compliances-question"]);
        await queryClient.invalidateQueries(["compliances-answers"]);
        closeAnswerModalRef.current.click();
      },
      onError: (e) => {
        toast.error(e);
      },
    });
  const [imgUrl, setImgUrl] = React.useState("");

  const handleFileUpload = async (file) => {
    const s3 = new ReactS3Client({ ...s3Config });
    try {
      const res = await s3.uploadFile(file);
      return res.location;
    } catch (exception) {
      console.log(exception);
    }
  };

  const handleUpload = async (file) => {
    setFileObj({
      name: file?.name,
      loading: true,
    });
    const loc = await handleFileUpload(file);

    setImgUrl(loc);
    setFileObj((e) => ({ ...e, loading: false }));
  };

  const handleAnswerSubmit = async (values) => {
    const payload = {
      ...values,
      answer:
        selectedQuestion?.question_type === "file" ? imgUrl : values.answer,
    };
    submitComplianceAnswerMutation(payload);
  };

  return (
    <section>
      <div className="my-5 statuses">
        <hr />
        <div className="row mt-5">
          <div className="col">
            <ul className="nav nav-tabs tog" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active tog-btn"
                  id="quest-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#quest-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="quest-tab-pane"
                  aria-selected="true"
                >
                  Compliance Questions
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="sub-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#sub-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="sub-tab-pane"
                  aria-selected="false"
                >
                  Your Submissions
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="quest-tab-pane"
                role="tabpanel"
                aria-labelledby="quest-tab"
                tabIndex="0"
              >
                <div>
                  <div className="my-5 statuses">
                    {data?.data?.map((item, index) => (
                      <div className="comp-card" key={index}>
                        <div className="row">
                          <div className="col-sm-8">
                            <h4>{item?.question}</h4>
                            <button
                              className="btn btn-success register-btn py-2 px-4 mt-3"
                              data-bs-toggle="modal"
                              data-bs-target="#answer-comp"
                              onClick={() => setSelectedQuestion(item)}
                            >
                              Answer
                            </button>
                          </div>
                          <div className="col-sm-4 text-end">
                            <span className="tan">
                              <i className="bi bi-fonts me-2"></i>
                              {item?.question_type == "text"
                                ? "Text"
                                : "File"}{" "}
                              Answer
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {data?.data?.length > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={data?.total / 10 || 1}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handlePaginateChange}
                    containerClassName={"paginate"}
                    activeClassName={"active"}
                    forcePage={currPage - 1}
                  />
                )}
              </div>
              <div
                className="tab-pane fade"
                id="sub-tab-pane"
                role="tabpanel"
                aria-labelledby="sub-tab"
                tabIndex="0"
              >
                <div className="my-5 statuses">
                  {answerData?.data?.map((item, index) => (
                    <div className="comp-card" key={index}>
                      <div className="row">
                        <div className="col-sm-8">
                          <h4>{item?.compliance?.question}</h4>
                        </div>
                        <div className="col-sm-4 text-end">
                          <div className="d-flex flex-row justify-content-end">
                            {item?.status === "pending" ? (
                              <span
                                className="tan"
                                style={{ background: "#F4F4F5" }}
                              >
                                <i className="bi bi-circle-fill me-2"></i>
                                Pending
                              </span>
                            ) : item?.status === "rejected" ? (
                              <span className="rejectedbg">
                                <i className="bi bi-circle-fill me-2"></i>
                                Rejected
                              </span>
                            ) : (
                              <span className="badgers">
                                <i className="bi bi-check me-2"></i>Approved
                              </span>
                            )}
                            <span className="tan mx-4">
                              <i className="bi bi-folder me-2"></i>
                              {item?.compliance?.question_type === "text"
                                ? "Text"
                                : "File"}{" "}
                              Answer
                            </span>
                            {/* <div className="dropdown">
															<i
																className="bi bi-three-dots actions"
																data-bs-toggle="dropdown"
																aria-expanded="false"
															></i>
															<ul className="dropdown-menu listing-drop">
																<li>
																	<Link
																		className="dropdown-item listing-item"
																		data-bs-toggle="modal"
																		data-bs-target="#edit-comp"
																	>
																		<i className="bi bi-pencil me-2"></i> Edit
																	</Link>
																	<Link
																		className="dropdown-item listing-item"
																		to="/"
																	>
																		<i className="bi bi-archive me-2"></i>{' '}
																		Delete
																	</Link>
																</li>
															</ul>
														</div> */}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-sm-12">
                          {item?.compliance?.question_type === "text" ? (
                            <p>{item?.answer}</p>
                          ) : (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div style={{ marginRight: 10 }}>
                                <img
                                  src={DocIcon}
                                  alt=""
                                  className="img-fluid"
                                  style={{ height: 40, width: 40 }}
                                />
                              </div>
                              <p
                                style={{
                                  fontSize: 14,
                                  color: "#039855",
                                  margin: 0,
                                }}
                              >
                                {item?.answer?.split("/")[4]}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {answerData?.data?.length > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={answerData?.total / 10 || 1}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handleAnswerPaginateChange}
                    containerClassName={"paginate"}
                    activeClassName={"active"}
                    forcePage={answerCurrPage - 1}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Answer Compliance Question - Text */}
      <div
        className="modal fade"
        id="answer-comp"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <Formik
                  initialValues={{
                    compliance_id: selectedQuestion?.id,
                    answer: "",
                  }}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    handleAnswerSubmit(values);
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                    errors,
                    handleReset,
                  }) => (
                    <FormikForm onSubmit={handleSubmit}>
                      <div className="col">
                        <img
                          src={add}
                          alt="update"
                          className="img-fluid mb-3"
                          style={{ marginTop: "-30px" }}
                        />
                        <h4>Answer Compliance Question</h4>
                        <p>{selectedQuestion?.question}</p>
                        {selectedQuestion?.question_type === "file" ? (
                          <div className="mb-3">
                            <label className="form-label">Upload File</label>
                            <FileUploader
                              name="file"
                              types={fileTypes}
                              multiple={false}
                              handleChange={handleUpload}
                            />
                            <div className="uploader-loading-block">
                              <p style={{ margin: 0 }}>
                                {fileObj.loading
                                  ? `${fileObj.name} Uploading`
                                  : fileObj.name
                                  ? fileObj.name
                                  : "No file(s) uploaded yet"}
                              </p>
                              {fileObj.loading && (
                                <Oval
                                  wrapperClass="ml-2"
                                  height={20}
                                  width={20}
                                />
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="mb-3">
                            <label className="form-label">Your Answer</label>
                            <textarea
                              className="form-control"
                              placeholder="Enter your answer"
                              rows="7"
                              name="answer"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.answer}
                              required={true}
                            ></textarea>
                          </div>
                        )}

                        <div className="d-flex flex-row">
                          <button
                            type={"button"}
                            className="btn btn-success border-g bg-white w-100 cancel-btn mt-3 mb-3 me-3"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleReset}
                            ref={closeAnswerModalRef}
                          >
                            Cancel
                          </button>
                          <Button
                            type={"submit"}
                            className="btn btn-success w-100 login-btn mt-3"
                            loading={isLoading}
                          >
                            Post Answer
                          </Button>
                        </div>
                      </div>
                    </FormikForm>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Compliance Question - Text */}
      <div
        className="modal fade"
        id="edit-comp"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <div className="col">
                  <img
                    src={add}
                    alt="update"
                    className="img-fluid mb-3"
                    style={{ marginTop: "-30px" }}
                  />
                  <h4>Edit Submission</h4>
                  <p>
                    What is the agenda of your organization for the next 10
                    years?
                  </p>
                  <div className="mb-3">
                    <label className="form-label">Your Answer</label>
                    <textarea
                      className="form-control"
                      placeholder="Enter your answer"
                      rows="7"
                      name="message"
                    ></textarea>
                  </div>
                  <div className="d-flex flex-row">
                    <Button
                      type={"button"}
                      className="btn btn-success border-g bg-white w-100 cancel-btn mt-3 me-3"
                    >
                      Cancel
                    </Button>
                    <Button
                      type={"button"}
                      className="btn btn-success w-100 login-btn mt-3"
                    >
                      Update Answer
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Compliance;
