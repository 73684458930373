import EditResources from './dashboardParts/editResources';
import Sidebar from './dashboardParts/sideBar'

function EditArticle() {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Sidebar/>
      <EditResources/>
    </div>
  )
}

export default EditArticle;
