import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import AppButton from './Button'
import OtpInput from 'react18-input-otp'
import { verifyOTP } from '../handlers/authhandler'
import { useMutation } from 'react-query'
import { toast } from './toast'
import { AuthContext } from '../context/AuthContext'

function EnterOtp() {
	const navigate = useNavigate()
	const [otp, setOtp] = React.useState('')
	const { handleUpdateAuthenticated } = React.useContext(AuthContext)

	const handleChange = (enteredOtp) => {
		setOtp(enteredOtp)
	}

	const { mutate: verifyOTPMutation, isLoading: isLoading } = useMutation(
		verifyOTP,
		{
			onSuccess: async (res) => {
				toast.success('Registration is successful')
				handleUpdateAuthenticated(true)
				navigate('/verified')
			},
			onError: (e) => {
				toast.error(e)
			},
		}
	)

	const handleSubmit = () => {
		verifyOTPMutation({
			otp: otp,
		})
	}
	return (
		<section className="register-section">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-5 text-center">
						<h2 className="mb-3">Enter your OTP</h2>
						<h6 className="mb-5">
							Enter the 6-digit confirmation code sent to your email.
							<br />
							The code expires in 10 minutes
						</h6>

						<div className="text-center container flex-column items-center justify-center">
							<OtpInput
								value={otp}
								onChange={handleChange}
								numInputs={6}
								inputStyle="form-control otp-form"
							/>
							<div className="mt-5 w-100">
								<AppButton
									className="w-75"
									style={{ marginRight: 'auto', marginLeft: 'auto' }}
									type="button"
									onClick={handleSubmit}
									loading={isLoading}
								>
									Submit
								</AppButton>
							</div>
							<div className="text-center">
								<small className="agree">
									<Link to="/signup" style={{ color: '#2EA275' }}>
										Return to Sign up Page
									</Link>
								</small>
							</div>
						</div>
						<div className="row mt-5">
							<div className="col text-center">
								<small className="agree">
									Didn’t receive OTP via email? <Link to="">Resend OTP</Link>
								</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default EnterOtp
