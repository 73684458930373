import Adminsidebar from './dashboardParts/adminsidebar';
import CreateCompliance from './dashboardParts/complianceCreate';

function AdminCompliance() {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Adminsidebar/>
      <CreateCompliance/>
    </div>
  )
}

export default AdminCompliance;
