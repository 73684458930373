import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
// import { Button } from 'react-bootstrap'

import { FileUploader } from 'react-drag-drop-files'
import { Form as FormikForm, Formik } from 'formik'
import { createFeedValidationSchema } from '../../utlis/validation'
import Input from '../../component/Input'
import Select from '../../component/Select'
import Button from '../../component/Button'
import { s3Config } from '../../utlis/s3config'
import ReactS3Client from 'react-aws-s3-typescript'
import { Oval } from 'react-loader-spinner'
import { toast } from '../../component/toast'
import { useMutation, useQueryClient } from 'react-query'
import { createFeed } from '../../handlers/newsHandler'

const fileTypes = ['JPEG', 'PNG', 'JPG']

function AdminCreateNews() {
	const [setFile] = useState(null)
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const [featuredImgFileObj, setFeaturedImgFileObj] = React.useState({
		name: '',
		loading: false,
	})
	const [featuredImgUrl, setFeaturedImgUrl] = React.useState('')

	const handleFileUpload = async (file) => {
		const s3 = new ReactS3Client({ ...s3Config })
		try {
			const res = await s3.uploadFile(file)
			console.log({ res })
			return res.location
		} catch (exception) {
			console.log(exception)
		}
	}

	const handleFeaturedImgUpload = async (file) => {
		setFeaturedImgFileObj({
			name: file?.name,
			loading: true,
		})
		const loc = await handleFileUpload(file)
		setFeaturedImgUrl(loc)
		setFeaturedImgFileObj((e) => ({ ...e, loading: false }))
	}

	const { mutate: createFeedMutation, isLoading: isLoading } = useMutation(
		createFeed,
		{
			onSuccess: async (res) => {
				toast.success('News created successfully')
				await queryClient.invalidateQueries(['feeds'])
				navigate('/admin/news')
			},
			onError: (e) => {
				toast.error(e)
			},
		}
	)
	const handleSubmit = (values) => {
		if (featuredImgUrl) {
			const data = {
				...values,
				image: featuredImgUrl,
			}
			createFeedMutation(data)
		} else {
			toast.error('Featured Image is required')
		}
	}
	return (
		<section className="">
			<div className="content overview">
				<div className="row">
					<div className="col-md-10 mb-2 back">
						<Link to="/admin/news">
							<i className="bi bi-arrow-left me-2"></i> Back
						</Link>
					</div>
					<div className="col-md-2 text-end">
						<div
							className="d-flex flex-row"
							style={{ justifyContent: 'flex-end' }}
						></div>
					</div>
				</div>
				<div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
					<hr />
				</div>
				<div className="row">
					<div className="col">
						<h4>Add News</h4>
					</div>
				</div>
				<Formik
					initialValues={{
						title: '',
						content: '',
						author: '',
					}}
					onSubmit={(values) => handleSubmit(values)}
					validationSchema={createFeedValidationSchema}
				>
					{({
						values,
						handleChange,
						handleSubmit,
						handleBlur,
						setFieldValue,
						errors,
					}) => (
						<FormikForm className="row mt-5" onSubmit={handleSubmit}>
							<div className="col-md-8 mb-3">
								<>
									<Form.Group className="mb-3" controlId="">
										<Input
											type="text"
											name="title"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.title}
											label="Title"
										/>
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Label>Upload Featured Image</Form.Label>
										<FileUploader
											multiple={false}
											handleChange={handleFeaturedImgUpload}
											name="file"
											label="Drag and drop document here or Browse"
											types={fileTypes}
										/>
										<div className="uploader-loading-block">
											<p style={{ margin: 0 }}>
												{featuredImgFileObj.loading
													? `${featuredImgFileObj.name} Uploading`
													: featuredImgFileObj.name
													? featuredImgFileObj.name
													: 'No file(s) uploaded yet'}
											</p>
											{featuredImgFileObj.loading && (
												<Oval wrapperClass="ml-2" height={20} width={20} />
											)}
										</div>
									</Form.Group>
									<Form.Group className="mb-3" controlId="">
										<Input
											type="text"
											name="content"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.content}
											as="textarea"
											maxLength={1500}
											minLength={300}
											rows={10}
											placeholder="Enter content"
											label="Content"
										/>
										<Form.Text
											id="passwordHelpBlock"
											muted
											className="float-end"
										>
											{values?.content?.length} / 1500 Words
										</Form.Text>
									</Form.Group>
								</>
							</div>
							<div className="col-md-4">
								<Form.Group className="mb-3" controlId="">
									<Form.Label>Author Name</Form.Label>
									<Input
										type="text"
										name="author"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.author}
										label="Author"
									/>
								</Form.Group>
								{/* <Form.Group className="mb-3" controlId="">
                  <Form.Label>External link to news</Form.Label>
                  <Form.Control type="text" placeholder="Link to news" />
                </Form.Group> */}
							</div>
							<div className="row justify-content-end mt-5 ">
								<div className="col-md-4">
									<div className="d-flex flex-row">
										<Button
											type="button"
											className="btn btn-success login-btn me-3 w-100"
											onClick={() => navigate('/admin/news')}
										>
											Cancel
										</Button>
										<Button
											type={'submit'}
											className="btn btn-success bg-white login-btn lin w-100"
											loading={isLoading}
										>
											Publish News
										</Button>
									</div>
								</div>
							</div>
						</FormikForm>
					)}
				</Formik>
			</div>
		</section>
	)
}

export default AdminCreateNews
