import FPassword from '../component/FPassword'
import HeaderLogin from '../component/navbarLogin'

function ForgotPassword() {
	return (
		<div>
			<HeaderLogin />
			<FPassword />
		</div>
	)
}

export default ForgotPassword
