import HeaderLogin from "../component/navbarLogin";
import Register from "../component/Register";

function SignUp() {
  return (
    <div>
      <HeaderLogin/>
      <Register/>
    </div>
  );
}

export default SignUp;
