import React from "react";
import AdminResourcesCenter from "./dashboardParts/adminResourceCenter";
import Adminsidebar from "./dashboardParts/adminsidebar";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getCreatorResources,
  getAllResources,
} from "../handlers/resourceHandler";

function AdminResources() {
  const [currPage, setCurrPage] = React.useState(1);
  const [userCurrPage, setUserCurrPage] = React.useState(1);
  const [allType, setAllType] = React.useState("");
  const [allSearch, setAllSearch] = React.useState("");
  const [adminType, setAdminType] = React.useState("");
  const [adminSearch, setAdminSearch] = React.useState("");
  const timeout = React.useRef();
  const searchInputRef = React.useRef();
  const adminSearchInputRef = React.useRef();

  const { data: resources } = useQuery(
    ["admin-all-resources", currPage, allType, allSearch],
    () => getAllResources({ page: currPage, type: allType, search: allSearch })
  );

  const { data: adminResources } = useQuery(
    ["admin-resources", userCurrPage, adminType, adminSearch],
    () =>
      getCreatorResources({
        page: userCurrPage,
        type: adminType,
        search: adminSearch,
      })
  );

  const handlePaginateChange = async (page) => {
    setCurrPage(page.selected + 1);
  };

  const handleUserPaginateChange = async (page) => {
    setUserCurrPage(page.selected + 1);
  };

  const handleAllTypeChange = (e) => {
    setAllType(e.target.value);
  };

  const handleAllSearch = (e) => {
    const value = e.target.value;
    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setAllSearch(value);
    }, 600);
  };

  const handleAdminTypeChange = (e) => {
    setAdminType(e.target.value);
  };

  const handleAdminSearch = (e) => {
    const value = e.target.value;
    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setAdminSearch(value);
    }, 600);
  };

  const handleAllClear = () => {
    setAllSearch("");
    setAllType("");
    setAdminSearch("");
    setAdminType("");
    searchInputRef.current.value = "";
    adminSearchInputRef.current.value = "";
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Adminsidebar />
      <AdminResourcesCenter
        resources={resources?.resources?.data}
        resourcesCount={resources?.resources_count}
        adminResources={adminResources?.data}
        allTotal={resources?.resources?.total}
        userTotal={adminResources?.total}
        handlePaginateChange={handlePaginateChange}
        currPage={currPage}
        handleUserPaginateChange={handleUserPaginateChange}
        userCurrPage={userCurrPage}
        allType={allType}
        handleAllTypeChange={handleAllTypeChange}
        adminType={adminType}
        handleAdminTypeChange={handleAdminTypeChange}
        handleAllClear={handleAllClear}
        handleAllSearch={handleAllSearch}
        handleAdminSearch={handleAdminSearch}
        searchInputRef={searchInputRef}
        adminSearchInputRef={adminSearchInputRef}
      />
    </div>
  );
}

export default AdminResources;
