// import ProfileSettings from "./dashboardParts/profileSettings";
import MISProfileSettings from "./dashboardParts/misProfileSettings";
import Sidebar from "./dashboardParts/sideBar";


function MISSettings() {
  return (
    <div style={{backgroundColor: "#fff"}}>
      <Sidebar/>
      {/* <ProfileSettings/> */}
      <MISProfileSettings/>
    </div>
  );
}

export default MISSettings;
