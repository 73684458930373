import React from "react";
import { Link, useParams } from "react-router-dom";
// import flag from '../../img/flag.png'
import update from "../../img/feedback.svg";
import Button from "../../component/Button";
import dayjs from "dayjs";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "../../component/toast";
import { Form as FormikForm, Formik } from "formik";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { updateGrantView } from "../../handlers/grantHandler";

function SingleGrantBody({ data }) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { userInfo } = React.useContext(AuthContext);
  const closeAnswerModalRef = React.useRef();
  const { mutate: updateGrantViewMutation, isLoading: isLoading } = useMutation(
    updateGrantView,
    {
      onSuccess: async (res) => {
        toast.success("Answered successfully");
        await queryClient.invalidateQueries([`grant-${id}`]);
        closeAnswerModalRef.current.click();
      },
      onError: (e) => {
        toast.error(e);
      },
    }
  );

  const handleAnswerSubmit = (values) => {
    const payload = {
      applied: values?.applied === "true" ? true : false,
      granted: values?.granted === "true" ? true : false,
    };
    updateGrantViewMutation({ id, data: payload });
  };

  return (
    <section>
      <div className="content overview">
        <div className="row justify-content-between">
          <div className="col-md-8 mb-3 sbm">
            <Link to="/mis/opportunities">
              <i className="bi bi-arrow-left me-2"></i> Back to Grants
            </Link>
          </div>
          {data?.author?.includes(userInfo?.firstname) &&
            data?.author?.includes(userInfo?.lastname) && (
              <div className="col-md-4 text-end mt-3">
                <Link
                  to={`/mis/opportunities/${data?.id}/edit`}
                  className="btn btn-success w-50 login-btn mb-3"
                >
                  Edit Grant
                </Link>
              </div>
            )}
        </div>
        <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
          <hr />
        </div>
        <div className="row mt-5">
          <div className="col-sm-7">
            <div className="single-tex">
              <h2>{data?.title}</h2>
              <img
                src={data?.image}
                alt=""
                className="img-fluid w-100"
                style={{
                  borderRadius: 20,
                  height: 200,
                  width: "100%",
                  objectFit: "cover",
                }}
              />
              <div className="d-flex my-2">
                {data?.status === "approved" ? (
                  <span className="me-3 badgers">
                    <i className="bi bi-check me-2"></i>Approved
                  </span>
                ) : (
                  <span className="me-3 pendg">
                    <i className="bi bi-circle-fill me-2"></i>
                    Pending
                  </span>
                )}
                <h6 className="mt-1">By {data?.author}</h6>
              </div>
              <p className="mt-4">{data?.description}</p>
              <div>
                <h6 className="mb-2 fw-bold">Donor Name</h6>
                <p>{data?.donor_name}</p>
                <h6 className="mb-2 mt-3 fw-bold">Grant Size</h6>
                <p>${data?.size}</p>
              </div>
              <div className="d-flex flex-row">
                <Button
                  type={"button"}
                  className="btn btn-success login-btn w-25 mt-3 me-3"
                  onClick={() => window.open(data?.url, "_blank")}
                >
                  Learn More <i className="bi bi-box-arrow-up-right ms-3"></i>
                </Button>
                <Button
                  type={"submit"}
                  className="btn btn-success bg-white w-25 cancel-btn mt-3"
                  data-bs-toggle="modal"
                  data-bs-target="#feedback"
                >
                  Give Us Your Feedback
                </Button>
              </div>
            </div>
          </div>
          <div className="col-sm-5">
            <div className="single-tex">
              <h5 className="mb-4">Grant Overview</h5>
              <p>
                <i className="bi bi-calendar-date me-3 mb-3"></i>Publication
                Date: {dayjs(data?.publication_date).format("MMMM DD, YYYY")}
              </p>
              <p>
                <i className="bi bi-clock me-3 mb-3"></i>Expiration Date:
                {dayjs(data?.closing_date).format("MMMM DD, YYYY")}
              </p>
              <p>
                <i className="bi bi-eye me-3 mb-3"></i>No of Views:{" "}
                {data?.total_clicks || "-"}
              </p>
              <p>
                <i className="bi bi-cursor me-3 mb-3"></i>No of Applications:{" "}
                {data?.total_applied || "-"}
              </p>
              <p>
                <i className="bi bi-check-circle me-3 mb-3"></i>No of Grant
                Recipients: {data?.total_granted || "-"}
              </p>
              <p style={{ wordBreak: "break-all" }}>
                <i className="bi bi-check-circle me-3 mb-3"></i>URL:{" "}
                {data?.url || "-"}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Feedback */}
      <div
        className="modal fade"
        id="feedback"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <Formik
                  initialValues={{
                    applied: false,
                    granted: false,
                  }}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    handleAnswerSubmit(values);
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                    errors,
                    handleReset,
                  }) => (
                    <FormikForm onSubmit={handleSubmit}>
                      <div className="col">
                        <img
                          src={update}
                          alt="update"
                          className="img-fluid mb-3"
                          style={{ marginTop: "-30px" }}
                        />
                        <h4>Help Us with Feedback </h4>
                        <p>Share your grant experience and help us improve</p>
                        <div>
                          <h6>1. Did you apply for this particular grant?</h6>
                          <div className="form-check mt-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="applied"
                              id="flexRadioDefault1"
                              value={true}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="flexRadioDefault1"
                            >
                              Yes I did
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="applied"
                              id="flexRadioDefault2"
                              value={false}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="flexRadioDefault2"
                            >
                              No I did not
                            </label>
                          </div>
                        </div>
                        <div className="mt-5">
                          <h6>
                            2. Were you successful in receiving this grant?
                          </h6>
                          <div className="form-check mt-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="granted"
                              id="flexRadioDefault1"
                              value={true}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="flexRadioDefault1"
                            >
                              Yes. I got the grant
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="granted"
                              id="flexRadioDefault2"
                              value={false}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="flexRadioDefault2"
                            >
                              No. I did not get the grant
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row">
                          <button
                            type={"button"}
                            className="btn btn-success bg-white w-100 cancel-btn mt-3 mb-3 me-3"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleReset}
                            ref={closeAnswerModalRef}
                          >
                            Cancel
                          </button>
                          <Button
                            type={"submit"}
                            className="btn btn-success w-100 login-btn mt-3"
                            loading={isLoading}
                          >
                            Confirm
                          </Button>
                        </div>
                      </div>
                    </FormikForm>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SingleGrantBody;
