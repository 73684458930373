import Login from '../component/Login'
import HeaderLogin from '../component/navbarLogin'

function SignIn() {
	return (
		<div>
			<HeaderLogin />
			<Login />
		</div>
	)
}

export default SignIn
