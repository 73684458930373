import avi from '../img/mg.svg'
import rating from '../img/4.0.svg'

function SingleProjectBanner({ data }) {
	return (
		<section className="single-project">
			<div className="container-fluid">
				<div className="row">
					<div
						className="col-md-6 left-pro"
						style={{ backgroundImage: `url(${data?.project?.pictures})` }}
					></div>
					<div className="col-md-6">
						<div className="right-pro">
							<h2>{data?.project?.name}</h2>
							<div className="d-flex flex-row my-3">
								<img src={avi} alt="user" className="img-fluid me-3" />
								<p className="mt-3">
									Posted by: {data?.project?.organization?.organization}
								</p>
							</div>
							<div className="d-flex">
								<span className="me-3 coat">
									<i className="bi bi-geo-alt me-2"></i>{' '}
									{data?.project?.location}
								</span>
								<span className="me-3 coat">
									<i className="bi bi-arrow-up me-2"></i>
									{data?.project?.project_status}
								</span>
								<img
									src={rating}
									alt="rates"
									className="img-fluid"
									// style={{ marginTop: '-17px' }}
									width={100}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default SingleProjectBanner
