import CSOSidebar from "./dashboardParts/csoSidebar";
import OverviewCSO from "./dashboardParts/OverviewCso";
import {
  getOrgOverview,
  getOrgThematicArea,
} from "../handlers/organizationHandler";
import { useQuery } from "react-query";
import { getAllFeed } from "../handlers/newsHandler";

function CSOOverview() {
  const { data } = useQuery(["cso-overview"], () => getOrgOverview());

  const { data: thematicOrgs } = useQuery(
    ["organizations-in-thematic-area"],
    () => getOrgThematicArea()
  );

  const { data: feedData } = useQuery(["feeds", 1], () =>
    getAllFeed({ page: 1, page_size: 10 })
  );

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <CSOSidebar />
      <OverviewCSO
        data={data}
        thematicOrganization={thematicOrgs}
        feedData={feedData}
      />
    </div>
  );
}

export default CSOOverview;
