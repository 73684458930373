import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
// import Form from 'react-bootstrap/Form'
// import InputGroup from 'react-bootstrap/InputGroup'
import one from "../../img/one.svg";
import two from "../../img/two.svg";
import three from "../../img/three.svg";
import OrganizationDetails from "./OrganizationDetails";
import BusinessDocumentation from "./BusinessDocumentation";
import BankDetails from "./BankDetails";
import ReviewSubmit from "./ReviewSubmit";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const fileTypes = ["JPEG", "PNG", "JPG", "PDF"];

function Application() {
  const navigate = useNavigate();
  const { userInfo } = React.useContext(AuthContext);
  const [tabKey, setTabKey] = React.useState("first");

  React.useEffect(() => {
    if (
      userInfo?.organization_profile &&
      userInfo?.business_document &&
      userInfo?.bank_details
    ) {
      navigate("/cso/overview");
    }
    if (
      userInfo?.organization_profile &&
      !userInfo?.business_document &&
      !userInfo?.bank_details
    ) {
      setTabKey("second");
    }
    if (
      userInfo?.organization_profile &&
      userInfo?.business_document &&
      !userInfo?.bank_details
    ) {
      setTabKey("third");
    }
  }, [userInfo]);

  const handleChangeTab = (key) => {
    setTabKey(key);
  };
  return (
    <section className="application">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>Welcome to ATLAS</h2>
            <h6 className="mt-2">
              This is your setup guide. It has all you need to get setup and
              begin listing your organization. This setup usually takes between
              10 to 15 minutes
            </h6>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col">
            <Tab.Container
              activeKey={tabKey}
              id="left-tabs-example"
              defaultActiveKey="first"
            >
              <Row>
                <Col sm={3} className="mb-3">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item className="mb-3">
                      <Nav.Link eventKey="first">Listing Information</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mb-3">
                      <Nav.Link eventKey="second" disabled>
                        Business Documentation
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mb-3">
                      <Nav.Link eventKey="third" disabled>
                        Additional Information
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item className="mb-3">
											<Nav.Link eventKey="fourth">Review and Submit</Nav.Link>
										</Nav.Item> */}
                  </Nav>
                  <div className="bg-tips p-4 mt-5">
                    <h4 className="text-white text-center">
                      Tips for Uploading Documents
                    </h4>
                    <div className="d-flex flex-row">
                      <img src={one} alt="tips" className="img-fluid me-2" />
                      <p>
                        Use clear and non-blurry
                        <br />
                        images
                      </p>
                    </div>
                    <div className="d-flex flex-row">
                      <img src={two} alt="tips" className="img-fluid me-2" />
                      <p>
                        Make sure ID numbers are
                        <br />
                        visible
                      </p>
                    </div>
                    <div className="d-flex flex-row">
                      <img src={three} alt="tips" className="img-fluid me-2" />
                      <p>Make sure all files are less than 10mb in size</p>
                    </div>
                  </div>
                </Col>

                <Col sm={9}>
                  <Tab.Content className="form-width">
                    <Tab.Pane eventKey="first">
                      <OrganizationDetails handleChangeTab={handleChangeTab} />
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                      <BusinessDocumentation
                        handleChangeTab={handleChangeTab}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="third">
                      <BankDetails handleChangeTab={handleChangeTab} />
                    </Tab.Pane>

                    {/* <Tab.Pane eventKey="fourth">
											<ReviewSubmit />
										</Tab.Pane> */}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Application;
