import Adminsidebar from './dashboardParts/adminsidebar'
import OverviewAdmin from './dashboardParts/overviewAdmin'
import { getOverviewData } from '../handlers/adminHandler'
import { useMutation, useQuery, useQueryClient } from 'react-query'

function AdminOverview() {
	const { data, isLoading } = useQuery(['admin-overview'], () =>
		getOverviewData()
	)

	return (
		<div style={{ backgroundColor: '#fff' }}>
			<Adminsidebar />
			<OverviewAdmin data={data} />
		</div>
	)
}

export default AdminOverview
