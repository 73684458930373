import React from "react";
import { getLocalStorageItem, decryptData } from "../utlis/helper";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { getOrgProfile } from "../handlers/organizationHandler";

export const AuthContext = React.createContext({
  userInfo: null,
  isAuthenticated: false,
  handleUpdateInfo: (data) => {},
  handleUpdateAuthenticated: (data) => {},
});

const AppAuthContext = ({ children }) => {
  const [userInfo, setUserInfo] = React.useState(null);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  React.useEffect(() => {
    const encryptedUserInfo = getLocalStorageItem("userInfo");
    if (encryptedUserInfo) {
      const userInfo = decryptData(encryptedUserInfo);
      if (userInfo?.email_verified_at || userInfo?.is_admin) {
        setIsAuthenticated(true);
      }
      if (userInfo?.is_admin) {
        setUserInfo(userInfo);
      }
    }
  }, []);

  const {} = useQuery("cso-profile", () => getOrgProfile(), {
    onSuccess: (data) => {
      setUserInfo(data);
    },
    enabled: !!isAuthenticated && !userInfo?.is_admin,
  });

  const handleUpdateInfo = (data) => {
    setUserInfo(data);
  };

  const handleUpdateAuthenticated = (value) => {
    setIsAuthenticated(value);
  };
  return (
    <AuthContext.Provider
      value={{
        userInfo,
        isAuthenticated,
        handleUpdateInfo,
        handleUpdateAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AppAuthContext;
