import React from 'react'
import avatar from '../../img/user-img.svg'
import p from '../../img/public.svg'
import manage from '../../img/manage.svg'
import log from '../../img/logout.svg'
import mis from '../../img/repeat.svg'
import add from '../../img/messagestate.svg'
import MISHeaderDropdownMenu from './MISHeaderDropdownMenu'
import { AuthContext } from '../../context/AuthContext'

import { Link } from 'react-router-dom'

function MessagesE() {
	const { userInfo } = React.useContext(AuthContext)
	return (
		<section>
			<div className="content overview">
				<div className="row justify-content-between">
					<div className="col-md-5 mb-2">
						<div className="form-group has-search">
							<span className="bi bi-search form-control-feedback"></span>
							<input
								type="text"
								className="form-control"
								placeholder="Search"
							/>
						</div>
					</div>
					<div className="col-md-3 text-end">
						<div className="d-flex justify-content-end">
							<p className="bg-lt me-5 mt-1">
								<i className="bi bi-circle-fill fs-6 me-2"></i> MIS
							</p>
							<div className="dropdown">
								<Link
									to="#"
									className="dropdown-toggle"
									role="button"
									data-bs-toggle="dropdown"
								>
									{userInfo?.organization?.orgfile?.org_logo ? (
										<img
											src={userInfo?.organization?.orgfile?.org_logo}
											alt="avatar"
											className="profile-topbar-avi"
										/>
									) : (
										<img src={avatar} alt="avi" className="img-fluid" />
									)}
								</Link>
								<MISHeaderDropdownMenu />
							</div>
						</div>
					</div>
				</div>
				<div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
					<hr />
				</div>
				<div className="row">
					<div className="col-md-8 mt-3">
						<h4>Messages</h4>
						<p>Send and receive messages to and from other organizations</p>
					</div>
					<div className="col-md-4 text-end mt-3">
						<Link to="" className="btn btn-success w-50 login-btn mb-3">
							New Message
						</Link>
					</div>
				</div>
				<div className="row justify-content-center empty">
					<div className="col-md-6 text-center">
						<img src={add} alt="projects" className="img-fluid" />
						<h3 className="my-4">No message yet</h3>
						<p className="mb-4">
							Here you'll find a list of all your direct messages, with the most
							recent at the top
						</p>
						<Link to="" className="btn btn-success w-25 login-btn mb-3">
							{' '}
							Write a Message
						</Link>
					</div>
				</div>
			</div>
		</section>
	)
}

export default MessagesE
