import React from 'react'
import DirectoryBanner from '../component/directoryBanner'
import DirectoryBottom from '../component/directoryBottom'
import DirectoryContent from '../component/directoryContent'
import Footer from '../component/footer'
import NavB from '../component/header'

import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getApprovedDirectory } from '../handlers/publicHandler'

function Directory() {
	const timeout = React.useRef()
	const [searchText, setSearchText] = React.useState('')
	const [state, setState] = React.useState('')
	const [area, setArea] = React.useState('')
	const [rank, setRank] = React.useState('')
	const [years, setYears] = React.useState('')
	const [currPage, setCurrPage] = React.useState(1)

	const handleUpdateState = (state) => {
		setState(state)
	}
	const handleUpdateArea = (e) => {
		setArea(e)
	}
	const handleUpdateRank = (e) => {
		setRank(e)
	}
	const handleUpdateYears = (e) => {
		setYears(e)
	}

	const handleClear = () => {
		setState('')
		setArea('')
		setRank('')
		setYears('')
	}

	console.log({ currPage })
	const { data: data, isLoading: isDataLoading } = useQuery(
		[
			'directory',
			`page-${currPage}`,
			`search-${searchText}`,
			`state-${state}`,
			`area-${area}`,
			`rank-${rank}`,
			`years-${years}`,
		],
		() =>
			getApprovedDirectory({
				searchText: searchText,
				state: state,
				page: currPage,
				area: area,
				rank: rank,
				years: years,
			})
	)

	const handleSearch = (e) => {
		const value = e.target.value
		clearTimeout(timeout.current)

		timeout.current = setTimeout(() => {
			setSearchText(value)
		}, 600)
	}

	const handlePaginateChange = async (page) => {
		setCurrPage(page.selected + 1)
	}

	return (
		<div>
			<NavB />
			<DirectoryBanner handleSearch={handleSearch} />
			<DirectoryContent
				data={data}
				isDataLoading={isDataLoading}
				state={state}
				handleUpdateState={handleUpdateState}
				area={area}
				handleUpdateArea={handleUpdateArea}
				rank={rank}
				handleUpdateRank={handleUpdateRank}
				years={years}
				handleUpdateYears={handleUpdateYears}
				handleClear={handleClear}
				handlePaginateChange={handlePaginateChange}
				currPage={currPage}
			/>
			<DirectoryBottom />
			<Footer />
		</div>
	)
}

export default Directory
