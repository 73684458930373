import React from "react";
import uploaded from "../../../img/items.png";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import Select from "react-select";
import { Form as FormikForm, Formik } from "formik";
import { updateOrganizationDetails } from "../../../handlers/onboardingHandler";
import { toast } from "../../../component/toast";
import { useMutation, useQueryClient } from "react-query";
import Button from "../../../component/Button";
import { s3Config } from "../../../utlis/s3config";
import ReactS3Client from "react-aws-s3-typescript";
import { FileUploader } from "react-drag-drop-files";
import { Oval } from "react-loader-spinner";
import dayjs from "dayjs";

const fileTypes = ["JPEG", "PNG", "JPG", "PDF"];

const EditListingInfo = ({ data }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [addressProof, setAddressProof] = React.useState(null);
  const [logo, setLogo] = React.useState(null);

  const [addressProofFileObj, setAddressProofFileObj] = React.useState({
    name: "",
    loading: false,
  });
  const [orgLogoFileObj, setOrgLogoFileObj] = React.useState({
    name: "",
    loading: false,
  });

  const [addressProofImgUrl, setAddressProofImgUrl] = React.useState("");
  const [orgLogoImgUrl, setOrgLogoImgUrl] = React.useState("");

  React.useEffect(() => {
    if (data?.organization?.orgfile?.address_proof) {
      setAddressProof(data?.organization?.orgfile?.address_proof);
    }
    if (data?.organization?.orgfile?.org_logo) {
      setLogo(data?.organization?.orgfile?.org_logo);
    }
  }, [data]);

  let initialValues = {
    organization: data?.organization?.organization || "",
    organization_email: data?.organization?.organization_email || "",
    organization_phone: data?.organization?.organization_phone || "",
    thematic_area:
      data?.organization?.thematic_area?.map((item) => ({
        value: item,
        label: item,
      })) || [],
    operation_years: data?.organization?.operation_years
      ? dayjs().year(data?.organization?.operation_years).format("YYYY-MM-DD")
      : "",
    services:
      data?.organization?.services?.map((item) => ({
        value: item,
        label: item,
      })) || [],
    location: data?.organization?.address || "",
    city: data?.organization?.city || "",
    state:
      data?.organization?.state?.map((item) => ({
        value: item,
        label: item,
      })) || [],
    geopolitical_zone: data?.organization?.geopolitical_zone || "",
    social_media:
      data?.organization?.social_media?.links?.map((link) => ({
        type: Object.keys(link)[0],
        link: Object.values(link)[0],
      })) || [],
    instagram:
      data?.organization?.socialmedia?.links?.find((item) => item.Instagram)
        ?.Instagram || "",
    facebook:
      data?.organization?.socialmedia?.links?.find((item) => item.Facebook)
        ?.Facebook || "",
    twitter:
      data?.organization?.socialmedia?.links?.find((item) => item.Twitter)
        ?.Twitter || "",
  };

  const handleFileUpload = async (file) => {
    const s3 = new ReactS3Client({ ...s3Config });
    try {
      const res = await s3.uploadFile(file);
      return res.location;
    } catch (exception) {
      console.log(exception);
    }
  };

  const hanldeProofOfAddressUpload = async (file) => {
    setAddressProofFileObj({
      name: file?.name,
      loading: true,
    });
    const loc = await handleFileUpload(file);

    setAddressProofImgUrl(loc);
    setAddressProofFileObj((e) => ({ ...e, loading: false }));
  };

  const handleOrgLogoUpload = async (file) => {
    setOrgLogoFileObj({
      name: file?.name,
      loading: true,
    });
    const loc = await handleFileUpload(file);

    setOrgLogoImgUrl(loc);
    setOrgLogoFileObj((e) => ({ ...e, loading: false }));
  };

  const { mutate: updateOrganizationDetailsMutation, isLoading: isLoading } =
    useMutation(updateOrganizationDetails, {
      onSuccess: async (res) => {
        toast.success("Details saved successfully");
        await queryClient.invalidateQueries("cso-profile");
        navigate("/cso/settings");
      },
      onError: (e) => {
        toast.error(e);
      },
    });

  const handleSubmit = (values) => {
    const social_links = [];
    if (values.instagram) {
      social_links.push({ Instagram: values.instagram });
    }
    if (values.facebook) {
      social_links.push({ Facebook: values.facebook });
    }
    if (values.twitter) {
      social_links.push({ Twitter: values.twitter });
    }

    const formdata = {
      name: values.organization,
      organization_email: values.organization_email,
      organization_phone: values.organization_phone,
      thematic_area: values.thematic_area.map((item) => item.value),
      services: values.other_services
        ? [values.other_services]
        : values.services.map((item) => item.value),
      operation_years: dayjs(values.operation_years).format("YYYY-MM"),
      geopolitical_zone: values.geopolitical_zone,
      address: values.location,
      city: values.city,
      state: values.state.map((item) => item.value),
      social_links: social_links,
      address_proof: addressProofImgUrl
        ? addressProofImgUrl
        : data?.organization?.orgfile?.address_proof,
      org_logo: orgLogoImgUrl
        ? orgLogoImgUrl
        : data?.organization?.orgfile?.org_logo,
    };

    updateOrganizationDetailsMutation({
      id: data?.organization?.id,
      data: formdata,
    });
  };
  return (
    <div className="row mt-5">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
        enableReinitialize={true}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          errors,
        }) => (
          <FormikForm onSubmit={handleSubmit}>
            <div className="col-md-8">
              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="4">
                  Name of Organization
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="text"
                    name="organization"
                    placeholder="African Society of Human Genetics"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.organization}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="4">
                  Email Address
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="email"
                    placeholder="johndoe@gmail.com"
                    disabled
                    value={data?.organization?.organization_email}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="4">
                  Phone Number
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="tel"
                    placeholder="234083029922"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="organization_phone"
                    value={values.organization_phone}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="4">
                  Year Founded
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="date"
                    name="operation_years"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.operation_years}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="4">
                  Thematic Area
                </Form.Label>
                <Col sm="8">
                  <Select
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => setFieldValue("thematic_area", e)}
                    name="thematic_area"
                    value={values.thematic_area}
                    options={[
                      { value: "Education", label: "Education" },
                      { value: "Disability", label: "Disability" },
                      { value: "Poverty", label: "Poverty" },
                      { value: "Health", label: "Health" },
                      {
                        value: "Economic/Community Development",
                        label: "Economic/Community Development",
                      },
                      {
                        value: "Democracy and Good Governance",
                        label: "Democracy and Good Governance",
                      },
                      {
                        value: "Orphanages and Shelters",
                        label: "Orphanages and Shelters",
                      },
                      {
                        value: "Civil Rights and Social Action",
                        label: "Civil Rights and Social Action",
                      },
                      {
                        value: "Peace and Conflict Resolution",
                        label: "Peace and Conflict Resolution",
                      },
                      {
                        value: "Corporative and Microfinance",
                        label: "Corporative and Microfinance",
                      },
                      {
                        value: "Crime and Law Related",
                        label: "Crime and Law Related",
                      },
                      {
                        value: "Youth and Youth Development",
                        label: "Youth and Youth Development",
                      },
                      {
                        value: "Information Communication and Technology",
                        label: "Information Communication and Technology",
                      },
                      { value: "Trade", label: "Trade" },
                      { value: "Migration", label: "Migration" },
                      {
                        value: "Gender and Social Inclusion",
                        label: "Gender and Social Inclusion",
                      },
                      { value: "Philanthropy", label: "Philanthropy" },
                      {
                        value: "Research and Development",
                        label: "Research and Development",
                      },
                      { value: "Environment", label: "Environment" },
                      {
                        value: "Water, Sanitation and Hygiene.",
                        label: "Water, Sanitation and Hygiene.",
                      },
                      {
                        value: "Religion Related ",
                        label: "Religion Related ",
                      },
                    ]}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="4">
                  Services Rendered
                </Form.Label>
                <Col sm="8">
                  <Select
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => setFieldValue("services", e)}
                    name="services"
                    value={values.services}
                    options={[
                      {
                        value: "Legal assistance",
                        label: "Legal assistance",
                      },
                      {
                        value: "Psychosocial / Counseling",
                        label: "Psychosocial / Counseling",
                      },
                      { value: "Mediation", label: "Mediation" },
                      { value: "Training", label: "Training" },
                      {
                        value: "Financial Assistance",
                        label: "Financial Assistance",
                      },
                      {
                        value: "Police / Other Security Actors",
                        label: "Police / Other Security Actors",
                      },
                    ]}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="4">
                  Organization’s Address
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="text"
                    placeholder="P.M.B 1515. Ilorin, Kwara State, Ilorin, Kwara, Nigeria"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="location"
                    value={values.location}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="4">
                  City
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="text"
                    placeholder="Kosofe"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="city"
                    value={values.city}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="4">
                  State
                </Form.Label>
                <Col sm="8">
                  <Select
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => setFieldValue("state", e)}
                    name="state"
                    value={values.state}
                    options={[
                      { value: "Abia", label: "Abia" },
                      { value: "Adamawa", label: "Adamawa" },
                      { value: "Akwa Ibom", label: "Akwa Ibom" },
                      { value: "Anambra", label: "Anambra" },
                      { value: "Bauchi", label: "Bauchi" },
                      { value: "Bayelsa", label: "Bayelsa" },
                      { value: "Benue", label: "Benue" },
                      { value: "Borno", label: "Borno" },
                      { value: "Cross River", label: "Cross River" },
                      { value: "Delta", label: "Delta" },
                      { value: "Ebonyi", label: "Ebonyi" },
                      { value: "Edo", label: "Edo" },
                      { value: "Ekiti", label: "Ekiti" },
                      { value: "Enugu", label: "Enugu" },
                      { value: "FCT", label: "Federal Capital Territory" },
                      { value: "Gombe", label: "Gombe" },
                      { value: "Imo", label: "Imo" },
                      { value: "Jigawa", label: "Jigawa" },
                      { value: "Kaduna", label: "Kaduna" },
                      { value: "Kano", label: "Kano" },
                      { value: "Katsina", label: "Katsina" },
                      { value: "Kebbi", label: "Kebbi" },
                      { value: "Kogi", label: "Kogi" },
                      { value: "Kwara", label: "Kwara" },
                      { value: "Lagos", label: "Lagos" },
                      { value: "Nasarawa", label: "Nasarawa" },
                      { value: "Niger", label: "Niger" },
                      { value: "Ogun", label: "Ogun" },
                      { value: "Ondo", label: "Ondo" },
                      { value: "Osun", label: "Osun" },
                      { value: "Oyo", label: "Oyo" },
                      { value: "Plateau", label: "Plateau" },
                      { value: "Rivers", label: "Rivers" },
                      { value: "Sokoto", label: "Sokoto" },
                      { value: "Taraba", label: "Taraba" },
                      { value: "Yobe", label: "Yobe" },
                      { value: "Zamfara", label: "Zamfara" },
                    ]}
                  />
                </Col>
              </Form.Group>
              {/* <Form.Group as={Row} className="mb-3" controlId="">
							<Form.Label column sm="4">
								Other services not included (please specify)
							</Form.Label>
							<Col sm="8">
								<Form.Control as="textarea" rows={4} />
							</Col>
						</Form.Group> */}
              <div className="row mb-3">
                <Form.Label column sm="4">
                  Social Profiles
                </Form.Label>
                <div className="col-md-8">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      Instagram
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="instagram.com/timesoftherecent"
                      name="instagram"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.instagram}
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      Facebook
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="facebook.com/timesoftherecent"
                      name="facebook"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.facebook}
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">Twitter</InputGroup.Text>
                    <Form.Control
                      placeholder="twitter.com/timesoftherecent"
                      name="twitter"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.twitter}
                    />
                  </InputGroup>
                </div>
              </div>
              <div className="row mb-3">
                <Form.Label column sm="4">
                  Uploaded Items
                </Form.Label>
                <div className="col-md-8">
                  {addressProof ? (
                    <div className="mb-4">
                      <span>
                        Proof of Address. Example Utility bill (Valid within 3
                        months)
                      </span>
                      <div className="up-items mt-3">
                        <div className="d-flex flex-row items-center">
                          <img
                            src={
                              data?.organization?.orgfile?.address_proof ||
                              uploaded
                            }
                            alt=""
                            className="img-fluid"
                            style={{
                              width: "146px",
                              height: "82px",
                              objectFit: "cover",
                            }}
                          />
                          <div className="ms-3">
                            <h5>
                              {
                                data?.organization?.orgfile?.address_proof?.split(
                                  "/"
                                )[4]
                              }
                            </h5>
                          </div>
                        </div>
                      </div>
                      <span
                        className="float-end removd mt-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => setAddressProof(null)}
                      >
                        <i className="bi bi-archive me-2 fs-6"></i>Remove
                      </span>
                    </div>
                  ) : (
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Upload Proof of Address. Example Utility bill (Valid
                        within 3 months)
                      </Form.Label>
                      <FileUploader
                        multiple={false}
                        handleChange={hanldeProofOfAddressUpload}
                        name="file"
                        types={fileTypes}
                        label="Drag and drop Image here or Browse"
                      />
                      <div className="uploader-loading-block">
                        <p style={{ margin: 0 }}>
                          {addressProofFileObj.loading
                            ? `${addressProofFileObj.name} Uploading`
                            : addressProofFileObj.name
                            ? addressProofFileObj.name
                            : "No file(s) uploaded yet"}
                        </p>
                        {addressProofFileObj.loading && (
                          <Oval wrapperClass="ml-2" height={20} width={20} />
                        )}
                      </div>
                    </Form.Group>
                  )}
                  {logo ? (
                    <div className="mb-3">
                      <span>Logo Mark of your Organization</span>
                      <div className="up-items mt-3">
                        <div className="d-flex flex-row items-center">
                          <img
                            src={
                              data?.organization?.orgfile?.org_logo || uploaded
                            }
                            alt=""
                            className="img-fluid"
                            style={{
                              width: "146px",
                              height: "82px",
                              objectFit: "cover",
                            }}
                          />
                          <div className="ms-3">
                            <h5>
                              {
                                data?.organization?.orgfile?.org_logo?.split(
                                  "/"
                                )[4]
                              }
                            </h5>
                          </div>
                        </div>
                      </div>
                      <span
                        className="float-end removd mt-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => setLogo(null)}
                      >
                        <i className="bi bi-archive me-2 fs-6"></i>Remove
                      </span>
                    </div>
                  ) : (
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Upload Logo Mark of your Organization
                      </Form.Label>
                      <FileUploader
                        multiple={false}
                        handleChange={handleOrgLogoUpload}
                        name="file"
                        types={fileTypes}
                        label="Drag and drop Image here or Browse"
                      />
                      <div className="uploader-loading-block">
                        <p style={{ margin: 0 }}>
                          {orgLogoFileObj.loading
                            ? `${orgLogoFileObj.name} Uploading`
                            : orgLogoFileObj.name
                            ? orgLogoFileObj.name
                            : "No file(s) uploaded yet"}
                        </p>
                        {orgLogoFileObj.loading && (
                          <Oval wrapperClass="ml-2" height={20} width={20} />
                        )}
                      </div>
                    </Form.Group>
                  )}
                </div>
              </div>
            </div>
            <hr />
            <div className="row justify-content-end mt-5 ">
              <div className="col-md-4 text-end">
                <Button
                  type="submit"
                  className="btn btn-success register-btn mt-5 login-btn px-5"
                  loading={isLoading}
                >
                  Update Details
                </Button>
              </div>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default EditListingInfo;
