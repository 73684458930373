import React from "react";
import Sidebar from "./dashboardParts/sideBar";
import SingleGrantBody from "./dashboardParts/singleGrantbody";
import { useParams } from "react-router-dom";
import { getSingleGrant, updateGrantClick } from "../handlers/grantHandler";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { toast } from "../component/toast";

function SingleGrant() {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(`grant-${id}`, () => getSingleGrant(id));
  const shouldClick = React.useRef(true);

  const { mutate: updateGrantClickMutation } = useMutation(updateGrantClick, {
    onSuccess: async (res) => {
      await queryClient.invalidateQueries([`grant-${id}`]);
    },
    onError: (e) => {
      toast.error(e);
    },
  });

  React.useEffect(() => {
    if (shouldClick.current && id) {
      shouldClick.current = false;
      handleClickRecord();
    }
  }, [id]);

  const handleClickRecord = () => {
    updateGrantClickMutation({ id });
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Sidebar />
      <SingleGrantBody data={data} />
    </div>
  );
}

export default SingleGrant;
