import axiosClient from '../utlis/axiosClient'

export const getAllArticles = async () => {
	try {
		const response = await axiosClient.get(
			`/v1/resource-centers/fetch-articles?page_size=10`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		return response.data.data
	} catch (error) {
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const createArticle = async (data) => {
	try {
		const response = await axiosClient.post(
			`/v1/resource-centers/articles`,
			data,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		return response.data.data
	} catch (error) {
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const getAllResources = async ({page, type, search }) => {
	try {
		const response = await axiosClient.get(
			`/v1/resource-centers/fetch-all-resources?page=${page}&page_size=10&type=${type}&news_search=${search}`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		return response.data.data
	} catch (error) {
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const getCreatorResources = async ({page, type, search}) => {
	try {
		const response = await axiosClient.get(
			`/v1/resource-centers/fetch-by-creator?page=${page}&page_size=10&type=${type}&news_search=${search}`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		return response.data.data
	} catch (error) {
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const getSingleResource = async (id) => {
	try {
		const response = await axiosClient.get(
			`/v1/resource-centers/fetch-resource/${id}`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		return response.data.data
	} catch (error) {
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const createResource = async (data) => {
	try {
		const response = await axiosClient.post(`/v1/resource-centers`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return response.data.data
	} catch (error) {
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const updateResourceStatus = async ({ id, data }) => {
	try {
		const response = await axiosClient.put(
			`/v1/resource-centers/update-resource-status/${id}`,
			data,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		return response.data.data
	} catch (error) {
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const updateResource = async ({ id, data }) => {
	try {
		const response = await axiosClient.post(
			`/v1/resource-centers/${id}`,
			data,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		return response.data.data
	} catch (error) {
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}
