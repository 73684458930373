import { useNavigate, useParams } from 'react-router-dom'

function SingleDirectoryBanner({ data }) {
	const navigate = useNavigate()
	const { id } = useParams()
	const handleNavigation = () => {
		navigate(`/single-directory/${id}/projects`)
	}
	return (
		<section className="singledirectory">
			<div className="container">
				<div className="row">
					<div className="d-flex mobile-row">
						<img
							src={data?.orgfile?.org_logo}
							alt="directory"
							className="img-fluid me-5"
							style={{
								height: '100%',
								width: '290px',
								objectFit: 'contain',
								borderRadius: '20px',
							}}
						/>
						<div className="mt-4">
							<h2 className="text-white">{data?.organization || '-'}</h2>
							<p className="text-white">
								<i className="bi bi-geo-alt me-2"></i>
								{`${data?.address || '-'} ${data?.city || '-'}`}
							</p>
							{/* <img src={i} alt="ratings" className="img-fluid me-3" /> */}
							<div className="d-flex mobile-row my-3">
								<h6 className="me-3 mt-2 text-white">
									<i className="bi bi-telephone me-2"></i>
									{data?.organization_phone || '-'}
								</h6>
								<h6 className="me-3 mt-2 text-white">
									<i className="bi bi-envelope me-2"></i>
									{data?.organization_email || '-'}
								</h6>
							</div>
							<button
								className="btn btn-success white-btn px-5"
								onClick={handleNavigation}
							>
								View Project
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default SingleDirectoryBanner
