import ListingsBody from "./dashboardParts/listingsBody";
import Sidebar from "./dashboardParts/sideBar";

function Listing() {
  return (
    <div style={{backgroundColor: "#FAFAFA"}}>
      <Sidebar/>
      <ListingsBody/>
    </div>
  );
}

export default Listing;
