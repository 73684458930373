import Adminsidebar from './dashboardParts/adminsidebar'
import UpdateGrant from './dashboardParts/updateGrants'
import { getSingleGrant } from '../handlers/grantHandler'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

function AdminUpdateGrant() {
	const { id } = useParams()
	const { data, isLoading } = useQuery(`grant-${id}`, () => getSingleGrant(id))
	return (
		<div style={{ backgroundColor: '#fff' }}>
			<Adminsidebar />
			<UpdateGrant data={data} id={id} />
		</div>
	)
}

export default AdminUpdateGrant
