import CSOSidebar from "./dashboardParts/csoSidebar";
import DonationSingle from "./dashboardParts/donationSingle";


function SingleDonation() {
  return (
    <div style={{backgroundColor: "#fff"}}>
      <CSOSidebar/>
      <DonationSingle/>
    </div>
  );
}

export default SingleDonation;
