import about from "../img/about-us.svg"


function AboutusContent() {
  return (
    <section className="aboutsec">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-sm-5 mb-3">
            <h2 className="mb-4">About Us</h2>
            <p>It is a technology platform that houses a Public Directory and a Management Information System for civil society organisations in Nigeria. </p>
            <p>The Public Directory is designed to function as a site that lists CSOs according to their thematic areas, projects implemented, collaborators, capacities, providing each CSO more visibility. </p>
            <p>The Management Information System is designed to act as an e-learning platform, providing access to trainings, lessons, and capacity development on various areas of nonprofit management.</p>
          </div>
          <div className="col-sm-6">
            <img src={about} alt="about us" className="img-fluid w-100" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutusContent;
