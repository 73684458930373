import React from 'react'
import comp from '../../img/comp.svg'
import add from '../../img/added.svg'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'


function CreateCompliance() {
  return (
    <section>
      <div className="content overview">
        <div className="row">
          <div className="col-md-6 mt-3">
            <h4>Compliance Requirements</h4>
            <p>Create, view, and update compliance requirements</p>
          </div>
        </div>
        <div className='row mt-5 justify-content-center e-com'>
          <div className='col-sm-5 text-center'>
            <img src={comp} alt="compliance" className='img-fluid' />
            <h3 className='my-3'>Create a Compliance Question</h3>
            <p>Create, view and update compliance requirements for organizations</p>
            <Link className='btn btn-success login-btn py-2 px-4 mt-3 w-50'
              // data-bs-toggle="modal"
              // data-bs-target="#create-comp"
              to="/admin/compliance"
            > <i className='bi bi-plus me-3 fs-5'></i>Create Question
            </Link>
          </div>
        </div>

                    
      </div>
      {/* Create Compliance Question  */}
      <div
        className="modal fade"
        id="create-comp"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <div className="col">
                  <img
                    src={add}
                    alt="update"
                    className="img-fluid mb-3"
                    style={{ marginTop: '-30px' }}
                  />
                  <h4>Create Compliance Question</h4>
                  <p>Create a compliance question to be answered by organizations.</p>
                  <div className="mb-3">
                    <label className="form-label">Question</label>
                    <input type="text" className="form-control" placeholder="What is the title of your Question?"/>
                  </div>
                  <div className="mb-3 creat-comp">
                    <label className="form-label">Question Type</label>
                    <select className="form-select" aria-label="Default select example">
                      <option value="1">Text Answer</option>
                      <option value="2">File Answer</option>
                    </select>
                  </div>
                  <div className="d-flex flex-row">
                    <Button
                      type={'button'}
                      className="btn btn-success border-g bg-white w-100 cancel-btn mt-3 me-3"
                    >
                      Cancel
                    </Button>
                    <Button
                      type={'button'}
                      className="btn btn-success w-100 login-btn mt-3"
                    >
                      Publish Question
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CreateCompliance
