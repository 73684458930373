import React from 'react'
import avatar from '../../img/user-img.svg'
import { Link } from 'react-router-dom'
import MISHeaderDropdownMenu from './MISHeaderDropdownMenu'
import dayjs from 'dayjs'
import { AuthContext } from '../../context/AuthContext'

function SingleLink({ data, id }) {
	const { userInfo } = React.useContext(AuthContext)
	const handleOpenLink = () => {
		window.open(data?.link, '_blank')
	}
	return (
		<section>
			<div className="content overview">
				<div className="row justify-content-between">
					<div className="col-md-8 mt-2 sbm">
						<Link to="/mis/resources">
							<i className="bi bi-arrow-left me-2"></i> Back to Resource Center
						</Link>
					</div>
					<div className="col-md-3 text-end">
						<div className="d-flex justify-content-end">
							<p className="bg-lt me-5 mt-1">
								<i className="bi bi-circle-fill fs-6 me-2"></i> MIS
							</p>
							<div className="dropdown">
								<Link
									to="#"
									className="dropdown-toggle"
									role="button"
									data-bs-toggle="dropdown"
								>
									{userInfo?.organization?.orgfile?.org_logo ? (
										<img
											src={userInfo?.organization?.orgfile?.org_logo}
											alt="avatar"
											className="profile-topbar-avi"
										/>
									) : (
										<img src={avatar} alt="avi" className="img-fluid" />
									)}
								</Link>
								<MISHeaderDropdownMenu />
							</div>
						</div>
					</div>
				</div>
				<div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
					<hr />
				</div>
				<div className="row mt-5 justify-content-center">
					<div className="col-sm-7">
						<div className="single-tex">
							<h2>{data?.title}</h2>
							<div className="d-flex my-2">
								<span className="badgers me-3">
									<i className="bi bi-link-45deg me-2"></i>Links
								</span>
								<h6 className="mt-1">By {data?.author}</h6>
							</div>
							<h3>{dayjs(data?.created_at).format('MMMM DD, YYYY')}</h3>
							<button
								type="button"
								onClick={handleOpenLink}
								className="btn btn-views float-end px-4"
							>
								View
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default SingleLink
