import EditGrant from './dashboardParts/editGrant'
import Sidebar from './dashboardParts/sideBar'
import { useParams } from 'react-router-dom'
import { getSingleGrant } from '../handlers/grantHandler'
import { useQuery } from 'react-query'

function MSIEditGrant() {
	const { id } = useParams()
	const { data, isLoading } = useQuery(`grant-${id}`, () => getSingleGrant(id))

	return (
		<div style={{ backgroundColor: '#fff' }}>
			<Sidebar />
			<EditGrant data={data} id={id} />
		</div>
	)
}

export default MSIEditGrant
