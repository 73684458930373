import * as Yup from "yup";

export const registerValidationSchema = Yup.object({
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is required"),
  category_id: Yup.string().required("Category Id is required"),
  organization: Yup.string().required("Organization is required"),
  email: Yup.string().required("Email is required"),
  phone: Yup.string().required("Phone number is required"),
  password: Yup.string()
    .required("Password is a required field")
    .min(8, "Password must be atleast 8 characters"),
  password_confirmation: Yup.string().test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent.password === value;
    }
  ),
});

export const changePasswordValidationSchema = Yup.object({
  old_password: Yup.string()
    .required("Password is a required field")
    .min(8, "Password must be atleast 8 characters"),
  new_password: Yup.string()
    .required("Password is a required field")
    .min(8, "Password must be atleast 8 characters"),
  new_password_confirmation: Yup.string().test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent.new_password === value;
    }
  ),
});

export const loginValidationSchema = Yup.object({
  email: Yup.string().required("Email is required"),
  password: Yup.string()
    .required("Password is a required field")
    .min(8, "Password must be atleast 8 characters"),
});

export const organizationDetailsValidationSchema = Yup.object({
  organization_email: Yup.string().required("Organization Email is required"),
  organization_phone: Yup.string().required("Organization Phone is required"),
  // geopolitical_zone: Yup.string()
});

export const businessDocValidationSchema = Yup.object({
  // reg_cac: Yup.string(),
});

export const bankDetailValidationSchema = Yup.object({
  bank_name: Yup.string(),
  beneficiary: Yup.string(),
  acct_no: Yup.string(),
});

export const createProjectValidationSchema = Yup.object({
  name: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  start_date: Yup.string().required("Required"),
  completion_date: Yup.string().required("Required"),
  project_status: Yup.string().required("Required"),
});

export const resetPasswordSchema = Yup.object({
  password: Yup.string()
    .required("Password is a required field")
    .min(8, "Password must be atleast 8 characters"),
  password_confirmation: Yup.string().test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent.password === value;
    }
  ),
});

export const createFeedValidationSchema = Yup.object({
  title: Yup.string().required("Required"),
  content: Yup.string().required("Required"),
});
