import Adminsidebar from './dashboardParts/adminsidebar'
import AdminViewProject from './dashboardParts/singleProjectAdmin'

function AdminSingleProject() {
	return (
		<div style={{ backgroundColor: '#fff' }}>
			<Adminsidebar />
			<AdminViewProject />
		</div>
	)
}

export default AdminSingleProject
