import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../component/Button";
import { FileUploader } from "react-drag-drop-files";
import { useMutation, useQueryClient } from "react-query";
import { createResource } from "../../handlers/resourceHandler";
import { toast } from "../../component/toast";
import { Form, Formik } from "formik";
import { Oval } from "react-loader-spinner";
import ReactS3Client from "react-aws-s3-typescript";
import { s3Config } from "../../utlis/s3config";

const fileTypes = ["JPG", "PNG", "GIF", "PDF"];

function AdminCreateFile() {
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };
  const [fileObj, setFileObj] = React.useState({
    name: "",
    loading: false,
  });
  const [imgUrl, setImgUrl] = React.useState("");

  const handleFileUpload = async (file) => {
    const s3 = new ReactS3Client({ ...s3Config });
    try {
      const res = await s3.uploadFile(file);
      return res.location;
    } catch (exception) {
      console.log(exception);
    }
  };

  const handlepload = async (file) => {
    setFileObj({
      name: file?.name,
      loading: true,
    });
    const loc = await handleFileUpload(file);

    setImgUrl(loc);
    setFileObj((e) => ({ ...e, loading: false }));
  };

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: createResourceMutation, isLoading: isLoading } = useMutation(
    createResource,
    {
      onSuccess: async (res) => {
        toast.success("Resource created successfully");
        await queryClient.invalidateQueries(["admin-resources"]);
        navigate("/admin/resources");
      },
      onError: (e) => {
        toast.error(e);
      },
    }
  );

  const handleSubmit = (values) => {
    createResourceMutation({ ...values, file: imgUrl });
  };

  return (
    <section className="">
      <div className="content overview">
        <Formik
          initialValues={{
            title: "",
            type: "file",
          }}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="row justify-content-between">
                <div className="col-md-8 mt-4 sbm">
                  <Link to="/mis/resources">
                    <i className="bi bi-arrow-left me-2"></i> Back
                  </Link>
                </div>
                <div className="col-md-4 text-end mt-3">
                  <div className="d-flex flex-row">
                    <Link
                      to="/admin/resources"
                      className="btn btn-success w-50 sbm-btn mb-3 mx-3"
                    >
                      Cancel
                    </Link>
                    <Button
                      className="btn btn-success w-50 login-btn mb-3"
                      type="submit"
                      loading={isLoading}
                    >
                      Publish Resource
                    </Button>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
                <hr />
              </div>
              <div className="row mt-5">
                <div className="col">
                  <h3>Add New Resource</h3>
                </div>
                <div className="sendbulk p-5">
                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Name/Title of Resource
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="What is the title of your Resource?"
                          name="title"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                          required={true}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Upload File</label>
                        <FileUploader
                          handleChange={handlepload}
                          name="file"
                          types={fileTypes}
                          multiple={false}
                        />
                        <div className="uploader-loading-block">
                          <p style={{ margin: 0 }}>
                            {fileObj.loading
                              ? `${fileObj.name} Uploading`
                              : fileObj.name
                              ? fileObj.name
                              : "No file(s) uploaded yet"}
                          </p>
                          {fileObj.loading && (
                            <Oval wrapperClass="ml-2" height={20} width={20} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
}

export default AdminCreateFile;
