import React from "react";
import avatar from "../../img/user-img.svg";
import { Link } from "react-router-dom";
import MISHeaderDropdownMenu from "./MISHeaderDropdownMenu";
import dayjs from "dayjs";
import ReactPaginate from "react-paginate";
import { AuthContext } from "../../context/AuthContext";

function ResourcesCenter({
  userResources,
  allResources,
  handlePaginateChange,
  currPage,
  handleUserPaginateChange,
  userCurrPage,
  allTotal,
  userTotal,
  allType,
  handleAllTypeChange,
  handleAllClear,
  handleAllSearch,
  searchInputRef,
  userType,
  handleUserSearch,
  handleUserTypeChange,
  userSearchInputRef,
}) {
  const { userInfo } = React.useContext(AuthContext);
  return (
    <section>
      <div className="content overview">
        <div className="row justify-content-between">
          <div className="col-md-5 mb-2">
            <div className="form-group has-search">
              <span className="bi bi-search form-control-feedback"></span>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
              />
            </div>
          </div>
          <div className="col-md-3 text-end">
            <div className="d-flex justify-content-end">
              <p className="bg-lt me-5 mt-1">
                <i className="bi bi-circle-fill fs-6 me-2"></i> MIS
              </p>
              <div className="dropdown">
                <Link
                  to="#"
                  className="dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  {userInfo?.organization?.orgfile?.org_logo ? (
                    <img
                      src={userInfo?.organization?.orgfile?.org_logo}
                      alt="avatar"
                      className="profile-topbar-avi"
                    />
                  ) : (
                    <img src={avatar} alt="avi" className="img-fluid" />
                  )}
                </Link>
                <MISHeaderDropdownMenu />
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
          <hr />
        </div>
        <div className="row justify-content-between">
          <div className="col-md-6 mt-3">
            <h4>Welcome to the Resource Center</h4>
            <p>Explore Files, Downloadables, and Articles</p>
          </div>
          <div className="col-md-3 text-end mt-3">
            <div className="dropdown">
              <button
                to=""
                className="btn btn-success w-50 login-btn mb-3 dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Add Resources
              </button>
              <ul className="dropdown-menu">
                <li>
                  <Link
                    className="dropdown-item"
                    to="/mis/resources/add-resource"
                  >
                    Text
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/mis/resources/add-file">
                    File
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/mis/resources/add-link">
                    Link
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row listings-tog mt-3">
          <div className="col">
            <ul className="nav nav-tabs tog" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active tog-btn"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected="true"
                >
                  Resource Center
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="post-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#post-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="post-tab-pane"
                  aria-selected="false"
                >
                  My Resources
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home-tab-pane"
                role="tabpanel"
                aria-labelledby="home-tab"
                tabIndex="0"
              >
                <div className="p-4 group-cards gray mt-4">
                  <form className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Search Resources</label>
                        <div className="search-message">
                          <i className="bi bi-search"></i>
                          <input
                            type="text"
                            className="form-control form-input"
                            placeholder="Search Resources"
                            onChange={handleAllSearch}
                            ref={searchInputRef}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Resource Type</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={allType}
                          onChange={handleAllTypeChange}
                        >
                          <option value="">All</option>
                          <option value="file">File</option>
                          <option value="text">Text</option>
                          <option value="link">Link</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3 text-end align-content-center">
                      <button
                        type="button"
                        className="btn btn-primary bg-white text-muted btn-claer px-3"
                        onClick={handleAllClear}
                      >
                        Clear all
                      </button>
                    </div>
                  </form>
                </div>
                <div className="mt-4">
                  {allResources?.map((resource, idx) => (
                    <div className="text-resou" key={idx}>
                      <div className="row">
                        <div className="col-sm-12 mb-3">
                          <Link to={`/mis/resources/${resource.id}`}>
                            <h5>{resource.title}</h5>
                          </Link>
                          <div className="d-flex my-2">
                            {resource.type === "text" ? (
                              <span className="badgers">
                                <i className="bi bi-fonts me-2"></i>Text
                              </span>
                            ) : resource.type === "link" ? (
                              <span className="me-3 badgers">
                                <i className="bi bi-link-45deg me-2"></i>Links
                              </span>
                            ) : (
                              <span className="me-3 badgers">
                                <i className="bi bi-folder me-2"></i>Files
                              </span>
                            )}
                            {resource.status === "approved" && (
                              <span className="mx-3 badgers">
                                <i className="bi bi-check me-2"></i>Approved
                              </span>
                            )}
                            <h6 style={{ marginTop: "3px" }}>
                              By {resource.author}
                            </h6>
                          </div>
                          <p>
                            {dayjs(resource.created_at).format("MMMM DD, YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {allResources?.length > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={allTotal / 10 || 1}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handlePaginateChange}
                    containerClassName={"paginate"}
                    activeClassName={"active"}
                    forcePage={currPage - 1}
                  />
                )}
              </div>

              <div
                className="tab-pane fade"
                id="post-tab-pane"
                role="tabpanel"
                aria-labelledby="post-tab"
                tabIndex="0"
              >
                <div className="p-4 group-cards gray mt-4">
                  <form className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Search Resources</label>
                        <div className="search-message">
                          <i className="bi bi-search"></i>
                          <input
                            type="text"
                            className="form-control form-input"
                            placeholder="Search Resources"
                            ref={userSearchInputRef}
                            onChange={handleUserSearch}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Resource Type</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={userType}
                          onChange={handleUserTypeChange}
                        >
                          <option value="">All</option>
                          <option value="file">File</option>
                          <option value="text">Text</option>
                          <option value="link">Link</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3 text-end align-content-center">
                      <button
                        type="button"
                        className="btn btn-primary bg-white text-muted btn-claer px-3"
                        onClick={handleAllClear}
                      >
                        Clear all
                      </button>
                    </div>
                  </form>
                </div>
                <div className="mt-4">
                  {userResources?.map((resource, idx) => (
                    <div className="text-resou" key={idx}>
                      <div className="row">
                        <div className="col-sm-12 mb-3">
                          <Link to={`/mis/resources/${resource.id}`}>
                            <h5>{resource.title}</h5>
                          </Link>
                          <div className="d-flex my-2">
                            {resource.type === "text" ? (
                              <span className="badgers">
                                <i className="bi bi-fonts me-2"></i>Text
                              </span>
                            ) : resource.type === "link" ? (
                              <span className="me-3 badgers">
                                <i className="bi bi-link-45deg me-2"></i>Links
                              </span>
                            ) : (
                              <span className="me-3 badgers">
                                <i className="bi bi-folder me-2"></i>Files
                              </span>
                            )}
                            {resource.status === "approved" && (
                              <span className="mx-3 badgers">
                                <i className="bi bi-check me-2"></i>Approved
                              </span>
                            )}
                            <h6 style={{ marginTop: "3px" }}>
                              By {resource.author}
                            </h6>
                          </div>
                          <p>
                            {dayjs(resource.created_at).format("MMMM DD, YYYY")}
                          </p>
                          <div className="dropdown float-end">
                            <i
                              className="bi bi-three-dots actions fs-5 float-end"
                              style={{ marginTop: "-51px", color: "#70707B" }}
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            ></i>
                            <ul className="dropdown-menu listing-drop">
                              <li>
                                <Link
                                  className="dropdown-item listing-item"
                                  to={`/mis/resources/${resource.id}/edit`}
                                >
                                  <i className="bi bi-pencil me-2"></i>Edit
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item listing-item"
                                  to=""
                                >
                                  <i className="bi bi-share me-2"></i>Share
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {userResources?.length > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={userTotal / 10 || 1}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handleUserPaginateChange}
                    containerClassName={"paginate"}
                    activeClassName={"active"}
                    forcePage={userCurrPage - 1}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResourcesCenter;
