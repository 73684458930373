import React from 'react'
import DirectoryBanner from '../component/directoryBanner'
import DirectoryBottom from '../component/directoryBottom'
import Footer from '../component/footer'
import NavB from '../component/header'
import EmptyDirectoryContent from '../component/emptyDirectoryContent'


function EmptyDirectory() {
  

  return (
    <div>
      <NavB />
      <DirectoryBanner />
      <EmptyDirectoryContent/>
      <DirectoryBottom />
      <Footer />
    </div>
  )
}

export default EmptyDirectory
