import React from "react";
import add from "../../img/added.svg";
import Button from "../../component/Button";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient, useQuery } from "react-query";
import {
  createCompliance,
  getAllCompliance,
} from "../../handlers/complianceHandlers";
import { toast } from "../../component/toast";
import { Form as FormikForm, Formik } from "formik";
import ReactPaginate from "react-paginate";
import dayjs from "dayjs";
import { Oval } from "react-loader-spinner";

function ComplianceBody() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const closeAddRef = React.useRef();
  const [currPage, setCurrPage] = React.useState(1);

  const { mutate: createComplianceMutation, isLoading } = useMutation(
    createCompliance,
    {
      onSuccess: async (res) => {
        toast.success("Question created successfully");
        await queryClient.invalidateQueries(["admin-compliances"]);
        closeAddRef.current.click();
      },
      onError: (e) => {
        toast.error(e);
      },
    }
  );

  const { data, isLoading: isComplianceLoading } = useQuery(
    [`admin-compliances`, currPage],
    () => getAllCompliance(currPage)
  );

  const handleSubmit = async (values) => {
    createComplianceMutation(values);
  };

  const handlePaginateChange = async (page) => {
    setCurrPage(page.selected + 1);
  };

  return (
    <section>
      <div className="content overview">
        <div className="row justify-content-between">
          <div className="col-md-7 mt-3">
            <h4>Compliance Requirements</h4>
            <p>Create, view, and update compliance requirements</p>
          </div>
          <div className="col-md-5 text-end mt-3">
            <button
              className="btn btn-success login-btn py-2 px-4 mt-3 w-50"
              data-bs-toggle="modal"
              data-bs-target="#create-comp"
            >
              {" "}
              <i className="bi bi-plus me-3 fs-5"></i>Create Question
            </button>
          </div>
        </div>
        <div className="mt-5">
          {isComplianceLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
              }}
            >
              <Oval wrapperClass="ml-2" height={40} width={40} />
            </div>
          ) : (
            <div className="bg-white group-cards no-head">
              <div className="row mt-4 mb-4 mx-4">
                <div className="col-sm-12 ps-0">
                  <h3>Compliance Questions</h3>
                </div>
              </div>
              <hr />
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Date Published</th>
                      <th scope="col">Question Title</th>
                      <th scope="col">Type of Question</th>
                      <th scope="col">No of Answers</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {" "}
                          {dayjs(item?.created_at)?.format("YYYY/MM/DD")}
                        </td>
                        <td>{item?.question}</td>
                        <td>
                          {item?.question_type === "text" ? "Text" : "File"}
                        </td>
                        <td>{item?.compliance_answers_count}</td>
                        <td>
                          <div className="dropdown">
                            <i
                              className="bi bi-three-dots-vertical actions"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            ></i>
                            <ul className="dropdown-menu listing-drop">
                              <li>
                                {/* <Link
                       className="dropdown-item listing-item"
                       data-bs-toggle="modal"
                       data-bs-target="#edit-comp"
                     >
                       <i className="bi bi-pencil me-2"></i>
                       Edit Question
                     </Link> */}
                                <Link
                                  to={`/admin/compliance/${item.id}`}
                                  className="dropdown-item listing-item"
                                >
                                  <i className="bi bi-eye me-2"></i>
                                  View Submission
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {data?.data?.length > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={data?.total / 10 || 1}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={3}
                  onPageChange={handlePaginateChange}
                  containerClassName={"paginate"}
                  activeClassName={"active"}
                  forcePage={currPage - 1}
                />
              )}
            </div>
          )}
        </div>
      </div>

      {/* Edit Compliance Question  */}
      <div
        className="modal fade"
        id="edit-comp"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <div className="col">
                  <img
                    src={add}
                    alt="update"
                    className="img-fluid mb-3"
                    style={{ marginTop: "-30px" }}
                  />
                  <h4>Edit Compliance Question</h4>
                  <p>
                    Edit a compliance question to be answered by organizations.
                  </p>
                  <div className="mb-3">
                    <label className="form-label">Question</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="What is the title of your Question?"
                    />
                  </div>
                  <div className="mb-3 creat-comp">
                    <label className="form-label">Question Type</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option value="1">Text Answer</option>
                      <option value="2">File Answer</option>
                    </select>
                  </div>
                  <div className="d-flex flex-row">
                    <Button
                      type={"button"}
                      className="btn btn-success border-g bg-white w-100 cancel-btn mt-3 me-3"
                    >
                      Cancel
                    </Button>
                    <Button
                      type={"button"}
                      className="btn btn-success w-100 login-btn mt-3"
                    >
                      Update Question
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Create Compliance Question  */}
      <div
        className="modal fade"
        id="create-comp"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeAddRef}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <Formik
                  initialValues={{
                    question: "",
                    question_type: "",
                  }}
                  onSubmit={(values) => handleSubmit(values)}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                    errors,
                  }) => (
                    <FormikForm onSubmit={handleSubmit}>
                      <div className="col">
                        <img
                          src={add}
                          alt="update"
                          className="img-fluid mb-3"
                          style={{ marginTop: "-30px" }}
                        />
                        <h4>Create Compliance Question</h4>
                        <p>
                          Create a compliance question to be answered by
                          organizations.
                        </p>
                        <div className="mb-3">
                          <label className="form-label">Question</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="What is the title of your Question?"
                            name="question"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.question}
                            required={true}
                          />
                        </div>
                        <div className="mb-3 creat-comp">
                          <label className="form-label">Question Type</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            name="question_type"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.question_type}
                            required={true}
                          >
                            <option value="">Select</option>
                            <option value="text">Text Answer</option>
                            <option value="file">File Answer</option>
                          </select>
                        </div>
                        <div className="d-flex flex-row">
                          <Button
                            type={"button"}
                            className="btn btn-success border-g bg-white w-100 cancel-btn mt-3 me-3"
                            onClick={() => closeAddRef.current.click()}
                          >
                            Cancel
                          </Button>
                          <Button
                            type={"submit"}
                            className="btn btn-success w-100 login-btn mt-3"
                            loading={isLoading}
                          >
                            Publish Question
                          </Button>
                        </div>
                      </div>
                    </FormikForm>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ComplianceBody;
