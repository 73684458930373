import DashboardHeader from '../component/DashboardNavbar'
import Application from '../component/onboarding/Onboarding'

function Onboarding() {
	return (
		<div>
			<DashboardHeader />
			<Application />
		</div>
	)
}

export default Onboarding
