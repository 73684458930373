import AdminEditFeeds from './dashboardParts/adminEditFeeds'
import Adminsidebar from './dashboardParts/adminsidebar'


function AdminNewFEdit() {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Adminsidebar />
      <AdminEditFeeds/>
    </div>
  )
}

export default AdminNewFEdit
