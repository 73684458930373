import CreateFile from './dashboardParts/createFile';
import Sidebar from './dashboardParts/sideBar'

function AddFile() {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Sidebar/>
      <CreateFile/>
    </div>
  )
}

export default AddFile;
