import Adminsidebar from './dashboardParts/adminsidebar'
import EditGrant from './dashboardParts/editGrant'
import { useParams } from 'react-router-dom'
import { getSingleGrant } from '../handlers/grantHandler'
import { useQuery } from 'react-query'

function AdminEditGrant() {
	const { id } = useParams()
	const { data, isLoading } = useQuery(`grant-${id}`, () => getSingleGrant(id))

	return (
		<div style={{ backgroundColor: '#fff' }}>
			<Adminsidebar />
			<EditGrant data={data} id={id} />
		</div>
	)
}

export default AdminEditGrant
