import Adminsidebar from "./dashboardParts/adminsidebar";
import ViewSubmission from "./dashboardParts/viewSubmissions";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAdminSingleCompliance } from "../handlers/complianceHandlers";
import { useParams } from "react-router-dom";

function AdminViewSubmission() {
  const { id } = useParams();
  const { data, isLoading } = useQuery(`admin-compliance-${id}`, () =>
    getAdminSingleCompliance(id)
  );

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Adminsidebar />
      <ViewSubmission data={data} isLoading={isLoading} />
    </div>
  );
}

export default AdminViewSubmission;
