import HeaderLogin from '../component/navbarLogin'
import ResetP from '../component/resetP'

function ResetPassword() {
  return (
    <div>
      <HeaderLogin />
      <ResetP/>
    </div>
  )
}

export default ResetPassword
