import React from 'react'
import avatar from '../../img/user-img.svg'
// import p from '../../img/public.svg'
// import manage from '../../img/manage.svg'
// import uploaded from '../../img/items.png'
import { Link } from 'react-router-dom'
// import Col from 'react-bootstrap/Col'
// import Form from 'react-bootstrap/Form'
// import Row from 'react-bootstrap/Row'
// import InputGroup from 'react-bootstrap/InputGroup'
// import mis from '../../img/repeat.svg'
import HeaderDropdownMenu from '../../component/HeaderDropdownMenu'
// import Select from 'react-select'
import { useQuery } from 'react-query'
import { getOrgProfile } from '../../handlers/organizationHandler'
import EditListingInfo from './editSettings/editListingInfo'
import EditBankInfo from './editSettings/editBankInfo'
import EditBusinessDoc from './editSettings/editBusinessDoc'

function EditProfile() {
	const { data: profileData, isLoading } = useQuery('cso-profile', () =>
		getOrgProfile()
	)
	return (
		<section>
			<div className="content overview">
				<div className="row">
					<div className="col-md-8 mb-2 back">
						<Link to="/cso/settings">
							<i className="bi bi-arrow-left me-2"></i> Back
						</Link>
					</div>
					<div className="col-md-4 text-end">
						<div className="dropdown">
							<Link
								to="#"
								className="dropdown-toggle"
								role="button"
								data-bs-toggle="dropdown"
							>
								{profileData?.organization?.orgfile?.org_logo ? (
									<img
										src={profileData?.organization?.orgfile?.org_logo}
										alt="avatar"
										className="profile-topbar-avi"
									/>
								) : (
									<img src={avatar} alt="avi" className="img-fluid" />
								)}
							</Link>
							<HeaderDropdownMenu />
						</div>
					</div>
				</div>
				<div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
					<hr />
				</div>
				<div className="row">
					<div className="col-md-7 mt-4">
						<h4>Edit Organization Profile</h4>
					</div>
					<div className="col-md-5 text-end mt-3">
						<Link to="" className="btn btn-success w-50 login-btn mb-3">
							{' '}
							Update Profile
						</Link>
					</div>
				</div>
				<div className="row listings-tog mt-3">
					<div className="col">
						<ul className="nav nav-tabs tog" id="myTab" role="tablist">
							<li className="nav-item" role="presentation">
								<button
									className="nav-link active tog-btn"
									id="home-tab"
									data-bs-toggle="tab"
									data-bs-target="#home-tab-pane"
									type="button"
									role="tab"
									aria-controls="home-tab-pane"
									aria-selected="true"
								>
									Listing Information
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className="nav-link"
									id="profile-tab"
									data-bs-toggle="tab"
									data-bs-target="#profile-tab-pane"
									type="button"
									role="tab"
									aria-controls="profile-tab-pane"
									aria-selected="false"
								>
									Business Documentation
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className="nav-link"
									id="contact-tab"
									data-bs-toggle="tab"
									data-bs-target="#contact-tab-pane"
									type="button"
									role="tab"
									aria-controls="contact-tab-pane"
									aria-selected="false"
								>
									Additional Information
								</button>
							</li>
						</ul>
					</div>
					<div className="tab-content" id="myTabContent">
						<div
							className="tab-pane fade show active"
							id="home-tab-pane"
							role="tabpanel"
							aria-labelledby="home-tab"
							tabIndex="0"
						>
							<EditListingInfo data={profileData} />
						</div>
						<div
							className="tab-pane fade"
							id="profile-tab-pane"
							role="tabpanel"
							aria-labelledby="profile-tab"
							tabIndex="0"
						>
							<EditBusinessDoc data={profileData} />
						</div>
						<div
							className="tab-pane fade"
							id="contact-tab-pane"
							role="tabpanel"
							aria-labelledby="contact-tab"
							tabIndex="0"
						>
							<EditBankInfo data={profileData} />
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default EditProfile
