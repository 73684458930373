import Adminsidebar from './dashboardParts/adminsidebar';
import SingleComplianceView from './dashboardParts/singleComplianceView';


function SingleQuestion() {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Adminsidebar/>
      <SingleComplianceView/>
    </div>
  )
}

export default SingleQuestion;
