import React from 'react'
import { ToastContainer, toast as tast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ucfirst } from '../utlis/helper'

const toastConfig = {
	hideProgressBar: true,
	duration: 1000,
	pauseOnHover: false,
}

export const Success = ({ message }) => (
	<div>
		<span style={{ fontSize: 14 }}>{ucfirst(message)}</span>
	</div>
)

export const Error = ({ message }) => (
	<div>
		<span style={{ fontSize: 14 }}>{ucfirst(message)}</span>
	</div>
)

export const Info = ({ message }) => (
	<div>
		<span style={{ fontSize: 14 }}>{ucfirst(message)}</span>
	</div>
)

export const Warn = ({ message }) => (
	<div>
		<span style={{ fontSize: 14 }}>{ucfirst(message)}</span>
	</div>
)

const Toast = () => {
	return (
		<ToastContainer
			autoClose={3000}
			pauseOnHover={true}
			position="top-right"
			transition={Slide}
		/>
	)
}

export const toast = {
	success: (message, otherConfig) => {
		tast.success(<Success message={message} />, {
			...toastConfig,
			...otherConfig,
		})
	},
	info: (message, otherConfig) => {
		tast.info(<Info message={message} />, {
			...toastConfig,
			...otherConfig,
		})
	},
	error: (message, otherConfig) => {
		tast.error(<Error message={message} />, {
			...toastConfig,
			...otherConfig,
		})
	},
	warn: (message, otherConfig) => {
		tast.warn(<Warn message={message} />, {
			...toastConfig,
			...otherConfig,
		})
	},
}

export default Toast
