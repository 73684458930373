import React from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup'
// import google from '../img/google.svg'
import { Link, useNavigate } from 'react-router-dom'
import { Form as FormikForm, Formik } from 'formik'
import { useMutation, useQuery } from 'react-query'
import { registerCSO } from '../handlers/authhandler'
import { toast } from './toast'
import AppButton from './Button'
import Input from './Input'
import Select from './Select'
import { registerValidationSchema } from '../utlis/validation'
import { AuthContext } from '../context/AuthContext'
import { saveToLocalStorage, encryptData } from '../utlis/helper'
import { getAllCategories } from '../handlers/categoryHandler'

function Register() {
	const navigate = useNavigate()
	const { handleUpdateAuthenticated, handleUpdateInfo } =
		React.useContext(AuthContext)

	const { data: categoryData, isCategoryDataLoading } = useQuery(
		'category',
		() => getAllCategories()
	)

	const { mutate: registerUserMutation, isLoading: isLoading } = useMutation(
		registerCSO,
		{
			onSuccess: async (res) => {
				toast.success('Registration successful')
				handleUpdateInfo(res)
				const encryptedUserInfo = encryptData(res)
				saveToLocalStorage('userInfo', encryptedUserInfo)
				if (res?.email_verified_at) {
					handleUpdateAuthenticated(true)
					navigate('/onboarding')
				} else {
					navigate('/otp')
				}
			},
			onError: (e) => {
				toast.error(e)
			},
		}
	)

	const handleSubmit = (values) => {
		registerUserMutation(values)
	}

	return (
		<section className="register-section">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-5">
						<h2 className="text-center">Welcome to CSOATLAS</h2>
						<h6 className="mt-3 text-center">Let’s get to know you</h6>
						<Formik
							initialValues={{
								firstname: '',
								lastname: '',
								organization: '',
								email: '',
								phone: '',
								category_id: '',
								password: '',
								password_confirmation: '',
							}}
							onSubmit={(values) => handleSubmit(values)}
							validationSchema={registerValidationSchema}
						>
							{({
								values,
								handleChange,
								handleSubmit,
								handleBlur,
								setFieldValue,
								errors,
							}) => (
								<FormikForm className="mt-5" onSubmit={handleSubmit}>
									<Row className="mb-3">
										<Col>
											<Input
												name="firstname"
												type="text"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.firstname}
												label="First Name"
											/>
										</Col>
										<Col>
											<Input
												name="lastname"
												type="text"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.lastname}
												label="Last Name"
											/>
										</Col>
									</Row>

									<Form.Group className="mb-4">
										<Input
											type="text"
											name="organization"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.organization}
											label="Name of Organization"
										/>
									</Form.Group>

									<Form.Group className="mb-4">
										<Input
											type="email"
											name="email"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.email}
											label="Email address"
										/>
									</Form.Group>

									<InputGroup className="mb-3">
										<Input
											type="tel"
											name="phone"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.phone}
										/>
									</InputGroup>

									<Form.Group className="mb-3">
										<Select
											name="category_id"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.category_id}
											label="Select Category"
											placeholder={'Select Category'}
											options={categoryData?.map((item) => ({
												value: item.id,
												label: item.category,
											}))}
										/>
									</Form.Group>

									<Form.Group className="mb-3">
										<Input
											type="password"
											name="password"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.password}
											label="Create a Password"
											bottomText="Must contain 8+ characters with at least 1 number"
										/>
									</Form.Group>

									<Form.Group className="mb-3">
										<Input
											type="password"
											name="password_confirmation"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.password_confirmation}
											label="Confirm Password"
										/>
									</Form.Group>

									<div className="form-check my-3">
										<input
											className="form-check-input"
											type="checkbox"
											required={true}
										/>
										<label className="form-check-label agree">
											I have read and I agree to the{' '}
											<Link to="/privacy" style={{ color: '#2EA275' }}>Privacy Policy</Link>{' '}
											and{' '}
											<span style={{ color: '#2EA275' }}>
												Terms of Conditions
											</span>
										</label>
									</div>

									<AppButton
										className="w-100"
										type="submit"
										loading={isLoading}
									>
										Register
									</AppButton>

									<div className="text-center">
										<small className="agree">
											If you already have an account, you can{' '}
											<Link to="/login" style={{ color: '#2EA275' }}>
												Log in here
											</Link>
										</small>
									</div>
								</FormikForm>
							)}
						</Formik>
						{/* <div className="separator mt-4">
							<div className="line"></div>
							<h6>OR</h6>
							<div className="line"></div>
						</div>
						<div className="d-flex flex-row mt-4">
							<img src={google} alt="google" className="img-fluid" />
							<Button variant="primary" className="w-100 google-sign">
								Register with Google <i className="bi bi-arrow-right ms-3"></i>
							</Button>
						</div> */}
					</div>
				</div>
			</div>
		</section>
	)
}

export default Register
