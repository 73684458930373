import React from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import ReactPaginate from "react-paginate";

function AdminOpportunitiesBody({
  grants,
  grant_aggregate,
  adminGrants,
  handlePaginateChange,
  currPage,
  handleUserPaginateChange,
  userCurrPage,
  allTotal,
  userTotal,
  handleSearch,
  handleAuthorSearch,
  handleUpdateStatus,
  handleAuthorUpdateStatus,
  status,
  authorStatus,
}) {
  const inputRef = React.useRef();
  const authorInputRef = React.useRef();

  const handleClearInput = () => {
    inputRef.current.value = "";
    handleSearch({ target: { value: "" } });
    handleUpdateStatus({ target: { value: "" } });
  };
  const handleClearAuthorInput = () => {
    authorInputRef.current.value = "";
    handleAuthorSearch({ target: { value: "" } });
    handleAuthorUpdateStatus({ target: { value: "" } });
  };

  return (
    <section>
      <div className="content overview">
        <div className="row">
          <div className="col-md-8">
            <h4>Grants Page</h4>
            <p>List funding opportunities, grants and more</p>
          </div>
          <div className="col-md-4 text-end">
            <Link
              to="/admin/add-grant"
              className="btn btn-success w-50 login-btn mb-3"
            >
              <i className="bi bi-plus me-3 fs-5"></i> Create Grant
            </Link>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4 mb-3">
            <div className="bg-listed" style={{ background: "#F6F7FE" }}>
              <h6>Total No of Grants</h6>
              <h5>{grant_aggregate?.total_grants}</h5>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="bg-listed" style={{ backgroundColor: "#FCF9EE" }}>
              <h6>Total Number of Applications</h6>
              <h5>{grant_aggregate?.applicants}</h5>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="bg-listed" style={{ background: "#EEFCF2" }}>
              <h6>Total Number of Grant Recipients</h6>
              <h5>{grant_aggregate?.recipients}</h5>
            </div>
          </div>
        </div>
        <div className="row listings-tog mt-3">
          <div className="col">
            <ul className="nav nav-tabs tog" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active tog-btn"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected="true"
                >
                  Grant Opportunities
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane"
                  aria-selected="false"
                >
                  My Grants
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home-tab-pane"
                role="tabpanel"
                aria-labelledby="home-tab"
                tabIndex="0"
              >
                <div className="p-4 group-cards gray mt-4">
                  <form className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Search Grants</label>
                        <div className="search-message">
                          <i className="bi bi-search"></i>
                          <input
                            type="text"
                            className="form-control form-input"
                            placeholder="Search Grants"
                            onChange={handleSearch}
                            ref={inputRef}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Grant Status</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          onChange={handleUpdateStatus}
                          value={status}
                        >
                          <option value="">All</option>
                          <option value="approved">Approved</option>
                          <option value="rejected">Rejected</option>
                          <option value="pending">Pending</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-3 text-end align-content-center">
                      <button
                        type="button"
                        className="btn btn-primary bg-white text-muted btn-claer px-3"
                        onClick={handleClearInput}
                      >
                        Clear all
                      </button>
                    </div>
                  </form>
                </div>
                <div className="bg-white group-cards mt-4">
                  <div style={{ padding: "20px" }}>
                    <h5>Grant Opportunities </h5>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped custom-class">
                      <thead>
                        <tr>
                          <th scope="col">Date Uploaded</th>
                          <th scope="col">Grant Title</th>
                          <th scope="col">Grant Size</th>
                          <th scope="col">Author</th>
                          <th scope="col">Grant Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {grants?.map((grant, idx) => (
                          <tr key={idx}>
                            <td className="w-25">
                              {dayjs(grant?.created_at).format("DD MMM, YYYY")}
                            </td>
                            <td className="w-2 5">{grant?.title}</td>
                            <td className="w-25">${grant?.size} </td>
                            <td className="w-25">{grant?.author}</td>
                            <td className="w-75">
                              {grant?.status === "approved" ? (
                                <span className="badgers">
                                  <i className="bi bi-check me-2"></i>Approved
                                </span>
                              ) : grant?.status === "pending" ? (
                                <span className="pendg">
                                  <i className="bi bi-circle-fill me-2"></i>
                                  Pending
                                </span>
                              ) : (
                                <span className="rejectedbg">
                                  <i className="bi bi-circle-fill me-2"></i>
                                  Rejected
                                </span>
                              )}
                            </td>
                            <td>
                              <div className="dropdown">
                                <i
                                  className="bi bi-three-dots-vertical actions"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                ></i>
                                <ul className="dropdown-menu listing-drop">
                                  <li>
                                    <Link
                                      className="dropdown-item listing-item"
                                      to={`/admin/opportunities/${grant?.id}`}
                                    >
                                      <i className="bi bi-eye me-2"></i> View
                                      Details
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className="dropdown-item listing-item"
                                      to={`/admin/opportunities/${grant?.id}/update-status`}
                                    >
                                      <i className="bi bi-pencil me-2"></i>{" "}
                                      Update Status
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {grants?.length > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={allTotal / 10 || 1}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handlePaginateChange}
                    containerClassName={"paginate"}
                    activeClassName={"active"}
                    forcePage={currPage - 1}
                  />
                )}
              </div>
              <div
                className="tab-pane fade"
                id="profile-tab-pane"
                role="tabpanel"
                aria-labelledby="profile-tab"
                tabIndex="0"
              >
                <div className="p-4 group-cards gray mt-4">
                  <form className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label">
                          Search Opportunities
                        </label>
                        <div className="search-message">
                          <i className="bi bi-search"></i>
                          <input
                            type="text"
                            className="form-control form-input"
                            placeholder="Search Opportunities"
                            onChange={handleAuthorSearch}
                            ref={authorInputRef}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Grant Status</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          onChange={handleAuthorUpdateStatus}
                          value={authorStatus}
                        >
                          <option value="">All</option>
                          <option value="approved">Approved</option>
                          <option value="rejected">Rejected</option>
                          <option value="pending">Pending</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-3 text-end align-content-center">
                      <button
                        type="button"
                        className="btn btn-primary bg-white text-muted btn-claer px-3"
                        onClick={handleClearAuthorInput}
                      >
                        Clear all
                      </button>
                    </div>
                  </form>
                </div>
                <div className="bg-white group-cards mt-4">
                  <div style={{ padding: "20px" }}>
                    <h5>Grant Opportunities </h5>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped custom-class">
                      <thead>
                        <tr>
                          <th scope="col">Date Uploaded</th>
                          <th scope="col">Grant Title</th>
                          <th scope="col">Grant Size</th>
                          <th scope="col">Author</th>
                          <th scope="col">Grant Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {adminGrants?.map((grant, idx) => (
                          <tr key={idx}>
                            <td className="w-25">
                              {dayjs(grant?.created_at).format("DD MMM, YYYY")}
                            </td>
                            <td className="w-2 5">{grant?.title}</td>
                            <td className="w-25">${grant?.size} </td>
                            <td className="w-25">{grant?.author}</td>
                            <td className="w-75">
                              {grant?.status === "approved" ? (
                                <span className="mx-3 badgers">
                                  <i className="bi bi-check me-2"></i>Approved
                                </span>
                              ) : (
                                <span className="mx-3 pendg">
                                  <i className="bi bi-circle-fill me-2"></i>
                                  Pending
                                </span>
                              )}
                            </td>
                            <td>
                              <div className="dropdown">
                                <i
                                  className="bi bi-three-dots-vertical actions"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                ></i>
                                <ul className="dropdown-menu listing-drop">
                                  <li>
                                    <Link
                                      className="dropdown-item listing-item"
                                      to={`/admin/opportunities/${grant?.id}`}
                                    >
                                      <i className="bi bi-eye me-2"></i> View
                                      Details
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className="dropdown-item listing-item"
                                      to={`/admin/opportunities/${grant?.id}/edit`}
                                    >
                                      <i className="bi bi-eye me-2"></i> Edit
                                      Details
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {adminGrants?.length > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={userTotal / 10 || 1}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handleUserPaginateChange}
                    containerClassName={"paginate"}
                    activeClassName={"active"}
                    forcePage={userCurrPage - 1}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdminOpportunitiesBody;
