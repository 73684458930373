import Sidebar from "./dashboardParts/sideBar";
import ViewFeeds from "./dashboardParts/singleF";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getSingleFeed } from "../handlers/newsHandler";
import { useParams } from "react-router-dom";

function SingleFeed() {
  const { id } = useParams();
  const { data: feedData, isLoading } = useQuery(["feeds", id], () =>
    getSingleFeed(id)
  );
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Sidebar />
      <ViewFeeds data={feedData} id={id} isLoading={isLoading} />
    </div>
  );
}

export default SingleFeed;
