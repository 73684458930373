import HeaderLogin from "../component/navbarLogin";
import Verified from "../component/verified";

function EmailVerified() {
  return (
    <div>
      <HeaderLogin/>
      <Verified/>
    </div>
  );
}

export default EmailVerified;
