import AccountCreate from '../component/accountCreate'
import DashboardHeader from '../component/DashboardNavbar'

function CreateAccount() {
	return (
		<div>
			<DashboardHeader />
			<AccountCreate />
		</div>
	)
}

export default CreateAccount
