import React from "react";
import NewsFeeds from "./dashboardParts/newsFeed";
import Sidebar from "./dashboardParts/sideBar";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllFeed } from "../handlers/newsHandler";
import NewsFeedEmpty from "./dashboardParts/newsFeedE";

function Feeds() {
  const [currPage, setCurrPage] = React.useState(1);
  const [searchName, setSearchName] = React.useState("");
  const timeout = React.useRef();
  const { data: feedData, isLoading } = useQuery(
    ["feeds", currPage, searchName],
    () => getAllFeed({ page: currPage, page_size: 10, name: searchName })
  );
  const handlePaginateChange = async (page) => {
    setCurrPage(page.selected + 1);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setSearchName(value);
    }, 600);
  };
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Sidebar />
      <NewsFeeds
        data={feedData}
        handlePaginateChange={handlePaginateChange}
        currPage={currPage}
        handleSearch={handleSearch}
        isLoading={isLoading}
      />
    </div>
  );
}

export default Feeds;
