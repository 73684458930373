import React from "react";
import avatar from "../../img/user-img.svg";
import mini from "../../img/mini.svg";
import dt from "../../img/dt.svg";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import MISHeaderDropdownMenu from "./MISHeaderDropdownMenu";
import ReactPaginate from "react-paginate";
import NewsFeedEmpty from "../dashboardParts/newsFeedE";
import { AuthContext } from "../../context/AuthContext";
import { Oval } from "react-loader-spinner";

function NewsFeeds({
  data,
  handlePaginateChange,
  currPage,
  handleSearch,
  isLoading,
}) {
  const { userInfo } = React.useContext(AuthContext);
  return (
    <section>
      <div className="content overview">
        <div className="row justify-content-between">
          <div className="col-md-5 mb-2">
            <div className="form-group has-search">
              <span className="bi bi-search form-control-feedback"></span>
              <input
                type="text"
                className="form-control"
                placeholder="Search by Name, Author, Organization"
                onChange={handleSearch}
              />
            </div>
          </div>
          <div className="col-md-3 text-end">
            <div className="d-flex justify-content-end">
              <p className="bg-lt me-5 mt-1">
                <i className="bi bi-circle-fill fs-6 me-2"></i> MIS
              </p>
              <div className="dropdown">
                <Link
                  to="#"
                  className="dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  {userInfo?.organization?.orgfile?.org_logo ? (
                    <img
                      src={userInfo?.organization?.orgfile?.org_logo}
                      alt="avatar"
                      className="profile-topbar-avi"
                    />
                  ) : (
                    <img src={avatar} alt="avi" className="img-fluid" />
                  )}
                </Link>
                <MISHeaderDropdownMenu />
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
          <hr />
        </div>
        <div className="row">
          <div className="col-md-8 mt-3">
            <h4>News Feed</h4>
          </div>
          <div className="col-md-4 text-end mt-3">
            <Link
              to="/mis/add-news"
              className="btn btn-success w-50 login-btn mb-3"
            >
              <i className="bi bi-plus me-3 fs-5"></i> Add News
            </Link>
          </div>
        </div>

        {/* <div className="row mt-3 news-f">
					<div className="col-md-6">
						<img
							src={data?.data && data?.data[0]?.image}
							alt="newa-img"
							className="img-fluid w-100"
							style={{ width: 100, height: '100%', resizeMode: 'cover' }}
						/>
					</div>
					<div className="col-md-6">
						<Link to={`/mis/feeds/${data?.data && data?.data[0]?.id}`}>
							{data?.data && data?.data[0]?.title?.length > 100
								? data?.data[0]?.title?.substring(9, 100)
								: data?.data && data?.data[0]?.title}
						</Link>
						<h6 className="mt-4">By {data?.data && data?.data[0]?.author}</h6>
						<p>
							{data?.data &&
								dayjs(data?.data[0]?.created_at).format('MMMM DD, YYYY')}
						</p>
					</div>
				</div>
				<hr /> */}

        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
            }}
          >
            <Oval wrapperClass="ml-2" height={40} width={40} />
          </div>
        ) : data?.data?.length > 0 ? (
          <div className="row">
            {data?.data?.map((item, idx) => (
              <div className="col-md-6" key={idx}>
                <Link to={`/mis/feeds/${item?.id}`}>
                  <div className="d-flex flex-row">
                    <img
                      src={item.image || mini}
                      alt="other post"
                      className="img-fluid me-3 news-feed-img"
                      style={{
                        width: 120,
                        height: "100px",
                        resizeMode: "cover",
                        objectFit: "cover",
                        objectPosition: "top",
                      }}
                    />
                    <div className="other-post">
                      <h4>
                        {item?.title?.length > 100
                          ? item?.title?.substring(9, 100)
                          : item?.title}
                      </h4>
                      <h3 className="my-3">By {item?.author}</h3>
                      <h6>{dayjs(item?.created_at).format("MMMM DD, YYYY")}</h6>
                    </div>
                  </div>
                </Link>
                <hr />
              </div>
            ))}
          </div>
        ) : (
          <NewsFeedEmpty />
        )}

        {data?.data?.length > 0 && (
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={data?.total / 10 || 1}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={handlePaginateChange}
            containerClassName={"paginate"}
            activeClassName={"active"}
            forcePage={currPage - 1}
          />
        )}
      </div>
    </section>
  );
}

export default NewsFeeds;
