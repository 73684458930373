import React from 'react'
import avatar from '../img/user-img.svg'
import p from '../img/public.svg'
import manage from '../img/manage.svg'
import log from '../img/logout.svg'
// import sr from '../img/sr.svg'
import mis from '../img/repeat.svg'
// import header from '../img/boi.svg'
// import archived from '../img/archived.svg'
import { AuthContext } from '../context/AuthContext'
import { useNavigate, Link } from 'react-router-dom'
import { removeLocalStorageItem } from '../utlis/helper'

const HeaderDropdownMenu = () => {
	const navigate = useNavigate()
	const { userInfo, handleUpdateInfo, handleUpdateAuthenticated } =
		React.useContext(AuthContext)
	const handleLogout = () => {
		handleUpdateAuthenticated(false)
		handleUpdateInfo(null)
		removeLocalStorageItem('userInfo')
		navigate('/login')
	}
	return (
		<ul className="dropdown-menu userdrop">
			{/* <Link className="d-flex flex-row">
				<img src={avatar} alt="status" className="img-fluid me-3" />
				<h6>African Society of Human Genetics</h6>
			</Link> */}
			<Link to="/mis/feeds">
				<h5>
					<img src={mis} alt="public" className="img-fluid me-3" /> Switch to
					MIS
				</h5>
			</Link>
			<hr />
			<Link
				to={`/single-directory/${userInfo?.organization?.id}`}
				target="_blank"
			>
				<h5>
					<img src={p} alt="public" className="img-fluid me-3" /> View public
					profile
				</h5>
			</Link>
			<hr />
			<Link to="/cso/settings" className="d-flex flex-row">
				<img src={manage} alt="status" className="img-fluid me-3" />
				<div>
					<h5>Manage Account</h5>
					<p>Control settings related to your account.</p>
				</div>
			</Link>
			<hr />
			<div onClick={handleLogout} style={{ cursor: 'pointer' }}>
				<h5>
					<img src={log} alt="public" className="img-fluid me-3" /> Log Out
				</h5>
			</div>
		</ul>
	)
}

export default HeaderDropdownMenu
