import React from 'react'
import avatar from '../../img/user-img.svg'
import add from '../../img/addp.svg'
import MISHeaderDropdownMenu from './MISHeaderDropdownMenu'

import { Link } from 'react-router-dom'

function NewsFeedEmpty() {
	return (
		<section>
			<div className="row justify-content-center empty">
				<div className="col-md-6 text-center">
					<img src={add} alt="projects" className="img-fluid" />
					<h3 className="my-4">No update yet</h3>
					<p className="mb-4">
						Be the first to share a story so others can see updates in their
						newsfeed.
					</p>
					<Link
						to="/mis/add-news"
						className="btn btn-success w-25 login-btn mb-3"
					>
						{' '}
						Add News
					</Link>
				</div>
			</div>
		</section>
	)
}

export default NewsFeedEmpty
