import axiosClient from "../utlis/axiosClient";

export const loginUser = async (data) => {
  try {
    const response = await axiosClient.post(`/v1/signin`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    console.log({ error });
    if (error?.response?.data?.reason) {
      throw error?.response?.data?.reason;
    }
    throw "An error occured. Please try again.";
  }
};

export const registerCSO = async (data) => {
  try {
    const response = await axiosClient.post(`/v1/register`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.errors?.email) {
      throw error?.response?.data?.errors.email[0];
    }
    if (error?.response?.data?.errors?.phone) {
      throw error?.response?.data?.errors.phone[0];
    }
    if (error?.response?.data?.errors?.password) {
      throw error?.response?.data?.errors.password[0];
    }
    if (error?.response?.data?.errors?.organization) {
      throw error?.response?.data?.errors.organization[0];
    }
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const verifyOTP = async (data) => {
  try {
    const response = await axiosClient.post(`/v1/otp`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const forgotPassword = async (data) => {
  try {
    const response = await axiosClient.post(`/v1/forgot`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    if (error?.response?.data?.reason) {
      throw error?.response?.data?.reason;
    }
    throw "An error occured. Please try again.";
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await axiosClient.post(`/v1/reset`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    if (error?.response?.data?.reason) {
      throw error?.response?.data?.reason;
    }
    throw "An error occured. Please try again.";
  }
};

export const changePassword = async (data) => {
  try {
    const response = await axiosClient.post(`/v1/update-password`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    if (error?.response?.data?.reason) {
      throw error?.response?.data?.reason;
    }
    throw "An error occured. Please try again.";
  }
};
