import React from 'react'
import Container from 'react-bootstrap/Container'
// import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import logo from '../img/atlas-logo.jpeg'
import avi from '../img/avi.svg'
import { AuthContext } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import {
	getLocalStorageItem,
	decryptData,
	removeLocalStorageItem,
} from '../utlis/helper'

function DashboardHeader() {
	const navigate = useNavigate()
	const { userInfo, handleUpdateInfo, handleUpdateAuthenticated } =
		React.useContext(AuthContext)

	React.useEffect(() => {
		const encryptedUserInfo = getLocalStorageItem('userInfo')
		if (!encryptedUserInfo) {
			navigate('/login', {
				replace: true,
			})
		}
	}, [])

	const handleLogout = () => {
		handleUpdateAuthenticated(false)
		handleUpdateInfo(null)
		removeLocalStorageItem('userInfo')
		navigate('/login')
	}
	return (
		<Navbar bg="light" expand="lg" className="bg-white p-4 border-bottom">
			<Container>
				<Navbar.Brand href="/">
					<img
						src={logo}
						alt="logo"
						className="img-fluid"
						style={{ width: 150, height: 'auto' }}
					/>
				</Navbar.Brand>
				<Navbar.Toggle />
				{userInfo && (
					<Navbar.Collapse className="justify-content-end">
						<Navbar.Text>
							<div className="user-name-badge">
								<p className="user-name-badge-text">{`${userInfo?.firstname?.charAt(
									0
								)} ${userInfo?.lastname?.charAt(0)}`}</p>
							</div>
						</Navbar.Text>
						<NavDropdown title="" id="basic-nav-dropdown">
							<NavDropdown.Item onClick={handleLogout}>
								<i className="bi bi-box-arrow-in-right me-2"></i>Log out
							</NavDropdown.Item>
						</NavDropdown>
					</Navbar.Collapse>
				)}
			</Container>
		</Navbar>
	)
}

export default DashboardHeader
