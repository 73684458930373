import CSOSidebar from "./dashboardParts/csoSidebar";
import ProfileSettings from "./dashboardParts/profileSettings";


function Settings() {
  return (
    <div style={{backgroundColor: "#fff"}}>
      <CSOSidebar/>
      <ProfileSettings/>
    </div>
  );
}

export default Settings;
