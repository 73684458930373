import ListingAdmin from "./dashboardParts/adminlistingsBody";
import Adminsidebar from "./dashboardParts/adminsidebar";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllCSOs } from "../handlers/adminHandler";
import { getOverviewData } from "../handlers/adminHandler";

function AdminListing() {
  const { data: csoData, isLoading: isDataLoading } = useQuery(
    "admin-all-csos",
    () => getAllCSOs()
  );
  const { data: overviewData, isLoading } = useQuery(["admin-overview"], () =>
    getOverviewData()
  );
  return (
    <div style={{ backgroundColor: "#FAFAFA" }}>
      <Adminsidebar />
      <ListingAdmin
        data={csoData}
        isDataLoading={isDataLoading}
        overviewData={overviewData}
      />
    </div>
  );
}

export default AdminListing;
