import CreateArticles from './dashboardParts/createArticle';
import Sidebar from './dashboardParts/sideBar'

function AddArticle() {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Sidebar/>
      <CreateArticles/>
    </div>
  )
}

export default AddArticle;
