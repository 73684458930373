import React from 'react'
import avatar from '../../img/user-img.svg'
import editimg from '../../img/edit-img.svg'
import recycle from '../../img/recycle.svg'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import MISHeaderDropdownMenu from './MISHeaderDropdownMenu'
import { AuthContext } from '../../context/AuthContext'

function MISProfileSettings() {
	const { userInfo } = React.useContext(AuthContext)
	return (
		<section>
			<div className="content overview">
				<div className="row">
					<div className="col-md-5 mb-2"></div>
					<div className="col-md-7 text-end">
						<div className="dropdown">
							<Link
								to="#"
								className="dropdown-toggle"
								role="button"
								data-bs-toggle="dropdown"
							>
								{userInfo?.organization?.orgfile?.org_logo ? (
									<img
										src={userInfo?.organization?.orgfile?.org_logo}
										alt="avatar"
										className="profile-topbar-avi"
									/>
								) : (
									<img src={avatar} alt="avi" className="img-fluid" />
								)}
							</Link>
							<MISHeaderDropdownMenu />
						</div>
					</div>
				</div>
				<div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
					<hr />
				</div>
				<div className="row">
					<div className="col-md-7">
						<h4>Settings</h4>
					</div>
				</div>
				<div className="row listings-tog mt-3">
					<div className="col">
						<ul className="nav nav-tabs tog" id="myTab" role="tablist">
							<li className="nav-item" role="presentation">
								<button
									className="nav-link active"
									id="profile-tab"
									data-bs-toggle="tab"
									data-bs-target="#profile-tab-pane"
									type="button"
									role="tab"
									aria-controls="profile-tab-pane"
									aria-selected="false"
								>
									Login & Security
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className="nav-link tog-btn"
									id="home-tab"
									data-bs-toggle="tab"
									data-bs-target="#home-tab-pane"
									type="button"
									role="tab"
									aria-controls="home-tab-pane"
									aria-selected="true"
								>
									Organization’s Profile
								</button>
							</li>
						</ul>
						<div className="tab-content" id="myTabContent">
							<div
								className="tab-pane fade"
								id="home-tab-pane"
								role="tabpanel"
								aria-labelledby="home-tab"
								tabIndex="0"
							>
								<div className="row mt-5">
									<div className="col-md-7">
										<img src={editimg} alt="avatar" className="img-fluid" />
									</div>
									<div className="col-md-5 text-end mt-3">
										<Link
											to="/cso/edit-profile"
											className="btn btn-success w-50 login-btn mb-3"
										>
											{' '}
											Edit Profile
										</Link>
									</div>
								</div>
								<div className="bg-white group-cards mt-5 p-4">
									<div className="row statuses ">
										<div className="col-md-6">
											<p>
												<i className="bi bi-house me-2"></i> Organization's Name
											</p>
											<h6>-</h6>
										</div>
										<div className="col-md-6">
											<p>
												<i className="bi bi-calendar me-2"></i> Year of
												Operation
											</p>
											<h6>-</h6>
										</div>
									</div>
									<hr />
									<div className="row my-5 statuses">
										<div className="col-md-6">
											<p>
												<i className="bi bi-envelope me-2"></i> Email Address
											</p>
											<Link>-</Link>
										</div>
										<div className="col-md-6">
											<p>
												<i className="bi bi-telephone me-2"></i> Phone Name
											</p>
											<Link>-</Link>
										</div>
									</div>
									<hr />
									<div className="row my-5 statuses">
										<div className="col-md-6">
											<p>
												<i className="bi bi-briefcase me-2"></i> Thematic Area
											</p>
											<h6>-</h6>
										</div>
										<div className="col-md-6">
											<p>
												<i className="bi bi-gear me-2"></i> Services Rendered
											</p>
											<h6>-</h6>
										</div>
									</div>
									<hr />
									<div className="row my-5 statuses">
										<div className="col-md-6">
											<p>
												<i className="bi bi-geo-alt me-2"></i> Organization's
												Address
											</p>
											<h6>-</h6>
										</div>
										<div className="col-md-6">
											<div className="row">
												<div className="col">
													<p>City</p>
													<h6>-</h6>
												</div>
												<div className="col">
													<p>State</p>
													<h6>-</h6>
												</div>
											</div>
										</div>
									</div>
									<hr />
									<div className="row my-5 statuses">
										<div className="col-md-6">
											<p>
												<i className="bi bi-bank me-2"></i> Bank Name
											</p>
											<h6>-</h6>
										</div>
										<div className="col-md-6">
											<div className="row">
												<div className="col">
													<p>Beneficiary Name</p>
													<h6>-</h6>
												</div>
												<div className="col">
													<p>Account</p>
													<h6>-</h6>
												</div>
											</div>
										</div>
									</div>
									<hr />
									<div className="row my-5 statuses">
										<div className="col-md-6">
											<div className="row">
												<div className="col">
													<p>Instagram Profile</p>
													<h6>-</h6>
												</div>
												<div className="col">
													<p>Facebook Profile</p>
													<h6>-</h6>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="row">
												<div className="col">
													<p>Linkedin Profile</p>
													<h6>-</h6>
												</div>
												<div className="col">
													<p>Twitter Profile</p>
													<h6>-</h6>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								className="tab-pane fade show active"
								id="profile-tab-pane"
								role="tabpanel"
								aria-labelledby="profile-tab"
								tabIndex="0"
							>
								<div className="row mt-4 acc-pref">
									<div className="col-md-6">
										<h5>Change your password</h5>
										<p>
											Please enter your current password to change your password
										</p>
										<Form.Group className="mb-3" controlId="formBasicEmail">
											<Form.Label>Current Password</Form.Label>
											<Form.Control type="password" />
										</Form.Group>
										<Form.Group className="mb-3" controlId="formBasicEmail">
											<Form.Label>New Password</Form.Label>
											<Form.Control type="password" />
										</Form.Group>
										<Form.Group className="mb-3" controlId="formBasicEmail">
											<Form.Label>Confirm New Password</Form.Label>
											<Form.Control type="password" />
										</Form.Group>
										<button
											type="button"
											className="btn btn-success gray-btn px-5"
										>
											Change Password
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Reject Modal */}
			<div
				className="modal fade"
				id="delete-acc"
				tabIndex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content px-3">
						<div className="modal-header">
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
							<div className="row listing-modal">
								<div className="col">
									<img
										src={recycle}
										alt="update"
										className="img-fluid mb-3"
										style={{ marginTop: '-30px' }}
									/>
									<h4>Delete Account</h4>
									<p>
										Are you sure you want to delete your account. You lose
										access to all MIS services, and we permanently delete your
										personal data. You can cancel the deletion within 14 days.
									</p>
									<form>
										<div className="d-flex flex-row">
											<Link
												to=""
												className="btn btn-success bg-white w-100 login-btn mt-3 me-3"
											>
												Cancel
											</Link>
											<Link
												to=""
												className="btn btn-success w-100 login-btn mt-3"
											>
												Confirm
											</Link>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default MISProfileSettings
