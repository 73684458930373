import CSOSidebar from './dashboardParts/csoSidebar'
import Doc from './dashboardParts/doc'
import { getOrgProfile } from '../handlers/organizationHandler'
import { useQuery } from 'react-query'

function OnboardingDoc() {
	const { data: csoData, isLoading: isDataLoading } = useQuery(
		'cos-profile',
		() => getOrgProfile()
	)
	return (
		<div style={{ backgroundColor: '#fff' }}>
			<CSOSidebar />
			<Doc data={csoData} isDataLoading={isDataLoading} />
		</div>
	)
}

export default OnboardingDoc
