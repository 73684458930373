import React from 'react'
import uploaded from '../../../img/items.png'
import { Link, useNavigate } from 'react-router-dom'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup'
import Select from 'react-select'
import { Form as FormikForm, Formik } from 'formik'
import { Oval } from 'react-loader-spinner'
import { s3Config } from '../../../utlis/s3config'
import ReactS3Client from 'react-aws-s3-typescript'
import { toast } from '../../../component/toast'
import { FileUploader } from 'react-drag-drop-files'
import { updateBusinessDocumentation } from '../../../handlers/onboardingHandler'
import { useMutation, useQueryClient } from 'react-query'
import Button from '../../../component/Button'

const fileTypes = ['JPEG', 'PNG', 'JPG', 'PDF']

const EditBusinessDoc = ({ data }) => {
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const [articleAssoFileObj, setArticleAssoFileObj] = React.useState({
		name: '',
		loading: false,
	})
	const [CACFileObj, setCACFileObj] = React.useState({
		name: '',
		loading: false,
	})
	const [CACImgUrl, setCACImgUrl] = React.useState('')
	const [articleAssoImgUrl, setArticleAssoImgUrl] = React.useState('')
	const [regCertImgUrl, setRegCertImgUrl] = React.useState('')

	const [articleImage, setArticleImage] = React.useState(null)
	const [CACImage, setCACImage] = React.useState(null)

	React.useEffect(() => {
		if (data?.organization?.orgfile?.cac) {
			setCACImage(data?.organization?.orgfile?.cac)
		}
		if (data?.organization?.orgfile?.article_of_asso) {
			setArticleImage(data?.organization?.orgfile?.article_of_asso)
		}
	}, [data])

	let initialValues = {
		reg_cac: data?.organization?.businessdoc?.reg_cac || '',
		rc_number: data?.organization?.businessdoc?.rc_number || '',
		tax_id_no: data?.organization?.businessdoc?.tax_id_no || '',
		name_of_registeration:
			data?.organization?.businessdoc?.name_of_registeration || '',
		registeration_with:
			data?.organization?.businessdoc?.registeration_with || '',
		registeration_no: data?.organization?.businessdoc?.registeration_no || '',
		registeration_name:
			data?.organization?.businessdoc?.registeration_name || '',
	}

	const handleFileUpload = async (file) => {
		const s3 = new ReactS3Client({ ...s3Config })
		try {
			const res = await s3.uploadFile(file)
			return res.location
		} catch (exception) {
			console.log(exception)
		}
	}

	const handleCACUpload = async (file) => {
		setCACFileObj({
			name: file?.name,
			loading: true,
		})
		const loc = await handleFileUpload(file)

		setCACImgUrl(loc)
		setCACFileObj((e) => ({ ...e, loading: false }))
	}

	const handleArticleAssoUpload = async (file) => {
		setArticleAssoFileObj({
			name: file?.name,
			loading: true,
		})
		const loc = await handleFileUpload(file)

		setArticleAssoImgUrl(loc)
		setArticleAssoFileObj((e) => ({ ...e, loading: false }))
	}

	const {
		mutate: updateBusinessDocumentationMutation,
		isLoading: isLoading,
	} = useMutation(updateBusinessDocumentation, {
		onSuccess: async (res) => {
			toast.success('Details saved successfully')
			await queryClient.invalidateQueries('cso-profile')
			navigate('/cso/settings')
		},
		onError: (e) => {
			toast.error(e)
		},
	})

	const handleSubmit = (values) => {
		const formdata = {
			...values,
			cac: CACImgUrl ? CACImgUrl : data?.organization?.orgfile?.cac,
			article_of_asso: articleAssoImgUrl
				? articleAssoImgUrl
				: data?.organization?.orgfile?.article_of_asso,
			registeration_certificate: regCertImgUrl
				? regCertImgUrl
				: data?.organization?.orgfile?.registeration_certificate,
		}
		updateBusinessDocumentationMutation({
			id: data?.organization?.businessdoc?.id,
			data: formdata,
		})
	}
	return (
		<div className="row mt-5">
			<Formik
				initialValues={initialValues}
				onSubmit={(values) => handleSubmit(values)}
				enableReinitialize={true}
			>
				{({
					values,
					handleChange,
					handleSubmit,
					handleBlur,
					setFieldValue,
					errors,
				}) => (
					<FormikForm onSubmit={handleSubmit}>
						<div className="col-md-8">
							<Form.Group as={Row} className="mb-3" controlId="">
								<Form.Label column sm="4">
									CAC Reg Status
								</Form.Label>
								<Col sm="8">
									<Form.Select
										aria-label=""
										onChange={handleChange}
										onBlur={handleBlur}
										name="reg_cac"
										value={values.reg_cac}
									>
										<option>Select</option>
										<option value="yes">Yes</option>
										<option value="no">No</option>
									</Form.Select>
								</Col>
							</Form.Group>
							{values.reg_cac === 'yes' && (
								<>
									<Form.Group as={Row} className="mb-3" controlId="">
										<Form.Label column sm="4">
											RC Number
										</Form.Label>
										<Col sm="8">
											<Form.Control
												type="text"
												placeholder="1937982158745"
												value={values?.rc_number}
												name="rc_number"
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="mb-3" controlId="">
										<Form.Label column sm="4">
											TIN
										</Form.Label>
										<Col sm="8">
											<Form.Control
												type="text"
												placeholder="1937982158745"
												name="tax_id_no"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.tax_id_no}
											/>
										</Col>
									</Form.Group>
									<div className="row mb-3">
										<Form.Label column sm="4">
											Uploaded Items
										</Form.Label>
										<div className="col-md-8">
											{CACImage ? (
												<div className="mb-4">
													<span>
														Organization’s CAC Certificateress. Example Utility
														bill (Valid within 3 months)
													</span>
													<div className="up-items mt-3">
														<div className="d-flex flex-row items-center">
															<img
																src={
																	data?.organization?.orgfile?.cac || uploaded
																}
																alt=""
																className="img-fluid"
																style={{
																	width: '146px',
																	height: '82px',
																	objectFit: 'cover',
																}}
															/>
															<div className="ms-3">
																<h5>
																	{
																		data?.organization?.orgfile?.cac?.split(
																			'/'
																		)[4]
																	}
																</h5>
															</div>
														</div>
													</div>
													<span
														className="float-end removd mt-2"
														style={{ cursor: 'pointer' }}
														onClick={() => setCACImage(null)}
													>
														<i className="bi bi-archive me-2 fs-6"></i>Remove
													</span>
												</div>
											) : (
												<Form.Group className="mb-3">
													<Form.Label>
														Upload a Copy of your Organization’s CAC Certificate
													</Form.Label>
													<FileUploader
														handleChange={handleCACUpload}
														name="file"
														types={fileTypes}
														label="Drag and drop Image here or Browse"
														multiple={false}
													/>
													<div className="uploader-loading-block">
														<p style={{ margin: 0 }}>
															{CACFileObj.loading
																? `${CACFileObj.name} Uploading`
																: CACFileObj.name
																? CACFileObj.name
																: 'No file(s) uploaded yet'}
														</p>
														{CACFileObj.loading && (
															<Oval
																wrapperClass="ml-2"
																height={20}
																width={20}
															/>
														)}
													</div>
												</Form.Group>
											)}
											{articleImage ? (
												<div className="mb-3">
													<span>Organization’s Article of Association</span>
													<div className="up-items mt-3">
														<div className="d-flex flex-row items-center">
															<img
																src={
																	data?.organization?.orgfile
																		?.article_of_asso || uploaded
																}
																alt=""
																className="img-fluid"
																style={{
																	width: '146px',
																	height: '82px',
																	objectFit: 'cover',
																}}
															/>
															<div className="ms-3">
																<h5>
																	{
																		data?.organization?.orgfile?.article_of_asso?.split(
																			'/'
																		)[4]
																	}
																</h5>
															</div>
														</div>
													</div>
													<span
														className="float-end removd mt-2"
														style={{ cursor: 'pointer' }}
														onClick={() => setArticleImage(null)}
													>
														<i className="bi bi-archive me-2 fs-6"></i>Remove
													</span>
												</div>
											) : (
												<Form.Group className="mb-3">
													<Form.Label>
														Upload Copy of your Organization’s Article of
														Association
													</Form.Label>
													<FileUploader
														multiple={false}
														handleChange={handleArticleAssoUpload}
														name="file"
														types={fileTypes}
														label="Drag and drop Image here or Browse"
													/>
													<div className="uploader-loading-block">
														<p style={{ margin: 0 }}>
															{articleAssoFileObj.loading
																? `${articleAssoFileObj.name} Uploading`
																: articleAssoFileObj.name
																? articleAssoFileObj.name
																: 'No file(s) uploaded yet'}
														</p>
														{articleAssoFileObj.loading && (
															<Oval
																wrapperClass="ml-2"
																height={20}
																width={20}
															/>
														)}
													</div>
												</Form.Group>
											)}
										</div>
									</div>
								</>
							)}
							{values.reg_cac === 'no' && (
								<>
									<Form.Group as={Row} className="mb-3" controlId="">
										<Form.Label column sm="4">
											Registered with
										</Form.Label>
										<Col sm="8">
											<Form.Select
												aria-label=""
												onChange={handleChange}
												onBlur={handleBlur}
												name="registeration_with"
												value={values.registeration_with}
											>
												<option>Select</option>
												<option value="federal ministry">
													Federal Ministry
												</option>
												<option value="state ministry">State Ministry</option>
												<option value="local ministry">Local Ministry</option>
												<option value="cooperatives">Cooperatives</option>
											</Form.Select>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="mb-3" controlId="">
										<Form.Label column sm="4">
											Registration Name
										</Form.Label>
										<Col sm="8">
											<Form.Control
												type="text"
												value={values?.registeration_name}
												name="registeration_name"
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="mb-3" controlId="">
										<Form.Label column sm="4">
											Registration Number
										</Form.Label>
										<Col sm="8">
											<Form.Control
												type="text"
												value={values?.registeration_no}
												name="registeration_no"
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Col>
									</Form.Group>
								</>
							)}
						</div>
						<hr />
						<div className="row justify-content-end mt-5 ">
							<div className="col-md-4 text-end">
								<Button
									type="submit"
									className="btn btn-success register-btn mt-5 login-btn px-5"
									loading={isLoading}
								>
									Update Details
								</Button>
							</div>
						</div>
					</FormikForm>
				)}
			</Formik>
		</div>
	)
}

export default EditBusinessDoc
