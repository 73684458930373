import AdminCreateArticles from './dashboardParts/adminCreateArticle';
import Adminsidebar from './dashboardParts/adminsidebar';

function AdminAddArticle() {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Adminsidebar/>
      <AdminCreateArticles/>
    </div>
  )
}

export default AdminAddArticle;
