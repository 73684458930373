import React from "react";
import Adminsidebar from "./dashboardParts/adminsidebar";
import AdminNewsFeeds from "./dashboardParts/newsFeed";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllFeed, getUserFeed } from "../handlers/newsHandler";

function AdminFeeds() {
  const [currPage, setCurrPage] = React.useState(1);
  const [searchName, setSearchName] = React.useState("");
  const [userNewsCurrPage, setUserNewsCurrPage] = React.useState(1);
  const [userNewsSearchName, setUserNewsSearchName] = React.useState("");
  const timeout = React.useRef();
  const userNewsTimeout = React.useRef();
  const [limit] = React.useState(10);
  const { data: feedData, isLoading } = useQuery(
    ["feeds", currPage, searchName],
    () => getAllFeed({ page: currPage, page_size: limit, name: searchName })
  );
  const { data: userFeedData } = useQuery(
    ["user-feeds", userNewsCurrPage, userNewsSearchName],
    () =>
      getUserFeed({
        page: userNewsCurrPage,
        page_size: limit,
        name: userNewsSearchName,
      })
  );
  const handlePaginateChange = async (page) => {
    setCurrPage(page.selected + 1);
  };

  const handleUserNewsPaginateChange = async (page) => {
    setUserNewsCurrPage(page.selected + 1);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setSearchName(value);
    }, 600);
  };

  const handleUserNewsSearch = (e) => {
    const value = e.target.value;
    clearTimeout(userNewsTimeout.current);

    userNewsTimeout.current = setTimeout(() => {
      setUserNewsSearchName(value);
    }, 600);
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Adminsidebar />
      <AdminNewsFeeds
        data={feedData}
        userFeedData={userFeedData}
        handlePaginateChange={handlePaginateChange}
        handleUserNewsPaginateChange={handleUserNewsPaginateChange}
        currPage={currPage}
        userNewsCurrPage={userNewsCurrPage}
        limit={limit}
        handleSearch={handleSearch}
        handleUserNewsSearch={handleUserNewsSearch}
        setSearchName={setSearchName}
        setUserNewsSearchName={setUserNewsSearchName}
        isLoading={isLoading}
      />
    </div>
  );
}

export default AdminFeeds;
