import React from "react";
import Footer from "../component/footer";
import NavB from "../component/header";
import ProjectsBanner from "../component/projectsBanner";
import ProjectsBottom from "../component/projectsBottom";
import ProjectsContent from "../component/projectsContent";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  fetchPublicListingProjects,
  getSingleDirectory,
} from "../handlers/publicHandler";

function DirectoryProjects() {
  const { id } = useParams();
  const [currPage, setCurrPage] = React.useState(1);
  const { data } = useQuery(`directory-${id}-projects`, () =>
    fetchPublicListingProjects(id)
  );

  const handlePaginateChange = async (page) => {
    setCurrPage(page.selected + 1);
  };

  return (
    <div>
      <NavB />
      <ProjectsBanner />
      <ProjectsContent
        data={data}
        currPage={currPage}
        handlePaginateChange={handlePaginateChange}
      />
      <ProjectsBottom />
      <Footer />
    </div>
  );
}

export default DirectoryProjects;
