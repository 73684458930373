import React from 'react'
import avatar from '../../img/user-img.svg'
import p from '../../img/public.svg'
import manage from '../../img/manage.svg'
import log from '../../img/logout.svg'
import add from '../../img/donations.svg'
import { Link } from 'react-router-dom'

function EmptyDonations() {
	return (
		<section>
			<div className="content overview">
				<div className="row">
					<div className="col-md-5 mb-2">
						<div className="form-group has-search">
							<span className="bi bi-search form-control-feedback"></span>
							<input
								type="text"
								className="form-control"
								placeholder="Search"
							/>
						</div>
					</div>
					<div className="col-md-7 text-end">
						<div className="dropdown">
							<Link
								to="#"
								className="dropdown-toggle"
								role="button"
								data-bs-toggle="dropdown"
							>
								<img src={avatar} alt="status" className="img-fluid" />
							</Link>
							<ul className="dropdown-menu userdrop">
								<Link className="d-flex flex-row">
									<img src={avatar} alt="status" className="img-fluid me-3" />
									<h6>African Society of Human Genetics</h6>
								</Link>
								<hr />
								<Link>
									<h5>
										<img src={p} alt="public" className="img-fluid me-3" /> View
										public profile
									</h5>
								</Link>
								<hr />
								<Link className="d-flex flex-row">
									<img src={manage} alt="status" className="img-fluid me-3" />
									<div>
										<h5>Manage Account</h5>
										<p>Control settings related to your account.</p>
									</div>
								</Link>
								<hr />
								<Link>
									<h5>
										<img src={log} alt="public" className="img-fluid me-3" />{' '}
										Log Out
									</h5>
								</Link>
							</ul>
						</div>
					</div>
				</div>
				<div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
					<hr />
				</div>
				<div className="row justify-content-center empty">
					<div className="col-md-6 text-center">
						<img src={add} alt="projects" className="img-fluid" />
						<h3 className="my-4">No donations yet</h3>
						<p className="mb-4">
							Here you can start receiving donations from people who
							<br />
							align with your cause
						</p>
						<Link
							to="/cso/campaign"
							className="btn btn-success w-25 login-btn mb-3"
						>
							{' '}
							Start a Campaign
						</Link>
					</div>
				</div>
			</div>
		</section>
	)
}

export default EmptyDonations
