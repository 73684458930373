import axiosClient from '../utlis/axiosClient'

export const addOrganizationDetails = async (data) => {
	try {
		const response = await axiosClient.post(`/v1/org/details`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return response.data.data
	} catch (error) {
		console.log({ error })
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const addBusinessDocumentation = async (data) => {
	try {
		const response = await axiosClient.post(`/v1/business/docs`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return response.data.data
	} catch (error) {
		console.log({ error })
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const addBankDetail = async (data) => {
	try {
		const response = await axiosClient.post(`/v1/bank/details`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return response.data.data
	} catch (error) {
		console.log({ error })
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const updateOrganizationDetails = async ({ id, data }) => {
	try {
		const response = await axiosClient.post(`/v1/org/details/${id}`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return response.data.data
	} catch (error) {
		console.log({ error })
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const updateBusinessDocumentation = async ({ id, data }) => {
	try {
		const response = await axiosClient.post(`/v1/business/docs/${id}`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return response.data.data
	} catch (error) {
		console.log({ error })
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const updateBankDetail = async ({ id, data }) => {
	try {
		const response = await axiosClient.post(`/v1/bank/details/${id}`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return response.data.data
	} catch (error) {
		console.log({ error })
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}
