import Adminsidebar from './dashboardParts/adminsidebar'
import ResourceSingle from './dashboardParts/resourceSingle'
import { getSingleResource } from '../handlers/resourceHandler'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

function SingleResourceAdmin() {
	const { id } = useParams()
	const { data } = useQuery(`get-resources-${id}`, () => getSingleResource(id))
	return (
		<div style={{ backgroundColor: '#fff' }}>
			<Adminsidebar />
			<ResourceSingle data={data} />
		</div>
	)
}

export default SingleResourceAdmin
