import React from "react";
import { useNavigate } from "react-router-dom";

function OverviewAdmin({ data, }) {
  const navigate = useNavigate(); 
  const handleNavigateToListings = (statusFilter) => {
    navigate("/admin/listing", { state: { statusFilter } });
  };

  return (
    <section>
      <div className="content overview">
        <div className="row justify-content-between">
          <div className="col-md-6 mt-3">
            <h3>Overview</h3>
          </div>
          <div className="col-md-3">
            <div className="form-group has-search">
              <span className="bi bi-search form-control-feedback"></span>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
              />
            </div>
          </div>
        </div>
        <div className="viewover mt-5">
          <div className="row">
            <div className="col-sm-6">
              <h4>Overview on Organisations</h4>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-sm-4 mb-3">
              <div className="nro">
                <h6>Number of Registered Organizations</h6>
                <h5 className="mt-3">{data?.organizations || "-"}</h5>
              </div>
            </div>
            <div className="col-sm-4 mb-3" onClick={() => handleNavigateToListings("approved")}>
              <div className="nro">
                <h6>Number of Approved Organizations</h6>
                <h5 className="mt-3">{data?.approved_organizations || "-"}</h5>
              </div>
            </div>
            <div className="col-sm-4 mb-3" onClick={() => handleNavigateToListings("pending")}>
              <div className="nro">
                <h6>Number of Pending Organizations</h6>
                <h5 className="mt-3">{data?.pending_organizations || "-"}</h5>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-sm-4 mb-3" onClick={() => handleNavigateToListings("rejected")} >
              <div className="nro">
                <h6>Number of Rejected Organizations</h6>
                <h5 className="mt-3">{data?.rejected_organizations}</h5>
              </div>
            </div>
            <div className="col-sm-4 mb-3" onClick={() => handleNavigateToListings("awaiting")}>
              <div className="nro ">
                <h6>Number of Organizations Awaiting Approval</h6>
                <h5 className="mt-3">{data?.awaiting_organizations || "-"}</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="viewover mt-5">
          <div className="row">
            <div className="col-sm-6">
              <h4>Overview on Resources</h4>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-sm-4 mb-3">
              <div className="nro">
                <h6>Number of Listed Projects</h6>
                <h5 className="mt-3">{data?.projects || "-"}</h5>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <div className="nro">
                <h6>Number of Listed Grants</h6>
                <h5 className="mt-3">{data?.grants || "-"}</h5>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <div className="nro">
                <h6>Number of Listed Opportunities</h6>
                <h5 className="mt-3">{data?.opportunities || "-"}</h5>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-4 mb-3">
              <div className="nro">
                <h6>Number of Listed Resources</h6>
                <h5 className="mt-3">{data?.total_resources || "-"}</h5>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <div className="nro">
                <h6>Number of Listed News Feed Items</h6>
                <h5 className="mt-3">{data?.news || "-"}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OverviewAdmin;
