import CreateLink from './dashboardParts/createLink';
import Sidebar from './dashboardParts/sideBar'

function AddLink() {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Sidebar/>
      <CreateLink/>
    </div>
  )
}

export default AddLink;
