function ProjectsBanner() {
  return (
    <section className="directorybanner pro">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 text-center">
            <h1 className="text-white">Explore Projects </h1>
            <h6 className="text-white mt-3">Explore work done by different organizations on the platform</h6>
            <div className="directory-search mt-5 p-2">
              <div className="form-search">
                <i className="bi bi-search"></i>
                <input type="text" className="form-control form-input" placeholder="Search projects"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProjectsBanner;