import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../Button'
import { Form as FormikForm, Formik } from 'formik'
import { bankDetailValidationSchema } from '../../utlis/validation'
import Select from '../Select'
import Input from '../Input'
import { Oval } from 'react-loader-spinner'
import { s3Config } from '../../utlis/s3config'
import ReactS3Client from 'react-aws-s3-typescript'
import { toast } from '../../component/toast'
import { useMutation } from 'react-query'
import { addBankDetail } from '../../handlers/onboardingHandler'

const fileTypes = ['JPEG', 'PNG', 'JPG', 'PDF']

const BankDetails = ({ handleChangeTab }) => {
	const navigate = useNavigate()
	const { mutate: addBankDetailMutation, isLoading: isLoading } = useMutation(
		addBankDetail,
		{
			onSuccess: async (res) => {
				toast.success('Details added successfully')
				navigate('/creating-account')
			},
			onError: (e) => {
				toast.error(e)
			},
		}
	)

	const handleSubmit = (values) => {
		addBankDetailMutation(values)
	}

	return (
		<div>
			<h2>Additional Information</h2>
			<h6>
				Please provide additional information to help us process and finalise
				your listing
			</h6>
			<div className="separator mt-4">
				<div className="line"></div>
				<h3>Bank Account Details </h3>
				<div className="line"></div>
			</div>
			<Formik
				initialValues={{
					acct_no: '',
					bank_name: '',
					beneficiary: '',
				}}
				onSubmit={(values) => handleSubmit(values)}
				validationSchema={bankDetailValidationSchema}
			>
				{({
					values,
					handleChange,
					handleSubmit,
					handleBlur,
					setFieldValue,
					errors,
				}) => (
					<FormikForm onSubmit={handleSubmit}>
						<Form.Group className="mb-3 mt-4">
							<Input
								type="text"
								value={values?.bank_name}
								name="bank_name"
								onChange={handleChange}
								onBlur={handleBlur}
								placeholder="Enter Bank Name"
								label="Bank Name"
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Input
								type="text"
								value={values?.beneficiary}
								name="beneficiary"
								onChange={handleChange}
								onBlur={handleBlur}
								placeholder="Enter Beneficiary Name on Account"
								label="Beneficiary Name"
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Input
								type="text"
								value={values?.acct_no}
								name="acct_no"
								onChange={handleChange}
								onBlur={handleBlur}
								placeholder="Enter Account Number"
								label="Account Number"
							/>
						</Form.Group>
						<Button
							type="submit"
							className="btn btn-success register-btn mt-5 login-btn px-5"
							loading={isLoading}
						>
							Save and Continue
						</Button>
					</FormikForm>
				)}
			</Formik>
		</div>
	)
}

export default BankDetails
