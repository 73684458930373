import React from 'react'
import avatar from '../../img/user-img.svg'
import p from '../../img/public.svg'
import manage from '../../img/manage.svg'
import log from '../../img/logout.svg'
import sr from '../../img/sr.svg'
import mis from '../../img/repeat.svg'
import header from '../../img/boi.svg'
import archived from '../../img/archived.svg'
import { Link } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { getAllProject } from '../../handlers/projectHandler'
import { Oval } from 'react-loader-spinner'
import Table from '../../component/Table'
import dayjs from 'dayjs'
import HeaderDropdownMenu from '../../component/HeaderDropdownMenu'

const columns = [
	{
		name: 'Title',
		selector: (row) => row.name,
		sortable: true,
	},
	{
		name: 'Project Location',
		selector: (row) => row.location,
		sortable: true,
	},
	{
		name: 'Project Start Date',
		selector: (row) => dayjs(row.start_date).format('DD/MM/YYYY'),
		sortable: true,
	},
	{
		name: 'Project Status',
		selector: (row) => row.project_status,
		sortable: true,
	},
	{
		name: 'Draft Date',
		selector: (row) => dayjs(row.created_at).format('DD/MM/YYYY'),
		sortable: true,
	},
	{
		name: 'Actions',
		selector: (row) => (
			<div className="dropdown table-dropdown">
				<i
					className="bi bi-three-dots-vertical actions"
					data-bs-toggle="dropdown"
					aria-expanded="false"
					id="draftDropdown"
				></i>
				<ul
					className="dropdown-menu listing-drop"
					// aria-labelledby="draftDropdown"
				>
					<li>
						<Link
							className="dropdown-item listing-item"
							data-bs-toggle="modal"
							data-bs-target="#"
							to=""
						>
							<i className="bi bi-blockquote-right me-2"></i> Publish Project
						</Link>
					</li>
					<li>
						<Link
							className="dropdown-item listing-item"
							data-bs-toggle="modal"
							data-bs-target="#"
							to=""
						>
							<i className="bi bi-pencil-square me-2"></i> Edit Project
						</Link>
					</li>
					<li>
						<Link
							className="dropdown-item listing-item"
							data-bs-toggle="modal"
							data-bs-target="#"
							to=""
						>
							<i className="bi bi-archive me-2"></i> Move to Archive
						</Link>
					</li>
					<li>
						<Link
							className="dropdown-item listing-item text-danger"
							data-bs-toggle="modal"
							data-bs-target="#"
							to=""
						>
							<i className="bi bi-archive me-2"></i> Delete Permanently
						</Link>
					</li>
				</ul>
			</div>
		),
	},
]

const DraftProjects = ({ data }) => {
	return (
		<div>
			<Table data={data || []} columns={columns} />
		</div>
	)
}

export default DraftProjects
