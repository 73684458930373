import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { FileUploader } from 'react-drag-drop-files'
import { Form as FormikForm, Formik } from 'formik'
import { createProjectValidationSchema } from '../../utlis/validation'
import Input from '../../component/Input'
import Select from '../../component/Select'
import Button from '../../component/Button'
import { s3Config } from '../../utlis/s3config'
import ReactS3Client from 'react-aws-s3-typescript'
import { Oval } from 'react-loader-spinner'
import { toast } from '../../component/toast'
import { useMutation, useQueryClient } from 'react-query'
import { createProject } from '../../handlers/projectHandler'

const fileTypes = ['JPEG', 'PNG', 'JPG']

function AddProjects() {
	// const [fromDate, setFromDate] = useState("");
	// const [toDate, setToDate] = useState("");
	// const assignFromDate = e => {
	// 	console.log(e.target.value);
	// 	setFromDate(e.target.value);
	// };

	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const [featuredImgFileObj, setFeaturedImgFileObj] = React.useState({
		name: '',
		loading: false,
	})
	const [additionalImgFileObj, setAdditionalImgFileObj] = React.useState({
		name: '',
		loading: false,
	})
	const [featuredImgUrl, setFeaturedImgUrl] = React.useState('')
	const [additionalImgUrl, setAdditionalImgUrl] = React.useState('')

	const handleFileUpload = async (file) => {
		const s3 = new ReactS3Client({ ...s3Config })
		try {
			const res = await s3.uploadFile(file)
			return res.location
		} catch (exception) {
			console.log(exception)
		}
	}

	const handleFeaturedImgUpload = async (file) => {
		setFeaturedImgFileObj({
			name: file?.name,
			loading: true,
		})
		const loc = await handleFileUpload(file)

		setFeaturedImgUrl(loc)
		setFeaturedImgFileObj((e) => ({ ...e, loading: false }))
	}

	const handleAdditionalImagesUpload = async (files) => {
		setAdditionalImgFileObj({
			name: [...files]?.map((item) => item.name)?.toString(),
			loading: true,
		})

		const fileLocations = []
		for (let i = 0; i < files.length; i++) {
			const loc = await handleFileUpload(files[i])
			fileLocations.push(loc)
		}

		setAdditionalImgFileObj((e) => ({ ...e, loading: false }))
		setAdditionalImgUrl(fileLocations)
	}

	const { mutate: createProjectMutation, isLoading: isLoading } = useMutation(
		createProject,
		{
			onSuccess: async (res) => {
				toast.success('Project created successfully')
				await queryClient.invalidateQueries(['cso-projects'])
				navigate('/cso/projects')
			},
			onError: (e) => {
				toast.error(e)
			},
		}
	)

	const handleSubmit = (values) => {
		if (featuredImgUrl) {
			const data = {
				...values,
				pictures: featuredImgUrl,
				additional_images: additionalImgUrl,
			}
			createProjectMutation(data)
		} else {
			toast.error('Featured Image is required')
		}
	}

	return (
		<section className="">
			<div className="content overview">
				<div className="row">
					<div className="col-md-10 mb-2 back">
						<Link to="/cso/projects">
							<i className="bi bi-arrow-left me-2"></i> Back
						</Link>
					</div>
					<div className="col-md-2 text-end">
						<div
							className="d-flex flex-row"
							style={{ justifyContent: 'flex-end' }}
						>
							{/* <Button to="" className="btn btn-success login-btn me-3 w-100">
								Save to Draft
							</Button> */}
							{/* <Link
								to=""
								data-bs-toggle="modal"
								data-bs-target="#approve"
								className="btn btn-success bg-white w-100 login-btn lin"
							>
								Publish Project
							</Link> */}
						</div>
					</div>
				</div>
				<div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
					<hr />
				</div>
				<div className="row">
					<div className="col">
						<h4>Add New Project</h4>
					</div>
				</div>
				<Formik
					initialValues={{
						name: '',
						description: '',
						location: '',
						draft: false,
						publish_status: 'activated',
						start_date: '',
						completion_date: '',
						project_status: '',
					}}
					onSubmit={(values) => handleSubmit(values)}
					validationSchema={createProjectValidationSchema}
				>
					{({
						values,
						handleChange,
						handleSubmit,
						handleBlur,
						setFieldValue,
						errors,
					}) => (
						<FormikForm className="row mt-5" onSubmit={handleSubmit}>
							<div className="col-md-8 mb-3">
								<>
									<Form.Group className="mb-3" controlId="formBasicEmail">
										<Input
											type="text"
											name="name"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.name}
											label="Project Title"
										/>
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Label>Upload Featured Image</Form.Label>
										<FileUploader
											multiple={false}
											handleChange={handleFeaturedImgUpload}
											name="file"
											types={fileTypes}
											label="Drag and drop document here or Browse"
										/>
										<div className="uploader-loading-block">
											<p style={{ margin: 0 }}>
												{featuredImgFileObj.loading
													? `${featuredImgFileObj.name} Uploading`
													: featuredImgFileObj.name
													? featuredImgFileObj.name
													: 'No file(s) uploaded yet'}
											</p>
											{featuredImgFileObj.loading && (
												<Oval wrapperClass="ml-2" height={20} width={20} />
											)}
										</div>
									</Form.Group>
									{/* <Form.Group className="mb-3">
										<Form.Label>Upload Additional Images</Form.Label>
										<FileUploader
											multiple={true}
											handleChange={handleAdditionalImagesUpload}
											name="file"
											types={fileTypes}
											label="Drag and drop document here or Browse"
										/>
										<div className="uploader-loading-block">
											<p style={{ margin: 0 }}>
												{additionalImgFileObj.loading
													? `${additionalImgFileObj.name} Uploading`
													: additionalImgFileObj.name
													? additionalImgFileObj.name
													: 'No file(s) uploaded yet'}
											</p>
											{additionalImgFileObj.loading && (
												<Oval wrapperClass="ml-2" height={20} width={20} />
											)}
										</div>
									</Form.Group> */}
									<Form.Group className="mb-3" controlId="">
										<Input
											type="text"
											name="description"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.description}
											as="textarea"
											minLength={300}
											maxLength={1500}
											rows={10}
											placeholder="Project Description"
											label="Description of the Project"
										/>
										<Form.Text
											id="passwordHelpBlock"
											muted
											className="float-end"
										>
											{values?.description?.length} / 1500 Words
										</Form.Text>
									</Form.Group>
								</>
							</div>
							<div className="col-md-4">
								<Select
									name="location"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.location}
									label="Project Location"
									isMulti={false}
									required={true}
									options={[
										{ value: 'Abuja', label: 'Abuja' },
										{ value: 'Abia', label: 'Abia' },
										{ value: 'Adamawa', label: 'Adamawa' },
										{ value: 'Akwa Ibom', label: 'Akwa Ibom' },
										{
											value: 'Anambra',
											label: 'Anambra',
										},
										{
											value: 'Bauchi',
											label: 'Bauchi',
										},
										{
											value: 'Bayelsa',
											label: 'Bayelsa',
										},
										{
											value: 'Benue',
											label: 'Benue',
										},
										{
											value: 'Borno',
											label: 'Borno',
										},
										{
											value: 'Cross River',
											label: 'Cross River',
										},
										{
											value: 'Delta',
											label: 'Delta',
										},
										{
											value: 'Ebonyi',
											label: 'Ebonyi',
										},
										{
											value: 'Edo',
											label: 'Edo',
										},
										{ value: 'Ekiti', label: 'Ekiti' },
										{ value: 'Enugu', label: 'Enugu' },
										{
											value: 'Gombe',
											label: 'Gombe',
										},
										{ value: 'Imo', label: 'Imo' },
										{
											value: 'Jigawa',
											label: 'Jigawa',
										},
										{ value: 'Kaduna', label: 'Kaduna' },
										{
											value: 'Kano',
											label: 'Kano',
										},
										{
											value: 'Katsina',
											label: 'Katsina',
										},
										{
											value: 'Kebbi',
											label: 'Kebbi',
										},
										{
											value: 'Kogi',
											label: 'Kogi',
										},
										{
											value: 'Kwara',
											label: 'Kwara',
										},
										{
											value: 'Lagos',
											label: 'Lagos',
										},
										{
											value: 'Nassarawa',
											label: 'Nassarawa',
										},
										{
											value: 'Niger',
											label: 'Niger',
										},
										{
											value: 'Ogun',
											label: 'Ogun',
										},
										{
											value: 'Ondo',
											label: 'Ondo',
										},
										{
											value: 'Osun',
											label: 'Osun',
										},
										{
											value: 'Oyo',
											label: 'Oyo',
										},
										{
											value: 'Plateau',
											label: 'Plateau',
										},
										{
											value: 'Rivers',
											label: 'Rivers',
										},
										{
											value: 'Sokoto',
											label: 'Sokoto',
										},
										{
											value: 'Taraba',
											label: 'Taraba',
										},
										{
											value: 'Yobe',
											label: 'Yobe',
										},
										{
											value: 'Zamfara',
											label: 'Zamfara',
										},
									]}
								/>

								{/* <InputGroup className="mb-3">
									<InputGroup.Text id="basic-addon1">
										<i className="bi bi-geo-alt"></i>
									</InputGroup.Text>
									<Input
										type="text"
										name="location"
										placeholder="Location of project"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.location}
										required={true}
									/>
								</InputGroup> */}
								<Form.Group className="my-3">
									<Select
										name="project_status"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.project_status}
										placeholder={'Select project status'}
										label={'Project Status'}
										options={[
											{ value: 'ongoing', label: 'Ongoing' },
											{ value: 'pending', label: 'Pending' },
											{ value: 'completed', label: 'Completed' },
										]}
									/>
								</Form.Group>
								<Form.Group className="mb-3" controlId="">
									<Input
										type="date"
										name="start_date"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.start_date}
										label="Start Date"
										// value={fromDate}
										// onChange={assignFromDate}
									/>
								</Form.Group>
								<Form.Group className="mb-5" controlId="">
									<Input
										type="date"
										name="completion_date"
										min={values.start_date}
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.completion_date}
										label="Completion Date"
										// value={toDate}
										// placeholder="Select Date"
										// onChange={e => setToDate(e.target.value)}
									/>
								</Form.Group>
							</div>
							<div className="row justify-content-end mt-5 ">
								<div className="col-md-6">
									<div className="d-flex flex-row">
										<Button
											className="btn btn-success login-btn me-3 w-100"
											onClick={() => navigate('/cso/projects')}
										>
											Cancel
										</Button>
										{/* <Button
											type="button"
											onClick={() => {
												setFieldValue('draft', true)
												setFieldValue('publish_status', 'deactivated')
												handleSubmit()
											}}
											className="btn btn-success login-btn me-3 w-100"
										>
											Save to Draft
										</Button> */}
										<Button
											type={'submit'}
											className="btn btn-success bg-white login-btn lin w-100"
											loading={isLoading}
										>
											Publish Project
										</Button>
									</div>
								</div>
							</div>
						</FormikForm>
					)}
				</Formik>
			</div>
		</section>
	)
}

export default AddProjects
