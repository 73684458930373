import React from "react";
import member from "../../../img/new-member.svg";
import { Col, Form, Row } from "react-bootstrap";
import { Form as FormikForm, Formik } from "formik";
import { createTeamMember } from "../../../handlers/eLearningHandler";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "../../../component/toast";
import Button from "../../../component/Button";

const AddTeamMember = () => {
  const queryClient = useQueryClient();
  const closeRef = React.useRef();

  const { mutate: createTeamMemberMutation, isLoading } = useMutation(
    createTeamMember,
    {
      onSuccess: async (res) => {
        toast.success("Team members created successfully");
        await queryClient.invalidateQueries(["cso-all-team-members"]);
        closeRef.current.click();
      },
      onError: (e) => {
        toast.error(e);
      },
    }
  );

  const handleSubmit = (values) => {
    createTeamMemberMutation(values);
  };

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content px-3">
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref={closeRef}
          ></button>
        </div>
        <div className="modal-body">
          <Formik
            initialValues={{
              firstname: "",
              lastname: "",
              username: "",
              email: "",
              designation: "",
            }}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue,
              errors,
            }) => (
              <FormikForm onSubmit={handleSubmit}>
                <div className="row listing-modal">
                  <div className="col">
                    <img
                      src={member}
                      alt="update"
                      className="img-fluid mb-3"
                      style={{ marginTop: "-30px" }}
                    />
                    <h4>Add New Team Member</h4>
                    <p>Add team members here</p>
                    <div className="my-3">
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="firstname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.firstname}
                              required={true}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="lastname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.lastname}
                              required={true}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group className="mb-3">
                        <Form.Label>User Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="username"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.username}
                          required={true}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          required={true}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Designation</Form.Label>
                        <Form.Control
                          type="text"
                          name="designation"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.designation}
                          //   required={true}
                        />
                      </Form.Group>
                    </div>
                    <div className="d-flex flex-row">
                      <Button
                        type={"button"}
                        className="btn btn-success border-g bg-white w-100 cancel-btn mt-3 me-3"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </Button>
                      <Button
                        type={"submit"}
                        className="btn btn-success w-100 login-btn mt-3"
                        loading={isLoading}
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddTeamMember;
