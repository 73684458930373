import Footer from "../component/footer";
import NavB from "../component/header";
import ProjectDesc from "../component/projectDescription";
import SingleProjectBanner from "../component/singleprojectBanner";
import { fetchPublicSingleProject } from "../handlers/publicHandler";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Oval } from "react-loader-spinner";

function SingleProject() {
  const { id } = useParams();
  const { data: data, isLoading: isDataLoading } = useQuery(
    `directory-project-${id}`,
    () => fetchPublicSingleProject(id)
  );

  return (
    <div>
      <NavB />
      {isDataLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 500,
          }}
        >
          <Oval wrapperClass="ml-2" height={40} width={40} />
        </div>
      ) : (
        <>
          <SingleProjectBanner data={data} />
          <ProjectDesc data={data} />
        </>
      )}

      <Footer />
    </div>
  );
}

export default SingleProject;
