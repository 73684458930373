import React from 'react'
import Form from 'react-bootstrap/Form'
import { Link, useNavigate } from 'react-router-dom'
import Button from './Button'
import { useMutation } from 'react-query'
import { toast } from './toast'
import { forgotPassword } from '../handlers/authhandler'

function FPassword() {
	const navigate = useNavigate()
	const [email, setEmail] = React.useState('')

	const { mutate: forgotPasswordMutation, isLoading } = useMutation(
		forgotPassword,
		{
			onSuccess: async (res) => {
				toast.success(res.message)
				navigate('/sent-email')
			},
			onError: (e) => {
				toast.error(e)
			},
		}
	)

	const handleSubmit = () => {
		if (!email) return
		forgotPasswordMutation({
			email,
		})
	}
	return (
		<section className="register-section">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-5">
						<h2 className="text-center">Forgot your password?</h2>
						<h6 className="mt-3 text-center">
							Enter your email address and we will send you a link to
							<br /> reset your password
						</h6>
						<Form className="mt-5">
							<Form.Group className="mb-4">
								<Form.Label>Email</Form.Label>
								<Form.Control
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</Form.Group>
							<Button
								// to="/sent-email"
								className="btn btn-success w-100 login-btn mb-3"
								type="button"
								onClick={handleSubmit}
								loading={isLoading}
							>
								Send link to email
							</Button>
							<div className="text-center">
								<small className="agree">
									<Link to="/login" style={{ color: '#2EA275' }}>
										Return to Log in Page
									</Link>
								</small>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</section>
	)
}

export default FPassword
