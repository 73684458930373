import AdminCreateGrant from './dashboardParts/adminCreateGrant';
import Adminsidebar from './dashboardParts/adminsidebar';


function AddGrant() {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Adminsidebar/>
      <AdminCreateGrant/>
    </div>
  )
}

export default AddGrant;
