import React from "react";
import avi from "../../img/msg-avi.svg";
import dp from "../../img/dp.svg";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "../../component/toast";
import { createMessage } from "../../handlers/messagesHandler";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { AuthContext } from "../../context/AuthContext";

function MISSingleMessage({ data, adminData, userId, userMessage }) {
  const queryClient = useQueryClient();
  const [message, setMessage] = React.useState("");
  const { userInfo } = React.useContext(AuthContext);

  const { mutate: createMessageMutation, isLoading: isLoading } = useMutation(
    createMessage,
    {
      onSuccess: async (res) => {
        setMessage("");
        await queryClient.invalidateQueries([`message-${userId}`]);
        await queryClient.invalidateQueries([`messages`]);
      },
      onError: (e) => {
        toast.error(e);
      },
    }
  );

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (message) {
      const dt = {
        user_id: userId,
        body: message,
      };
      createMessageMutation(dt);
    }
  };

  return (
    <section>
      <div className="content content-msg overview">
        <div className="container-fluid">
          <div className="row" style={{ marginTop: "-30px" }}>
            <div
              className="col-md-8"
              style={{ paddingTop: "40px", paddingBottom: "40px" }}
            >
              <Link to="/mis/messages">
                <i className="bi bi-arrow-left"></i> All Messages
              </Link>
              <h4 className="mt-3">
                {data?.organization || adminData?.name || "-"}
              </h4>
            </div>
            <div className="col-md-4 bg-message"></div>
          </div>
        </div>
        <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
          <hr />
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 mb-4 pe-0">
              <div
                className="container py-5 px-3"
                style={{
                  height: "640px",
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
              >
                {userMessage?.map((msg, index) => {
                  if (msg?.receiver === userInfo?.organization?.organization) {
                    return (
                      <div className="row mb-4" key={index}>
                        <div className="col-md-10">
                          <div className="d-flex justify-content-start">
                            <div>
                              <img
                                src={dp}
                                alt=""
                                className="img-fluid w-100"
                                style={{
                                  height: 40,
                                  width: 40,
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                            <div className="org-msg" style={{ width: "100%" }}>
                              <span>{data?.organization}</span>
                              <div className="msg-bar p-3">{msg?.message}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="row justify-content-end mb-4" key={index}>
                        <div className="col-md-9 org-msg">
                          <span>You</span>
                          <div className="you-msg">
                            {msg?.message}
                            <span className="float-end mt-5">
                              {dayjs(msg?.created_at).format(
                                "hh:mm A DD MMM, YYYY"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              <div className="hr-divider">
                <hr />
              </div>
              <div className="type-msg pe-3">
                <form onSubmit={handleSendMessage}>
                  <div className="mb-3">
                    <textarea
                      className="form-control"
                      id=""
                      rows="2"
                      placeholder="Enter a message"
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                    ></textarea>
                    <button
                      to=""
                      className="btn btn-success login-btn py-2 px-4 float-end"
                      type="submit"
                      style={{ marginBottom: "57px" }}
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div
              className="col-md-4 border-start py-4 px-5"
              style={{ height: "auto" }}
            >
              <img
                src={avi}
                alt="avatar"
                className="img-fluid"
                style={{ marginTop: "-76px" }}
              />
              <h4>{data?.organization || adminData?.name || "-"}</h4>
              <div className="my-4">
                <p>
                  <i className="bi bi-envelope-at me-2"></i>
                  {data?.organization_email || adminData?.email || "-"}
                </p>
                {data?.address && (
                  <p>
                    <i className="bi bi-geo-alt me-2"></i>
                    {data?.address || "-"}
                  </p>
                )}
                {data?.organization_phone && (
                  <p>
                    <i className="bi bi bi-telephone me-2"></i>
                    {data?.organization_phone || "-"}
                  </p>
                )}
              </div>
              {data?.thematic_area?.length > 0 && (
                <div className="mb-3">
                  <h4>Thematic Area</h4>
                  {data?.thematic_area?.map((item, index) => (
                    <p key={index}>{item}</p>
                  ))}
                </div>
              )}
              {data?.services?.length > 0 && (
                <div className="mb-3">
                  <h4>Services Rendered</h4>
                  {data?.services?.map((item, index) => (
                    <p key={index}>{item}</p>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MISSingleMessage;
