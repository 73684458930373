import Adminsidebar from "./dashboardParts/adminsidebar";
import ComplianceBody from "./dashboardParts/complianceBody";

function AdminComplianceBody() {
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Adminsidebar />
      <ComplianceBody />
    </div>
  );
}

export default AdminComplianceBody;
