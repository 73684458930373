import axiosClient from "../utlis/axiosClient";

export const getMessages = async ({ page = 1 }) => {
  try {
    const url = `/v1/messages?page=${page}&page_size=10`;
    const response = await axiosClient.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const createMessage = async (data) => {
  try {
    const response = await axiosClient.post(`/v1/messages`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const getMessagesToUser = async (id) => {
  try {
    const url = `/v1/messages/${id}`;
    const response = await axiosClient.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const sendBulkMessages = async (data) => {
  try {
    const response = await axiosClient.post(`/admin/messages`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};
