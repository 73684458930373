import CSOSidebar from "./dashboardParts/csoSidebar";
import StartCampaign from "./dashboardParts/startCampaign";


function Campaign() {
  return (
    <div style={{backgroundColor: "#fff"}}>
      <CSOSidebar/>
      <StartCampaign/>
    </div>
  );
}

export default Campaign;
