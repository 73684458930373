import AddProjects from './dashboardParts/AddProject'
import CSOSidebar from './dashboardParts/csoSidebar'

function ProjectAdd() {
	return (
		<div style={{ backgroundColor: '#fff' }}>
			<CSOSidebar />
			<AddProjects />
		</div>
	)
}

export default ProjectAdd
