import React from "react";
import av from "../../img/user-msg.svg";
import { Link, useNavigate } from "react-router-dom";
import { getOrganizations, getAdmins } from "../../handlers/publicHandler";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Button from "../../component/Button";
import RSelect from "react-select";
import { Form } from "react-bootstrap";
import member from "../../img/new-member.svg";

function AdminMessagesB({ data }) {
  const navigate = useNavigate();
  const [searchText, setSearchText] = React.useState("");
  const [selectedOrg, setSelectedOrg] = React.useState(null);
  const [selectedAdmin, setSelectedAdmin] = React.useState(null);

  const [adminearchText, setAdmiinSearchText] = React.useState("");

  const { data: orgData, isLoading: isDataLoading } = useQuery(
    ["organization", `search-${searchText}`],
    () => getOrganizations(searchText)
  );

  const { data: adminData, isLoading } = useQuery(
    ["admin", `search-admin-${adminearchText}`],
    () => getAdmins(adminearchText)
  );

  const closeRef = React.useRef();
  const closeAdminRef = React.useRef();

  return (
    <section>
      <div className="content overview">
        <div className="row">
          <div className="col-md-6 mt-3">
            <h4>Messages</h4>
            <p>Send and receive messages to and from other organizations</p>
          </div>
          <div className="col-md-6 text-end mt-3">
            <div className="d-flex flex-row">
              <button
                // to="/message-admin"
                className="btn btn-success w-50 md-btn mb-3"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#msg-admin"
              >
                Message Admin
              </button>
              <Link
                to="/sendbulk"
                className="btn btn-success w-50 sbm-btn mb-3 mx-3"
              >
                Send Bulk Message
              </Link>
              <Link
                to=""
                className="btn btn-success w-50 login-btn mb-3"
                data-bs-toggle="modal"
                data-bs-target="#messagesend"
              >
                New Message
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <div className="p-4 group-cards gray mt-4">
              <form className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Search Messages</label>
                    <div className="search-message">
                      <i className="bi bi-search"></i>
                      <input
                        type="text"
                        className="form-control form-input"
                        placeholder="Search messages"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="group-cards py-4">
              {data?.map((msg, index) => (
                <React.Fragment key={index}>
                  <div className="d-flex flex-row px-4">
                    <img src={av} alt="user-img" className="img-fluid me-3" />
                    <div className="msg-b">
                      <h3>
                        <Link to={`/admin/messages/${msg.id}`}>
                          {msg?.receiver.includes("Admin")
                            ? msg?.sender
                            : msg?.receiver}
                        </Link>
                      </h3>
                      <p className="py-1">{msg?.message}</p>
                      <h6>{msg?.created_at}</h6>
                    </div>
                  </div>
                  {index !== data?.length - 1 && <hr />}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="messagesend"
          tabIndex="-1"
          aria-labelledby=""
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content px-3">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeRef}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row listing-modal">
                  <div className="col" style={{ marginTop: "-30px" }}>
                    {/* <img src={update} alt="update" className="img-fluid mb-3" /> */}
                    <h4>Send a Message to:</h4>
                    <p>Search for who you intend to message</p>
                    <form className="row">
                      <div className="mb-3">
                        <RSelect
                          className="basic-single"
                          classNamePrefix="select"
                          placeholder="Search Organization"
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          value={selectedOrg}
                          onChange={(e) => setSelectedOrg(e)}
                          options={
                            orgData?.map((item) => ({
                              value: item.id,
                              label: item.organization,
                            })) || []
                          }
                        />
                      </div>

                      <div className="d-flex flex-row">
                        <Button
                          className="btn btn-success bg-white w-100 login-btn mt-3 me-3"
                          onClick={() => closeRef.current.click()}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn btn-success w-100 login-btn mt-3"
                          onClick={
                            selectedOrg
                              ? () => {
                                  navigate(
                                    `/admin/messages/${selectedOrg?.value}`
                                  );
                                  closeRef.current.click();
                                }
                              : null
                          }
                        >
                          Confirm
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Message Admin */}
      <div
        className="modal fade"
        id="msg-admin"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeAdminRef}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <div className="col" style={{ marginTop: "-30px" }}>
                  <h4>Send a Message to Admin:</h4>
                  <p>Search for who you intend to message</p>
                  <form className="row">
                    <div className="mb-3">
                      <RSelect
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Search Admins"
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        value={selectedAdmin}
                        onChange={(e) => setSelectedAdmin(e)}
                        options={
                          adminData?.map((item) => ({
                            value: item.id,
                            label: item.name,
                          })) || []
                        }
                      />
                    </div>

                    <div className="d-flex flex-row">
                      <Button
                        className="btn btn-success bg-white w-100 login-btn mt-3 me-3"
                        onClick={() => closeAdminRef.current.click()}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn btn-success w-100 login-btn mt-3"
                        onClick={
                          selectedAdmin
                            ? () => {
                                navigate(
                                  `/admin/messages/${selectedAdmin?.value}`
                                );
                                closeAdminRef.current.click();
                              }
                            : null
                        }
                      >
                        Confirm
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdminMessagesB;
