import React from 'react'
import Form from 'react-bootstrap/Form'
import { ErrorMessage } from 'formik'
import MSelect from 'react-select'

const renderError = (message) => (
	<span style={{ color: 'red', fontSize: 12 }}>{message}</span>
)

const Select = ({
	name,
	placeholder,
	onBlur,
	onChange,
	value,
	label,
	options,
	isMulti,
	required,
}) => {
	return isMulti ? (
		<>
			{label && <Form.Label>{label}</Form.Label>}
			<MSelect
				isMulti
				name={name}
				onChange={onChange}
				onBlur={onBlur}
				value={value}
				options={options}
				className="basic-multi-select"
				classNamePrefix="select"
				required={required}
			/>
			<ErrorMessage name={name} render={renderError} />
		</>
	) : (
		<>
			{label && <Form.Label>{label}</Form.Label>}
			<Form.Select
				aria-label="Select menu"
				name={name}
				placeholder={placeholder}
				onChange={onChange}
				onBlur={onBlur}
				value={value}
				className="auth-select"
				required={required}
			>
				<option>Select</option>
				{options?.map((item, idx) => (
					<option value={item.value} key={idx}>
						{item.label}
					</option>
				))}
			</Form.Select>
			<ErrorMessage name={name} render={renderError} />
		</>
	)
}

export default Select
