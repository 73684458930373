import React from "react";
import avatar from "../../img/user-img.svg";
import editimg from "../../img/edit-img.svg";
import ProfilePlaceholderImg from "../../img/user-image-placeholder.svg";
import EditIconImg from "../../img/edit-icon.svg";
import recycle from "../../img/recycle.svg";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import HeaderDropdownMenu from "../../component/HeaderDropdownMenu";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { getOrgProfile } from "../../handlers/organizationHandler";
import { toast } from "../../component/toast";
import { s3Config } from "../../utlis/s3config";
import ReactS3Client from "react-aws-s3-typescript";
import { updateOrganizationDetails } from "../../handlers/onboardingHandler";
import { AuthContext } from "../../context/AuthContext";
import CsoPasswordManagement from "./CsoPasswordManagement";

function ProfileSettings() {
  const queryClient = useQueryClient();
  const { data: profileData } = useQuery("cso-profile", () => getOrgProfile());
  const [profileImageUrl, setProfileImageUrl] = React.useState("");
  const [uploadedImageData, setUploadedImageData] = React.useState(null);
  const { userInfo } = React.useContext(AuthContext);

  const handleFileUpload = async (file) => {
    const s3 = new ReactS3Client({ ...s3Config });
    try {
      const res = await s3.uploadFile(file);
      return res.location;
    } catch (exception) {
      console.log(exception);
    }
  };

  const { mutate: updateOrganizationDetailsMutation, isLoading } = useMutation(
    updateOrganizationDetails,
    {
      onSuccess: async (res) => {
        toast.success("Profile updated successfully");
        await queryClient.invalidateQueries("cso-profile");
      },
      onError: (e) => {
        toast.error(e);
      },
    }
  );

  const handleUpload = async (e) => {
    setUploadedImageData(e.target.files[0]);
    const loc = await handleFileUpload(e.target.files[0]);

    if (loc) {
      updateOrganizationDetailsMutation({
        id: profileData?.organization?.id,
        data: {
          org_logo: loc,
          organization_email: profileData?.organization?.organization_email,
          organization_phone: profileData?.organization?.organization_phone,
        },
      });
    }
  };
  return (
    <section>
      <div className="content overview">
        <div className="row">
          <div className="col-md-5 mb-2"></div>
          <div className="col-md-7 text-end">
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
              >
                {userInfo?.organization?.orgfile?.org_logo ? (
                  <img
                    src={userInfo?.organization?.orgfile?.org_logo}
                    alt="avatar"
                    className="profile-topbar-avi"
                  />
                ) : (
                  <img src={avatar} alt="avi" className="img-fluid" />
                )}
              </Link>
              <HeaderDropdownMenu />
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
          <hr />
        </div>
        <div className="row">
          <div className="col-md-7">
            <h4>Settings</h4>
          </div>
        </div>
        <div className="row listings-tog mt-3">
          <div className="col">
            <ul className="nav nav-tabs tog" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link tog-btn active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected="true"
                >
                  Organization’s Profile
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane"
                  aria-selected="false"
                >
                  Login & Security
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home-tab-pane"
                role="tabpanel"
                aria-labelledby="home-tab"
                tabIndex="0"
              >
                <div className="row mt-5">
                  <div className="col-md-7">
                    <div className="profile-image-container">
                      {uploadedImageData ? (
                        <img
                          src={URL.createObjectURL(uploadedImageData)}
                          alt="avatar"
                          className="profile-image"
                        />
                      ) : profileData?.organization?.orgfile?.org_logo ? (
                        <img
                          src={profileData?.organization?.orgfile?.org_logo}
                          alt="avatar"
                          className="profile-image"
                        />
                      ) : (
                        <img
                          src={ProfilePlaceholderImg}
                          alt="avatar"
                          className="img-fluid"
                        />
                      )}
                      <label htmlFor="photo">
                        <div className="profile-edit-icon-container">
                          <img
                            src={EditIconImg}
                            alt="icon"
                            className="img-fluid"
                          />
                        </div>
                      </label>
                    </div>
                    <input
                      type="file"
                      id="photo"
                      name="photo"
                      accept="image/*"
                      className="profile-image-input"
                      onChange={(e) =>
                        e.currentTarget.files[0].size > 2200000
                          ? toast.error(
                              "Image size is too large. Please upload image less than 2MB."
                            )
                          : handleUpload(e)
                      }
                    />
                  </div>
                  <div className="col-md-5 text-end mt-3">
                    <Link
                      to="/cso/edit-profile"
                      className="btn btn-success w-50 login-btn mb-3"
                    >
                      {" "}
                      Edit Profile
                    </Link>
                  </div>
                </div>
                <div className="bg-white group-cards mt-5 p-4">
                  <div className="row statuses ">
                    <div className="col-md-6">
                      <p>
                        <i className="bi bi-house me-2"></i> Organization's Name
                      </p>
                      <h6>{profileData?.organization?.organization || "-"}</h6>
                    </div>
                    <div className="col-md-6">
                      <p>
                        <i className="bi bi-calendar me-2"></i> Year Founded
                      </p>
                      <h6>{profileData?.organization?.operation_years}</h6>
                    </div>
                  </div>
                  <hr />
                  <div className="row my-5 statuses">
                    <div className="col-md-6">
                      <p>
                        <i className="bi bi-envelope me-2"></i> Email Address
                      </p>
                      <Link>{profileData?.email || "-"}</Link>
                    </div>
                    <div className="col-md-6">
                      <p>
                        <i className="bi bi-telephone me-2"></i> Phone Number
                      </p>
                      <Link>
                        {profileData?.organization?.organization_phone || "-"}{" "}
                      </Link>
                    </div>
                  </div>
                  <hr />
                  <div className="row my-5 statuses">
                    <div className="col-md-6">
                      <p>
                        <i className="bi bi-briefcase me-2"></i> Thematic Area
                      </p>
                      <h6>
                        {profileData?.organization?.thematic_area?.join(", ") ||
                          "-"}
                      </h6>
                    </div>
                    <div className="col-md-6">
                      <p>
                        <i className="bi bi-gear me-2"></i> Services Rendered
                      </p>
                      <h6>
                        {profileData?.organization?.services?.join(", ") || "-"}
                      </h6>
                    </div>
                  </div>
                  <hr />
                  <div className="row my-5 statuses">
                    <div className="col-md-6">
                      <p>
                        <i className="bi bi-geo-alt me-2"></i> Organization's
                        Address
                      </p>
                      <h6>{profileData?.organization?.address || "-"}</h6>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col">
                          <p>City</p>
                          <h6>{profileData?.organization?.city || "-"}</h6>
                        </div>
                        <div className="col">
                          <p>State</p>
                          <h6>
                            {profileData?.organization?.state?.join(", ") ||
                              "-"}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row my-5 statuses">
                    <div className="col-md-6">
                      <p>
                        <i className="bi bi-bank me-2"></i> Bank Name
                      </p>
                      <h6>
                        {profileData?.organization?.bankdetail?.bank_name ||
                          "-"}
                      </h6>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col">
                          <p>Beneficiary Name</p>
                          <h6>
                            {profileData?.organization?.bankdetail
                              ?.beneficiary || "-"}
                          </h6>
                        </div>
                        <div className="col">
                          <p>Account</p>
                          <h6>
                            {profileData?.organization?.bankdetail?.acct_no ||
                              "-"}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row my-5 statuses">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col">
                          <p>Instagram Profile</p>
                          <h6>
                            {profileData?.organization?.socialmedia?.links?.find(
                              (item) => item.Instagram
                            )?.Instagram || "-"}
                          </h6>
                        </div>
                        <div className="col">
                          <p>Facebook Profile</p>
                          <h6>
                            {profileData?.organization?.socialmedia?.links?.find(
                              (item) => item.Facebook
                            )?.Facebook || "-"}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col">
                          <p>Linkedin Profile</p>
                          <h6>
                            {profileData?.organization?.socialmedia?.links?.find(
                              (item) => item.LinkedIn
                            )?.LinkedIn || "-"}
                          </h6>
                        </div>
                        <div className="col">
                          <p>Twitter Profile</p>
                          <h6>
                            {profileData?.organization?.socialmedia?.links?.find(
                              (item) => item.Twitter
                            )?.Twitter || "-"}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade "
                id="profile-tab-pane"
                role="tabpanel"
                aria-labelledby="profile-tab"
                tabIndex="0"
              >
                <CsoPasswordManagement />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Reject Modal */}
      <div
        className="modal fade"
        id="delete-acc"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <div className="col">
                  <img
                    src={recycle}
                    alt="update"
                    className="img-fluid mb-3"
                    style={{ marginTop: "-30px" }}
                  />
                  <h4>Delete Account</h4>
                  <p>
                    Are you sure you want to delete your account. You lose
                    access to all MIS services, and we permanently delete your
                    personal data. You can cancel the deletion within 14 days.
                  </p>
                  <form>
                    <div className="d-flex flex-row">
                      <Link
                        to=""
                        className="btn btn-success bg-white w-100 login-btn mt-3 me-3"
                      >
                        Cancel
                      </Link>
                      <Link
                        to=""
                        className="btn btn-success w-100 login-btn mt-3"
                      >
                        Confirm
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProfileSettings;
