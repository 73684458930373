import React from "react";
import OpportunitiesBody from "./dashboardParts/opportunitiesBody";
import Sidebar from "./dashboardParts/sideBar";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllGrants, getAuthorGrants } from "../handlers/grantHandler";

function Opportunities() {
  const [currPage, setCurrPage] = React.useState(1);
  const [userCurrPage, setUserCurrPage] = React.useState(1);
  const [searchName, setSearchName] = React.useState("");
  const [authorSearchName, setAuthorSearchName] = React.useState("");
  const timeout = React.useRef();
  const authorTimeout = React.useRef();

  const { data: grants } = useQuery(
    ["admin-all-grants", currPage, searchName],
    () => getAllGrants({ page: currPage, name: searchName })
  );

  const { data: userGrants } = useQuery(
    ["admin-grants", userCurrPage, authorSearchName],
    () => getAuthorGrants({ page: userCurrPage, name: authorSearchName })
  );

  const handlePaginateChange = async (page) => {
    setCurrPage(page.selected + 1);
  };

  const handleUserPaginateChange = async (page) => {
    setUserCurrPage(page.selected + 1);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setSearchName(value);
    }, 600);
  };

  const handleAuthorSearch = (e) => {
    const value = e.target.value;
    clearTimeout(authorTimeout.current);

    authorTimeout.current = setTimeout(() => {
      setAuthorSearchName(value);
    }, 600);
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Sidebar />
      <OpportunitiesBody
        grants={grants?.grants?.data}
        userGrants={userGrants?.data}
        allTotal={grants?.grants?.total}
        userTotal={userGrants?.total}
        handlePaginateChange={handlePaginateChange}
        currPage={currPage}
        handleUserPaginateChange={handleUserPaginateChange}
        userCurrPage={userCurrPage}
        handleSearch={handleSearch}
        handleAuthorSearch={handleAuthorSearch}
      />
    </div>
  );
}

export default Opportunities;
