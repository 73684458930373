import axiosClient from "../utlis/axiosClient";

export const createGrant = async (data) => {
  try {
    const response = await axiosClient.post(`/v1/grant`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const getAllGrants = async ({ page, name, status = "" }) => {
  try {
    const response = await axiosClient.get(
      `/v1/grant/fetch-grants?page=${page}&page_size=10&grant_title=${name}&grant_status=${status}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const getAuthorGrants = async ({ page, name, status = "" }) => {
  try {
    const response = await axiosClient.get(
      `/v1/grant/fetch-grant-by-author?page=${page}&page_size=10&title=${name}&grant_status=${status}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const getSingleGrant = async (id) => {
  try {
    const response = await axiosClient.get(`/v1/grant/fetch-grant/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const updateGrantStatus = async ({ id, data }) => {
  try {
    const response = await axiosClient.put(
      `/admin/grant/update-grant-status/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const updateGrant = async ({ id, data }) => {
  try {
    const response = await axiosClient.post(`/v1/grant/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const updateGrantClicks = async ({ id }) => {
  try {
    const response = await axiosClient.post(`/v1/grant/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const updateGrantView = async ({ id, data }) => {
  try {
    const response = await axiosClient.put(
      `/v1/grant/applied-grant/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const updateGrantClick = async ({ id }) => {
  try {
    const response = await axiosClient.put(`/v1/grant/applied-grant/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};
