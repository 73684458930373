import MISMessageAdmin from "./dashboardParts/misMessageAdmin";
import Sidebar from "./dashboardParts/sideBar";

function MISMessageA() {
  return (
    <div style={{backgroundColor: "#fff"}}>
      <Sidebar/>
      <MISMessageAdmin/>
    </div>
  );
}

export default MISMessageA;
