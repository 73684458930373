import CSOSidebar from "./dashboardParts/csoSidebar";
import EmptyProject from "./dashboardParts/emptyProject";


function ProjectEmpty() {
  return (
    <div style={{backgroundColor: "#fff"}}>
      <CSOSidebar/>
      <EmptyProject/>
    </div>
  );
}

export default ProjectEmpty;
