import axiosClient from "../utlis/axiosClient";

export const createCompliance = async (data) => {
  try {
    const response = await axiosClient.post(`/admin/compliance`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const getAllCompliance = async (page) => {
  try {
    const response = await axiosClient.get(
      `/v1/compliances/fetch-all-questions?page=${page}&page_size=10`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const submitComplianceAnswer = async (data) => {
  try {
    const response = await axiosClient.post(`/v1/compliance`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const getComplianceAnswer = async (page) => {
  try {
    const response = await axiosClient.get(
      `/v1/compliance/cso-compliance-answers?page=${page}&page_size=10`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const getAdminSingleCompliance = async (id) => {
  try {
    const response = await axiosClient.get(
      `/admin/compliance/question-with-answers/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const approveComplianceAnswer = async ({ id, data }) => {
  try {
    const response = await axiosClient.put(
      `/admin/compliance/update-answer-status/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};
