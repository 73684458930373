import act from "../img/act.svg";
import diamond from "../img/diamond.svg";
import dia from "../img/dia.svg";
import gold from "../img/goldie.svg";
import silver from "../img/silver.svg";
import bronze from "../img/bronze.svg";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import { toast } from "../component/toast";
// import Select from '../component/Select'

function DirectoryContent({
  data,
  isDataLoading,
  state,
  area,
  rank,
  years,
  handleUpdateArea,
  handleUpdateState,
  handleUpdateRank,
  handleUpdateYears,
  handleClear,
  handlePaginateChange,
  currPage,
}) {
  const handleNavigationError = () => {
    toast.warn(
      "CSO has not been approved, so you cannot view their information"
    );
  };
  return (
    <section className="directorycontent">
      <div className="container">
        <div className="row">
          <div className="col-md-3 mb-3">
            <div className="filterby p-4 bg-white">
              <h4>Filter By</h4>
              <h6
                className="float-end"
                style={{ marginTop: "-38px", cursor: "pointer" }}
                onClick={handleClear}
              >
                <i className="bi bi-arrow-repeat me-2"></i> Reset
              </h6>
              <Form className="mt-4">
                <Form.Group className="mb-3">
                  <Form.Label>Location</Form.Label>
                  <Form.Select
                    value={state}
                    onChange={(e) => handleUpdateState(e.target.value)}
                  >
                    <option value="">Select State</option>
                    <option value="Abia">Abia</option>
                    <option value="Adamawa">Adamawa</option>
                    <option value="Akwa Ibom">Akwa Ibom</option>
                    <option value="Anambra">Anambra</option>
                    <option value="Bauchi">Bauchi</option>
                    <option value="Bayelsa">Bayelsa</option>
                    <option value="Benue">Benue</option>
                    <option value="Borno">Borno</option>
                    <option value="Cross River">Cross River</option>
                    <option value="Delta">Delta</option>
                    <option value="Ebonyi">Ebonyi</option>
                    <option value="Edo">Edo</option>
                    <option value="Ekiti">Ekiti</option>
                    <option value="Enugu">Enugu</option>
                    <option value="FCT">Federal Capital Territory</option>
                    <option value="Gombe">Gombe</option>
                    <option value="Imo">Imo</option>
                    <option value="Jigawa">Jigawa</option>
                    <option value="Kaduna">Kaduna</option>
                    <option value="Kano">Kano</option>
                    <option value="Katsina">Katsina</option>
                    <option value="Kebbi">Kebbi</option>
                    <option value="Kogi">Kogi</option>
                    <option value="Kwara">Kwara</option>
                    <option value="Lagos">Lagos</option>
                    <option value="Nasarawa">Nasarawa</option>
                    <option value="Niger">Niger</option>
                    <option value="Ogun">Ogun</option>
                    <option value="Ondo">Ondo</option>
                    <option value="Osun">Osun</option>
                    <option value="Oyo">Oyo</option>
                    <option value="Plateau">Plateau</option>
                    <option value="Rivers">Rivers</option>
                    <option value="Sokoto">Sokoto</option>
                    <option value="Taraba">Taraba</option>
                    <option value="Yobe">Yobe</option>
                    <option value="Zamfara">Zamfara</option>
                  </Form.Select>
                </Form.Group>
                <div className="tarea p-3 mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Thematic Area</Form.Label>
                    <Form.Select
                      value={area}
                      onChange={(e) => handleUpdateArea(e.target.value)}
                    >
                      <option value="">Select Area</option>
                      <option value="Education">Education</option>
                      <option value="Disability">Disability</option>
                      <option value="Poverty">Poverty</option>
                      <option value="Health">Health</option>
                      <option value="Economic/Community Development">
                        Economic/Community Development
                      </option>
                      <option value="Democracy and Good Governance">
                        Democracy and Good Governance
                      </option>
                      <option value="Orphanages and Shelters">
                        Orphanages and Shelters
                      </option>
                      <option value="Civil Rights and Social Action">
                        Civil Rights and Social Action
                      </option>
                      <option value="Peace and Conflict Resolution">
                        Peace and Conflict Resolution
                      </option>
                      <option value="Corporative and Microfinance">
                        Corporative and Microfinance
                      </option>
                      <option value="Corporative and Microfinance">
                        Corporative and Microfinance
                      </option>
                      <option value="Crime and Law Related">
                        Crime and Law Related
                      </option>
                      <option value="Youth and Youth Development">
                        Youth and Youth Development
                      </option>
                      <option value="Information Communication and Technology">
                        Information Communication and Technology
                      </option>
                      <option value="Trade">Trade</option>
                      <option value="Migration">Migration</option>
                      <option value="Gender and Social Inclusion">
                        Gender and Social Inclusion
                      </option>
                      <option value="Philanthropy">Philanthropy</option>
                      <option value="Research and Development">
                        Research and Development
                      </option>
                      <option value="Environment">Environment</option>
                      <option value="Water, Sanitation and Hygiene.">
                        Water, Sanitation and Hygiene.
                      </option>
                      <option value="Religion Related">Religion Related</option>
                      <option value="Media/Communication">
                        Media/Communication
                      </option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="tarea p-3 mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Ranking</Form.Label>
                    <Form.Select
                      value={rank}
                      onChange={(e) => handleUpdateRank(e.target.value)}
                    >
                      <option value="">Select Rank</option>
                      <option value="bronze">Bronze</option>
                      <option value="silver">Silver</option>
                      <option value="gold">Gold</option>
                      <option value="diamond">Diamond</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="tarea p-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Years of Operation</Form.Label>
                    <Form.Select
                      value={years}
                      onChange={(e) => handleUpdateYears(e.target.value)}
                    >
                      <option value="">Select Years</option>
                      <option value="0,5">0 - 5 Years</option>
                      <option value="5,20">5 - 20 Years</option>
                      <option value="20,50">20 - 60 Years</option>
                      <option value="60,100">60 - 100 Years</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                {/* <div className="mt-5">
									<h5>Thematic Area</h5>
								</div> */}
                {/* <div className="tarea thematic-areas p-3">
									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="checkbox"
											value="Education"
											id="flexCheckDefault"
											onChange={(e) => handleUpdateArea(e.target.value)}
										/>
										<label
											className="form-check-label"
											htmlFor="flexCheckDefault"
										>
											Education
										</label>
									</div>
									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="flexCheckDefault"
										/>
										<label
											className="form-check-label"
											htmlFor="flexCheckDefault"
										>
											Disability
										</label>
									</div>
									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="flexCheckDefault"
										/>
										<label
											className="form-check-label"
											htmlFor="flexCheckDefault"
										>
											Poverty
										</label>
									</div>
									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="flexCheckDefault"
										/>
										<label
											className="form-check-label"
											htmlFor="flexCheckDefault"
										>
											Orphanages and Shelters
										</label>
									</div>

									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="flexCheckDefault"
										/>
										<label
											className="form-check-label"
											htmlFor="flexCheckDefault"
										>
											Health
										</label>
									</div>

									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="flexCheckDefault"
										/>
										<label
											className="form-check-label"
											htmlFor="flexCheckDefault"
										>
											Economic/Community Development
										</label>
									</div>
									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="flexCheckDefault"
										/>
										<label
											className="form-check-label"
											htmlFor="flexCheckDefault"
										>
											Democracy and Good Governance
										</label>
									</div>
									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="flexCheckDefault"
										/>
										<label
											className="form-check-label"
											htmlFor="flexCheckDefault"
										>
											Civil Rights and Social Action
										</label>
									</div>
									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="flexCheckDefault"
										/>
										<label
											className="form-check-label"
											htmlFor="flexCheckDefault"
										>
											Peace and Conflict Resolution
										</label>
									</div>
									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="flexCheckDefault"
										/>
										<label
											className="form-check-label"
											htmlFor="flexCheckDefault"
										>
											Corporative and Microfinance
										</label>
									</div>
									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="flexCheckDefault"
										/>
										<label
											className="form-check-label"
											htmlFor="flexCheckDefault"
										>
											Reforms
										</label>
									</div>
									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="flexCheckDefault"
										/>
										<label
											className="form-check-label"
											htmlFor="flexCheckDefault"
										>
											Information Communication and Technology
										</label>
									</div>

									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="flexCheckDefault"
										/>
										<label
											className="form-check-label"
											htmlFor="flexCheckDefault"
										>
											Crime and Law Related
										</label>
									</div>
									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="flexCheckDefault"
										/>
										<label
											className="form-check-label"
											htmlFor="flexCheckDefault"
										>
											Youth and Youth Development
										</label>
									</div>
									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="flexCheckDefault"
										/>
										<label
											className="form-check-label"
											htmlFor="flexCheckDefault"
										>
											Trade
										</label>
									</div>
									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="flexCheckDefault"
										/>
										<label
											className="form-check-label"
											htmlFor="flexCheckDefault"
										>
											Migration
										</label>
									</div>
									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="flexCheckDefault"
										/>
										<label
											className="form-check-label"
											htmlFor="flexCheckDefault"
										>
											Gender and Social Inclusion
										</label>
									</div>
								</div> */}
                {/* <div className="mt-5">
									<h5>Years in Operation</h5>
								</div> */}
                {/* <div className="tarea p-3">
									<Form.Group className="mb-3">
										<Form.Select>
											<option>0 - 5 Years</option>
											<option value="1">5 - 20 Years</option>
											<option value="2">20 - 60 Years</option>
											<option value="3">60 - 100 Years</option>
										</Form.Select>
									</Form.Group>
								</div> */}
              </Form>
            </div>
          </div>
          {isDataLoading ? (
            <div className="col-md-9">
              <div className="loading-container">
                <Oval wrapperClass="ml-2" height={50} width={50} />
              </div>
            </div>
          ) : (
            <div className="col-md-9">
              {data?.data?.map((item, idx) => (
                <div className="direct-cards bg-white p-4 mb-3" key={idx}>
                  <div className="d-flex mobile-row">
                    <img
                      src={item?.orgfile?.org_logo || act}
                      alt="logo"
                      className="img-fluid me-3"
                      style={{
                        height: "100%",
                        width: "120px",
                        objectFit: "contain",
                      }}
                    />
                    <div className="text-direct">
                      <h2>
                        <Link
                          onClick={() =>
                            item?.profile_ranking === "bronze"
                              ? handleNavigationError()
                              : null
                          }
                          to={
                            item?.profile_ranking === "bronze"
                              ? ""
                              : `/single-directory/${item.id}`
                          }
                        >
                          {item?.organization}
                        </Link>
                      </h2>
                      <div className="d-flex mobile-row my-3">
                        <Link
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#rank-meaning"
                        >
                          {item?.profile_ranking === "diamond" ? (
                            <img
                              src={diamond}
                              alt="ranking-stars"
                              className="img-fluid rank-level"
                            />
                          ) : item?.profile_ranking === "gold" ? (
                            <img
                              src={gold}
                              alt="ranking-stars"
                              className="img-fluid rank-level"
                            />
                          ) : item?.profile_ranking === "silver" ? (
                            <img
                              src={silver}
                              alt="ranking-stars"
                              className="img-fluid rank-level"
                            />
                          ) : (
                            <img
                              src={bronze}
                              alt="ranking-stars"
                              className="img-fluid rank-level"
                            />
                          )}
                        </Link>
                        <h6 className="me-4 mt-2">
                          <i className="bi bi-telephone me-2"></i>
                          {item?.organization_phone}
                        </h6>
                        <h6 className="me-4 mt-2">
                          <i className="bi bi-globe me-2"></i>
                          {item?.organization_email}
                        </h6>
                      </div>
                      <p>
                        <i className="bi bi-geo-alt me-2"></i> {item?.address}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={data?.total / 10 || 1}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={handlePaginateChange}
                containerClassName={"paginate"}
                activeClassName={"active"}
                forcePage={currPage - 1}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className="modal fade"
        id="rank-meaning"
        tabIndex="-1"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row rankie">
                <div className="col-sm-12 text-center headtit">
                  <h2>What do these ranks mean?</h2>
                  <hr />
                  <p>
                    The various ranks identifies the presence of organizations
                    <br />
                    on the platform and their track record
                  </p>
                </div>
                <div className="col-md-12">
                  <div className="rankcard mt-4">
                    <div className="row">
                      <div className="col-md-3 mb-2">
                        <img src={dia} alt="diamond" className="img-fluid" />
                      </div>
                      <div className="col-md-9 mb-2">
                        <h6>
                          This CSO must have greater than 12 number for each
                          activity (news, projects, resources) on the platform
                          before getting the diamond rank.
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="rankcard my-4">
                    <div className="row">
                      <div className="col-md-3 mb-2">
                        <img src={gold} alt="gold" className="img-fluid" />
                      </div>
                      <div className="col-md-9 mb-2">
                        <h6>
                          This CSO must have at least 6 - 12 number for each
                          activity (news, projects, resources) on the platform
                          before getting the gold rank.
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="rankcard mb-4">
                    <div className="row">
                      <div className="col-md-3 mb-2">
                        <img src={silver} alt="diamond" className="img-fluid" />
                      </div>
                      <div className="col-md-9 mb-2">
                        <h6>
                          This CSO must have lesser than or equal to 5 number
                          for each activity (news, projects, resources) on the
                          platform before getting the bronze rank.
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="rankcard my-4">
                    <div className="row">
                      <div className="col-md-3 mb-2">
                        <img src={bronze} alt="bronze" className="img-fluid" />
                      </div>
                      <div className="col-md-9 mb-2">
                        <h6>The CSO that have not been verified.</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DirectoryContent;
