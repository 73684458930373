import CSOSidebar from "./dashboardParts/csoSidebar";
import EditProfile from "./dashboardParts/editProfile";


function EditSettings() {
  return (
    <div style={{backgroundColor: "#fff"}}>
      <CSOSidebar/>
      <EditProfile/>
    </div>
  );
}

export default EditSettings;
