import axiosClient from "../utlis/axiosClient";

export const getAllCSOs = async () => {
  try {
    const response = await axiosClient.get(`/admin/cso`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const getSingleCSO = async (id) => {
  try {
    const response = await axiosClient.get(`/admin/cso/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const updateOrgStatus = async ({ id, data }) => {
  try {
    const response = await axiosClient.put(`/admin/cso/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const bulkUpdateOrgStatus = async (data) => {
  try {
    const response = await axiosClient.post(
      `/admin/cso/bulk-update-status`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const getAllProjects = async ({
  page = 1,
  page_size = 9,
  name = "",
}) => {
  try {
    const response = await axiosClient.get(
      `/admin/projects?page=${page}&page_size=${page_size}&project_search=${name}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const getSingleProject = async (id) => {
  try {
    const response = await axiosClient.get(
      `/admin/projects/fetch_project/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const getOverviewData = async () => {
  try {
    const response = await axiosClient.get(`/admin/count_data`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const createAdmin = async (data) => {
  try {
    const response = await axiosClient.post(`/admin/create_admin`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const updateAdmin = async ({ id, data }) => {
  try {
    const response = await axiosClient.put(`/admin/update-admin/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const getAllAdmins = async ({ page = 1, page_size = 10 }) => {
  try {
    const response = await axiosClient.get(
      `/v1/list-admins?page=${page}&page_size=${page_size}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};
