import ElearningView from './dashboardParts/elearningView';
import Sidebar from './dashboardParts/sideBar'

function MISElearning() {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Sidebar/>
      <ElearningView/>
    </div>
  )
}

export default MISElearning;
