import ListingView from './dashboardParts/listingView'
import Adminsidebar from './dashboardParts/adminsidebar'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getSingleCSO } from '../handlers/adminHandler'
import { useParams } from 'react-router-dom'

function AdminViewListing() {
	const { id } = useParams()
	const { data: csoData, isLoading: isDataLoading } = useQuery(
		`admin-cso-${id}`,
		() => getSingleCSO(id)
	)

	return (
		<div style={{ backgroundColor: '#FAFAFA' }}>
			<Adminsidebar />
			<ListingView data={csoData} isDataLoading={isDataLoading} />
		</div>
	)
}

export default AdminViewListing
