import EditFeeds from './dashboardParts/editFeeds'
import Sidebar from './dashboardParts/sideBar'

function NewsFeedEdit() {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Sidebar />
      <EditFeeds/>
    </div>
  )
}

export default NewsFeedEdit
