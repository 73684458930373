function DirectoryBanner({ handleSearch }) {
	return (
		<section className="directorybanner ani">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-6 text-center">
						<h1 className="text-white">Discover organizations around you </h1>
						<h6 className="text-white mt-3">
							One Stop Hub for CSOs in Nigeria
						</h6>
						<div className="directory-search mt-5 p-2">
							<div className="form-search">
								<i className="bi bi-search"></i>
								<input
									type="text"
									className="form-control form-input"
									placeholder="Search organizations"
									onChange={handleSearch}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default DirectoryBanner
