import { Link, useNavigate } from 'react-router-dom'
import Button from '../../component/Button'
import { useMutation, useQueryClient } from 'react-query'
import { createResource } from '../../handlers/resourceHandler'
import { toast } from '../../component/toast'
import { Form, Formik } from 'formik'

function CreateLink() {
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const { mutate: createResourceMutation, isLoading: isLoading } = useMutation(
		createResource,
		{
			onSuccess: async (res) => {
				toast.success('Resource created successfully')
				await queryClient.invalidateQueries(['resources'])
				navigate('/mis/resources')
			},
			onError: (e) => {
				toast.error(e)
			},
		}
	)

	const handleSubmit = (values) => {
		createResourceMutation(values)
	}
	return (
		<section className="">
			<div className="content overview">
				<Formik
					initialValues={{
						title: '',
						link: '',
						type: 'link',
					}}
					onSubmit={(values) => handleSubmit(values)}
				>
					{({
						values,
						handleChange,
						handleSubmit,
						handleBlur,
						setFieldValue,
						errors,
					}) => (
						<Form onSubmit={handleSubmit}>
							<div className="row justify-content-between">
								<div className="col-md-8 mt-4 sbm">
									<Link to="/mis/resources">
										<i className="bi bi-arrow-left me-2"></i> Back
									</Link>
								</div>
								<div className="col-md-4 text-end mt-3">
									<div className="d-flex flex-row">
										<Link
											to="/mis/resources"
											className="btn btn-success w-50 sbm-btn mb-3 mx-3"
										>
											Cancel
										</Link>
										<Button
											className="btn btn-success w-50 login-btn mb-3"
											type="submit"
										>
											Publish Resource
										</Button>
									</div>
								</div>
							</div>
							<div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
								<hr />
							</div>
							<div className="row mt-5">
								<div className="col">
									<h3>Add New Link Resource</h3>
								</div>
								<div className="sendbulk p-5">
									<div className="row justify-content-center">
										<div className="col-md-6">
											<div className="mb-3">
												<label className="form-label">Title of Link</label>
												<input
													type="text"
													className="form-control"
													placeholder="What is the title?"
													name="title"
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.title}
													required={true}
												/>
											</div>
											<div className="mb-3">
												<label className="form-label">Resource Link</label>
												<input
													type="url"
													className="form-control"
													name="link"
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.link}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</section>
	)
}

export default CreateLink
