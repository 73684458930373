import React from 'react'
import logo from '../../img/logo-white.png'
import avi from '../../img/avatar.svg'
import { AuthContext } from '../../context/AuthContext'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { removeLocalStorageItem, getLocalStorageItem } from '../../utlis/helper'
import { toast } from '../../component/toast'
import { getOverviewData } from '../../handlers/adminHandler'
import { useQuery} from 'react-query'



function Adminsidebar () {
	const location = useLocation(); 
	const isLinkActive = (path) => {
		return location.pathname === path;
	};
	const { data } = useQuery(['admin-overview'], () =>
		getOverviewData()
	)

	const navigate = useNavigate()
	const {
		isAuthenticated,
		userInfo,
		handleUpdateInfo,
		handleUpdateAuthenticated,
	} = React.useContext(AuthContext)

	React.useEffect(() => {
		const encryptedUserInfo = getLocalStorageItem('userInfo')
		if (!encryptedUserInfo) {
			navigate('/login')
			toast.error('You are not authorized to view this page, kindly login!')
		}
	}, [navigate])

	const handleLogout = () => {
		handleUpdateAuthenticated(false)
		handleUpdateInfo(null)
		removeLocalStorageItem('userInfo')
		navigate('/login')
	}
	return (
		<section className="">
			<div className="sidebar p-4 flex-sm-column">
				<div className="text-center">
					<img
						src={logo}
						alt="logo"
						className="img-fluid"
						style={{ width: 120, height: 'auto' }}
					/>
				</div>
				<button
					className="navbar-toggler mobile"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="bi bi-caret-down fs-1 text-white"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<div>
						<Link to="/admin/overview">
							<i className="bi bi-list-task me-2 fs-5"></i> Overview
						</Link>
						<Link to="/admin/listing">
							<i className="bi bi-list-task me-2 fs-5"></i> Listing
						</Link>
						<Link to="/admin/news">
							<i className="bi bi-newspaper me-2 fs-5"></i> News Feed
						</Link>
						<Link to="/admin/projects">
							<i className="bi bi-layers me-2 fs-5"></i> Projects
						</Link>
						<Link to="/admin/resources">
							<i className="bi bi-box me-2 fs-5"></i> Resource Center
						</Link>
						<Link to="/admin/opportunities">
							<i className="bi bi-lightbulb me-2 fs-5"></i> Grants{' '}
							<span className="badge nots ms-4">{data?.grants || '-'}</span>
						</Link>
						<Link to="/admin/messages">
							<i className="bi bi-envelope me-2 fs-5"></i> Messages{' '}
							<span className="badge nots ms-4">{data?.msgs || '-'}</span>
						</Link>
						<Link to="">
							<i className="bi bi-book me-2 fs-5"></i> E-learning
						</Link>
						<Link to="/admin/compliance">
							<i className="bi bi-patch-question me-2 fs-5"></i> Compliance Reqs
						</Link>
					</div>
					<div className="py-sm-4 mt-sm-auto ms-auto ms-sm-0">
						<Link to="/admin/settings">
							<i className="bi bi-gear me-2 fs-5"></i> Settings
						</Link>
						<hr className="underline" />
						<div className="d-flex flex-row">
							<img src={avi} alt="avi" className="img-fluid" />
							<div className="mx-3 mt-2">
								<h5 className="text-white">{userInfo?.name}</h5>
								<p className="text-white">{userInfo?.email}</p>
							</div>
							<span style={{ cursor: 'pointer', marginTop: "20px" }} onClick={handleLogout}>
								<i className="bi bi-box-arrow-right mt-3 fs-5 text-white"></i>
							</span>
						</div>
					</div>
				</div>
				<div className="desktop mt-5">
					<Link to="/admin/overview" className={isLinkActive('/admin/overview') ? 'active-link' : ''}>
						<i className="bi bi-list-task me-2 fs-5"></i> Overview
					</Link>
					<Link to="/admin/listing" className={isLinkActive('/admin/listing') ? 'active-link' : ''}>
						<i className="bi bi-list-task me-2 fs-5"></i> Listing
					</Link>
					<Link to="/admin/news" className={isLinkActive('/admin/news') ? 'active-link' : ''}>
						<i className="bi bi-newspaper me-2 fs-5"></i> News Feed
					</Link>
					<Link to="/admin/projects" className={isLinkActive('/admin/projects') ? 'active-link' : ''}>
						<i className="bi bi-layers me-2 fs-5"></i> Projects
					</Link>
					<Link to="/admin/resources" className={isLinkActive('/admin/resources') ? 'active-link' : ''}>
						<i className="bi bi-box me-2 fs-5"></i> Resource Center
					</Link>
					<Link to="/admin/opportunities" className={isLinkActive('/admin/opportunities') ? 'active-link' : ''}>
						<i className="bi bi-lightbulb me-2 fs-5"></i> Grants{' '}
						<span className="badge nots ms-4">{data?.grants || '-'}</span>
					</Link>
					<Link to="/admin/messages" className={isLinkActive('/admin/messages') ? 'active-link' : ''}>
						<i className="bi bi-envelope me-2 fs-5"></i> Messages{' '}
						<span className="badge nots ms-4">{data?.msgs || '-'}</span>
					</Link>
					<Link to="">
						<i className="bi bi-book me-2 fs-5"></i> E-learning
					</Link>
					<Link to="/admin/compliance" className={isLinkActive('/admin/compliance') ? 'active-link' : ''}>
						<i className="bi bi-patch-question me-2 fs-5 mt-3"></i> Compliance Reqs
					</Link>
				</div>
				<div className="py-sm-4 mt-sm-auto ms-auto ms-sm-0 desktop">
					<Link to="/admin/settings" className={isLinkActive('/admin/settings') ? 'active-link' : ''}>
						<i className="bi bi-gear me-2 fs-5"></i> Settings
					</Link>
					<hr className="underline" />
					<div className="d-flex flex-row">
						<img src={avi} alt="avi" className="img-fluid" />
						<div className="mx-3 mt-2">
							<h5 className="text-white">{userInfo?.name}</h5>
							<p className="text-white">{userInfo?.email}</p>
						</div>
						<span style={{ cursor: 'pointer', marginTop: "20px" }} onClick={handleLogout}>
							<i className="bi bi-box-arrow-right mt-3 fs-5 text-white"></i>
						</span>
					</div>
				</div>
			</div>

			{/* </div> */}
		</section>
	)
}

export default Adminsidebar
