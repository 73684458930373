import CSOSidebar from './dashboardParts/csoSidebar'
import Projects from './dashboardParts/Projects'

function CSOProjects() {
	return (
		<div style={{ backgroundColor: '#fff' }}>
			<CSOSidebar />
			<Projects />
		</div>
	)
}

export default CSOProjects
