import React from "react";
import Adminsidebar from "./dashboardParts/adminsidebar";
import SettingsAdmin from "./dashboardParts/settingsAdmin";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllAdmins } from "../handlers/adminHandler";

function AdminSettings() {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const { data, isLoading } = useQuery(["all-admins", page, pageSize], () =>
    getAllAdmins({ page, page_size: pageSize })
  );
  const handlePaginateChange = async (page) => {
    setPage(page.selected + 1);
  };
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Adminsidebar />
      <SettingsAdmin
        data={data}
        page={page}
        handlePaginateChange={handlePaginateChange}
      />
    </div>
  );
}

export default AdminSettings;
