import { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
// import edit from '../../img/edit.png'
import Form from 'react-bootstrap/Form'
// import { Button } from 'react-bootstrap'

import { FileUploader } from 'react-drag-drop-files'
import { Form as FormikForm, Formik } from 'formik'
import Input from '../../component/Input'
// import Select from '../../component/Select'
import Button from '../../component/Button'
import { s3Config } from '../../utlis/s3config'
import ReactS3Client from 'react-aws-s3-typescript'
import { Oval } from 'react-loader-spinner'
import { toast } from '../../component/toast'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { getSingleFeed, updateFeed } from '../../handlers/newsHandler'

const fileTypes = ['JPEG', 'PNG', 'JPG']

function AdminEditFeeds() {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { data: feedData } = useQuery(['feeds', id], () => getSingleFeed(id))
  const [featuredImgFileObj, setFeaturedImgFileObj] = useState({
    name: '',
    loading: false,
  })
  const [featuredImgUrl, setFeaturedImgUrl] = useState('')
  const [featuredImage, setFeaturedImage] = useState('')

  let initialValues = {
    title: feedData?.title,
    content: feedData?.content,
    author: feedData?.author,
  }

  useEffect(() => {
    if (feedData?.image) {
      setFeaturedImage(feedData?.image)
    }
  }, [feedData])

  const handleFileUpload = async file => {
    const s3 = new ReactS3Client({ ...s3Config })
    try {
      const res = await s3.uploadFile(file)
      console.log({ res })
      return res.location
    } catch (exception) {
      console.log(exception)
    }
  }

  const handleFeaturedImgUpload = async file => {
    setFeaturedImgFileObj({
      name: file?.name,
      loading: true,
    })
    const loc = await handleFileUpload(file)
    setFeaturedImgUrl(loc)
    setFeaturedImgFileObj(e => ({ ...e, loading: false }))
  }

  const { mutate: updateFeedMutation, isLoading } = useMutation(updateFeed, {
    onSuccess: async res => {
      toast.success('News created successfully')
      await queryClient.invalidateQueries(['feeds'])
      navigate('/admin/news')
    },
    onError: e => {
      toast.error(e)
    },
  })
  const handleSubmit = values => {
    const payload = {
      title: values.title,
      content: values.content,
      image: featuredImgUrl ? featuredImgUrl : feedData?.image,
    }
    updateFeedMutation({ id: feedData?.id, data: payload })
  }

  return (
    <section>
      <div className='content overview'>
        <div className='row justify-content-between'>
          <div className='col-md-6 mt-3 back'>
            <Link to={`/admin/news/${id}`}>
              <i className='bi bi-arrow-left'></i> Back
            </Link>
          </div>
        </div>
        <div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
          <hr />
        </div>
        <div className='row mt-5'>
          <div className='col'>
            <h3>Edit News Item</h3>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={values => handleSubmit(values)}>
            {({
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue,
              errors,
            }) => (
              <FormikForm className='row mt-5' onSubmit={handleSubmit}>
                <div className='col-md-8'>
                  <>
                    <Form.Group className='mb-3' controlId=''>
                      <Input
                        type='text'
                        name='title'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                        label='Title'
                      />
                    </Form.Group>
                    <div className='mb-4'>
                      <label className='form-label'>Featured Image</label>
                      {featuredImage ? (
                        <div className='col'>
                          <div className=''>
                            <div className='up-items'>
                              <div className='d-flex flex-row items-center'>
                                <img
                                  src={feedData?.image}
                                  alt='new-img'
                                  className='img-fluid'
                                  style={{
                                    width: '146px',
                                    height: '82px',
                                    objectFit: 'cover',
                                  }}
                                />
                                <div className='ms-3'>
                                  <h5>{feedData?.image?.split('/')[4]}</h5>
                                </div>
                              </div>
                            </div>
                            <span
                              className='float-end removd mt-2'
                              style={{ cursor: 'pointer' }}
                              onClick={() => setFeaturedImage(null)}>
                              <i className='bi bi-archive me-2 fs-6'></i>
                              Remove
                            </span>
                          </div>
                        </div>
                      ) : (
                        <Form.Group className='mb-3'>
                          <Form.Label>Upload Featured Image</Form.Label>
                          <FileUploader
                            multiple={false}
                            handleChange={handleFeaturedImgUpload}
                            name='file'
                            label='Drag and drop document here or Browse'
                            types={fileTypes}
                          />
                          <div className='uploader-loading-block'>
                            <p style={{ margin: 0 }}>
                              {featuredImgFileObj.loading
                                ? `${featuredImgFileObj.name} Uploading`
                                : featuredImgFileObj.name
                                ? featuredImgFileObj.name
                                : 'No file(s) uploaded yet'}
                            </p>
                            {featuredImgFileObj.loading && (
                              <Oval
                                wrapperClass='ml-2'
                                height={20}
                                width={20}
                              />
                            )}
                          </div>
                        </Form.Group>
                      )}
                    </div>
                    <Form.Group className='mb-4' controlId=''>
                      <Input
                        type='text'
                        name='content'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.content}
                        as='textarea'
                        maxLength={1500}
                        minLength={300}
                        rows={10}
                        placeholder='Enter content'
                        label='Content'
                      />
                      <Form.Text
                        id='passwordHelpBlock'
                        muted
                        className='float-end'>
                        {values?.content?.length} / 1500 Words
                      </Form.Text>
                    </Form.Group>
                  </>
                </div>
                <div className='col-md-4'>
                  <div className='mb-4'>
                    <Input
                      type='text'
                      name='author'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.author}
                      label='Author'
                    />
                  </div>
                </div>
                <div className='row justify-content-end mt-5 '>
                  <div className='col-md-4'>
                    <div className='d-flex flex-row'>
                      <Button
                        type='button'
                        className='btn btn-success login-btn me-3 w-100'
                        onClick={() => navigate(`/admin/news/${id}`)}>
                        Cancel
                      </Button>
                      <Button
                        type={'submit'}
                        className='btn btn-success bg-white login-btn lin w-100'
                        loading={isLoading}>
                        Update News
                      </Button>
                    </div>
                  </div>
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>
    </section>
  )
}

export default AdminEditFeeds
