import React from "react";
import avatar from "../../img/user-img.svg";
import add from "../../img/uas.svg";
import member from "../../img/new-member.svg";
import { Link } from "react-router-dom";
import MISHeaderDropdownMenu from "./MISHeaderDropdownMenu";
import { AuthContext } from "../../context/AuthContext";
import { Button, Col, Form, Row } from "react-bootstrap";
import TeamMember from "./team-member/TeamMember";
import AddTeamMember from "./team-member/AddTeamMember";

function ElearningView() {
  const { userInfo } = React.useContext(AuthContext);
  return (
    <section>
      <div className="content overview">
        <div className="row justify-content-between">
          <div className="col-md-5 mb-2">
            <div className="form-group has-search">
              <span className="bi bi-search form-control-feedback"></span>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
              />
            </div>
          </div>
          <div className="col-md-3 text-end">
            <div className="d-flex justify-content-end">
              <p className="bg-lt me-5 mt-1">
                <i className="bi bi-circle-fill fs-6 me-2"></i> MIS
              </p>
              <div className="dropdown">
                <Link
                  to="#"
                  className="dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  {userInfo?.organization?.orgfile?.org_logo ? (
                    <img
                      src={userInfo?.organization?.orgfile?.org_logo}
                      alt="avatar"
                      className="profile-topbar-avi"
                    />
                  ) : (
                    <img src={avatar} alt="avi" className="img-fluid" />
                  )}
                </Link>
                <MISHeaderDropdownMenu />
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
          <hr />
        </div>
        <div className="row justify-content-between">
          <div className="col-md-6 mt-3">
            <h4>Welcome to the E-Learning Page</h4>
            <p>Find trainings, programs, resources, and more</p>
          </div>
        </div>
        <TeamMember />
        {/* <div className="row listings-tog mt-3">
          <div className="col">
            <ul className="nav nav-tabs tog" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active tog-btn"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected="true"
                >
                  Courses
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="post-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#post-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="post-tab-pane"
                  aria-selected="false"
                >
                  Programs & Trainings
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="l-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#l-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="l-tab-pane"
                  aria-selected="false"
                >
                  Learning Resources
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="t-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#t-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="t-tab-pane"
                  aria-selected="false"
                >
                  Team Members
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home-tab-pane"
                role="tabpanel"
                aria-labelledby="home-tab"
                tabIndex="0"
              ></div>
              <div
                className="tab-pane fade"
                id="post-tab-pane"
                role="tabpanel"
                aria-labelledby="post-tab"
                tabIndex="0"
              ></div>
              <div
                className="tab-pane fade"
                id="post-tab-pane"
                role="tabpanel"
                aria-labelledby="post-tab"
                tabIndex="0"
              ></div>
              <div
                className="tab-pane fade"
                id="t-tab-pane"
                role="tabpanel"
                aria-labelledby="t-tab"
                tabIndex="0"
              >
                
              </div>
            </div>
          </div>
        </div> */}
        {/* Update Member  */}
        <div
          className="modal fade"
          id="update-member"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content px-3">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row listing-modal">
                  <div className="col">
                    <img
                      src={add}
                      alt="update"
                      className="img-fluid mb-3"
                      style={{ marginTop: "-30px" }}
                    />
                    <h4>Update Team Member Status </h4>
                    <div className="update-card mt-3">
                      <h4>Olaoluwa Samuel</h4>
                      <p>abioyesamuel@arcasolutions.com</p>
                    </div>
                    <div className="form-check my-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Active
                      </label>
                    </div>
                    <div className="form-check mb-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        Inactive
                      </label>
                    </div>
                    <div className="d-flex flex-row">
                      <Button
                        type={"button"}
                        className="btn btn-success border-g bg-white w-100 cancel-btn mt-3 me-3"
                      >
                        Cancel
                      </Button>
                      <Button
                        type={"button"}
                        className="btn btn-success w-100 login-btn mt-3"
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add Member  */}
        <div
          className="modal fade"
          id="add-member"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <AddTeamMember />
        </div>
        {/* Edit Member  */}
        <div
          className="modal fade"
          id="edit-member"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content px-3">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row listing-modal">
                  <div className="col">
                    <img
                      src={member}
                      alt="update"
                      className="img-fluid mb-3"
                      style={{ marginTop: "-30px" }}
                    />
                    <h4>Edit Team Member</h4>
                    <p>Add team members here</p>
                    <Form className="my-3">
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              defaultValue="Olamilekan"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" defaultValue="Akindele" />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group className="mb-3">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          type="email"
                          defaultValue="johndoe@gmail.com"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Create a Password</Form.Label>
                        <Form.Control type="password" />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Designation</Form.Label>
                        <Form.Control
                          type="text"
                          defaultValue="Program Manager"
                        />
                      </Form.Group>
                    </Form>
                    <div className="d-flex flex-row">
                      <Button
                        type={"button"}
                        className="btn btn-success border-g bg-white w-100 cancel-btn mt-3 me-3"
                      >
                        Cancel
                      </Button>
                      <Button
                        type={"button"}
                        className="btn btn-success w-100 login-btn mt-3"
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ElearningView;
