import Footer from '../component/footer'
import NavB from '../component/header'
import PrivacyContent from '../component/privacyContent'



function Privacy() {
  return (
    <div>
      <NavB />
      <PrivacyContent/>
      <Footer />
    </div>
  )
}

export default Privacy
