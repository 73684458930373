import React from "react";
import Footer from "../component/footer";
import NavB from "../component/header";
import ProjectsBanner from "../component/projectsBanner";
import ProjectsBottom from "../component/projectsBottom";
import ProjectsContent from "../component/projectsContent";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchPublicProjects } from "../handlers/publicHandler";
import { Oval } from "react-loader-spinner";

function Projects() {
  const [currPage, setCurrPage] = React.useState(1);
  const { data: data, isLoading: isDataLoading } = useQuery(
    ["projects", currPage],
    () => fetchPublicProjects(currPage)
  );
  const handlePaginateChange = async (page) => {
    setCurrPage(page.selected + 1);
  };
  return (
    <div>
      <NavB />
      <ProjectsBanner />
      {isDataLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 300,
          }}
        >
          <Oval wrapperClass="ml-2" height={40} width={40} />
        </div>
      ) : (
        <>
          <ProjectsContent
            data={data}
            handlePaginateChange={handlePaginateChange}
            currPage={currPage}
          />
          <ProjectsBottom />
        </>
      )}

      <Footer />
    </div>
  );
}

export default Projects;
