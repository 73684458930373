import React from 'react'
import uploaded from '../../../img/items.png'
import { Link, useNavigate } from 'react-router-dom'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup'
import Select from 'react-select'
import { Form as FormikForm, Formik } from 'formik'
import { updateBankDetail } from '../../../handlers/onboardingHandler'
import { toast } from '../../../component/toast'
import { useMutation, useQueryClient } from 'react-query'
import Button from '../../../component/Button'

const EditBankInfo = ({ data }) => {
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	let initialValues = {
		bank_name: data?.organization?.bankdetail?.bank_name || '',
		beneficiary: data?.organization?.bankdetail?.beneficiary || '',
		acct_no: data?.organization?.bankdetail?.acct_no || '',
	}

	const {
		mutate: updateBankDetailMutation,
		isLoading: isLoading,
	} = useMutation(updateBankDetail, {
		onSuccess: async (res) => {
			toast.success('Details saved successfully')
			await queryClient.invalidateQueries('cso-profile')
			navigate('/cso/settings')
		},
		onError: (e) => {
			toast.error(e)
		},
	})

	const handleSubmit = (values) => {
		updateBankDetailMutation({
			id: data?.organization?.bankdetail?.id,
			data: values,
		})
	}
	return (
		<div className="row mt-5">
			<Formik
				initialValues={initialValues}
				onSubmit={(values) => handleSubmit(values)}
				enableReinitialize={true}
			>
				{({
					values,
					handleChange,
					handleSubmit,
					handleBlur,
					setFieldValue,
					errors,
				}) => (
					<FormikForm onSubmit={handleSubmit}>
						<div className="col-md-8">
							<div className="row mb-3">
								<Form.Label column sm="4">
									Bank Details
								</Form.Label>
								<div className="col-md-8">
									<Form.Label>Bank Name</Form.Label>
									<Form.Control
										type="text"
										placeholder="1937982158745"
										className="mb-3"
										value={values?.bank_name}
										name="bank_name"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									<Form.Label>Beneficiary Name</Form.Label>
									<Form.Control
										type="text"
										placeholder="1937982158745"
										className="mb-3"
										value={values?.beneficiary}
										name="beneficiary"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									<Form.Label>Account Number</Form.Label>
									<Form.Control
										type="text"
										placeholder="1937982158745"
										className="mb-3"
										value={values?.acct_no}
										name="acct_no"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</div>
							</div>
						</div>
						<hr />
						<div className="row justify-content-end mt-5 ">
							<div className="col-md-4 text-end">
								<Button
									type="submit"
									className="btn btn-success register-btn mt-5 login-btn px-5"
									loading={isLoading}
								>
									Update Details
								</Button>
							</div>
						</div>
					</FormikForm>
				)}
			</Formik>
		</div>
	)
}

export default EditBankInfo
