import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import "./home.css";
// import App from './App'
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Toast from "./component/toast";
import AppAuthContext from "./context/AuthContext";

// import Logins from './pages/loginOptions'
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import ForgotPassword from "./pages/ForgotPassword";
import EmailSent from "./pages/emailSent";
import OTP from "./pages/otp";
import EmailVerified from "./pages/emailVerified";
import Onboarding from "./pages/Onboarding";
import CreateAccount from "./pages/createAccount";
import MISSignin from "./pages/mis/signIn";
import Listing from "./misDashboard/listing";
import ViewListing from "./misDashboard/viewListing";
import CSOOverview from "./csoDashboard/CsoOverview";
import CSOProjects from "./csoDashboard/CsoProjects";
import ProjectEmpty from "./csoDashboard/ProjectEmpty";
import ProjectView from "./csoDashboard/ProjectView";
import ProjectAdd from "./csoDashboard/ProjectAdd";
import ProjectEdit from "./csoDashboard/ProjectEdit";
// import Home from './pages/home'
import Directory from "./pages/directory";
import Projects from "./pages/projects";
import SingleProject from "./pages/singleProject";
import SingleDirectory from "./pages/singleDirectory";
import CSODonations from "./csoDashboard/donations";
import Campaign from "./csoDashboard/campaign";
import SingleDonation from "./csoDashboard/singleDonation";
import CreateDonations from "./csoDashboard/CreateDonations";
import Settings from "./csoDashboard/settings";
import EditSettings from "./csoDashboard/editSettings";
import Feeds from "./misDashboard/News";
import SingleFeed from "./misDashboard/singleFeed";
import OnboardingDoc from "./csoDashboard/onboardingDoc";
import Opportunities from "./misDashboard/opportunities";
import AddNews from "./misDashboard/addNews";
import Resources from "./misDashboard/resources";
import DirectoryProjects from "./pages/DirectoryProjects";
import MSIEditGrant from "./misDashboard/misEditGrant";

//admin
import AdminListing from "./adminDashboard/adminListing";
import AdminViewListing from "./adminDashboard/adminViewListing";
import AddArticle from "./misDashboard/addArticles";
import ResetPassword from "./pages/resetPassword";
import Messages from "./misDashboard/messages";
import EmptyFeeds from "./misDashboard/emptyNews";
import MessagesEmpty from "./misDashboard/messagesEmpty";
import Contact from "./pages/contact";
import AdminResources from "./adminDashboard/resourceCenter";
import AdminAddArticle from "./adminDashboard/adminAddArticles";
import AdminMessages from "./adminDashboard/adminMessages";
import AdminFeeds from "./adminDashboard/News";
import AdminSingleFeed from "./adminDashboard/adminSingleFeed";
import AdminAddNews from "./adminDashboard/adminAddNews";
import AdminOpportunities from "./adminDashboard/opportunities";
import AddGrant from "./adminDashboard/adminAddGrant";
import AdminProjects from "./adminDashboard/adminProjects";
import AdminSettings from "./adminDashboard/adminSettings";
import AdminSingleProject from "./adminDashboard/adminSingleProject";
import Privacy from "./pages/privacy";
import MessageSingle from "./adminDashboard/messageSingle";
import MISSettings from "./misDashboard/missettings";
import AdminMessageAdmin from "./adminDashboard/adminMessageAdmin";
import AdminSendBulk from "./adminDashboard/adminSendBulk";
import MISMessageA from "./misDashboard/misMessageA";
import MISSendBulk from "./misDashboard/misSendBulk";
import MISMessageSingle from "./misDashboard/misMessageSingle";
import NewsFeedEdit from "./misDashboard/newsFeedEdit";
import AdminNewFEdit from "./adminDashboard/adminNewFeedEdit";
import AdminOverview from "./adminDashboard/adminOverview";
import EmptyDirectory from "./pages/emptyDirectory";
import EditArticle from "./misDashboard/editArticle";
import ViewResource from "./misDashboard/ViewResource";
import UpdateStatus from "./adminDashboard/updateStatus";
import ApprovedStatus from "./adminDashboard/approvedStatus";
import SingleResourceAdmin from "./adminDashboard/singleresourceAdmin";
import EditResourcesAdmin from "./adminDashboard/EditResourcesAdmin";
import SingleGrant from "./misDashboard/grantSingle";
import CreateGrant from "./misDashboard/createGrant";
import AdminUpdateGrant from "./adminDashboard/adminUpdateGrant";
import AdminViewSingleGrant from "./adminDashboard/adminViewGrant";
import AdminEditGrant from "./adminDashboard/adminEditGrant";
import AddFile from "./misDashboard/addFile";
import AddLink from "./misDashboard/addLink";
import AdminAddFile from "./adminDashboard/adminAddFile";
import AdminAddLink from "./adminDashboard/adminAddLink";
import AdminCompliance from "./adminDashboard/adminCompliances";
import AdminComplianceBody from "./adminDashboard/adminComplianceBody";
import AdminViewSubmission from "./adminDashboard/adminviewSubmission";
import SingleQuestion from "./adminDashboard/singleQuestion";
import MISElearning from "./misDashboard/elearning";
import Aboutus from "./pages/aboutUs";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppAuthContext>
        <BrowserRouter>
          <Toast />
          <Routes>
            <Route path="/signup" element={<SignUp />}></Route>
            <Route path="/login" element={<SignIn />}></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route path="/sent-email" element={<EmailSent />}></Route>
            <Route path="/otp" element={<OTP />}></Route>
            <Route path="/verified" element={<EmailVerified />}></Route>
            <Route path="/onboarding" element={<Onboarding />}></Route>
            <Route path="/creating-account" element={<CreateAccount />}></Route>
            <Route path="/mis-login" element={<MISSignin />}></Route>
            <Route path="/" element={<Directory />}></Route>
            <Route path="/empty-dir" element={<EmptyDirectory />}></Route>
            <Route path="/projects" element={<Projects />}></Route>
            <Route path="/projects/:id" element={<SingleProject />}></Route>
            <Route path="/reset-password" element={<ResetPassword />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/privacy" element={<Privacy />}></Route>
            <Route path="/about-us" element={<Aboutus />}></Route>
            <Route
              path="/single-directory/:id"
              element={<SingleDirectory />}
            ></Route>
            <Route
              path="/single-directory/:id/projects"
              element={<DirectoryProjects />}
            ></Route>

            {/* MIS Dashboard */}
            <Route path="/mis/feeds" element={<Feeds />}></Route>
            <Route path="/mis/empty-feeds" element={<EmptyFeeds />}></Route>
            <Route path="/mis/listings" element={<Listing />}></Route>
            <Route path="/mis/view-listing" element={<ViewListing />}></Route>
            <Route path="/mis/feeds/:id" element={<SingleFeed />}></Route>
            <Route
              path="/mis/feeds/edit/:id/"
              element={<NewsFeedEdit />}
            ></Route>
            <Route
              path="/mis/opportunities"
              element={<Opportunities />}
            ></Route>
            <Route
              path="/mis/opportunities/:id"
              element={<SingleGrant />}
            ></Route>
            <Route
              path="/mis/opportunities/:id/edit"
              element={<MSIEditGrant />}
            ></Route>
            <Route path="/mis/add-grant" element={<CreateGrant />}></Route>
            <Route path="/mis/add-news" element={<AddNews />}></Route>
            <Route path="/mis/resources" element={<Resources />}></Route>
            <Route
              path="/mis/resources/add-resource"
              element={<AddArticle />}
            ></Route>
            <Route path="/mis/resources/add-file" element={<AddFile />}></Route>
            <Route path="/mis/resources/add-link" element={<AddLink />}></Route>
            <Route
              path="/mis/resources/:id/edit"
              element={<EditArticle />}
            ></Route>
            <Route path="/mis/resources/:id" element={<ViewResource />}></Route>
            <Route path="/mis/messages" element={<Messages />}></Route>
            <Route
              path="/mis/messages/:id"
              element={<MISMessageSingle />}
            ></Route>
            <Route
              path="/mis/messages-empty"
              element={<MessagesEmpty />}
            ></Route>
            <Route path="/mis/message-admin" element={<MISMessageA />}></Route>
            {/* <Route path="/mis/messaging" element={<MISMessageSingle />}></Route> */}
            <Route path="/mis/sendbulk" element={<MISSendBulk />}></Route>
            <Route path="/mis/settings" element={<MISSettings />}></Route>
            <Route path="/mis/elearning" element={<MISElearning />}></Route>

            {/* CSO Dashboard */}
            <Route path="/cso/overview" element={<CSOOverview />}></Route>
            <Route path="/cso/projects" element={<CSOProjects />}></Route>
            <Route path="/cso/add-project" element={<ProjectEmpty />}></Route>
            <Route
              path="/cso/view-project/:id"
              element={<ProjectView />}
            ></Route>
            <Route
              path="/cso/view-project/:id/edit"
              element={<ProjectEdit />}
            ></Route>
            <Route path="/cso/create-project" element={<ProjectAdd />}></Route>
            <Route path="/cso/donations" element={<CSODonations />}></Route>
            <Route path="/cso/campaign" element={<Campaign />}></Route>

            <Route
              path="/cso/single-donation"
              element={<SingleDonation />}
            ></Route>
            <Route
              path="/cso/create-campaign"
              element={<CreateDonations />}
            ></Route>
            <Route path="/cso/settings" element={<Settings />}></Route>
            <Route path="/cso/edit-profile" element={<EditSettings />}></Route>
            <Route
              path="/cso/onboarding-doc"
              element={<OnboardingDoc />}
            ></Route>

            {/* Admin */}
            <Route path="/admin/overview" element={<AdminOverview />}></Route>
            <Route path="/admin/listing" element={<AdminListing />}></Route>
            <Route path="/admin/resources" element={<AdminResources />}></Route>
            <Route
              path="/admin/resources/:id/edit"
              element={<EditResourcesAdmin />}
            ></Route>
            <Route
              path="/admin/resources/:id"
              element={<SingleResourceAdmin />}
            ></Route>
            <Route
              path="/admin/resources/add-text"
              element={<AdminAddArticle />}
            ></Route>
            <Route
              path="/admin/resources/add-link"
              element={<AdminAddLink />}
            ></Route>
            <Route
              path="/admin/resources/add-file"
              element={<AdminAddFile />}
            ></Route>
            <Route path="/admin/messages" element={<AdminMessages />}></Route>
            <Route
              path="/admin/messages/:id"
              element={<MessageSingle />}
            ></Route>
            <Route
              path="/message-admin"
              element={<AdminMessageAdmin />}
            ></Route>
            <Route path="/sendbulk" element={<AdminSendBulk />}></Route>
            <Route path="/admin/news" element={<AdminFeeds />}></Route>
            <Route path="/admin/news/:id" element={<AdminSingleFeed />}></Route>
            <Route
              path="/admin/news/edit/:id/"
              element={<AdminNewFEdit />}
            ></Route>
            <Route path="/admin/add-news" element={<AdminAddNews />}></Route>
            <Route
              path="/admin/opportunities"
              element={<AdminOpportunities />}
            ></Route>
            <Route path="/admin/add-grant" element={<AddGrant />}></Route>
            <Route
              path="/admin/opportunities/:id/edit"
              element={<AdminEditGrant />}
            ></Route>
            <Route
              path="/admin/opportunities/:id"
              element={<AdminViewSingleGrant />}
            ></Route>
            <Route
              path="/admin/opportunities/:id/update-status"
              element={<AdminUpdateGrant />}
            ></Route>
            <Route path="/admin/projects" element={<AdminProjects />}></Route>
            <Route path="/admin/approved" element={<ApprovedStatus />}></Route>
            <Route
              path="/admin/resources/:id/update-status"
              element={<UpdateStatus />}
            ></Route>
            <Route
              path="/admin/projects/:id"
              element={<AdminSingleProject />}
            ></Route>
            <Route path="/admin/settings" element={<AdminSettings />}></Route>
            <Route
              path="/admin/create-compliance"
              element={<AdminCompliance />}
            ></Route>
            <Route
              path="/admin/compliance"
              element={<AdminComplianceBody />}
            ></Route>
            <Route
              path="/admin/compliance/:id"
              element={<AdminViewSubmission />}
            ></Route>
            <Route
              path="/admin/view-question"
              element={<SingleQuestion />}
            ></Route>
            <Route
              path="/admin/view-listing/:id"
              element={<AdminViewListing />}
            ></Route>
          </Routes>
        </BrowserRouter>
      </AppAuthContext>
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
