import React from "react";
import { Link } from "react-router-dom";
import client from "../../img/client.svg";
import grant from "../../img/op.svg";
import update from "../../img/update.svg";
import resourceimg from "../../img/women.svg";
import placeholderImg from "../../img/placeholder.jpg";
import dayjs from "dayjs";
import FileSaver from "file-saver";
import { Button, Form } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
// import TeamMember from "../../misDashboard/dashboardParts/team-member/TeamMember";

function ListingView({ data, isDataLoading }) {
  const downloadImage = (image_url) => {
    const arr = image_url.split("/");
    const arrLen = arr?.length;
    const name = arr[arrLen - 1];
    FileSaver.saveAs(image_url, name); // Put your image url here.
  };

  const formatLink = (key, value) => {
    if (key === "Instagram") {
      return value.includes("https") ? value : "https://instagram.com/" + value;
    }
    if (key === "Twitter") {
      return value.includes("https") ? value : "https://twitter.com/" + value;
    }
    if (key === "Facebook") {
      return value.includes("https") ? value : "https://facebook.com/" + value;
    }
    return "";
  };

  return (
    <section className="">
      <div className="content overview">
        <div className="row">
          <div className="col-md-8 back">
            <Link to="/admin/listing">
              <i className="bi bi-arrow-left me-2"></i> Back to Listing
            </Link>
          </div>
          <div className="col-md-4 text-end">
            <Button
              className="btn btn-success w-50 login-btn"
              data-bs-toggle="modal"
              data-bs-target="#updatelistingdetails"
            >
              Update Status
            </Button>
          </div>
        </div>
        <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
          <hr />
        </div>

        <div className="row statuses">
          <div className="col-md-2">
            <img
              src={data?.orgfile?.org_logo || client}
              alt="logo"
              className="img-fluid w-75"
            />
          </div>
          <div className="col-md-10">
            <h3 className="py-3">{data?.organization}</h3>
            <hr />
            <div className="row pt-2">
              <div className="col-md-3 border-end mb-2">
                <h6>
                  {data?.status === "rejected" ? (
                    <span className="reject-listing">
                      Rejected
                      <i className="bi bi-circle-fill ms-2"></i>
                    </span>
                  ) : data?.status === "approved" ? (
                    <span className="approved-listing">
                      Approved
                      <i className="bi bi-circle-fill ms-2"></i>
                    </span>
                  ) : data?.status === "awaiting" ? (
                    <span className="approved-listing">
                      Awaiting <i className="bi bi-circle-fill ms-2"></i>
                    </span>
                  ) : (
                    <span className="pending-listing">
                      Pending <i className="bi bi-circle-fill ms-2"></i>
                    </span>
                  )}
                </h6>
                <p>Listing Status</p>
              </div>
              <div className="col-md-3 border-end mb-2">
                <h6>{data?.profile_ranking ? "Verified" : "Unverified"}</h6>
                <p>Ranking</p>
              </div>
              <div className="col-md-6 mb-2">
                <h6>{data?.thematic_area?.join(", ") || "-"}</h6>
                <p>Thematic Area</p>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
          <hr />
        </div>

        {isDataLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 400,
            }}
          >
            <Oval wrapperClass="ml-2" height={30} width={30} />
          </div>
        ) : (
          <div className="p-4 bg-white group-cards mt-4">
            <div className="row listings-tog">
              <div className="col">
                <ul className="nav nav-tabs tog" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active tog-btn"
                      id="onboard-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#onboard-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="onboard-tab-pane"
                      aria-selected="true"
                    >
                      Onboarding Information
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="org-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#org-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="org-tab-pane"
                      aria-selected="false"
                    >
                      Organization Information
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="onboard-tab-pane"
                    role="tabpanel"
                    aria-labelledby="onboard-tab"
                    tabIndex="0"
                  >
                    <div className="row mt-4">
                      <div className="col subset">
                        <ul
                          className="nav nav-pills nav-justify"
                          id="myTab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="home-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#home-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="home-tab-pane"
                              aria-selected="true"
                            >
                              Listing Information
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#profile-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="profile-tab-pane"
                              aria-selected="false"
                            >
                              Business Documentation
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="contact-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#contact-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="contact-tab-pane"
                              aria-selected="false"
                            >
                              Additional Information
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="home-tab-pane"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                            tabIndex="0"
                          >
                            <div className="row my-5 statuses">
                              <div className="col-md-6 border-end">
                                <p>
                                  <i className="bi bi-house me-2"></i>{" "}
                                  Organization's Name
                                </p>
                                <h6>{data?.organization}</h6>
                              </div>
                              <div className="col-md-6">
                                <p>
                                  <i className="bi bi-calendar me-2"></i>{" "}
                                  Operation Year
                                </p>
                                <h6>{data?.operation_years}</h6>
                              </div>
                            </div>
                            <hr />
                            <div className="row my-5 statuses">
                              <div className="col-md-4 border-end">
                                <p>
                                  <i className="bi bi-house me-2"></i>First Name
                                </p>
                                <Link>{data?.cso?.firstname}</Link>
                              </div>
                              <div className="col-md-4 border-end">
                                <p>
                                  <i className="bi bi-house me-2"></i>Last Name
                                </p>
                                <Link>{data?.cso?.lastname}</Link>
                              </div>
                              <div className="col-md-4">
                                <p>
                                  <i className="bi bi-envelope me-2"></i>{" "}
                                  Registration Email
                                </p>
                                <Link>{data?.cso?.email}</Link>
                              </div>
                            </div>
                            <hr />
                            <div className="row my-5 statuses">
                              <div className="col-md-4 border-end">
                                <p>
                                  <i className="bi bi-envelope me-2"></i>{" "}
                                  Organization's Email
                                </p>
                                <Link>{data?.organization_email}</Link>
                              </div>

                              <div className="col-md-4 border-end">
                                <p>
                                  <i className="bi bi-telephone me-2"></i>{" "}
                                  Organization&apos;s Phone
                                </p>
                                <Link>{data?.organization_phone}</Link>
                              </div>
                              {/* <div className='col-md-4'>
                                <p>
                                  <i className='bi bi-geo-alt me-2'></i>
                                  Geopolitical Zone
                                </p>
                                <Link>{data?.geopolitical_zone || '-'}</Link>
                              </div> */}
                            </div>
                            <hr />
                            <div className="row my-5 statuses">
                              <div className="col-md-6 border-end">
                                <p>
                                  <i className="bi bi-geo-alt me-2"></i>{" "}
                                  Organization's Address
                                </p>
                                <h6>{data?.address}</h6>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col">
                                    <p>City</p>
                                    <h6>{data?.city}</h6>
                                  </div>
                                  <div className="col">
                                    <p>State</p>
                                    <h6>{data?.state}</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row my-5 statuses">
                              <div className="col-md-6 border-end">
                                <p>
                                  <i className="bi bi-gear me-2"></i> Services
                                  Rendered
                                </p>
                                <h6>{data?.services?.join(", ")}</h6>
                              </div>
                              <div className="col-md-6">
                                <p>
                                  <i className="bi bi-briefcase me-2"></i>{" "}
                                  Thematic Area
                                </p>
                                <h6>{data?.thematic_area?.join(", ")}</h6>
                              </div>
                            </div>
                            <hr />
                            <div className="row my-5 statuses">
                              <div className="col-md-6 border-end">
                                <div className="row">
                                  <div className="col">
                                    <p>
                                      <i className="bi bi-calendar-event me-2"></i>{" "}
                                      Registration Date
                                    </p>
                                    <h6>
                                      {dayjs(data?.created_at).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </h6>
                                  </div>
                                  <div className="col">
                                    <p>
                                      <i className="bi bi-calendar-event me-2"></i>{" "}
                                      Listing Date
                                    </p>
                                    <h6>
                                      {dayjs(data?.created_at).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col">
                                    <p>
                                      <i className="bi bi-layers me-2"></i> No
                                      of Projects
                                    </p>
                                    <h6>{data?.projects} Projects</h6>
                                  </div>
                                  {/* <div className="col">
                                    <p>
                                      <i className="bi bi-cash me-2"></i> Received
                                      Donations
                                    </p>
                                    <h6>-</h6>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="my-5 statuses">
                              <div className="row">
                                <div className="col">
                                  <p>
                                    <i className="bi bi-phone me-2"></i> Social
                                    Media Links
                                  </p>
                                </div>
                              </div>
                              <div className="row">
                                {data?.socialmedia?.links?.map(
                                  (link, index) => {
                                    return (
                                      <div className="col mb-2" key={index}>
                                        <a
                                          href={
                                            Object.values(link)[0]
                                              ? formatLink(
                                                  Object.keys(link)[0],
                                                  Object.values(link)[0]
                                                )
                                              : ""
                                          }
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {Object.values(link)[0] || "-"}
                                        </a>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-5 statuses">
                              <div className="col-md-3 mb-2">
                                <h6 className="mb-2">
                                  Organization&apos;s Proof of Address
                                </h6>
                                <div>
                                  <div className="bg-download text-center">
                                    <img
                                      src={
                                        data?.orgfile?.address_proof ||
                                        placeholderImg
                                      }
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="bg-white p-4 bg-dwd">
                                    <h4 style={{ wordBreak: "break-word" }}>
                                      {
                                        data?.orgfile?.address_proof?.split(
                                          "/"
                                        )[4]
                                      }
                                    </h4>
                                    <p>
                                      Uploaded{" "}
                                      {dayjs(data?.created_at).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </p>
                                    <span
                                      className="float-end dload"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        downloadImage(
                                          data?.orgfile?.address_proof
                                        )
                                      }
                                    >
                                      <i className="bi bi-download"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <h6 className="mb-2">
                                  Organization&apos;s Logo Mark
                                </h6>
                                <div>
                                  <div className="bg-download text-center">
                                    <img
                                      src={
                                        data?.orgfile?.org_logo ||
                                        placeholderImg
                                      }
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="bg-white p-4 bg-dwd">
                                    <h4 style={{ wordBreak: "break-word" }}>
                                      {data?.orgfile?.org_logo?.split("/")[4]}
                                    </h4>
                                    <p>
                                      Uploaded{" "}
                                      {dayjs(data?.created_at).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </p>
                                    <span
                                      className="float-end dload"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        downloadImage(data?.orgfile?.org_logo)
                                      }
                                    >
                                      <i className="bi bi-download"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="profile-tab-pane"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                            tabIndex="0"
                          >
                            {data?.businessdoc?.reg_cac === "yes" ? (
                              <>
                                <div className="row my-5 statuses">
                                  <div className="col-md-4 border-end">
                                    <p>CAC Registered</p>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                      {data?.businessdoc?.reg_cac}
                                    </h6>
                                  </div>
                                  <div className="col-md-4 border-end">
                                    <p>Organization’s RC Number</p>
                                    <h6>
                                      {data?.businessdoc?.rc_number || "-"}
                                    </h6>
                                  </div>
                                  <div className="col-md-4">
                                    <p>
                                      Organization’s Tax Identification Number
                                    </p>
                                    <h6>
                                      {data?.businessdoc?.tax_id_no || "-"}
                                    </h6>
                                  </div>
                                </div>
                                <hr />
                              </>
                            ) : (
                              <>
                                {/* CAC Registered - NO  */}
                                <div className="row my-5 statuses">
                                  <div className="col-md-3 border-end">
                                    <p>CAC Registered</p>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                      {data?.businessdoc?.reg_cac}
                                    </h6>
                                  </div>
                                  <div className="col-md-3 border-end">
                                    <p>Registed with</p>
                                    <h6>
                                      {data?.businessdoc?.registeration_with}
                                    </h6>
                                  </div>
                                  <div className="col-md-3 border-end">
                                    <p>Name of Registration</p>
                                    <h6>
                                      {data?.businessdoc?.registeration_name}
                                    </h6>
                                  </div>
                                  <div className="col-md-3">
                                    <p>Registration Number</p>
                                    <h6>
                                      {data?.businessdoc?.registeration_no}
                                    </h6>
                                  </div>
                                </div>
                                <hr />
                                {/* End  */}
                              </>
                            )}

                            <div className="row mt-5 statuses">
                              {data?.orgfile?.registeration_certificate && (
                                <div className="col-md-3 mb-2">
                                  <h6 className="mb-2">
                                    Organization’s CAC Certificate
                                  </h6>
                                  <div>
                                    <div className="bg-download text-center">
                                      <img
                                        src={
                                          data?.orgfile
                                            ?.registeration_certificate ||
                                          placeholderImg
                                        }
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="bg-white p-4 bg-dwd">
                                      <h4 style={{ wordBreak: "break-word" }}>
                                        {
                                          data?.orgfile?.registeration_certificate?.split(
                                            "/"
                                          )[4]
                                        }
                                      </h4>
                                      <p>
                                        Uploaded{" "}
                                        {dayjs(data?.created_at).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </p>
                                      <span
                                        className="float-end dload"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          downloadImage(
                                            data?.orgfile
                                              ?.registeration_certificate
                                          )
                                        }
                                      >
                                        <i className="bi bi-download"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {data?.orgfile?.article_of_asso && (
                                <div className="col-md-3">
                                  <h6 className="mb-2">
                                    Organization’s Article of Association
                                  </h6>
                                  <div>
                                    <div className="bg-download text-center">
                                      <img
                                        src={
                                          data?.orgfile?.article_of_asso ||
                                          placeholderImg
                                        }
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="bg-white p-4 bg-dwd">
                                      <h4 style={{ wordBreak: "break-word" }}>
                                        {
                                          data?.orgfile?.article_of_asso?.split(
                                            "/"
                                          )[4]
                                        }
                                      </h4>
                                      <p>
                                        Uploaded{" "}
                                        {dayjs(data?.created_at).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </p>
                                      <span
                                        className="float-end dload"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          downloadImage(
                                            data?.orgfile?.article_of_asso
                                          )
                                        }
                                      >
                                        <i className="bi bi-download"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="contact-tab-pane"
                            role="tabpanel"
                            aria-labelledby="contact-tab"
                            tabIndex="0"
                          >
                            <div className="my-5 statuses">
                              <div className="row">
                                <div className="col">
                                  <h6>
                                    <i className="bi bi-bank me-2"></i>{" "}
                                    Organisation’s Bank Account Details
                                  </h6>
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-md-4 border-end mb-2">
                                  <p>Bank Name</p>
                                  <h6>{data?.bankdetail?.bank_name}</h6>
                                </div>
                                <div className="col-md-4 border-end mb-2">
                                  <p>Beneficiary Name</p>
                                  <h6>{data?.bankdetail?.beneficiary}</h6>
                                </div>
                                <div className="col-md-4 mb-2">
                                  <p>Account Number</p>
                                  <h6>{data?.bankdetail?.acct_no}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="org-tab-pane"
                    role="tabpanel"
                    aria-labelledby="org-tab"
                    tabIndex="0"
                  >
                    <div className="row mt-4">
                      <div className="col subset">
                        <ul
                          className="nav nav-pills nav-justify"
                          id="myTab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="news-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#news-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="news-tab-pane"
                              aria-selected="true"
                            >
                              News Feed
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="projects-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#projects-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="projects-tab-pane"
                              aria-selected="false"
                            >
                              Projects
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="resources-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#resources-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="resources-tab-pane"
                              aria-selected="false"
                            >
                              Resources
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="grants-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#grants-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="grants-tab-pane"
                              aria-selected="false"
                            >
                              Grants
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="team-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#team-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="team-tab-pane"
                              aria-selected="false"
                            >
                              Team Members
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="messages-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#messages-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="messages-tab-pane"
                              aria-selected="false"
                            >
                              Messages
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="compliance-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#compliance-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="compliance-tab-pane"
                              aria-selected="false"
                            >
                              Compliance Answers
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="news-tab-pane"
                            role="tabpanel"
                            aria-labelledby="news-tab"
                            tabIndex="0"
                          >
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <Link to="">
                                  <div className="d-flex flex-row">
                                    <img
                                      src={grant}
                                      alt="other post"
                                      className="img-fluid me-3"
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        resizeMode: "contain",
                                        objectFit: "cover",
                                        objectPosition: "top",
                                      }}
                                    />
                                    <div className="other-post">
                                      <h4>
                                        Gender Development and Advocacy: Safe
                                        Space Program for Women and Girls
                                      </h4>
                                      <h3 className="my-3">By XXX</h3>
                                      <h6>November 23, 2022</h6>
                                    </div>
                                  </div>
                                </Link>
                                <hr />
                              </div>
                              <div className="col-md-6">
                                <Link to="">
                                  <div className="d-flex flex-row">
                                    <img
                                      src={grant}
                                      alt="other post"
                                      className="img-fluid me-3"
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        resizeMode: "contain",
                                        objectFit: "cover",
                                        objectPosition: "top",
                                      }}
                                    />
                                    <div className="other-post">
                                      <h4>
                                        Gender Development and Advocacy: Safe
                                        Space Program for Women and Girls
                                      </h4>
                                      <h3 className="my-3">By XXX</h3>
                                      <h6>November 23, 2022</h6>
                                    </div>
                                  </div>
                                </Link>
                                <hr />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <Link to="">
                                  <div className="d-flex flex-row">
                                    <img
                                      src={grant}
                                      alt="other post"
                                      className="img-fluid me-3"
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        resizeMode: "contain",
                                        objectFit: "cover",
                                        objectPosition: "top",
                                      }}
                                    />
                                    <div className="other-post">
                                      <h4>
                                        Gender Development and Advocacy: Safe
                                        Space Program for Women and Girls
                                      </h4>
                                      <h3 className="my-3">By XXX</h3>
                                      <h6>November 23, 2022</h6>
                                    </div>
                                  </div>
                                </Link>
                                <hr />
                              </div>
                              <div className="col-md-6">
                                <Link to="">
                                  <div className="d-flex flex-row">
                                    <img
                                      src={grant}
                                      alt="other post"
                                      className="img-fluid me-3"
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        resizeMode: "contain",
                                        objectFit: "cover",
                                        objectPosition: "top",
                                      }}
                                    />
                                    <div className="other-post">
                                      <h4>
                                        Gender Development and Advocacy: Safe
                                        Space Program for Women and Girls
                                      </h4>
                                      <h3 className="my-3">By XXX</h3>
                                      <h6>November 23, 2022</h6>
                                    </div>
                                  </div>
                                </Link>
                                <hr />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <Link to="">
                                  <div className="d-flex flex-row">
                                    <img
                                      src={grant}
                                      alt="other post"
                                      className="img-fluid me-3"
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        resizeMode: "contain",
                                        objectFit: "cover",
                                        objectPosition: "top",
                                      }}
                                    />
                                    <div className="other-post">
                                      <h4>
                                        Gender Development and Advocacy: Safe
                                        Space Program for Women and Girls
                                      </h4>
                                      <h3 className="my-3">By XXX</h3>
                                      <h6>November 23, 2022</h6>
                                    </div>
                                  </div>
                                </Link>
                                <hr />
                              </div>
                              <div className="col-md-6">
                                <Link to="">
                                  <div className="d-flex flex-row">
                                    <img
                                      src={grant}
                                      alt="other post"
                                      className="img-fluid me-3"
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        resizeMode: "contain",
                                        objectFit: "cover",
                                        objectPosition: "top",
                                      }}
                                    />
                                    <div className="other-post">
                                      <h4>
                                        Gender Development and Advocacy: Safe
                                        Space Program for Women and Girls
                                      </h4>
                                      <h3 className="my-3">By XXX</h3>
                                      <h6>November 23, 2022</h6>
                                    </div>
                                  </div>
                                </Link>
                                <hr />
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="projects-tab-pane"
                            role="tabpanel"
                            aria-labelledby="projects-tab"
                            tabIndex="0"
                          >
                            <div className="mt-3">
                              <div className="bg-white group-cards no-head">
                                <div className="row justify-content-between mt-4 mb-4 mx-4">
                                  <div className="col-sm-6 ps-0">
                                    <h3>
                                      Projects{" "}
                                      <span className="mem-list">
                                        0 Projects
                                      </span>
                                    </h3>
                                  </div>
                                </div>
                                <hr />
                                <div className="table-responsive table-min-height">
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th scope="col">Project Title</th>
                                        <th scope="col">Project Status</th>
                                        <th scope="col">Date Published</th>
                                        <th scope="col">Publish Status</th>
                                        <th scope="col">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          Gender Development and Advocacy: Safe
                                          Space Program for Women and Girls
                                        </td>
                                        <td>
                                          <span className="pendg">
                                            <i></i>Ongoing
                                          </span>
                                        </td>
                                        <td>
                                          Published
                                          <br />
                                          2022/09/12 at 4:00am
                                        </td>
                                        <td>
                                          <span className="badgers">
                                            <i></i>Active
                                          </span>
                                        </td>
                                        <td>
                                          <div className="dropdown">
                                            <i
                                              className="bi bi-three-dots-vertical actions"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            ></i>
                                            <ul className="dropdown-menu listing-drop">
                                              <li>
                                                <Link
                                                  className="dropdown-item listing-item"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#edit-member"
                                                >
                                                  <i className="bi bi-pencil me-2"></i>
                                                  Edit Details
                                                </Link>
                                                <Link
                                                  to=""
                                                  className="dropdown-item listing-item text-danger"
                                                >
                                                  <i className="bi bi-archive me-2"></i>
                                                  Delete Member
                                                </Link>
                                              </li>
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Gender Development and Advocacy: Safe
                                          Space Program for Women and Girls
                                        </td>
                                        <td>
                                          <span className="pendg">
                                            <i></i>Ongoing
                                          </span>
                                        </td>
                                        <td>
                                          Published
                                          <br />
                                          2022/09/12 at 4:00am
                                        </td>
                                        <td>
                                          <span className="badgers">
                                            <i></i>Active
                                          </span>
                                        </td>
                                        <td>
                                          <div className="dropdown">
                                            <i
                                              className="bi bi-three-dots-vertical actions"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            ></i>
                                            <ul className="dropdown-menu listing-drop">
                                              <li>
                                                <Link
                                                  className="dropdown-item listing-item"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#edit-member"
                                                >
                                                  <i className="bi bi-pencil me-2"></i>
                                                  Edit Details
                                                </Link>
                                                <Link
                                                  to=""
                                                  className="dropdown-item listing-item text-danger"
                                                >
                                                  <i className="bi bi-archive me-2"></i>
                                                  Delete Member
                                                </Link>
                                              </li>
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Gender Development and Advocacy: Safe
                                          Space Program for Women and Girls
                                        </td>
                                        <td>
                                          <span className="pendg">
                                            <i></i>Ongoing
                                          </span>
                                        </td>
                                        <td>
                                          Published
                                          <br />
                                          2022/09/12 at 4:00am
                                        </td>
                                        <td>
                                          <span className="badgers">
                                            <i></i>Active
                                          </span>
                                        </td>
                                        <td>
                                          <div className="dropdown">
                                            <i
                                              className="bi bi-three-dots-vertical actions"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            ></i>
                                            <ul className="dropdown-menu listing-drop">
                                              <li>
                                                <Link
                                                  className="dropdown-item listing-item"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#edit-member"
                                                >
                                                  <i className="bi bi-pencil me-2"></i>
                                                  Edit Details
                                                </Link>
                                                <Link
                                                  to=""
                                                  className="dropdown-item listing-item text-danger"
                                                >
                                                  <i className="bi bi-archive me-2"></i>
                                                  Delete Member
                                                </Link>
                                              </li>
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Gender Development and Advocacy: Safe
                                          Space Program for Women and Girls
                                        </td>
                                        <td>
                                          <span className="pendg">
                                            <i></i>Ongoing
                                          </span>
                                        </td>
                                        <td>
                                          Published
                                          <br />
                                          2022/09/12 at 4:00am
                                        </td>
                                        <td>
                                          <span className="badgers">
                                            <i></i>Active
                                          </span>
                                        </td>
                                        <td>
                                          <div className="dropdown">
                                            <i
                                              className="bi bi-three-dots-vertical actions"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            ></i>
                                            <ul className="dropdown-menu listing-drop">
                                              <li>
                                                <Link
                                                  className="dropdown-item listing-item"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#edit-member"
                                                >
                                                  <i className="bi bi-pencil me-2"></i>
                                                  Edit Details
                                                </Link>
                                                <Link
                                                  to=""
                                                  className="dropdown-item listing-item text-danger"
                                                >
                                                  <i className="bi bi-archive me-2"></i>
                                                  Delete Member
                                                </Link>
                                              </li>
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="resources-tab-pane"
                            role="tabpanel"
                            aria-labelledby="resources-tab"
                            tabIndex="0"
                          >
                            <div className="row">
                              <div className="col-sm-4 mb-2">
                                <div className="act-pro">
                                  <img
                                    src={resourceimg}
                                    alt="resource-img"
                                    className="img-fluid w-100"
                                  />
                                  <div className="other-post m-3">
                                    <h4>
                                      Gender Development and Advocacy: Safe
                                      Space Program for Women and Girls
                                    </h4>
                                    <h3 className="my-2">By XXX</h3>
                                    <h6>November 23, 2022</h6>
                                    <div className="d-flex flex-row mt-3">
                                      <div className="me-4">
                                        <p>
                                          <i className="bi bi-hand-thumbs-up fs-6 me-2"></i>
                                          0
                                        </p>
                                      </div>
                                      <div>
                                        <p>
                                          <i className="bi bi-chat-left fs-6 me-2"></i>
                                          0
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4 mb-2">
                                <div className="act-pro">
                                  <img
                                    src={resourceimg}
                                    alt="resource-img"
                                    className="img-fluid w-100"
                                  />
                                  <div className="other-post m-3">
                                    <h4>
                                      Gender Development and Advocacy: Safe
                                      Space Program for Women and Girls
                                    </h4>
                                    <h3 className="my-2">By XXX</h3>
                                    <h6>November 23, 2022</h6>
                                    <div className="d-flex flex-row mt-3">
                                      <div className="me-4">
                                        <p>
                                          <i className="bi bi-hand-thumbs-up fs-6 me-2"></i>
                                          0
                                        </p>
                                      </div>
                                      <div>
                                        <p>
                                          <i className="bi bi-chat-left fs-6 me-2"></i>
                                          0
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4 mb-2">
                                <div className="act-pro">
                                  <img
                                    src={resourceimg}
                                    alt="resource-img"
                                    className="img-fluid w-100"
                                  />
                                  <div className="other-post m-3">
                                    <h4>
                                      Gender Development and Advocacy: Safe
                                      Space Program for Women and Girls
                                    </h4>
                                    <h3 className="my-2">By XXX</h3>
                                    <h6>November 23, 2022</h6>
                                    <div className="d-flex flex-row mt-3">
                                      <div className="me-4">
                                        <p>
                                          <i className="bi bi-hand-thumbs-up fs-6 me-2"></i>
                                          0
                                        </p>
                                      </div>
                                      <div>
                                        <p>
                                          <i className="bi bi-chat-left fs-6 me-2"></i>
                                          0
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="grants-tab-pane"
                            role="tabpanel"
                            aria-labelledby="grants-tab"
                            tabIndex="0"
                          >
                            <div className="op-bg p-3">
                              <div className="d-flex flex-row">
                                <div className="me-3">
                                  <img
                                    src={grant}
                                    alt=""
                                    className="img-fluid"
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                </div>
                                <div className="mt-1">
                                  <Link to="">
                                    <h3>
                                      United States African Development
                                      Foundation&apos;s Fund for the Development
                                      of Africa (ADF)
                                    </h3>
                                  </Link>
                                  <div className="d-flex flex-row">
                                    <h4>Grant Size: $2000</h4>
                                    <i
                                      className="bi bi-circle-fill mx-3 mt-1"
                                      style={{ color: "#2EA275" }}
                                    ></i>
                                    <h4>Expiration Date: November 8</h4>
                                  </div>
                                </div>
                              </div>
                              <hr
                                style={{
                                  marginLeft: "-17px",
                                  marginRight: "-17px",
                                }}
                              />
                              <div className="d-flex flex-row">
                                <div className="me-3">
                                  <img
                                    src={grant}
                                    alt=""
                                    className="img-fluid"
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                </div>
                                <div className="mt-1">
                                  <Link to="">
                                    <h3>
                                      United States African Development
                                      Foundation&apos;s Fund for the Development
                                      of Africa (ADF)
                                    </h3>
                                  </Link>
                                  <div className="d-flex flex-row">
                                    <h4>Grant Size: $2000</h4>
                                    <i
                                      className="bi bi-circle-fill mx-3 mt-1"
                                      style={{ color: "#2EA275" }}
                                    ></i>
                                    <h4>Expiration Date: November 8</h4>
                                  </div>
                                </div>
                              </div>
                              <hr
                                style={{
                                  marginLeft: "-17px",
                                  marginRight: "-17px",
                                }}
                              />
                              <div className="d-flex flex-row">
                                <div className="me-3">
                                  <img
                                    src={grant}
                                    alt=""
                                    className="img-fluid"
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                </div>
                                <div className="mt-1">
                                  <Link to="">
                                    <h3>
                                      United States African Development
                                      Foundation&apos;s Fund for the Development
                                      of Africa (ADF)
                                    </h3>
                                  </Link>
                                  <div className="d-flex flex-row">
                                    <h4>Grant Size: $2000</h4>
                                    <i
                                      className="bi bi-circle-fill mx-3 mt-1"
                                      style={{ color: "#2EA275" }}
                                    ></i>
                                    <h4>Expiration Date: November 8</h4>
                                  </div>
                                </div>
                              </div>
                              <hr
                                style={{
                                  marginLeft: "-17px",
                                  marginRight: "-17px",
                                }}
                              />
                              <div className="d-flex flex-row">
                                <div className="me-3">
                                  <img
                                    src={grant}
                                    alt=""
                                    className="img-fluid"
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                </div>
                                <div className="mt-1">
                                  <Link to="">
                                    <h3>
                                      United States African Development
                                      Foundation&apos;s Fund for the Development
                                      of Africa (ADF)
                                    </h3>
                                  </Link>
                                  <div className="d-flex flex-row">
                                    <h4>Grant Size: $2000</h4>
                                    <i
                                      className="bi bi-circle-fill mx-3 mt-1"
                                      style={{ color: "#2EA275" }}
                                    ></i>
                                    <h4>Expiration Date: November 8</h4>
                                  </div>
                                </div>
                              </div>
                              <hr
                                style={{
                                  marginLeft: "-17px",
                                  marginRight: "-17px",
                                }}
                              />
                              <div className="d-flex flex-row">
                                <div className="me-3">
                                  <img
                                    src={grant}
                                    alt=""
                                    className="img-fluid"
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                </div>
                                <div className="mt-1">
                                  <Link to="">
                                    <h3>
                                      United States African Development
                                      Foundation&apos;s Fund for the Development
                                      of Africa (ADF)
                                    </h3>
                                  </Link>
                                  <div className="d-flex flex-row">
                                    <h4>Grant Size: $2000</h4>
                                    <i
                                      className="bi bi-circle-fill mx-3 mt-1"
                                      style={{ color: "#2EA275" }}
                                    ></i>
                                    <h4>Expiration Date: November 8</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="team-tab-pane"
                            role="tabpanel"
                            aria-labelledby="team-tab"
                            tabIndex="0"
                          >
                            <div className="mt-3">
                              <div className="bg-white group-cards no-head">
                                <div className="row justify-content-between mt-4 mb-4 mx-4">
                                  <div className="col-sm-6 ps-0">
                                    <h3>
                                      Team Members{" "}
                                      <span className="mem-list">
                                        0 Members
                                      </span>
                                    </h3>
                                    <p>Manage your team members here.</p>
                                  </div>
                                </div>
                                <hr />
                                <div className="table-responsive table-min-height">
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th scope="col">Date added</th>
                                        <th scope="col">
                                          Name & Email Address
                                        </th>
                                        <th scope="col">Designation</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Mar 14,2022</td>
                                        <td>
                                          Olaoluwa Samuel <br />
                                          johndoe@gmail.com
                                        </td>
                                        <td>
                                          Quis autem vel eum iure reprehenderit
                                          qui i
                                        </td>
                                        <td>
                                          <span className="badgers">
                                            <i></i>Active
                                          </span>
                                        </td>
                                        <td>
                                          <div className="dropdown">
                                            <i
                                              className="bi bi-three-dots-vertical actions"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            ></i>
                                            <ul className="dropdown-menu listing-drop">
                                              <li>
                                                <Link
                                                  className="dropdown-item listing-item"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#edit-member"
                                                >
                                                  <i className="bi bi-pencil me-2"></i>
                                                  Edit Details
                                                </Link>
                                                <Link
                                                  to=""
                                                  className="dropdown-item listing-item text-danger"
                                                >
                                                  <i className="bi bi-archive me-2"></i>
                                                  Delete Member
                                                </Link>
                                              </li>
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="messages-tab-pane"
                            role="tabpanel"
                            aria-labelledby="messages-tab"
                            tabIndex="0"
                          ></div>
                          <div
                            className="tab-pane fade"
                            id="compliance-tab-pane"
                            role="tabpanel"
                            aria-labelledby="compliance-tab"
                            tabIndex="0"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Reject Modal */}
      <div
        className="modal fade"
        id="approve"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <div className="col">
                  <img
                    src={update}
                    alt="update"
                    className="img-fluid mb-3"
                    style={{ marginTop: "-30px" }}
                  />
                  <h4>Approve Application</h4>
                  <p>Are you sure you want to approve this application?</p>
                  <form>
                    <div className="d-flex flex-row">
                      <Link
                        to=""
                        className="btn btn-success bg-white w-100 login-btn mt-3 me-3"
                      >
                        Cancel
                      </Link>
                      <Link
                        to=""
                        className="btn btn-success w-100 login-btn mt-3"
                      >
                        Confirm
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Update Modal */}
      <div
        className="modal fade"
        id="updatelistingdetails"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <div className="col">
                  <img
                    src={update}
                    alt="update"
                    className="img-fluid mb-3"
                    style={{ marginTop: "-30px" }}
                  />
                  <h4>Update</h4>
                  <p>Update organization listing status</p>
                  <Form>
                    <div className="mb-3">
                      <label className="form-label">Listing Status</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="status"
                      >
                        <option value="">Select Status</option>
                        <option value="pending">Pending</option>
                        <option value="rejected">Rejected</option>
                        <option value="approved">Approved</option>
                        <option value="deactivate">Deactivate</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Send a Message</label>
                      <textarea
                        className="form-control"
                        placeholder="Enter a message"
                        rows="7"
                        name="message"
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Send notification email
                        </label>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <Button
                        type={"button"}
                        className="btn btn-success bg-white w-100 login-btn mt-3 me-3"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </Button>
                      <Button
                        type={"submit"}
                        className="btn btn-success w-100 login-btn mt-3"
                      >
                        Confirm
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ListingView;
