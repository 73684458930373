import React from 'react'
import { Button } from 'react-bootstrap'
import { Oval } from 'react-loader-spinner'

const AppButton = ({ children, loading, disabled, className, ...rest }) => {
	return (
		<Button
			{...rest}
			variant="primary"
			className={`btn btn-success register-btn mb-3 login-btn flex items-center justify-center ${className}`}
			disabled={loading || disabled}
		>
			{loading ? (
				<Oval wrapperClass="ml-2" color="#fff" height={20} width={20} />
			) : (
				children
			)}
		</Button>
	)
}

export default AppButton
