import React from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllTeamMembers } from "../../../handlers/eLearningHandler";
import dayjs from "dayjs";
import ReactPaginate from "react-paginate";

const TeamMember = () => {
  const [currPage, setCurrPage] = React.useState(1);

  const { data } = useQuery(["cso-all-team-members", currPage], () =>
    getAllTeamMembers({ page: currPage })
  );

  const handlePaginateChange = async (page) => {
    setCurrPage(page.selected + 1);
  };

  return (
    <div className="mt-3">
      <div className="bg-white group-cards no-head">
        <div className="row justify-content-between mt-4 mb-4 mx-4">
          <div className="col-sm-6 ps-0">
            <h3>
              Team Members{" "}
              <span className="mem-list">{data?.data?.length} Members</span>
            </h3>
            <p>Manage your team members here.</p>
          </div>
          <div className="col-sm-4 text-end">
            <button
              to=""
              className="btn btn-success w-50 login-btn"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#add-member"
            >
              <i className="bi bi-plus me-2"></i> Add Member
            </button>
          </div>
        </div>
        <hr />
        <div className="table-responsive table-min-height">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Date added</th>
                <th scope="col">Name & Email Address</th>
                <th scope="col">Designation</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((item, idx) => (
                <tr>
                  <td>{dayjs(item?.created_at)?.format("MMM DD, YYYY")}</td>
                  <td>
                    {`${item.firstname} ${item.lastname}`} <br />
                    {item.email}
                  </td>
                  <td>{item.designation || "-"}</td>
                  <td>
                    <div className="dropdown">
                      <i
                        className="bi bi-three-dots-vertical actions"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      ></i>
                      <ul className="dropdown-menu listing-drop">
                        <li>
                          <Link
                            className="dropdown-item listing-item"
                            data-bs-toggle="modal"
                            data-bs-target="#edit-member"
                          >
                            <i className="bi bi-pencil me-2"></i>
                            Edit Details
                          </Link>
                          {/* <Link
                            to=""
                            className="dropdown-item listing-item"
                            data-bs-toggle="modal"
                            data-bs-target="#update-member"
                          >
                            <i className="bi bi-arrow-repeat me-2"></i>
                            Update Status
                          </Link> */}
                          <Link
                            to=""
                            className="dropdown-item listing-item text-danger"
                          >
                            <i className="bi bi-archive me-2"></i>
                            Delete Member
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {data?.data?.length > 0 && (
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={data?.total / 10 || 1}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={handlePaginateChange}
          containerClassName={"paginate"}
          activeClassName={"active"}
          forcePage={currPage - 1}
        />
      )}
    </div>
  );
};

export default TeamMember;
