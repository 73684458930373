import Footer from "../component/footer";
import NavB from "../component/header";
import Location from "../component/location";
import ProjectDirectory from "../component/projectsDirectory";
import ServicesRendered from "../component/servicesRenderd";
import SingleDirectoryBanner from "../component/singleDirectoryBanner";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getSingleDirectory } from "../handlers/publicHandler";
import { useNavigate, useParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";

function SingleDirectory() {
  const { id } = useParams();
  const { data: data, isLoading: isDataLoading } = useQuery(
    `directory-${id}`,
    () => getSingleDirectory(id)
  );

  return (
    <div>
      <NavB />
      {isDataLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 500,
          }}
        >
          <Oval wrapperClass="ml-2" height={40} width={40} />
        </div>
      ) : (
        <>
          <SingleDirectoryBanner data={data} />
          <ServicesRendered data={data} />
          {/* <Location /> */}
          <ProjectDirectory data={data} />
        </>
      )}
      <Footer />
    </div>
  );
}

export default SingleDirectory;
