import MISLogin from "../../component/mis/login";
import HeaderLogin from "../../component/navbarLogin";

function MISSignin() {
  return (
    <div>
      <HeaderLogin/>
      <MISLogin/>
    </div>
  );
}

export default MISSignin;
