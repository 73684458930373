import React from "react";
import Adminsidebar from "./dashboardParts/adminsidebar";
import AdminOpportunitiesBody from "./dashboardParts/opportunitiesBody";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllGrants, getAuthorGrants } from "../handlers/grantHandler";

function AdminOpportunities() {
  const [currPage, setCurrPage] = React.useState(1);
  const [userCurrPage, setUserCurrPage] = React.useState(1);
  const [searchName, setSearchName] = React.useState("");
  const [authorSearchName, setAuthorSearchName] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [authorStatus, setAuthorStatus] = React.useState("");
  const timeout = React.useRef();
  const authorTimeout = React.useRef();

  const { data: grants } = useQuery(
    ["admin-all-grants", currPage, searchName, status],
    () => getAllGrants({ page: currPage, name: searchName, status })
  );

  const { data: adminGrants } = useQuery(
    ["admin-grants", userCurrPage, authorStatus],
    () =>
      getAuthorGrants({
        page: userCurrPage,
        name: authorSearchName,
        status: authorStatus,
      })
  );

  const handlePaginateChange = async (page) => {
    setCurrPage(page.selected + 1);
  };

  const handleUserPaginateChange = async (page) => {
    setUserCurrPage(page.selected + 1);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setSearchName(value);
    }, 600);
  };

  const handleUpdateStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleAuthorSearch = (e) => {
    const value = e.target.value;
    clearTimeout(authorTimeout.current);

    authorTimeout.current = setTimeout(() => {
      setAuthorSearchName(value);
    }, 600);
  };

  const handleAuthorUpdateStatus = (e) => {
    setAuthorStatus(e.target.value);
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Adminsidebar />
      <AdminOpportunitiesBody
        grants={grants?.grants?.data}
        grant_aggregate={grants?.grant_aggregate}
        adminGrants={adminGrants?.data}
        allTotal={grants?.grants?.total}
        userTotal={adminGrants?.total}
        handlePaginateChange={handlePaginateChange}
        currPage={currPage}
        handleUserPaginateChange={handleUserPaginateChange}
        userCurrPage={userCurrPage}
        handleSearch={handleSearch}
        handleAuthorSearch={handleAuthorSearch}
        handleUpdateStatus={handleUpdateStatus}
        handleAuthorUpdateStatus={handleAuthorUpdateStatus}
        status={status}
        authorStatus={authorStatus}
      />
    </div>
  );
}

export default AdminOpportunities;
