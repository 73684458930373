import EditProject from './dashboardParts/EditProject'
import CSOSidebar from './dashboardParts/csoSidebar'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getSingleProject } from '../handlers/projectHandler'

function ProjectEdit() {
	const { id } = useParams()
	const { data, isLoading } = useQuery(`project-${id}`, () =>
		getSingleProject(id)
	)
	return (
		<div style={{ backgroundColor: '#fff' }}>
			<CSOSidebar />
			<EditProject data={data} />
		</div>
	)
}

export default ProjectEdit
