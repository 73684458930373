import MISSingleMessage from './dashboardParts/misSingleMessage'
import Sidebar from './dashboardParts/sideBar'
import { getSingleDirectory, getSingleAdmin } from '../handlers/publicHandler'
import { getMessagesToUser } from '../handlers/messagesHandler'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'

function MISMessageSingle() {
	const { id } = useParams()
	const { data: data, isLoading: isDataLoading } = useQuery(
		`directory-${id}`,
		() => getSingleDirectory(id)
	)

	const { data: adminData } = useQuery(`admin-${id}`, () => getSingleAdmin(id))

	const { data: userMessage, isLoading } = useQuery(`message-${id}`, () =>
		getMessagesToUser(id)
	)

	return (
		<div style={{ backgroundColor: '#fff' }}>
			<Sidebar />
			<MISSingleMessage
				data={data}
				adminData={adminData}
				userId={id}
				userMessage={userMessage}
			/>
		</div>
	)
}

export default MISMessageSingle
