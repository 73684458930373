import React from "react";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { changePassword } from "../../handlers/authhandler";
import { toast } from "../../component/toast";
import { Form as FormikForm, Formik, ErrorMessage } from "formik";
import Button from "../../component/Button";
import { changePasswordValidationSchema } from "../../utlis/validation";
import Input from "../../component/Input";
import Form from "react-bootstrap/Form";

const renderError = (message) => (
  <span style={{ color: "red", fontSize: 12 }}>{message}</span>
);

const PasswordManagement = () => {
  const handleSubmit = (values) => {
    changePasswordMutation(values);
  };

  const { mutate: changePasswordMutation, isLoading } = useMutation(
    changePassword,
    {
      onSuccess: async (res) => {
        toast.success("Password change successfully");
      },
      onError: (e) => {
        toast.error(e);
      },
    }
  );

  return (
    <div className="row mt-5">
      <div className="col-md-3 mb-3">
        <h6 className="fw-bold">Password Management</h6>
        <p>Please enter your current password to change your password</p>
      </div>
      <div className="col-md-9">
        <Formik
          initialValues={{
            old_password: "",
            new_password: "",
            new_password_confirmation: "",
          }}
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={changePasswordValidationSchema}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            errors,
          }) => (
            <FormikForm onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Input
                  type="password"
                  name="old_password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.old_password}
                  label="Current Password"
                />
              </Form.Group>
              <div className="mb-3">
                <Form.Group className="mb-3">
                  <Input
                    type="password"
                    name="new_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.new_password}
                    label="New Password"
                  />
                </Form.Group>
              </div>
              <div className="mb-3">
                <Form.Group className="mb-3">
                  <Input
                    type="password"
                    name="new_password_confirmation"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.new_password_confirmation}
                    label="Confirm New Password"
                  />
                </Form.Group>
              </div>
              <div className="">
                <Button
                  className="btn btn-success gray-btn px-5"
                  type="submit"
                  loading={isLoading}
                >
                  Update Password
                </Button>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PasswordManagement;
