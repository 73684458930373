import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import update from '../../img/update.svg'


function ApprovedUpdate() {
  return (
    <section>
      <div className="content overview">
        <div className="row justify-content-between">
          <div className="col-md-8 mt-4 sbm">
            <Link to="/admin/resources">
              <i className="bi bi-arrow-left me-2"></i> Back to Resource Center
            </Link>
          </div>
          <div className="col-md-3 text-end mt-3">
            <Button
              to=""
              className="btn btn-success w-50 login-btn mb-3"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#approved"
            >
              Update Status
            </Button>
          </div>
        </div>
        <div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
          <hr />
        </div>
        <div className='row mt-5 justify-content-center'>
          <div className='col-sm-7'>
            <div className='single-tex'>
              <h2>An Experimental Approach to Early-Stage Nonprofit Governance</h2>
              <div className='d-flex my-2'>
                <span className='badgers me-3'><i className="bi bi-fonts me-2"></i>Text</span>
                <span className='badgers me-3'><i className="bi bi-check me-2"></i>Approved</span>
                <h6 className='mt-1'>By Humanitarian Center for Research and Development</h6>
              </div>
              <h3>November 23, 2022</h3>
              <p className='mt-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Update Status for Approved */}
      <div
        className="modal fade"
        id="approved"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <div className="col">
                  <img
                    src={update}
                    alt="update"
                    className="img-fluid mb-3"
                    style={{ marginTop: '-30px' }}
                  />
                  <h4>Update Resource Status </h4>
                  <p>Update resource viewing status</p>
                  <div className="form-check mb-4">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                    <label className="form-check-label" for="pending">
                      Pending
                    </label>
                  </div>
                  <div className="form-check mb-4">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label className="form-check-label" for="rejected">
                      Rejected
                    </label>
                  </div>
                  <div className="form-check mb-4">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                    <label className="form-check-label" for="approved">
                      Approved
                    </label>
                  </div>
                  <div className="d-flex flex-row">
                    <Button
                      type={'button'}
                      className="btn btn-success bg-white w-100 login-btn mt-3 me-3"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                            
                    >
                      Cancel
                    </Button>
                    <Button
                      type={'submit'}
                      className="btn btn-success w-100 login-btn mt-3"
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </section>
  )
}

export default ApprovedUpdate
