import Sidebar from './dashboardParts/sideBar'
import { useQuery } from 'react-query'
import { getSingleResource } from '../handlers/resourceHandler'
import { useParams } from 'react-router-dom'
import SingleText from './dashboardParts/singleText'
import SingleLink from './dashboardParts/singleLink'
import SingleFile from './dashboardParts/singleFile'
import { Oval } from 'react-loader-spinner'

function ViewResource() {
	const { id } = useParams()
	const { data, isLoading } = useQuery(`get-resources-${id}`, () =>
		getSingleResource(id)
	)
	return (
		<div style={{ backgroundColor: '#fff' }}>
			<Sidebar />
			{isLoading ? (
				<section>
					<div className="content overview">
						<div
							className="flex items-center justify-center"
							style={{ height: 400, width: '100%' }}
						>
							<Oval wrapperClass="ml-2" height={40} width={40} />
						</div>
					</div>
				</section>
			) : (
				<div>
					{data?.type === 'text' ? (
						<SingleText data={data} id={id} />
					) : data?.type === 'link' ? (
						<SingleLink data={data} id={id} />
					) : (
						<SingleFile data={data} id={id} />
					)}
				</div>
			)}
		</div>
	)
}

export default ViewResource
