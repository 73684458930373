import React from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getSingleProject } from '../../handlers/adminHandler'
import dayjs from 'dayjs'
import trash from "../../img/dump.svg";
import { Oval } from 'react-loader-spinner'
import { Button, Form } from 'react-bootstrap'

function AdminViewProject() {
	const { id } = useParams()
	const { data: projectData, isLoading } = useQuery(`admin-project-${id}`, () =>
		getSingleProject(id)
	)

	return (
		<section className="">
			<div className="content overview">
				<div className="row justify-content-between">
					<div className="col-md-6 mt-3 back">
						<Link to="/admin/projects">
							<i className="bi bi-arrow-left me-2"></i> Back to Projects
						</Link>
					</div>
					<div className="col-md-4 text-end mt-3">
						<div className="d-flex flex-row">
							<Link
								// to="/sendbulk"
								className="btn btn-success w-50 sbm-btn mb-3 mx-3"
								data-bs-toggle="modal"
								data-bs-target="#rejectproject"
							>
								Reject Project
							</Link>
							<Link
								to=""
								className="btn btn-success w-50 login-btn mb-3"
								data-bs-toggle="modal"
								data-bs-target="#"
							>
								Accept Project
							</Link>
						</div>
					</div>
				</div>
				<div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
					<hr />
				</div>
				{isLoading ? (
					<div className="loading-container">
						<Oval wrapperClass="ml-2" height={50} width={50} />
					</div>
				) : (
					<div className="mt-4">
						<div className="row">
							<div className="col-md-8 mb-3">
								<div className="p-4 bg-white group-cards">
									<div className="project-view">
										<h6>Project Title</h6>
										<h4>{projectData?.name}</h4>
										<h6>Pictures of the Project</h6>
										<img
											src={projectData?.pictures}
											alt="profile"
											className="img-fluid w-100 mb-3"
											style={{ height: '600px', objectFit: 'cover' }}
										/>
										<h6>Description of the project</h6>
										<p>{projectData?.description}</p>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="p-4 bg-white group-cards">
									<div className="row listings-tog">
										<div className="col">
											<ul
												className="nav nav-tabs tog"
												id="myTab"
												role="tablist"
											>
												<li className="nav-item" role="presentation">
													<button
														className="nav-link active review-btn"
														id="home-tab"
														data-bs-toggle="tab"
														data-bs-target="#home-tab-pane"
														type="button"
														role="tab"
														aria-controls="home-tab-pane"
														aria-selected="true"
													>
														Project Details
													</button>
												</li>
												{/* <li className="nav-item" role="presentation">
													<button
														className="nav-link review-btn"
														id="profile-tab"
														data-bs-toggle="tab"
														data-bs-target="#profile-tab-pane"
														type="button"
														role="tab"
														aria-controls="profile-tab-pane"
														aria-selected="false"
													>
														Ratings & Reviews
													</button>
												</li> */}
											</ul>
											<div className="tab-content" id="myTabContent">
												<div
													className="tab-pane fade show active"
													id="home-tab-pane"
													role="tabpanel"
													aria-labelledby="home-tab"
													tabIndex="0"
												>
													<div className="row mt-3">
														<div className="col p-d">
															<div>
																<h3>Project Overview</h3>
																<h2>
																	Project Location:{' '}
																	<span className="sm">
																		{projectData?.location}
																	</span>
																</h2>
															</div>
															<h2>
																Start Date:{' '}
																<span className="sm">
																	{dayjs(projectData?.start_date).format(
																		'DD, MMMM YYYY'
																	)}
																</span>
															</h2>
															<h2>
																Project Status:{' '}
																<span className="sm">
																	{projectData?.project_status}
																</span>
															</h2>
															<h2>
																Publish Status:{' '}
																<span className="sm">
																	{projectData?.publish_status}
																</span>
															</h2>
														</div>
													</div>
												</div>
												{/* <div
													className="tab-pane fade"
													id="profile-tab-pane"
													role="tabpanel"
													aria-labelledby="profile-tab"
													tabIndex="0"
												>
													<div className="row mt-3">
														<div className="col p-d">
														<div>
															<h3>Project Overview</h3>
															<h2>
																Project Location:{' '}
																<span className="sm">Lagos, Nigeria</span>
															</h2>
														</div>
														<h2>
															Start Date:{' '}
															<span className="sm">28, September 2022</span>
														</h2>
														<h2>
															Project Status:{' '}
															<span className="sm">Ongoing</span>
														</h2>
														<h2>
															Project Ratings: <span className="sm">4.8</span>
														</h2>
														<h2>
															Project Reviews: <span className="sm">320</span>
														</h2>
														<h2>
															Publish Status: <span className="sm">Active</span>
														</h2>
														<h2>
															No of Views: <span className="sm">350</span>
														</h2>
													</div>
													</div>
												</div> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			{/* Reject Modal */}
			<div
				className="modal fade"
				id="rejectproject"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content px-3">
						<div className="modal-header">
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								
							></button>
						</div>
						<div className="modal-body">
							<div className="row listing-modal">
								<div className="col">
									<img
										src={trash}
										alt="update"
										className="img-fluid mb-3"
										style={{ marginTop: "-30px" }}
									/>
									<h4>Reject Project “Title” </h4>
									<p>Are you sure you want to reject this project?</p>
									<Form className="row">
										<div className="mb-3">
											<label className="form-label">Send a Message</label>
											<textarea
												className="form-control"
												placeholder="Enter a message"
												rows="7"
												name="message"
														
											></textarea>
										</div>
										<div className="mb-3">
											<div className="form-check">
												<input
													className="form-check-input"
													type="checkbox"
															
												/>
												<label
													className="form-check-label"
													htmlFor="flexCheckDefault"
												>
													Send notification email
												</label>
											</div>
										</div>
										<div className="d-flex flex-row">
											<Button
												type={"button"}
												className="btn cancel-btn bg-white w-100 mt-3 me-3"
												data-bs-dismiss="modal"
												aria-label="Close"
											>
												Cancel
											</Button>
											<Button
												type={"submit"}
												className="btn del-btn w-100 login-btn mt-3"
											>
												Confirm
											</Button>
										</div>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default AdminViewProject
