import React from "react";
import avatar from "../../img/user-img.svg";
import ltd from "../../img/ltd.png";
import { Link } from "react-router-dom";
import MISHeaderDropdownMenu from "./MISHeaderDropdownMenu";
import dayjs from "dayjs";
import ReactPaginate from "react-paginate";
import { AuthContext } from "../../context/AuthContext";

function OpportunitiesBody({
  grants,
  userGrants,
  handlePaginateChange,
  currPage,
  handleUserPaginateChange,
  userCurrPage,
  allTotal,
  userTotal,
  handleSearch,
  handleAuthorSearch,
}) {
  const { userInfo } = React.useContext(AuthContext);
  const inputRef = React.useRef();
  const authorInputRef = React.useRef();

  const handleClearInput = () => {
    inputRef.current.value = "";
    handleSearch({ target: { value: "" } });
  };
  const handleClearAuthorInput = () => {
    authorInputRef.current.value = "";
    handleAuthorSearch({ target: { value: "" } });
  };
  return (
    <section>
      <div className="content overview">
        <div className="row justify-content-between">
          <div className="col-md-5 mb-2"></div>
          <div className="col-md-3 text-end">
            <div className="d-flex justify-content-end">
              <p className="bg-lt me-5 mt-1">
                <i className="bi bi-circle-fill fs-6 me-2"></i> MIS
              </p>
              <div className="dropdown">
                <Link
                  to="#"
                  className="dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  {userInfo?.organization?.orgfile?.org_logo ? (
                    <img
                      src={userInfo?.organization?.orgfile?.org_logo}
                      alt="avatar"
                      className="profile-topbar-avi"
                    />
                  ) : (
                    <img src={avatar} alt="avi" className="img-fluid" />
                  )}
                </Link>
                <MISHeaderDropdownMenu />
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
          <hr />
        </div>
        <div className="row">
          <div className="col-md-7 mt-3">
            <h4>Welcome to the Grants Page</h4>
            <p>Explore and apply for funding opportunities</p>
          </div>
          <div className="col-md-5 text-end mt-3">
            <Link
              to="/mis/add-grant"
              className="btn btn-success w-50 login-btn mb-3"
            >
              <i className="bi bi-plus me-3 fs-5"></i> Add New Grant
            </Link>
          </div>
        </div>
        <div className="row listings-tog mt-3">
          <div className="col">
            <ul className="nav nav-tabs tog" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active tog-btn"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected="true"
                >
                  Grants
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane"
                  aria-selected="false"
                >
                  My Grants
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home-tab-pane"
                role="tabpanel"
                aria-labelledby="home-tab"
                tabIndex="0"
              >
                <div className="p-4 group-cards gray mt-4">
                  <form className="row justify-content-between">
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label className="form-label">Search Grants</label>
                        <div className="search-message">
                          <i className="bi bi-search"></i>
                          <input
                            type="text"
                            className="form-control form-input"
                            placeholder="Search Grants"
                            onChange={handleSearch}
                            ref={inputRef}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 text-end align-content-center">
                      <button
                        type="button"
                        className="btn btn-primary bg-white text-muted btn-claer px-3"
                        onClick={handleClearInput}
                      >
                        Clear all
                      </button>
                    </div>
                  </form>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <div className="op-bg p-3">
                      {grants?.map((grant, idx) => (
                        <React.Fragment key={idx}>
                          <div className="d-flex flex-row">
                            <div className="me-3">
                              <img
                                src={grant?.image}
                                alt=""
                                className="img-fluid"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "10px",
                                }}
                              />
                            </div>
                            <div className="mt-1">
                              <Link to={`/mis/opportunities/${grant?.id}`}>
                                <h3>{grant?.title}</h3>
                              </Link>
                              <div className="d-flex flex-row">
                                <h4>Grant Size: ${grant?.size}</h4>
                                <i
                                  className="bi bi-circle-fill mx-3 mt-1"
                                  style={{ color: "#2EA275" }}
                                ></i>
                                <h4>
                                  Expiration Date:{" "}
                                  {dayjs(grant?.closing_date).format("MMMM D")}
                                </h4>
                              </div>
                            </div>
                          </div>
                          {idx + 1 !== grants?.length && (
                            <hr
                              style={{
                                marginLeft: "-17px",
                                marginRight: "-17px",
                              }}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    {grants?.length > 0 && (
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={allTotal / 10 || 1}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        onPageChange={handlePaginateChange}
                        containerClassName={"paginate"}
                        activeClassName={"active"}
                        forcePage={currPage - 1}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="profile-tab-pane"
                role="tabpanel"
                aria-labelledby="profile-tab"
                tabIndex="0"
              >
                <div className="p-4 group-cards gray mt-4">
                  <form className="row justify-content-between">
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label className="form-label">
                          Search Opportunities
                        </label>
                        <div className="search-message">
                          <i className="bi bi-search"></i>
                          <input
                            type="text"
                            className="form-control form-input"
                            placeholder="Search Opportunities"
                            onChange={handleAuthorSearch}
                            ref={authorInputRef}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 text-end align-content-center">
                      <button
                        type="button"
                        className="btn btn-primary bg-white text-muted btn-claer px-3"
                        onClick={handleClearAuthorInput}
                      >
                        Clear all
                      </button>
                    </div>
                  </form>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <div className="op-bg p-3">
                      {userGrants?.map((grant, idx) => (
                        <React.Fragment key={idx}>
                          
                          <div className="d-flex flex-row">
                            <div className="me-3">
                              <img
                                src={grant?.image}
                                alt=""
                                className="img-fluid"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "10px",
                                }}
                              />
                            </div>
                            <div className="mt-1">
                              <Link to={`/mis/opportunities/${grant?.id}`}>
                                <h3>{grant?.title}</h3>
                              </Link>
                              <div className="d-flex flex-row">
                                <h4>Grant Size: ${grant?.size}</h4>
                                <i
                                  className="bi bi-circle-fill mx-3 mt-1"
                                  style={{ color: "#2EA275" }}
                                ></i>
                                <h4>
                                  Expiration Date:{" "}
                                  {dayjs(grant?.closing_date).format("MMMM D")}
                                </h4>
                              </div>
                            </div>
                          </div>
                          {idx + 1 !== userGrants?.length && (
                            <hr
                              style={{
                                marginLeft: "-17px",
                                marginRight: "-17px",
                              }}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    {userGrants?.length > 0 && (
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={userTotal / 10 || 1}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        onPageChange={handleUserPaginateChange}
                        containerClassName={"paginate"}
                        activeClassName={"active"}
                        forcePage={userCurrPage - 1}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OpportunitiesBody;
