import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";

const Table = ({
  data,
  columns,
  isAdmin,
  listingStatusUpdate,
  setBulkSelectedOrganizations,
}) => {
  const [selectedData, setSelectedData] = React.useState([]);
  const [searchtext, setSearchText] = React.useState("");

  const customStyles = {
    headCells: {
      style: {
        fontSize: 14,
        color: "#666666",
      },
    },
    rows: {
      style: {
        fontSize: 14,
        minHeight: "60px",
        borderBottomWidth: 0,

        "&:not(:last-of-type)": {
          borderBottomWidth: "0",
        },
        "&:nth-child(odd)": {
          backgroundColor: "#F8F8FD",
        },
        "&:last-child": {
          marginBottom: isAdmin ? 100 : 250,
        },
      },
    },
  };

  const handleChange = (state) => {
    setSelectedData(state.selectedRows);
    setBulkSelectedOrganizations &&
      setBulkSelectedOrganizations(state.selectedRows);
  };

  const handleOnInputChange = (e) => {
    const currValue = e.target.value;
    setSearchText(currValue);
  };

  const filterProduct = (filterItem) => {
    if (!filterItem) {
      return data;
    }

    return data.filter((item) =>
      Object.keys(item).some((el) => {
        if (el === "teacherId") {
          return String(item[el]?.teacherName)
            .toLowerCase()
            .includes(filterItem.toLowerCase());
        }
        return String(item[el])
          .toLowerCase()
          .includes(filterItem.toLowerCase());
      })
    );
  };

  const filteredData = filterProduct(searchtext);

  const exportData =
    selectedData?.length > 0
      ? selectedData?.map((item) => {
          return {
            id: item?.id,
            name: item?.organization,
            email: item?.email,
            phone: item?.organization_phone,
            address: item?.address,
            state: item?.state,
            geopolitical_zone: item?.geopolitical_zone,
            thematic_area: item?.thematic_area
              ? item?.thematic_area?.join(", ")
              : null,
            services: item?.services ? item?.services?.join(", ") : null,
            created_at: item?.created_at,
            status: item?.status,
            reg_cac: item?.businessdoc?.reg_cac,
            registeration_no: item?.businessdoc?.registeration_no,
            tax_id_no: item?.businessdoc?.tax_id_no,
            bank_name: item?.bankdetail?.bank_name,
            beneficiary: item?.bankdetail?.beneficiary,
            acct_no: item?.bankdetail?.acct_no,
          };
        })
      : data?.map((item) => ({
          id: item?.id,
          name: item?.organization,
          email: item?.email,
          phone: item?.organization_phone,
          address: item?.address,
          state: item?.state,
          geopolitical_zone: item?.geopolitical_zone,
          thematic_area: item?.thematic_area
            ? item?.thematic_area?.join(", ")
            : null,
          services: item?.services ? item?.services?.join(", ") : null,
          created_at: item?.created_at,
          status: item?.status,
          reg_cac: item?.businessdoc?.reg_cac,
          registeration_no: item?.businessdoc?.registeration_no,
          tax_id_no: item?.businessdoc?.tax_id_no,
          bank_name: item?.bankdetail?.bank_name,
          beneficiary: item?.bankdetail?.beneficiary,
          acct_no: item?.bankdetail?.acct_no,
        }));

  const headers = [
    { label: "Id", key: "id" },
    { label: "Organizations's name", key: "name" },
    { label: "Organization's email", key: "email" },
    { label: "Organization's phone number", key: "phone" },
    { label: "Organization's address", key: "address" },
    { label: "Organization's state", key: "state" },
    { label: "Geopolitical zone", key: "geopolitical_zone" },
    { label: "Thematic area", key: "thematic_area" },
    { label: "Services rendered", key: "services" },
    { label: "Status", key: "status" },
    { label: "CAC registered", key: "reg_cac" },
    { label: "Organization's RC Number", key: "registeration_no" },
    { label: "Organization's Tax Identification Number", key: "tax_id_no" },
    { label: "Bank Name", key: "bank_name" },
    { label: "Beneficiary Name", key: "beneficiary" },
    { label: "Account Number", key: "acct_no" },
  ];

  const downloadExcel = (el) => {
    const worksheet = XLSX.utils.json_to_sheet(el);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          "Id",
          "Organizations's name",
          "Organization's email",
          "Organization's phone number",
          "Organization's address",
          "Organization's state",
          "Geopolitical zone",
          "Thematic area",
          "Services rendered",
          "Created At",
          "Status",
          "CAC registered",
          "Organization's RC Number",
          "Organization's Tax Identification Number",
          "Bank Name",
          "Beneficiary Name",
          "Account Number",
        ],
      ],
      {
        origin: "A1",
      }
    );
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "CSOATLAS - Registered organizations.xlsx");
  };

  return (
    <div>
      <div className="table-nav">
        <div>
          <input
            type="text"
            className="form-control table-nav-input"
            placeholder="Search"
            value={searchtext}
            onChange={handleOnInputChange}
          />
        </div>
        {selectedData?.length > 0 && (
          <div className="bulk-actions">
            <div className="bulk-actions-selected">
              <p>{selectedData?.length} selected</p>
            </div>
            <div className="">
              <div
                className="bulk-actions"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ cursor: "pointer" }}
              >
                <p className="bulk-actions-title">Bulk Actions</p>
                <i className="bi bi-three-dots-vertical actions"></i>
              </div>
              <ul className="dropdown-menu">
                <li>
                  <CSVLink
                    data={exportData}
                    headers={headers}
                    filename="CSOATLAS - Registered organizations.csv"
                    className="dropdown-item table-nav-link"
                  >
                    <i className="bi bi-download me-2"></i>
                    Export to CSV
                  </CSVLink>
                </li>
                <li>
                  <a
                    onClick={() => downloadExcel(exportData)}
                    className="dropdown-item table-nav-link"
                  >
                    <i className="bi bi-download me-2"></i>
                    Export to Xlsx
                  </a>
                </li>
                {listingStatusUpdate && <li>{listingStatusUpdate}</li>}
              </ul>
            </div>
          </div>
        )}
      </div>

      <DataTable
        columns={columns}
        data={filteredData || []}
        pagination
        defaultSortField="id"
        defaultSortAsc={false}
        noHeader
        selectableRows
        onSelectedRowsChange={handleChange}
        customStyles={customStyles}
      />
    </div>
  );
};

export default Table;
