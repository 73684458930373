import AdminEditResources from './dashboardParts/adminEditResource'
import Adminsidebar from './dashboardParts/adminsidebar'
import { useQuery } from 'react-query'
import { getSingleResource } from '../handlers/resourceHandler'
import { useParams } from 'react-router-dom'

function EditResourcesAdmin() {
	const { id } = useParams()
	const { data } = useQuery(`get-resources-${id}`, () => getSingleResource(id))

	return (
		<div style={{ backgroundColor: '#fff' }}>
			<Adminsidebar />
			<AdminEditResources data={data} id={id} />
		</div>
	)
}

export default EditResourcesAdmin
