import projects from "../img/school.png";
import rr from "../img/rr.svg";
import avi from "../img/mg.svg";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

function ProjectsContent({ data, handlePaginateChange, currPage }) {
  return (
    <section className="directorycontent">
      <div className="container">
        <div className="row">
          <div className="col-md-3 mb-3">
            <div className="filterby p-4 bg-white">
              <h4>Filter By</h4>
              <h6 className="float-end" style={{ marginTop: "-38px" }}>
                <i className="bi bi-arrow-repeat me-2"></i> Reset
              </h6>
              <Form className="mt-4">
                <Form.Group className="mb-3">
                  <Form.Label>Location</Form.Label>
                  <Form.Select>
                    <option>Location</option>
                    <option value="1">Lagos</option>
                    <option value="2">Abuja</option>
                    <option value="3">Rivers</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Project Status</Form.Label>
                  <Form.Select>
                    <option>Ongoing</option>
                    <option value="1">Ongoing</option>
                    <option value="2">Pending</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </Form.Group>
                <div className="mt-5">
                  <h5>Thematic Area</h5>
                </div>
                <div className="tarea p-3">
                  <div className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Orphanages and Shelters (120)
                    </label>
                  </div>
                  <div className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Communications and Media (140)
                    </label>
                  </div>
                  <div className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Community Development (180)
                    </label>
                  </div>
                  <div className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Economic Development (56)
                    </label>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <div className="col-md-9">
            <div className="row">
              {data?.data?.map((item, idx) => (
                <div className="col-md-4 mb-3" key={idx}>
                  <div className="proj bg-white p-3">
                    <img
                      src={item.pictures}
                      alt="projects"
                      className="img-fluid w-100"
                      style={{
                        height: 130,
                        width: 272,
                        objectFit: "cover",
                        borderRadius: 15,
                      }}
                    />
                    <h6 className="my-3">
                      <Link to={`/projects/${item.id}`}>{item.name}</Link>
                    </h6>
                    <img src={rr} alt="review" className="img-fluid w-100" />
                    <hr />
                    <div className="d-flex flex-row">
                      <img
                        src={item?.organization?.orgfile?.org_logo || avi}
                        alt="user"
                        className="img-fluid me-3"
                        style={{
                          height: 50,
                          width: 50,
                          borderRadius: 50,
                          objectFit: "cover",
                        }}
                      />
                      <p className="mt-3">
                        Posted by: {item?.organization?.organization}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={data?.total / 9 || 1}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={handlePaginateChange}
              containerClassName={"paginate"}
              activeClassName={"active"}
              forcePage={currPage - 1}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectsContent;
