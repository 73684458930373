import logo from '../img/atlaslogo.png'
import { Link } from 'react-router-dom'

function NavB() {
	return (
		<nav className="navbar navbar-expand-md navbar-light bg-white main-nav py-3 sticky-top">
			<div className="container header">
				<Link className="navbar-brand" to="/">
					<img
						src={logo}
						alt=""
						className="img-fluid"
						style={{ width: 120, height: 'auto',marginTop: '-8px' }}
						width=""
					/>
				</Link>
				<button
					className="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarNavDropdown"
				>
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarNavDropdown">
					<div className="mx-auto">
						<ul className="navbar-nav">
							{/* <li className="nav-item dropdown">
								<Link className="nav-link" to="/">
									Home
								</Link>
							</li> */}
							<li className="nav-item">
								<Link className="nav-link" to="/">
									Directory
								</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to="/projects">
									Projects
								</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to="/about-us">About</Link>
							</li>
							{/* <li className="nav-item">
								<Link className="nav-link">Donate</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link">Blog</Link>
							</li> */}
							<li className="nav-item">
								<Link className="nav-link" to="/contact">
									Contact Us
								</Link>
							</li>
						</ul>
					</div>
					<div className="ms-auto">
						<ul className="navbar-nav">
							<li className="nav-item">
								<Link className="nav-link me-3" to="/login">
									Log in
								</Link>
							</li>
							<li className="nav-item">
								<Link className="btn btn-outline-dark px-4 py-2" to="/signup">
									Sign up
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</nav>
	)
}

export default NavB
