import Adminsidebar from './dashboardParts/adminsidebar'
import StatusUpdate from './dashboardParts/statusUpdate'
import { useQuery } from 'react-query'
import { getSingleResource } from '../handlers/resourceHandler'
import { useParams } from 'react-router-dom'

function UpdateStatus() {
	const { id } = useParams()
	const { data } = useQuery(`get-resources-${id}`, () => getSingleResource(id))
	return (
		<div style={{ backgroundColor: '#fff' }}>
			<Adminsidebar />
			<StatusUpdate data={data} id={id} />
		</div>
	)
}

export default UpdateStatus
