import facebook from "../img/f.svg";
import linkedin from "../img/linke.svg";
import twitter from "../img/whats.svg";
import insta from "../img/instag.svg";
import aa from "../img/aa.svg";
import an from "../img/an.svg";
import downloadIcon from "../img/download-icon.svg";
import portfolio from "../img/bag.svg";
import { Link } from "react-router-dom";

function ServicesRendered({ data }) {
  const formatLink = (key, value) => {
    if (key === "Instagram") {
      return value.includes("https") ? value : "https://instagram.com/" + value;
    }
    if (key === "Twitter") {
      return value.includes("https") ? value : "https://twitter.com/" + value;
    }
    if (key === "Facebook") {
      return value.includes("https") ? value : "https://facebook.com/" + value;
    }
    return "";
  };
  return (
    <section className="">
      <div className="services-rendered">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-4">
              <div className="d-flex flex-row">
                <img
                  src={portfolio}
                  alt=""
                  className="img-fluid"
                  style={{ marginTop: "-59px" }}
                />
                <div className="ms-3" style={{ minHeight: "90px" }}>
                  <h6 className="mb-3">Thematic Area</h6>
                  <p>{data?.thematic_area?.join(", ") || "-"}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="d-flex flex-row">
                <img
                  src={portfolio}
                  alt=""
                  className="img-fluid"
                  style={{ marginTop: "-59px" }}
                />
                <div className="ms-3" style={{ minHeight: "90px" }}>
                  <h6 className="mb-3">Services Rendered</h6>
                  <p>{data?.services?.join(", ") || "-"}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6">
              <div className="d-flex flex-row">
                <img
                  src={portfolio}
                  alt=""
                  className="img-fluid"
                  style={{ marginTop: "-59px" }}
                />
                <div className="ms-3" style={{ minHeight: "90px" }}>
                  <h6 className="mb-3">Social Media Links</h6>
                  <div className="d-flex flex-row">
                    {data?.socialmedia?.links?.map((link, index) => (
                      <a
                        href={
                          Object.values(link)[0]
                            ? formatLink(
                                Object.keys(link)[0],
                                Object.values(link)[0]
                              )
                            : ""
                        }
                        target="_blank"
                        key={index}
                      >
                        <img
                          src={
                            Object.keys(link)[0] === "Facebook"
                              ? facebook
                              : Object.keys(link)[0] === "Instagram"
                              ? insta
                              : Object.keys(link)[0] === "LinkedIn"
                              ? linkedin
                              : null
                          }
                          alt="fb"
                          className="img-fluid me-3"
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="compliance-req p-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-5 mb-3">
              <h3>Compliance Requirements</h3>
              <h4 className="my-3">
                Find all the answers you are looking for here
              </h4>
              <h4 className="fw-bold">Keys</h4>
              <div className="d-flex flex-row mt-3">
                <div className="me-3">
                  <img src={aa} alt="answered" className="img-fluid" />
                </div>
                <div className="mt-1">
                  <h5>Answered & Approved</h5>
                </div>
              </div>
              <div className="d-flex flex-row mt-3">
                <div className="me-3">
                  <img src={an} alt="answered" className="img-fluid" />
                </div>
                <div className="mt-1">
                  <h5>Answered & Not approved / Unanswered</h5>
                </div>
              </div>
            </div>
            <div className="col-sm-7 mb-3">
              {data?.compliance?.unanswered_and_pending?.map((el, idx) => (
                <div className="mb-5" key={idx}>
                  <div className="d-flex flex-row">
                    <div className="me-3">
                      <img
                        src={an}
                        alt="answered"
                        className="img-fluid"
                        width={25}
                      />
                    </div>
                    <div>
                      <h2>{el?.question}</h2>
                      {el?.compliance_answers?.map((ans, i) => (
                        <p className="mt-3" key={i}>
                          {el?.question_type === "text" ? (
                            ans?.answer
                          ) : (
                            <a
                              style={{ color: "#039855" }}
                              href={ans?.answer}
                              target="_blank"
                              download
                            >
                              Download Here
                              <img
                                src={downloadIcon}
                                alt="answered"
                                className="img-fluid"
                                style={{ marginLeft: "5px" }}
                                width={15}
                              />
                            </a>
                          )}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
              {data?.compliance?.answered_and_approved?.map((el, idx) => (
                <div className="mb-5" key={idx}>
                  <div className="d-flex flex-row">
                    <div className="me-3">
                      <img
                        src={aa}
                        alt="answered"
                        className="img-fluid"
                        width={25}
                      />
                    </div>
                    <div>
                      <h2>{el?.question}</h2>
                      {el?.compliance_answers?.map((ans, i) => (
                        <p className="mt-3" key={i}>
                          {el?.question_type === "text" ? (
                            ans?.answer
                          ) : (
                            <a
                              style={{ color: "#039855" }}
                              href={ans?.answer}
                              target="_blank"
                              download
                            >
                              Download Here
                              <img
                                src={downloadIcon}
                                alt="answered"
                                className="img-fluid"
                                style={{ marginLeft: "5px" }}
                                width={15}
                              />
                            </a>
                          )}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              ))}

              {/* <div className="mb-5">
								<div className="d-flex flex-row">
									<div className="me-3">
										<img
											src={aa}
											alt="answered"
											className="img-fluid"
											width={130}
										/>
									</div>
									<div>
										<h2>
											What have you been involved in to target the Sustainable
											Development Goals?
										</h2>
										<p className="mt-3">
											Our organization's agenda for the next 10 years is to
											drive innovation, sustainability, and social impact. We
											aim to leverage emerging technologies, foster strategic
											partnerships, and embrace sustainable practices to address
											pressing global challenges. Our focus will be on
											developing cutting-edge solutions, promoting inclusivity,
											and making a positive difference in the communities we
											serve. Through continuous adaptation, collaboration, and a
											forward-thinking approach, we strive to create a brighter
											and more sustainable future for all.
										</p>
									</div>
								</div>
							</div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServicesRendered;
