import { useRef } from "react";
import mini from "../../img/mini.svg";
import dayjs from "dayjs";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Oval } from "react-loader-spinner";
// import avatar from "../../img/user-img.svg";
// import p from "../../img/public.svg";
// import manage from "../../img/manage.svg";
// import log from "../../img/logout.svg";
// import mis from "../../img/repeat.svg";
// import dt from '../../img/dt.svg'

function AdminNewsFeeds({
  data,
  userFeedData,
  handlePaginateChange,
  handleUserNewsPaginateChange,
  currPage,
  userNewsCurrPage,
  limit,
  handleSearch,
  setSearchName,
  isLoading,
  // handleUserNewsSearch,
  // setUserNewsSearchName,
}) {
  const searchInput = useRef();
  // const userNewsSearchInput = useRef()

  return (
    <section>
      <div className="content overview">
        <div className="row">
          <div className="col-md-8">
            <h4>News Feed</h4>
            {/* <p>Parturient Venenatis Etiam dolor sit enam</p> */}
          </div>
          <div className="col-md-4 text-end">
            <Link
              to="/admin/add-news"
              className="btn btn-success w-50 login-btn mb-3"
            >
              <i className="bi bi-plus me-3 fs-5"></i> Add News
            </Link>
          </div>
        </div>
        <div className="row listings-tog mt-3">
          <div className="col">
            <ul className="nav nav-tabs tog" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active tog-btn"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected="true"
                >
                  All News Items
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane"
                  aria-selected="false"
                >
                  Posted by Me
                </button>
              </li>
            </ul>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 200,
                }}
              >
                <Oval wrapperClass="ml-2" height={30} width={30} />
              </div>
            ) : (
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home-tab-pane"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  tabIndex="0"
                >
                  <div className="p-4 group-cards gray mt-4">
                    <form className="row">
                      <div className="col-md-5 mb-2">
                        <label className="form-label">Search News Items</label>
                        <div className="form-group has-search">
                          <span className="bi bi-search form-control-feedback"></span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={handleSearch}
                            ref={searchInput}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-3">
											<div className="mb-3">
												<label className="form-label">Date Created</label>
												<input type="date" className="form-control news-form" />
											</div>
										</div> */}
                      <div className="col-md-4"></div>
                      <div className="col-md-3 text-end align-content-center">
                        <button
                          type="button"
                          className="btn btn-primary bg-white text-muted btn-claer px-3"
                          onClick={() => {
                            searchInput.current.value = "";
                            setSearchName("");
                          }}
                        >
                          Clear all
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* <div className="row mt-3 news-f">
									<div className="col-md-6">
										<img
											src={data?.data && data?.data[0]?.image}
											alt="newa-img"
											className="img-fluid w-100"
											style={{
												width: 100,
												height: '100%',
												resizeMode: 'contain',
											}}
										/>
									</div>
									<div className="col-md-6">
										<Link to={`/admin/news/${data?.data && data?.data[0]?.id}`}>
											{data?.data && data?.data[0]?.title?.length > 100
												? data?.data[0]?.title?.substring(9, 100)
												: data?.data && data?.data[0]?.title}
										</Link>
										<h6 className="mt-4">
											By {data?.data && data?.data[0]?.author}
										</h6>
										<p>
											{data?.data &&
												dayjs(data?.data[0]?.created_at).format(
													'MMMM DD, YYYY'
												)}
										</p>
									</div>
								</div> */}
                  <hr />
                  <div className="row">
                    {data?.data?.map((item, idx) => (
                      <div className="col-md-6" key={idx}>
                        <Link to={`/admin/news/${item?.id}`}>
                          <div className="d-flex flex-row">
                            <img
                              src={item.image}
                              alt="other post"
                              className="img-fluid me-3"
                              style={{
                                width: 120,
                                height: "100px",
                                resizeMode: "contain",
                                objectFit: "cover",
                                objectPosition: "top",
                              }}
                            />
                            <div className="other-post">
                              <h4>
                                {item?.title?.length > 100
                                  ? item?.title?.substring(9, 100)
                                  : item?.title}
                              </h4>
                              <h3 className="my-3">By {item?.author}</h3>
                              <h6>
                                {dayjs(item?.created_at).format(
                                  "MMMM DD, YYYY"
                                )}
                              </h6>
                            </div>
                          </div>
                        </Link>
                        <hr />
                      </div>
                    ))}
                  </div>
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={data?.total / limit || 1}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handlePaginateChange}
                    containerClassName={"paginate"}
                    activeClassName={"active"}
                    forcePage={currPage - 1}
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="profile-tab-pane"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                  tabIndex="0"
                >
                  {/* <div className="p-4 group-cards gray mt-4">
									<form className="row">
										<div className="col-md-5 mb-2">
											<label className="form-label">Search News Items</label>
											<div className="form-group has-search">
												<span className="bi bi-search form-control-feedback"></span>
												<input
													type="text"
													className="form-control"
													placeholder="Search"
													onChange={handleUserNewsSearch}
													ref={userNewsSearchInput}
												/>
											</div>
										</div>
										<div className="col-md-4"></div>
										<div className="col-md-3 text-end align-content-center">
											<button
												type="button"
												className="btn btn-primary bg-white text-muted btn-claer px-3"
												onClick={() => {
													userNewsSearchInput.current.value = ''
													setUserNewsSearchName('')
												}}
											>
												Clear all
											</button>
										</div>
									</form>
								</div> */}
                  <div className="row mt-5">
                    {userFeedData?.data?.map((item, idx) => (
                      <div className="col-md-6" key={idx}>
                        <Link to={`/admin/news/${item?.id}`}>
                          <div className="d-flex flex-row">
                            <img
                              src={item.image || mini}
                              alt="other post"
                              className="img-fluid me-3"
                              style={{
                                width: 120,
                                height: "100px",
                                resizeMode: "contain",
                                objectFit: "cover",
                                objectPosition: "top",
                              }}
                            />
                            <div className="other-post">
                              <h4>
                                {item?.title?.length > 100
                                  ? item?.title?.substring(9, 100)
                                  : item?.title}
                              </h4>
                              <h3 className="my-3">
                                By {item?.author_name || item?.author}
                              </h3>
                              <h6>
                                {dayjs(item?.created_at).format(
                                  "MMMM DD, YYYY"
                                )}
                              </h6>
                            </div>
                          </div>
                        </Link>
                        <hr />
                      </div>
                    ))}
                  </div>
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={userFeedData?.total / limit || 1}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handleUserNewsPaginateChange}
                    containerClassName={"paginate"}
                    activeClassName={"active"}
                    forcePage={userNewsCurrPage - 1}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdminNewsFeeds;
