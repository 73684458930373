import axiosClient from "../utlis/axiosClient";

export const getAllTeamMembers = async ({ page }) => {
  try {
    const response = await axiosClient.get(
      `/v1/moodle/team-members?page=${page}&page_size=10`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};

export const createTeamMember = async (data) => {
  try {
    const response = await axiosClient.post(`/v1/moodle`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw "An error occured. Please try again.";
  }
};
