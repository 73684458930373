import React from "react";
import { Link } from "react-router-dom";
import flag from "../../img/flag.png";
import dayjs from "dayjs";
import { Oval } from "react-loader-spinner";

function ViewGrant({ data, isLoading }) {
  return (
    <section>
      <div className="content overview">
        <div className="row justify-content-between">
          <div className="col-md-8 mt-4 sbm">
            <Link to="/admin/opportunities">
              <i className="bi bi-arrow-left me-2"></i> Back to Grants
            </Link>
          </div>
          {data?.author === "admin" && (
            <div className="col-md-4 text-end mt-3">
              <Link
                to={`/admin/opportunities/${data?.id}/edit`}
                className="btn btn-success w-50 login-btn mb-3"
              >
                Edit Grant
              </Link>
            </div>
          )}
        </div>
        <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
          <hr />
        </div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 400,
            }}
          >
            <Oval wrapperClass="ml-2" height={30} width={30} />
          </div>
        ) : (
          <div className="row mt-5">
            <div className="col-sm-7">
              <div className="single-tex">
                <h2>{data?.title}</h2>
                <img
                  src={data?.image}
                  alt=""
                  className="img-fluid w-100"
                  style={{
                    borderRadius: 20,
                    height: 200,
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
                <div className="d-flex my-2">
                  {data?.status === "approved" ? (
                    <span className="mx-3 badgers">
                      <i className="bi bi-check me-2"></i>Approved
                    </span>
                  ) : data?.status === "pending" ? (
                    <span className="mx-3 pendg">
                      <i className="bi bi-circle-fill me-2"></i>
                      Pending
                    </span>
                  ) : (
                    <span className="mx-3 rejectedbg">
                      <i className="bi bi-circle-fill me-2"></i>
                      Rejected
                    </span>
                  )}
                  <h6 className="mt-1">By {data?.author}</h6>
                </div>
                <p className="mt-4">{data?.description}</p>
                <div>
                  <h6 className="mb-2 fw-bold">Donor Name</h6>
                  <p>{data?.donor_name}</p>
                  <h6 className="mb-2 mt-3 fw-bold">Grant Size</h6>
                  <p>${data?.size}</p>
                </div>
              </div>
            </div>
            <div className="col-sm-5">
              <div className="single-tex">
                <h5 className="mb-4">Grant Overview</h5>
                <p>
                  <i className="bi bi-calendar-date me-3 mb-3"></i>Publication
                  Date: {dayjs(data?.publication_date).format("MMMM DD, YYYY")}
                </p>
                <p>
                  <i className="bi bi-clock me-3 mb-3"></i>Expiration Date:
                  {dayjs(data?.closing_date).format("MMMM DD, YYYY")}
                </p>
                <p>
                  <i className="bi bi-eye me-3 mb-3"></i>No of Views:{" "}
                  {data?.total_clicks || "-"}
                </p>
                <p>
                  <i className="bi bi-cursor me-3 mb-3"></i>No of Applications:{" "}
                  {data?.total_applied || "-"}
                </p>
                <p>
                  <i className="bi bi-check-circle me-3 mb-3"></i>No of Grant
                  Recipients: {data?.total_granted || "-"}
                </p>
                <p>
                  <i className="bi bi-check-circle me-3 mb-3"></i>URL:{" "}
                  {data?.url || "-"}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default ViewGrant;
