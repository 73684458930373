import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient, useQuery } from "react-query";
import {
  updateResource,
  getSingleResource,
} from "../../handlers/resourceHandler";
import { toast } from "../../component/toast";
import { Form, Formik } from "formik";
import Button from "../../component/Button";
import { Oval } from "react-loader-spinner";
import ReactS3Client from "react-aws-s3-typescript";
import { s3Config } from "../../utlis/s3config";
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPG", "PNG", "GIF", "PDF"];

function EditResources() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useQuery(`get-resources-${id}`, () => getSingleResource(id));
  const queryClient = useQueryClient();
  const { mutate: updateResourceMutation, isLoading: isLoading } = useMutation(
    updateResource,
    {
      onSuccess: async (res) => {
        toast.success("Resource updated successfully");
        await queryClient.invalidateQueries(["resources"]);
        navigate("/mis/resources");
      },
      onError: (e) => {
        toast.error(e);
      },
    }
  );

  let initialValues = {
    title: data?.title || "",
    content: data?.content,
    type: data?.type,
    link: data?.link,
    file: data?.file,
  };

  const [fileObj, setFileObj] = React.useState({
    name: "",
    loading: false,
  });
  const [imgUrl, setImgUrl] = React.useState("");

  const handleFileUpload = async (file) => {
    const s3 = new ReactS3Client({ ...s3Config });
    try {
      const res = await s3.uploadFile(file);
      return res.location;
    } catch (exception) {
      console.log(exception);
    }
  };

  const handlepload = async (file) => {
    setFileObj({
      name: file?.name,
      loading: true,
    });
    const loc = await handleFileUpload(file);

    setImgUrl(loc);
    setFileObj((e) => ({ ...e, loading: false }));
  };

  const handleSubmit = (values) => {
    const resData = {
      ...values,
      file: imgUrl ? imgUrl : data?.file,
    };
    updateResourceMutation({ id: id, data: resData });
  };
  return (
    <section className="">
      <div className="content overview">
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize={true}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="row justify-content-between">
                <div className="col-md-8 mt-4 sbm">
                  <Link to="/mis/resources">
                    <i className="bi bi-arrow-left me-2"></i> Back
                  </Link>
                </div>
                <div className="col-md-4 text-end mt-3">
                  <div className="d-flex flex-row">
                    <Link
                      to=""
                      className="btn btn-success w-50 sbm-btn mb-3 mx-3"
                    >
                      Cancel
                    </Link>
                    <Button
                      className="btn btn-success w-50 login-btn mb-3"
                      type="submit"
                      loading={isLoading}
                    >
                      Update Resource
                    </Button>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
                <hr />
              </div>
              <div className="row mt-5">
                <div className="col">
                  <h3>Edit Resource</h3>
                </div>
                <div className="sendbulk p-5">
                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      <form>
                        <div className="mb-3">
                          <label className="form-label">Title</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="What is the title of your Article?"
                            name="title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            required={true}
                          />
                        </div>
                        {data?.type === "text" ? (
                          <div className="mb-3">
                            <label className="form-label">Content</label>
                            <textarea
                              className="form-control"
                              id=""
                              rows="5"
                              placeholder="Enter a description...."
                              name="content"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.content}
                            ></textarea>
                          </div>
                        ) : data?.type === "link" ? (
                          <div className="mb-3">
                            <label className="form-label">Resource Link</label>
                            <input
                              type="url"
                              className="form-control"
                              name="link"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.link}
                            />
                          </div>
                        ) : data?.type === "file" ? (
                          <div className="mb-3">
                            <label className="form-label">Upload File</label>
                            <FileUploader
                              handleChange={handlepload}
                              name="file"
                              types={fileTypes}
                              multiple={false}
                            />
                            <div className="uploader-loading-block">
                              <p style={{ margin: 0 }}>
                                {fileObj.loading
                                  ? `${fileObj.name} Uploading`
                                  : fileObj.name
                                  ? fileObj.name
                                  : data?.file}
                              </p>
                              {fileObj.loading && (
                                <Oval
                                  wrapperClass="ml-2"
                                  height={20}
                                  width={20}
                                />
                              )}
                            </div>
                          </div>
                        ) : null}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
}

export default EditResources;
