import React from 'react'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Form } from 'react-bootstrap'
import { FileUploader } from 'react-drag-drop-files'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../component/Button'
import { Form as FormikForm, Formik } from 'formik'
import { toast } from '../../component/toast'
import { createGrant } from '../../handlers/grantHandler'
import { Oval } from 'react-loader-spinner'
import ReactS3Client from 'react-aws-s3-typescript'
import { s3Config } from '../../utlis/s3config'

const fileTypes = ['JPG', 'PNG', 'GIF']

function GrantCreate() {
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const { mutate: createGrantMutation, isLoading: isLoading } = useMutation(
		createGrant,
		{
			onSuccess: async (res) => {
				toast.success('Grant created successfully')
				await queryClient.invalidateQueries(['grants'])
				navigate('/mis/opportunities')
			},
			onError: (e) => {
				toast.error(e)
			},
		}
	)

	const [fileObj, setFileObj] = React.useState({
		name: '',
		loading: false,
	})
	const [imgUrl, setImgUrl] = React.useState('')

	const handleFileUpload = async (file) => {
		const s3 = new ReactS3Client({ ...s3Config })
		try {
			const res = await s3.uploadFile(file)
			return res.location
		} catch (exception) {
			console.log(exception)
		}
	}

	const handleUpload = async (file) => {
		setFileObj({
			name: file?.name,
			loading: true,
		})
		const loc = await handleFileUpload(file)

		setImgUrl(loc)
		setFileObj((e) => ({ ...e, loading: false }))
	}

	const handleSubmit = (values) => {
		const dt = {
			...values,
			size: values.size.toString(),
			image: imgUrl,
		}
		createGrantMutation(dt)
	}

	return (
		<section>
			<div className="content overview">
				<Formik
					initialValues={{
						title: '',
						url: '',
						description: '',
						size: '',
						donor_name: '',
						closing_date: '',
						publication_date: '',
					}}
					onSubmit={(values) => handleSubmit(values)}
				>
					{({
						values,
						handleChange,
						handleSubmit,
						handleBlur,
						setFieldValue,
						errors,
					}) => (
						<FormikForm onSubmit={handleSubmit}>
							<div className="row justify-content-between">
								<div className="col-md-8 mt-4 sbm">
									<Link to="/mis/opportunities">
										<i className="bi bi-arrow-left me-2"></i> Back to Grant
									</Link>
								</div>
								<div className="col-md-4 text-end mt-3">
									<div className="d-flex flex-row">
										<Link
											to="/mis/opportunities"
											className="btn btn-success w-50 sbm-btn mb-3 mx-3"
										>
											Cancel
										</Link>
										<Button
											className="btn btn-success w-50 login-btn mb-3"
											type="submit"
											loading={isLoading}
										>
											Publish Grant
										</Button>
									</div>
								</div>
							</div>
							<div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
								<hr />
							</div>
							<div className="row mt-5">
								<div className="col-sm-7">
									<h4>Add New Grant</h4>
								</div>
							</div>
							<Form className="row mt-4">
								<div className="col-sm-8">
									<Form.Group className="mb-4">
										<Form.Label>Grant Title</Form.Label>
										<Form.Control
											type="text"
											name="title"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.title}
											required={true}
										/>
									</Form.Group>
									<Form.Group className="mb-4">
										<Form.Label>Grant Size</Form.Label>
										<Form.Control
											type="number"
											name="size"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.size}
											required={true}
										/>
									</Form.Group>
									<Form.Group className="mb-4">
										<Form.Label>Donor Name</Form.Label>
										<Form.Control
											type="text"
											name="donor_name"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.donor_name}
											required={true}
										/>
									</Form.Group>
									<Form.Group className="mb-4">
										<Form.Label>Grant Description</Form.Label>
										<Form.Control
											as="textarea"
											rows={3}
											placeholder="Enter a description...."
											name="description"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.description}
											required={true}
										/>
									</Form.Group>
									<Form.Group className="mb-4">
										<Form.Label>Upload Supporting Image (optional)</Form.Label>
										<FileUploader
											handleChange={handleUpload}
											name="file"
											types={fileTypes}
											multiple={false}
										/>
										<div className="uploader-loading-block">
											<p style={{ margin: 0 }}>
												{fileObj.loading
													? `${fileObj.name} Uploading`
													: fileObj.name
													? fileObj.name
													: 'No file(s) uploaded yet'}
											</p>
											{fileObj.loading && (
												<Oval wrapperClass="ml-2" height={20} width={20} />
											)}
										</div>
									</Form.Group>
									<Form.Group className="mb-4">
										<Form.Label>Grant URL</Form.Label>
										<Form.Control
											type="url"
											name="url"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.url}
											required={true}
										/>
									</Form.Group>
								</div>
								<div className="col-sm-4">
									<Form.Group className="mb-4">
										<Form.Label>Publication Date</Form.Label>
										<Form.Control
											type="date"
											name="publication_date"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.publication_date}
											required={true}
										/>
									</Form.Group>
									<Form.Group className="mb-4">
										<Form.Label>Expiration Date</Form.Label>
										<Form.Control
											type="date"
											name="closing_date"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.closing_date}
											required={true}
										/>
									</Form.Group>
								</div>
							</Form>
						</FormikForm>
					)}
				</Formik>
			</div>
		</section>
	)
}

export default GrantCreate
