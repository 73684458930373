import NewsFeedEmpty from "./dashboardParts/newsFeedE";
import Sidebar from "./dashboardParts/sideBar";

function EmptyFeeds() {
  return (
    <div style={{backgroundColor: "#fff"}}>
      <Sidebar/>
      <NewsFeedEmpty/>
    </div>
  );
}

export default EmptyFeeds;
