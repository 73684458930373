import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FileUploader } from "react-drag-drop-files";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import Input from "../Input";
import { Form as FormikForm, Formik } from "formik";
import { organizationDetailsValidationSchema } from "../../utlis/validation";
import Button from "../Button";
import Select from "../Select";
import { AuthContext } from "../../context/AuthContext";
import { s3Config } from "../../utlis/s3config";
import ReactS3Client from "react-aws-s3-typescript";
import { Oval } from "react-loader-spinner";
import { toast } from "../../component/toast";
import { addOrganizationDetails } from "../../handlers/onboardingHandler";
import CreatableSelect from "react-select/creatable";
import dayjs from "dayjs";

const fileTypes = ["JPEG", "PNG", "JPG", "PDF"];

const OrganizationDetails = ({ handleChangeTab }) => {
  const { userInfo } = React.useContext(AuthContext);
  const [addressProofFileObj, setAddressProofFileObj] = React.useState({
    name: "",
    loading: false,
  });
  const [orgLogoFileObj, setOrgLogoFileObj] = React.useState({
    name: "",
    loading: false,
  });

  const [addressProofImgUrl, setAddressProofImgUrl] = React.useState("");
  const [orgLogoImgUrl, setOrgLogoImgUrl] = React.useState("");
  const [socialLinkCount, setSocialLinkCount] = React.useState(1);

  const handleFileUpload = async (file) => {
    const s3 = new ReactS3Client({ ...s3Config });
    try {
      const res = await s3.uploadFile(file);
      return res.location;
    } catch (exception) {
      console.log(exception);
    }
  };

  const hanldeProofOfAddressUpload = async (file) => {
    setAddressProofFileObj({
      name: file?.name,
      loading: true,
    });
    const loc = await handleFileUpload(file);

    setAddressProofImgUrl(loc);
    setAddressProofFileObj((e) => ({ ...e, loading: false }));
  };

  const handleOrgLogoUpload = async (file) => {
    setOrgLogoFileObj({
      name: file?.name,
      loading: true,
    });
    const loc = await handleFileUpload(file);

    setOrgLogoImgUrl(loc);
    setOrgLogoFileObj((e) => ({ ...e, loading: false }));
  };

  const handleAddLink = () => {
    socialLinkCount < 3
      ? setSocialLinkCount((e) => e + 1)
      : setSocialLinkCount(3);
  };

  const { mutate: addOrganizationDetailsMutation, isLoading: isLoading } =
    useMutation(addOrganizationDetails, {
      onSuccess: async (res) => {
        toast.success("Details saved successfully");
        handleChangeTab("second");
      },
      onError: (e) => {
        toast.error(e);
      },
    });

  const handleSubmit = (values) => {
    const data = {
      organization_email: values.organization_email,
      organization_phone: values.organization_phone,
      thematic_area: values.thematic_area.map((item) => item.value),
      services: values.other_services
        ? [values.other_services]
        : values.services.map((item) => item.value),
      operation_years: dayjs(values.operation_years).format("YYYY-MM"),
      geopolitical_zone: values.geopolitical_zone,
      address: values.location,
      city: values.city,
      state: values.state.map((item) => item.value),
      address_proof: addressProofImgUrl,
      org_logo: orgLogoImgUrl,
      social_links:
        values.social_media.length > 0
          ? values.social_media?.map((item) => ({
              [item.type]: item.link,
            }))
          : null,
    };
    addOrganizationDetailsMutation(data);
  };

  return (
    <div>
      <h2>Tell us about your Organization</h2>
      <h6>Let us know a liitle about your organization</h6>
      <Formik
        initialValues={{
          organization_email: "",
          organization_phone: "",
          thematic_area: [],
          operation_years: "",
          services: [],
          location: "",
          city: "",
          state: [],
          other_services: "",
          geopolitical_zone: "",
          social_media: [
            {
              type: "",
              link: "",
            },
          ],
        }}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={organizationDetailsValidationSchema}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          errors,
        }) => (
          <FormikForm onSubmit={handleSubmit}>
            <Form.Group className="mb-3 mt-5">
              <Form.Label>Organization Name</Form.Label>
              <Form.Control
                type="text"
                value={userInfo?.organization?.organization}
                disabled={true}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Input
                type="email"
                name="organization_email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.organization_email}
                label="Organization’s Email"
              />
            </Form.Group>
            <Row className="mb-3">
              <Col>
                <Form.Label>Organization’s Phone Number</Form.Label>
                <InputGroup className="mb-3">
                  <Input
                    type="tel"
                    name="organization_phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.organization_phone}
                  />
                </InputGroup>
              </Col>
              <Col>
                <Input
                  name="operation_years"
                  type="date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.operation_years}
                  label="Year Founded"
                />
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>Thematic Area</Form.Label>
              <CreatableSelect
                name="thematic_area"
                onChange={(newThematic) =>
                  setFieldValue("thematic_area", newThematic)
                }
                value={values.thematic_area}
                onBlur={handleBlur}
                isMulti={true}
                options={[
                  { value: "Education", label: "Education" },
                  { value: "Disability", label: "Disability" },
                  { value: "Poverty", label: "Poverty" },
                  { value: "Health", label: "Health" },
                  {
                    value: "Economic/Community Development",
                    label: "Economic/Community Development",
                  },
                  {
                    value: "Democracy and Good Governance",
                    label: "Democracy and Good Governance",
                  },
                  {
                    value: "Orphanages and Shelters",
                    label: "Orphanages and Shelters",
                  },
                  {
                    value: "Civil Rights and Social Action",
                    label: "Civil Rights and Social Action",
                  },
                  {
                    value: "Peace and Conflict Resolution",
                    label: "Peace and Conflict Resolution",
                  },
                  {
                    value: "Corporative and Microfinance",
                    label: "Corporative and Microfinance",
                  },
                  {
                    value: "Crime and Law Related",
                    label: "Crime and Law Related",
                  },
                  {
                    value: "Youth and Youth Development",
                    label: "Youth and Youth Development",
                  },
                  {
                    value: "Information Communication and Technology",
                    label: "Information Communication and Technology",
                  },
                  { value: "Trade", label: "Trade" },
                  { value: "Migration", label: "Migration" },
                  {
                    value: "Gender and Social Inclusion",
                    label: "Gender and Social Inclusion",
                  },
                  { value: "Philanthropy", label: "Philanthropy" },
                  {
                    value: "Research and Development",
                    label: "Research and Development",
                  },
                  { value: "Environment", label: "Environment" },
                  {
                    value: "Water, Sanitation and Hygiene.",
                    label: "Water, Sanitation and Hygiene.",
                  },
                  { value: "Religion Related ", label: "Religion Related " },
                  {
                    value: "Media/Communication",
                    label: "Media/Communication",
                  },
                ]}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Select
                name="services"
                onChange={(e) => setFieldValue("services", e)}
                value={values.services}
                label="Service(s) Rendered"
                placeholder={"Select Service Rendered"}
                isMulti={true}
                options={[
                  { value: "Legal assistance", label: "Legal assistance" },
                  {
                    value: "Psychosocial / Counseling",
                    label: "Psychosocial / Counseling",
                  },
                  { value: "Mediation", label: "Mediation" },
                  { value: "Training", label: "Training" },
                  {
                    value: "Financial Assistance",
                    label: "Financial Assistance",
                  },
                  {
                    value: "Police / Other Security Actors",
                    label: "Police / Other Security Actors",
                  },
                ]}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Input
                name="other_services"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.other_services}
                label="Other services not included (please specify)"
                placeholder="What Services does your Organization Render?"
                as="textarea"
                rows={5}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Input
                name="location"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.location}
                label="Organization's Address"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Input
                name="city"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
                label="City"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Select
                name="state"
                onChange={(e) => setFieldValue("state", e)}
                value={values.state}
                label="State"
                placeholder={"Select State"}
                isMulti={true}
                options={[
                  { value: "Abia", label: "Abia" },
                  { value: "Adamawa", label: "Adamawa" },
                  { value: "Akwa Ibom", label: "Akwa Ibom" },
                  { value: "Anambra", label: "Anambra" },
                  { value: "Bauchi", label: "Bauchi" },
                  { value: "Bayelsa", label: "Bayelsa" },
                  { value: "Benue", label: "Benue" },
                  { value: "Borno", label: "Borno" },
                  { value: "Cross River", label: "Cross River" },
                  { value: "Delta", label: "Delta" },
                  { value: "Ebonyi", label: "Ebonyi" },
                  { value: "Edo", label: "Edo" },
                  { value: "Ekiti", label: "Ekiti" },
                  { value: "Enugu", label: "Enugu" },
                  { value: "FCT", label: "Federal Capital Territory" },
                  { value: "Gombe", label: "Gombe" },
                  { value: "Imo", label: "Imo" },
                  { value: "Jigawa", label: "Jigawa" },
                  { value: "Kaduna", label: "Kaduna" },
                  { value: "Kano", label: "Kano" },
                  { value: "Katsina", label: "Katsina" },
                  { value: "Kebbi", label: "Kebbi" },
                  { value: "Kogi", label: "Kogi" },
                  { value: "Kwara", label: "Kwara" },
                  { value: "Lagos", label: "Lagos" },
                  { value: "Nasarawa", label: "Nasarawa" },
                  { value: "Niger", label: "Niger" },
                  { value: "Ogun", label: "Ogun" },
                  { value: "Ondo", label: "Ondo" },
                  { value: "Osun", label: "Osun" },
                  { value: "Oyo", label: "Oyo" },
                  { value: "Plateau", label: "Plateau" },
                  { value: "Rivers", label: "Rivers" },
                  { value: "Sokoto", label: "Sokoto" },
                  { value: "Taraba", label: "Taraba" },
                  { value: "Yobe", label: "Yobe" },
                  { value: "Zamfara", label: "Zamfara" },
                ]}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Upload Proof of Address. Example Utility bill (Valid within 3
                months)
              </Form.Label>
              <FileUploader
                multiple={false}
                handleChange={hanldeProofOfAddressUpload}
                name="file"
                types={fileTypes}
                label="Drag and drop Image here or Browse"
              />
              <div className="uploader-loading-block">
                <p style={{ margin: 0 }}>
                  {addressProofFileObj.loading
                    ? `${addressProofFileObj.name} Uploading`
                    : addressProofFileObj.name
                    ? addressProofFileObj.name
                    : "No file(s) uploaded yet"}
                </p>
                {addressProofFileObj.loading && (
                  <Oval wrapperClass="ml-2" height={20} width={20} />
                )}
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Upload Logo Mark of your Organization</Form.Label>
              <FileUploader
                multiple={false}
                handleChange={handleOrgLogoUpload}
                name="file"
                types={fileTypes}
                label="Drag and drop Image here or Browse"
              />
              <div className="form-text">
                Logo dimension must be 300px by 300px
              </div>
              <div className="uploader-loading-block">
                <p style={{ margin: 0 }}>
                  {orgLogoFileObj.loading
                    ? `${orgLogoFileObj.name} Uploading`
                    : orgLogoFileObj.name
                    ? orgLogoFileObj.name
                    : "No file(s) uploaded yet"}
                </p>
                {orgLogoFileObj.loading && (
                  <Oval wrapperClass="ml-2" height={20} width={20} />
                )}
              </div>
            </Form.Group>
            <Form.Label>Social Media Links</Form.Label>
            {[...Array(socialLinkCount).keys()].map((_, idx) => (
              <div
                className="input-group"
                key={idx}
                style={{ marginBottom: 10 }}
              >
                <div style={{ width: "50%" }}>
                  <Select
                    name={`social_media.${idx}.type`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.social_media[idx]?.type}
                    placeholder={"Select Social Media"}
                    options={[
                      { value: "Instagram", label: "Instagram" },
                      { value: "Twitter", label: "Twitter" },
                      { value: "Facebook", label: "Facebook" },
                    ]}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <Input
                    type="text"
                    className="form-control"
                    value={values?.social_media[idx]?.link}
                    name={`social_media.${idx}.link`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter handle"
                  />
                </div>
              </div>
            ))}

            <div className="onboarding-social-container">
              <p className="onboarding-social-link" onClick={handleAddLink}>
                Add Social Media Links
              </p>
            </div>
            <Button
              type="submit"
              className="btn btn-success register-btn mt-5 login-btn px-5"
              loading={isLoading}
              disabled={orgLogoFileObj?.loading || addressProofFileObj?.loading}
            >
              Save and Continue
            </Button>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default OrganizationDetails;
