import React from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import add from "../../img/uas.svg";
import { Oval } from "react-loader-spinner";

function AdminViewFeeds({ data, isLoading }) {
  return (
    <section>
      <div className="content overview">
        <div className="row justify-content-between">
          <div className="col-md-6 mt-3 back">
            <Link to="/admin/news">
              <i className="bi bi-arrow-left me-2"></i> Back to News Feeds
            </Link>
          </div>
          <div className="col-md-6 text-end">
            <div className="d-flex flex-row">
              <button
                className="btn btn-success w-50 sbm-btn mb-3 mx-3"
                data-bs-toggle="modal"
                data-bs-target="#update-status"
              >
                Update Status
              </button>
              <Link
                to={`/admin/news/edit/${data?.id}`}
                className="btn btn-success w-50 login-btn mb-3"
              >
                Edit News Item
              </Link>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
          <hr />
        </div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
            }}
          >
            <Oval wrapperClass="ml-2" height={40} width={40} />
          </div>
        ) : (
          <div className="row mt-5 justify-content-center">
            <div className="col-md-8 mb-3">
              <div className="n-feeds">
                {data?.image && (
                  <img
                    src={data?.image}
                    alt="news"
                    className="img-fluid w-100"
                  />
                )}
                <div className="p-4">
                  <h5>{data?.title}</h5>
                  <Link to={`/admin/view-listing/${data?.organization?.id}`}>
                    <h6 className="mt-3 mb-0">By {data?.author}</h6>
                  </Link>
                  <Link
                    style={{
                      cursor: "pointer",
                      fontSize: 14,
                    }}
                    to={`/single-directory/${data?.organization?.id}`}
                  >
                    {data?.organization?.organization}
                  </Link>
                  <p className="my-3">
                    {dayjs(data?.created_at).format("MMMM DD, YYYY")}22
                  </p>
                  <h4>{data?.content}</h4>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Modal for Update Status */}
      <div
        className="modal fade"
        id="update-status"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <div className="col">
                  <img
                    src={add}
                    alt="update"
                    className="img-fluid mb-3"
                    style={{ marginTop: "-30px" }}
                  />
                  <h4>Update News Feed Item Status </h4>
                  <div className="update-card mt-3">
                    <h4>
                      Initiative for Rights Advocacy and Development Aims to Get
                      “Every Child in School”{" "}
                    </h4>
                    <p>November 23, 2022</p>
                  </div>
                  <div className="form-check my-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Active
                    </label>
                  </div>
                  <div className="form-check mb-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                    />
                    <label className="form-check-label" for="flexRadioDefault2">
                      Inactive
                    </label>
                  </div>
                  <div className="d-flex flex-row">
                    <Button
                      type={"button"}
                      className="btn btn-success border-g bg-white w-100 cancel-btn mt-3 me-3"
                    >
                      Cancel
                    </Button>
                    <Button
                      type={"button"}
                      className="btn btn-success w-100 login-btn mt-3"
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdminViewFeeds;
