import CryptoJS from 'crypto-js'

export function ucfirst(str) {
	const firstLetter = str.substr(0, 1)
	return firstLetter.toUpperCase() + str.substr(1)
}

export function localStorageChecker() {
	const storage = typeof window.localStorage
	return storage ? true : false
}

export function saveToLocalStorage(name, item) {
	if (localStorageChecker()) {
		localStorage.setItem(name, item)
		return true
	}
	return false
}

export function getLocalStorageItem(name) {
	if (localStorageChecker()) {
		const item = localStorage.getItem(name)
		return item
	}
	return null
}

export function removeLocalStorageItem(name) {
	if (localStorageChecker()) {
		localStorage.removeItem(name)
	}
	return false
}

export const createRandomId = () => {
	return Math.floor(Math.random() * 10000000000 + 1)
}

export const checkCrossOriginUrl = (url) => {
	if (!url || url === 'about:blank') {
		return false
	}

	const parser = document.createElement('a')
	parser.href = url

	const current = window.location

	if (
		(parser.hostname && current.hostname !== parser.hostname) ||
		(parser.protocol && current.protocol !== parser.protocol)
	) {
		return true
	}
	return false
}

export const encryptData = (data) => {
	const stringData = JSON.stringify(data)
	const SECRET_PASS = process.env.REACT_APP_SECRET_PASS
	const encrytedData = CryptoJS.AES.encrypt(stringData, SECRET_PASS).toString()

	return encrytedData
}

export const decryptData = (encrytedData) => {
	const SECRET_PASS = process.env.REACT_APP_SECRET_PASS
	const bytes = CryptoJS.AES.decrypt(encrytedData, SECRET_PASS)
	const originalText = bytes.toString(CryptoJS.enc.Utf8)
	return JSON.parse(originalText)
}

export const allStates = [
	{ value: 'Abia', label: 'Abia' },
	{ value: 'Adamawa', label: 'Adamawa' },
	{ value: 'Akwa Ibom', label: 'Akwa Ibom' },
	{ value: 'Anambra', label: 'Anambra' },
	{ value: 'Bauchi', label: 'Bauchi' },
	{ value: 'Bayelsa', label: 'Bayelsa' },
	{ value: 'Benue', label: 'Benue' },
	{ value: 'Borno', label: 'Borno' },
	{ value: 'Cross River', label: 'Cross River' },
	{ value: 'Delta', label: 'Delta' },
	{ value: 'Ebonyi', label: 'Ebonyi' },
	{ value: 'Edo', label: 'Edo' },
	{ value: 'Ekiti', label: 'Ekiti' },
	{ value: 'Enugu', label: 'Enugu' },
	{ value: 'FCT', label: 'Federal Capital Territory' },
	{ value: 'Gombe', label: 'Gombe' },
	{ value: 'Imo', label: 'Imo' },
	{ value: 'Jigawa', label: 'Jigawa' },
	{ value: 'Kaduna', label: 'Kaduna' },
	{ value: 'Kano', label: 'Kano' },
	{ value: 'Katsina', label: 'Katsina' },
	{ value: 'Kebbi', label: 'Kebbi' },
	{ value: 'Kogi', label: 'Kogi' },
	{ value: 'Kwara', label: 'Kwara' },
	{ value: 'Lagos', label: 'Lagos' },
	{ value: 'Nasarawa', label: 'Nasarawa' },
	{ value: 'Niger', label: 'Niger' },
	{ value: 'Ogun', label: 'Ogun' },
	{ value: 'Ondo', label: 'Ondo' },
	{ value: 'Osun', label: 'Osun' },
	{ value: 'Oyo', label: 'Oyo' },
	{ value: 'Plateau', label: 'Plateau' },
	{ value: 'Rivers', label: 'Rivers' },
	{ value: 'Sokoto', label: 'Sokoto' },
	{ value: 'Taraba', label: 'Taraba' },
	{ value: 'Yobe', label: 'Yobe' },
	{ value: 'Zamfara', label: 'Zamfara' },
]
