import axiosClient from '../utlis/axiosClient'

export const getAllCategories = async () => {
	try {
		const response = await axiosClient.get(`/v1/web_views/categories`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return response.data.data
	} catch (error) {
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}
