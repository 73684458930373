import React from "react";
import { Link } from "react-router-dom";
import client from "../../img/client.svg";
import update from "../../img/update.svg";
import dwl from '../../img/dwl.svg'



function ListingView() {
  return (
    <section className="">
      <div className="content overview">
        <div className="row">
          <div className="col-md-8 mb-2 back">
            <Link to="/mis/listings"><i className="bi bi-arrow-left me-2"></i> Back to Listing</Link>
          </div>
          <div className="col-md-4 text-end">
            <div className="d-flex flex-row">
              <Link to="" className="btn btn-success bg-white w-100 login-btn me-3">Reject Application</Link>
              <Link to="" data-bs-toggle="modal" data-bs-target="#approve" className="btn btn-success w-100 login-btn">Accept Application</Link>
            </div>
          </div>
        </div>
        <div style={{marginLeft: "-50px", marginRight:"-58px"}}><hr/></div>

        <div className="row statuses">
          <div className="col-md-2">
            <img src={client} alt="logo" className="img-fluid w-75" />
          </div>
          <div className="col-md-10">
            <h3 className="py-3">Youth Initiative for Advocacy Growth & Advancement. (YIAGA)</h3>
            <hr/>
            <div className="row pt-2">
              <div className="col-md-3 border-end mb-2">
                <h6>Pending <i className="bi bi-circle-fill ms-2"></i></h6>
                <p>Listing Status</p>
              </div>
              <div className="col-md-3 border-end mb-2">
                <h6>Unverified</h6>
                <p>Ranking</p>
              </div>
              <div className="col-md-6 mb-2">
                <h6>Elections, Governance and Development, and Legislative Engagement</h6>
                <p>Thematic Area</p>
              </div>
            </div>
          </div>
        </div>
        <div style={{marginLeft: "-50px", marginRight:"-58px"}}><hr/></div>

        <div className="p-4 bg-white group-cards mt-4">
          <div className="row listings-tog">
            <div className="col">
              <ul className="nav nav-tabs tog" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active tog-btn" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Listing Information</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Business Documentation</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Additional Information</button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                  <div className="row my-5 statuses">
                    <div className="col-md-6 border-end">
                      <p><i className="bi bi-house me-2"></i> Organization's Name</p>
                      <h6>Youth Initiative for Advocacy Growth & Advancement. (YIAGA)</h6>
                    </div>
                    <div className="col-md-6">
                      <p><i className="bi bi-calendar me-2"></i> Year Founded</p>
                      <h6>2022</h6>
                    </div>
                  </div>
                  <hr/>
                  <div className="row my-5 statuses">
                    <div className="col-md-6 border-end">
                      <p><i className="bi bi-envelope me-2"></i> Organization's Mail</p>
                      <Link>responsiblecitizensandhumandev@rechi.com.ng</Link>
                    </div>
                    <div className="col-md-6">
                      <p><i className="bi bi-telephone me-2"></i> Organization’s Phone</p>
                      <Link>+2348026589754 </Link>
                    </div>
                  </div>
                  <hr/>
                  <div className="row my-5 statuses">
                    <div className="col-md-6 border-end">
                      <p><i className="bi bi-geo-alt me-2"></i> Organization's Address</p>
                      <h6>P.M.B 1515. Ilorin, Kwara State, Ilorin, Kwara, Nigeria</h6>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col">
                          <p>City</p>
                          <h6>Surulere</h6>
                        </div>
                        <div className="col">
                          <p>State</p>
                          <h6>Lagos State</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div className="row my-5 statuses">
                    <div className="col-md-6 border-end">
                      <p><i className="bi bi-gear me-2"></i> Services Rendered</p>
                      <h6>Elections, Governance and Development, and Legislative Engagement</h6>
                    </div>
                    <div className="col-md-6">
                      <p><i className="bi bi-briefcase me-2"></i> Thematic Area</p>
                      <h6>Sustainable Development Goals (SDGs), Humanitarian Affairs, Peace and Security</h6>
                    </div>
                  </div>
                  <hr/>
                  <div className="row my-5 statuses">
                    <div className="col-md-6 border-end">
                      <div className="row">
                        <div className="col">
                          <p><i className="bi bi-calendar-event me-2"></i> Registration Date</p>
                          <h6>08/05/2022</h6>
                        </div>
                        <div className="col">
                          <p><i className="bi bi-calendar-event me-2"></i> Listing Date</p>
                          <h6>08/05/2022</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col">
                          <p><i className="bi bi-layers me-2"></i> No of Projects</p>
                          <h6>40 Projects</h6>
                        </div>
                        <div className="col">
                          <p><i className="bi bi-cash me-2"></i> Received Donations</p>
                          <h6>₦500,000</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div className="my-5 statuses">
                    <div className="row">
                      <div className="col">
                        <p><i className="bi bi-phone me-2"></i> Social Media Links</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mb-2">
                        <h6>instagram.com/rfhgsc</h6>
                      </div>
                      <div className="col mb-2">
                        <h6>instagram.com/rfhgsc</h6>
                      </div>
                      <div className="col mb-2">
                        <h6>instagram.com/rfhgsc</h6>
                      </div>
                      <div className="col mb-2">
                        <h6>instagram.com/rfhgsc</h6>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div className="row mt-5 statuses">
                    <div className="col-md-2 mb-2">
                      <h6 className="mb-2">Organization’s Address</h6>
                      <div>
                        <div className='bg-download text-center'>
                          <img src={dwl} alt="" className='img-fluid' />
                        </div>
                        <div className='bg-white p-4 bg-dwd'>
                          <h4>Cacdocument.pdf</h4>
                          <h5>1.8MB</h5>
                          <p>Uploaded 13/10/2022</p>
                          <Link className='float-end dload'><i className="bi bi-download"></i></Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <h6 className="mb-2">Organization’s Logo Mark</h6>
                      <div>
                        <div className='bg-download text-center'>
                          <img src={dwl} alt="" className='img-fluid' />
                        </div>
                        <div className='bg-white p-4 bg-dwd'>
                          <h4>Cacdocument.pdf</h4>
                          <h5>1.8MB</h5>
                          <p>Uploaded 13/10/2022</p>
                          <Link className='float-end dload'><i className="bi bi-download"></i></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                  <div className="row my-5 statuses">
                    <div className="col-md-4 border-end">
                      <p>CAC Registered</p>
                      <h6>Yes</h6>
                    </div>
                    <div className="col-md-4 border-end">
                      <p>Organization’s RC Number</p>
                      <h6>RC: 3436</h6>
                    </div>
                    <div className="col-md-4">
                      <p>Organization’s Tax Identification Number</p>
                      <h6>247243293</h6>
                    </div>
                  </div>
                  <hr/>
                  <div className="row mt-5 statuses">
                    <div className="col-md-2 mb-2">
                      <h6 className="mb-2">Organization’s CAC Certificate</h6>
                      <div>
                        <div className='bg-download text-center'>
                          <img src={dwl} alt="" className='img-fluid' />
                        </div>
                        <div className='bg-white p-4 bg-dwd'>
                          <h4>Cacdocument.pdf</h4>
                          <h5>1.8MB</h5>
                          <p>Uploaded 13/10/2022</p>
                          <Link className='float-end dload'><i className="bi bi-download"></i></Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <h6 className="mb-2">Organization’s Article of Association</h6>
                      <div>
                        <div className='bg-download text-center'>
                          <img src={dwl} alt="" className='img-fluid' />
                        </div>
                        <div className='bg-white p-4 bg-dwd'>
                          <h4>Cacdocument.pdf</h4>
                          <h5>1.8MB</h5>
                          <p>Uploaded 13/10/2022</p>
                          <Link className='float-end dload'><i className="bi bi-download"></i></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
                  <div className="my-5 statuses">
                    <div className="row">
                      <div className="col">
                        <h6><i className="bi bi-bank me-2"></i> Organisation’s Bank Account Details</h6>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-4 border-end mb-2">
                        <p>Bank Name</p>
                        <h6>Guaranty Trust Bank</h6>
                      </div>
                      <div className="col-md-4 border-end mb-2">
                        <p>Beneficiary Name</p>
                        <h6>Youth Initiative for Advocacy Growth & Advancement</h6>
                      </div>
                      <div className="col-md-4 mb-2">
                        <p>Account Number</p>
                        <h6>247243293</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Reject Modal */}
      <div className="modal fade" id="approve" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <div className="col">
                  <img src={update} alt="update" className="img-fluid mb-3" style={{marginTop: "-30px"}} />
                  <h4>Approve Application</h4>
                  <p>Are you sure you want to approve this application?</p>
                  <form>
                    <div className="d-flex flex-row">
                      <Link to="" className="btn btn-success bg-white w-100 login-btn mt-3 me-3">Cancel</Link>
                      <Link to="" className="btn btn-success w-100 login-btn mt-3">Confirm</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ListingView;