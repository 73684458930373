import React from "react";
import AdminMessagesB from "./dashboardParts/adminMessagesB";
import Adminsidebar from "./dashboardParts/adminsidebar";
import { getMessages } from "../handlers/messagesHandler";
import { useMutation, useQuery, useQueryClient } from "react-query";

function AdminMessages() {
  const [currPage, setCurrPage] = React.useState(1);
  const { data: data, isLoading: isDataLoading } = useQuery(
    [`messages`, currPage],
    () => getMessages({ page: currPage })
  );

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Adminsidebar />
      <AdminMessagesB data={data} />
    </div>
  );
}

export default AdminMessages;
