import React from "react";
import ResourcesCenter from "./dashboardParts/resourceCenter";
import Sidebar from "./dashboardParts/sideBar";
import { useQuery } from "react-query";
import {
  getAllResources,
  getCreatorResources,
} from "../handlers/resourceHandler";

function Resources() {
  const [currPage, setCurrPage] = React.useState(1);
  const [userCurrPage, setUserCurrPage] = React.useState(1);
  const [allType, setAllType] = React.useState("");
  const [allSearch, setAllSearch] = React.useState("");
  const [userType, setUserType] = React.useState("");
  const [userSearch, setUserSearch] = React.useState("");
  const timeout = React.useRef();
  const searchInputRef = React.useRef();
  const userSearchInputRef = React.useRef();

  const { data: allResources } = useQuery(
    ["all-system-resources", currPage, allType, allSearch],
    () => getAllResources({ page: currPage, type: allType, search: allSearch })
  );

  const { data: userResources } = useQuery(
    ["user-resources", userCurrPage, userType, userSearch],
    () =>
      getCreatorResources({
        page: userCurrPage,
        type: userType,
        search: userSearch,
      })
  );

  const handlePaginateChange = async (page) => {
    setCurrPage(page.selected + 1);
  };

  const handleUserPaginateChange = async (page) => {
    setUserCurrPage(page.selected + 1);
  };

  const handleAllTypeChange = (e) => {
    setAllType(e.target.value);
  };

  const handleAllSearch = (e) => {
    const value = e.target.value;
    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setAllSearch(value);
    }, 600);
  };

  const handleUserTypeChange = (e) => {
    setUserType(e.target.value);
  };

  const handleUserSearch = (e) => {
    const value = e.target.value;
    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setUserSearch(value);
    }, 600);
  };

  const handleAllClear = () => {
    setAllSearch("");
    setAllType("");
    setUserSearch("");
    setUserType("");
    searchInputRef.current.value = "";
    userSearchInputRef.current.value = "";
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Sidebar />
      <ResourcesCenter
        userResources={userResources?.data}
        allResources={allResources?.resources?.data}
        allTotal={allResources?.resources?.total}
        userTotal={userResources?.total}
        handlePaginateChange={handlePaginateChange}
        currPage={currPage}
        handleUserPaginateChange={handleUserPaginateChange}
        userCurrPage={userCurrPage}
        allType={allType}
        handleAllTypeChange={handleAllTypeChange}
        userType={userType}
        handleUserTypeChange={handleUserTypeChange}
        handleAllClear={handleAllClear}
        handleAllSearch={handleAllSearch}
        handleUserSearch={handleUserSearch}
        searchInputRef={searchInputRef}
        userSearchInputRef={userSearchInputRef}
      />
    </div>
  );
}

export default Resources;
