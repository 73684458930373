import React from "react";
import { Link } from "react-router-dom";
import pix from "../../img/singled.svg";



function DonationSingle() {
  return (
    <section className="">
      <div className="content overview">
        <div className="row">
          <div className="col-md-7 mb-2 back">
            <Link to="/cso/campaign"><i className="bi bi-arrow-left me-2"></i> Back</Link>
          </div>
          <div className="col-md-5 text-end">
            <div className="d-flex flex-row">
              <Link to="" className="btn btn-success w-100 login-btn me-3">View on Website <i className="bi bi-box-arrow-up-right ms-3"></i></Link>
              <Link to="" data-bs-toggle="modal" data-bs-target="#approve" className="btn btn-success bg-white w-100 login-btn lin">Edit Campaign</Link>
            </div>
          </div>
        </div>
        <div style={{marginLeft: "-50px", marginRight:"-58px"}}><hr/></div>
        <div className="mt-4">
          <div className="row">
            <div className="col-md-7 mb-3">
              <div className="p-4 bg-white group-cards">
                <div className="view-donations">
                  <img src={pix} alt="profile" className="img-fluid w-100 mb-3" />
                  <h6>Fundraiser for the Human Rights Peace Walk on World Human Rights Day</h6>
                  <p style={{marginBottom: "40px"}}>Help us build a community center for underprivileged children in our neighborhood. Your donation will go towards construction costs and funding programs for education, arts, and sports. Help us build a community center for underprivileged children in our neighborhood.</p>
                </div>
                
              </div>
            </div>
            <div className="col-md-5">
              <div className="p-4 bg-white group-cards mb-3">
                <div className="row">
                  <div className="col">
                    <div className="progress mb-2" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                      <div className="progress-bar w-75"></div>
                    </div>
                    <span>₦500,000 raised of ₦1,200,000</span>
                  </div>
                </div>
                <div className="row mt-4 donation-desc">
                  <div className="col-md-6">
                    <p>700 Received Donations</p>
                  </div>
                  <div className="col-md-6 text-end">
                    <span className="bg-don">Created 3 days ago</span>
                  </div>
                </div>
                <div className="row donation-desc">
                  <div className="col">
                    <h6>Campaign Start Date: 28, September 2022</h6>
                    <h6 className="mt-2">Campaign Completion Date: 28, September 2022</h6>
                  </div>
                </div>
              </div>
              <div className="p-4 bg-white group-cards">
                <div className="row">
                  <div className="col">
                   <div className="separator">
                    <div className="lines"></div>
                    <h1>Bank Account Details </h1>
                    <div className="lines"></div>
                   </div>
                  </div>
                </div>
                <div className="row details-bank mt-3">
                  <div className="col-md-6 mb-2">
                    <h5>BANK NAME</h5>
                    <h6>Zenith Bank</h6>
                  </div>
                  <div className="col-md-6 mb-2">
                    <h5>ACCOUNT NUMBER</h5>
                    <h6 style={{color: "#25815E"}}>0920304443</h6>
                  </div>
                </div>
                <div className="row details-bank mt-2">
                  <div className="col">
                    <h5>BENEFICIARY</h5>
                    <h6>HUMAN RIGHTS JUSTICE AND PEACE FOUNDATION</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white group-cards mt-4">
          <div style={{padding: "20px"}}>
            <h5>All Donations <span className="ms-3 tab-lists">₦500,000 (700 Donations)</span></h5>
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Date & Time</th>
                  <th scope="col">Transaction ID</th>
                  <th scope="col">Donor Name</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Payment Method</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>15, Mar 2022<br/>10:00am</td>
                  <td>BA9212320</td>
                  <td>Ola Akin</td>
                  <td>₦2, 536,100</td>
                  <td>Debit Card</td>
                </tr>
                <tr>
                  <td>15, Mar 2022<br/>10:00am</td>
                  <td>BA9212320</td>
                  <td>Ola Akin</td>
                  <td>₦2, 536,100</td>
                  <td>Debit Card</td>
                </tr>
                <tr>
                  <td>15, Mar 2022<br/>10:00am</td>
                  <td>BA9212320</td>
                  <td>Ola Akin</td>
                  <td>₦2, 536,100</td>
                  <td>Debit Card</td>
                </tr>
                <tr>
                  <td>15, Mar 2022<br/>10:00am</td>
                  <td>BA9212320</td>
                  <td>Ola Akin</td>
                  <td>₦2, 536,100</td>
                  <td>Debit Card</td>
                </tr>
                <tr>
                  <td>15, Mar 2022<br/>10:00am</td>
                  <td>BA9212320</td>
                  <td>Ola Akin</td>
                  <td>₦2, 536,100</td>
                  <td>Debit Card</td>
                </tr>
                <tr>
                  <td>15, Mar 2022<br/>10:00am</td>
                  <td>BA9212320</td>
                  <td>Ola Akin</td>
                  <td>₦2, 536,100</td>
                  <td>Debit Card</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DonationSingle;