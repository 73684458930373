import React from "react";
import { Link } from "react-router-dom";
import update from "../../img/update.svg";


function ListingsBody() {
  return (
    <section>
      <div className="content overview">
        <div className="row">
          <div className="col-md-9 mb-2">
            <h2>Listings</h2>
          </div>
          <div className="col-md-3 text-end">
            <div className="form-group has-search">
              <span className="bi bi-search form-control-feedback"></span>
              <input type="text" className="form-control" placeholder="Search"/>
            </div>
          </div>
        </div>
        <div className="p-4 group-cards gray mt-4">
          <form className="row">
            <div className="col-md-3">
              <div className="mb-3">
                <label className="form-label">Location</label>
                <select className="form-select" aria-label="Default select example">
                  <option defaultValue>All</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label className="form-label">Listings Status</label>
                <select className="form-select" aria-label="Default select example">
                  <option defaultValue>All</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label className="form-label">Date Created</label>
                <select className="form-select" aria-label="Default select example">
                  <option defaultValue>All</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div className="col-md-3 text-end align-content-center">
              <button type="submit" className="btn btn-primary bg-white text-muted btn-claer px-3">Clear all</button>
            </div>
          </form>
        </div>
        <div className="row mt-5">
          <div className="col-md-3 mb-3">
            <div className="bg-listed">
              <h6>Total No of Registered Orgs</h6>
              <h5>500,000</h5>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="bg-listed" style={{backgroundColor: "#FCF9EE"}}>
              <h6>Total No of Registered Orgs</h6>
              <h5>500,000</h5>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="bg-listed" style={{background: "#EEFCF2"}}>
              <h6>Total No of Registered Orgs</h6>
              <h5>500,000</h5>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="bg-listed" style={{background: "#F6F7FE"}}>
              <h6>Total No of Registered Orgs</h6>
              <h5>500,000</h5>
            </div>
          </div>
        </div>
        <div className="bg-white group-cards mt-4">
          <div style={{padding: "20px"}}>
            <h5>Listings <span className="ms-3 tab-lists">5,000 Listings</span></h5>
            <h1 className="float-end" style={{marginTop: "-28px"}}>Bulk Actions <i className="ms-3 bi bi-three-dots-vertical"></i></h1>
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Organization Name</th>
                  <th scope="col">Thematic Area</th>
                  <th scope="col">Listing Status</th>
                  <th scope="col">Ranking</th>
                  <th scope="col">No of Projects</th>
                  <th scope="col">Registration Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Responsible Citizens</td>
                  <td>Sustainable Development</td>
                  <td><span className="reject"><i className="bi bi-circle-fill me-2"></i>Rejected</span></td>
                  <td>Unverified</td>
                  <td>50</td>
                  <td>08/10/2022</td>
                  <td>
                    <div className="dropdown">
                      <i className="bi bi-three-dots-vertical actions" data-bs-toggle="dropdown" aria-expanded="false"></i>
                      <ul className="dropdown-menu listing-drop">
                        <li><Link className="dropdown-item listing-item" to="/mis/view-listing"><i className="bi bi-eye me-2"></i> View Listing Details</Link></li>
                        <li><Link className="dropdown-item listing-item" data-bs-toggle="modal" data-bs-target="#updatelistingdetails" to=""><i className="bi bi-arrow-repeat me-2"></i> Update Listing Status</Link></li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Responsible Citizens</td>
                  <td>Sustainable Development</td>
                  <td><span className="approved"><i className="bi bi-circle-fill me-2"></i>Approved</span></td>
                  <td>Unverified</td>
                  <td>50</td>
                  <td>08/10/2022</td>
                  <td>
                    <div className="dropdown">
                      <i className="bi bi-three-dots-vertical actions" data-bs-toggle="dropdown" aria-expanded="false"></i>
                      <ul className="dropdown-menu listing-drop">
                        <li><Link className="dropdown-item listing-item" to="/mis/view-listing"><i className="bi bi-eye me-2"></i> View Listing Details</Link></li>
                        <li><Link className="dropdown-item listing-item" data-bs-toggle="modal" data-bs-target="#updatelistingdetails" to=""><i className="bi bi-arrow-repeat me-2"></i> Update Listing Status</Link></li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Responsible Citizens</td>
                  <td>Human Rights, Peace and Security</td>
                  <td><span className="pending"><i className="bi bi-circle-fill me-2"></i>Pending</span></td>
                  <td>Unverified</td>
                  <td>50</td>
                  <td>08/10/2022</td>
                  <td>
                    <div className="dropdown">
                      <i className="bi bi-three-dots-vertical actions" data-bs-toggle="dropdown" aria-expanded="false"></i>
                      <ul className="dropdown-menu listing-drop">
                        <li><Link className="dropdown-item listing-item" to="/mis/view-listing"><i className="bi bi-eye me-2"></i> View Listing Details</Link></li>
                        <li><Link className="dropdown-item listing-item" data-bs-toggle="modal" data-bs-target="#updatelistingdetails" to=""><i className="bi bi-arrow-repeat me-2"></i> Update Listing Status</Link></li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Responsible Citizens</td>
                  <td>Human Rights, Peace and Security</td>
                  <td><span className="pending"><i className="bi bi-circle-fill me-2"></i>Pending</span></td>
                  <td>Unverified</td>
                  <td>50</td>
                  <td>08/10/2022</td>
                  <td><i className="bi bi-three-dots-vertical"></i></td>
                </tr>
                <tr>
                  <td>Responsible Citizens and<br/> Human Development <br/>Initiative (RECHI)</td>
                  <td>Human Rights, Peace and Security</td>
                  <td><span className="approved"><i className="bi bi-circle-fill me-2"></i>Approved</span></td>
                  <td>Verified</td>
                  <td>50</td>
                  <td>08/10/2022</td>
                  <td><i className="bi bi-three-dots-vertical"></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Update Modal */}
      <div className="modal fade" id="updatelistingdetails" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <div className="col">
                  <img src={update} alt="update" className="img-fluid mb-3" style={{marginTop: "-30px"}} />
                  <h4>Update Listing Status</h4>
                  <p>Update organization listing status</p>
                  <form className="row">
                    <div className="mb-3">
                      <label className="form-label">Listing Status</label>
                      <select className="form-select" aria-label="Default select example">
                        <option defaultValue>Active</option>
                        <option value="1">Inactive</option>
                        <option value="2">Active</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Send a Message</label>
                      <textarea className="form-control" placeholder="Enter a message" rows="7"></textarea>
                    </div>
                    <div className="mb-3">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                          Send notification email
                        </label>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <Link to="" className="btn btn-success bg-white w-100 login-btn mt-3 me-3">Cancel</Link>
                      <Link to="" className="btn btn-success w-100 login-btn mt-3">Confirm</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </section>
  )
}

export default ListingsBody;