import AdminCreateFile from './dashboardParts/adminCreateFile';
import Adminsidebar from './dashboardParts/adminsidebar';

function AdminAddFile() {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Adminsidebar/>
      <AdminCreateFile/>
    </div>
  )
}

export default AdminAddFile;
