import axiosClient from '../utlis/axiosClient'

export const getOrgProfile = async () => {
	try {
		const response = await axiosClient.get(`/v1/org`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return response.data.data
	} catch (error) {
		console.log({ error })
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const getOrgThematicArea = async () => {
	try {
		const response = await axiosClient.get(`/v1/org/org_same_thematic`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return response.data.data
	} catch (error) {
		console.log({ error })
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const getOrgOverview = async () => {
	try {
		const response = await axiosClient.get(`/v1/overview`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return response.data
	} catch (error) {
		console.log({ error })
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}