import React, { useState } from 'react'
import avatar from '../../img/user-img.svg'
import add from '../../img/added.svg'
import placeholderImg from '../../img/placeholder.jpg'
import { Link } from 'react-router-dom'
// import one from "../../img/ups.svg";
import HeaderDropdownMenu from '../../component/HeaderDropdownMenu'
import dayjs from 'dayjs'
import FileSaver from 'file-saver'
import { Button } from 'react-bootstrap'
import { FileUploader } from 'react-drag-drop-files'
import Compliance from './ComplianceQuestions'
import { AuthContext } from '../../context/AuthContext'

const fileTypes = ['JPG', 'PNG', 'GIF', 'PDF']

function Doc({ data }) {
	const [file, setFile] = useState(null)
	const { userInfo } = React.useContext(AuthContext)
	const handleChange = (file) => {
		setFile(file)
	}
	const downloadImage = (image_url) => {
		const arr = image_url.split('/')
		const arrLen = arr?.length
		const name = arr[arrLen - 1]
		FileSaver.saveAs(image_url, name) // Put your image url here.
	}

	return (
		<section>
			<div className="content overview">
				<div className="row justify-content-between">
					<div className="col-md-5 mb-2">
						<div className="form-group has-search">
							<span className="bi bi-search form-control-feedback"></span>
							<input
								type="text"
								className="form-control"
								placeholder="Search"
							/>
						</div>
					</div>
					<div className="col-md-3 text-end">
						<div className="d-flex justify-content-end">
							<p className="bg-lt me-5 mt-1">
								<i className="bi bi-circle-fill fs-6 me-2"></i> Directory
							</p>
							<div className="dropdown">
								<Link
									to="#"
									className="dropdown-toggle"
									role="button"
									data-bs-toggle="dropdown"
								>
									{userInfo?.organization?.orgfile?.org_logo ? (
										<img
											src={userInfo?.organization?.orgfile?.org_logo}
											alt="avatar"
											className="profile-topbar-avi"
										/>
									) : (
										<img src={avatar} alt="avi" className="img-fluid" />
									)}
								</Link>
								<HeaderDropdownMenu />
							</div>
						</div>
					</div>
				</div>
				<div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
					<hr />
				</div>
				<div className="row">
					<div className="col-md-7">
						<h4>Onboarding Documents</h4>
						<p>
							View and share your organization's ongoing and completed projects
						</p>
					</div>
				</div>
				{/* <div className="row mt-5">
					<div className="col-md-3 mb-3">
						<p>Organization’s CAC Certificate</p>
						<img src={cac} alt="cac" className="img-fluid" />
					</div>
					<div className="col-md-3">
						<p>Organization’s Article of Association</p>
						<img src={cac} alt="cac" className="img-fluid" />
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-3 mb-3">
						<p>Organization’s Proof of Address</p>
						<img src={cac} alt="cac" className="img-fluid" />
					</div>
					<div className="col-md-3">
						<p>Organization’s Logo Mark</p>
						<img src={cac} alt="cac" className="img-fluid" />
					</div>
				</div> */}
				<div className="p-4 bg-white group-cards mt-4">
					<div className="row listings-tog">
						<div className="col">
							<ul className="nav nav-tabs tog" id="myTab" role="tablist">
								<li className="nav-item" role="presentation">
									<button
										className="nav-link active tog-btn"
										id="home-tab"
										data-bs-toggle="tab"
										data-bs-target="#home-tab-pane"
										type="button"
										role="tab"
										aria-controls="home-tab-pane"
										aria-selected="true"
									>
										Listing Information
									</button>
								</li>
								<li className="nav-item" role="presentation">
									<button
										className="nav-link"
										id="profile-tab"
										data-bs-toggle="tab"
										data-bs-target="#profile-tab-pane"
										type="button"
										role="tab"
										aria-controls="profile-tab-pane"
										aria-selected="false"
									>
										Business Documents
									</button>
								</li>
								<li className="nav-item" role="presentation">
									<button
										className="nav-link"
										id="contact-tab"
										data-bs-toggle="tab"
										data-bs-target="#contact-tab-pane"
										type="button"
										role="tab"
										aria-controls="contact-tab-pane"
										aria-selected="false"
									>
										Bank Details
									</button>
								</li>
								<li className="nav-item" role="presentation">
									<button
										className="nav-link"
										id="comp-tab"
										data-bs-toggle="tab"
										data-bs-target="#comp-tab-pane"
										type="button"
										role="tab"
										aria-controls="comp-tab-pane"
										aria-selected="false"
									>
										Compliance Requirements
									</button>
								</li>
							</ul>
							<div className="tab-content" id="myTabContent">
								<div
									className="tab-pane fade show active"
									id="home-tab-pane"
									role="tabpanel"
									aria-labelledby="home-tab"
									tabIndex="0"
								>
									<div className="row my-5 statuses">
										<div className="col-md-6 border-end">
											<p>
												<i className="bi bi-house me-2"></i> Organization's Name
											</p>
											<h6>{data?.organization?.organization}</h6>
										</div>
										<div className="col-md-6">
											<p>
												<i className="bi bi-calendar me-2"></i> Year Founded
											</p>
											<h6>{data?.organization?.operation_years}</h6>
										</div>
									</div>
									<hr />
									<div className="row my-5 statuses">
										<div className="col-md-6 border-end">
											<p>
												<i className="bi bi-envelope me-2"></i> Organization's
												Mail
											</p>
											<h6>{data?.organization?.organization_email}</h6>
										</div>
										<div className="col-md-3 border-end">
											<p>
												<i className="bi bi-telephone me-2"></i> Organization’s
												Phone
											</p>
											<h6>{data?.organization?.organization_phone}</h6>
										</div>
										<div className="col-md-3">
											<p>
												<i className="bi bi-telephone me-2"></i> Geopolitical
												Zone Phone
											</p>
											<h6>{data?.organization?.geopolitical_zone || '-'}</h6>
										</div>
									</div>
									<hr />
									<div className="row my-5 statuses">
										<div className="col-md-6 border-end">
											<p>
												<i className="bi bi-geo-alt me-2"></i> Organization's
												Address
											</p>
											<h6>
												<h6>{data?.organization?.address}</h6>
											</h6>
										</div>
										<div className="col-md-6">
											<div className="row">
												<div className="col">
													<p>City</p>
													<h6>
														<h6>{data?.organization?.city}</h6>
													</h6>
												</div>
												<div className="col">
													<p>State</p>
													<h6>
														<h6>{data?.organization?.state}</h6>
													</h6>
												</div>
											</div>
										</div>
									</div>
									<hr />
									<div className="row my-5 statuses">
										<div className="col-md-6 border-end">
											<p>
												<i className="bi bi-gear me-2"></i> Services Rendered
											</p>
											<h6>
												<h6>{data?.organization?.services?.join(', ')}</h6>
											</h6>
										</div>
										<div className="col-md-6">
											<p>
												<i className="bi bi-briefcase me-2"></i> Thematic Area
											</p>
											<h6>
												<h6>{data?.organization?.thematic_area?.join(', ')}</h6>
											</h6>
										</div>
									</div>
									<hr />
									<div className="row my-5 statuses">
										<div className="col-md-6 border-end">
											<div className="row">
												<div className="col">
													<p>
														<i className="bi bi-calendar-event me-2"></i>{' '}
														Registration Date
													</p>
													<h6>
														{dayjs(data?.organization?.created_at).format(
															'DD/MM/YYYY'
														)}
													</h6>
												</div>
												<div className="col">
													<p>
														<i className="bi bi-calendar-event me-2"></i>{' '}
														Listing Date
													</p>
													<h6>
														{dayjs(data?.organization?.created_at).format(
															'DD/MM/YYYY'
														)}
													</h6>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="row">
												<div className="col">
													<p>
														<i className="bi bi-layers me-2"></i> No of Projects
													</p>
													<h6>{data?.organization?.projects} Projects</h6>
												</div>
												<div className="col">
													<p>
														<i className="bi bi-cash me-2"></i> Received
														Donations
													</p>
													<h6>-</h6>
												</div>
											</div>
										</div>
									</div>
									<hr />
									<div className="my-5 statuses">
										<div className="row">
											<div className="col">
												<p>
													<i className="bi bi-phone me-2"></i> Social Media
													Links
												</p>
											</div>
										</div>
										<div className="row">
											<div className="col mb-2">
												<h6>
													{data?.organization?.socialmedia?.links && data?.organization?.socialmedia?.links[0]
														.Instagram || '-'}
												</h6>
											</div>
											<div className="col mb-2">
												<h6>
													{ data?.organization?.socialmedia?.links &&  data?.organization?.socialmedia?.links[0].Twitter ||
														'-'}
												</h6>
											</div>
											<div className="col mb-2">
												<h6>
													{ data?.organization?.socialmedia?.links && data?.organization?.socialmedia?.links[0].Facebook ||
														'-'}
												</h6>
											</div>
											<div className="col mb-2">
												<h6>
													{data?.organization?.socialmedia?.links &&  data?.organization?.socialmedia?.links[0].LinkedIn ||
														'-'}
												</h6>
											</div>
										</div>
									</div>
									<hr />
									<div className="row mt-5 statuses">
										<div className="col-md-3 mb-2">
											<h6 className="mb-2">Organization’s Proof of Address</h6>
											<div>
												<div className="bg-download text-center">
													<img
														src={
															data?.organization?.orgfile?.address_proof ||
															placeholderImg
														}
														alt=""
														className="img-fluid"
													/>
												</div>
												<div className="bg-white p-4 bg-dwd">
													<h4 style={{ wordBreak: 'break-word' }}>
														{
															data?.organization?.orgfile?.address_proof?.split(
																'/'
															)[4]
														}
													</h4>
													<p>
														Uploaded{' '}
														{dayjs(data?.created_at).format('DD/MM/YYYY')}
													</p>
													<span
														className="float-end dload"
														style={{ cursor: 'pointer' }}
														onClick={() =>
															downloadImage(
																data?.organization?.orgfile?.address_proof
															)
														}
													>
														<i className="bi bi-download"></i>
													</span>
												</div>
											</div>
										</div>
										<div className="col-md-3">
											<h6 className="mb-2">Organization’s Logo Mark</h6>
											<div>
												<div className="bg-download text-center">
													<img
														src={
															data?.organization?.orgfile?.org_logo ||
															placeholderImg
														}
														alt=""
														className="img-fluid"
													/>
												</div>
												<div className="bg-white p-4 bg-dwd">
													<h4 style={{ wordBreak: 'break-word' }}>
														{
															data?.organization?.orgfile?.org_logo?.split(
																'/'
															)[4]
														}
													</h4>
													<p>
														Uploaded{' '}
														{dayjs(data?.created_at).format('DD/MM/YYYY')}
													</p>
													<span
														className="float-end dload"
														style={{ cursor: 'pointer' }}
														onClick={() =>
															downloadImage(
																data?.organization?.orgfile?.org_logo
															)
														}
													>
														<i className="bi bi-download"></i>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									className="tab-pane fade"
									id="profile-tab-pane"
									role="tabpanel"
									aria-labelledby="profile-tab"
									tabIndex="0"
								>
									<div className="row my-5 statuses">
										<div className="col-md-4 border-end">
											<p>CAC Registered</p>
											<h6 style={{ textTransform: 'capitalize' }}>
												{data?.organization?.businessdoc?.reg_cac}
											</h6>
										</div>
										<div className="col-md-4 border-end">
											<p>Organization’s RC Number</p>
											<h6>
												{data?.organization?.businessdoc?.rc_number || '-'}
											</h6>
										</div>
										<div className="col-md-4">
											<p>Organization’s Tax Identification Number</p>
											<h6>
												{data?.organization?.businessdoc?.tax_id_no || '-'}
											</h6>
										</div>
									</div>
									<hr />
									<div className="row mt-5 statuses">
										{data?.organization?.orgfile?.registeration_certificate && (
											<div className="col-md-3 mb-2">
												<h6 className="mb-2">Organization’s CAC Certificate</h6>
												<div>
													<div className="bg-download text-center">
														<img
															src={
																data?.organization?.orgfile
																	?.registeration_certificate || placeholderImg
															}
															alt=""
															className="img-fluid"
														/>
													</div>
													<div className="bg-white p-4 bg-dwd">
														<h4 style={{ wordBreak: 'break-word' }}>
															{
																data?.organization?.orgfile?.registeration_certificate?.split(
																	'/'
																)[4]
															}
														</h4>
														<p>
															Uploaded{' '}
															{dayjs(data?.created_at).format('DD/MM/YYYY')}
														</p>
														<span
															className="float-end dload"
															style={{ cursor: 'pointer' }}
															onClick={() =>
																downloadImage(
																	data?.organization?.orgfile
																		?.registeration_certificate
																)
															}
														>
															<i className="bi bi-download"></i>
														</span>
													</div>
												</div>
											</div>
										)}
										{data?.organization?.orgfile?.article_of_asso && (
											<div className="col-md-3">
												<h6 className="mb-2">
													Organization’s Article of Association
												</h6>
												<div>
													<div className="bg-download text-center">
														<img
															src={
																data?.organization?.orgfile?.article_of_asso ||
																placeholderImg
															}
															alt=""
															className="img-fluid"
														/>
													</div>
													<div className="bg-white p-4 bg-dwd">
														<h4 style={{ wordBreak: 'break-word' }}>
															{
																data?.organization?.orgfile?.article_of_asso?.split(
																	'/'
																)[4]
															}
														</h4>
														<p>
															Uploaded{' '}
															{dayjs(data?.created_at).format('DD/MM/YYYY')}
														</p>
														<span
															className="float-end dload"
															style={{ cursor: 'pointer' }}
															onClick={() =>
																downloadImage(
																	data?.organization?.orgfile?.article_of_asso
																)
															}
														>
															<i className="bi bi-download"></i>
														</span>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
								<div
									className="tab-pane fade"
									id="contact-tab-pane"
									role="tabpanel"
									aria-labelledby="contact-tab"
									tabIndex="0"
								>
									<div className="my-5 statuses">
										<div className="row">
											<div className="col">
												<h6>
													<i className="bi bi-bank me-2"></i> Organisation’s
													Bank Account Details
												</h6>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-4 border-end mb-2">
												<p>Bank Name</p>
												<h6>{data?.organization?.bankdetail?.bank_name}</h6>
											</div>
											<div className="col-md-4 border-end mb-2">
												<p>Beneficiary Name</p>
												<h6>{data?.organization?.bankdetail?.beneficiary}</h6>
											</div>
											<div className="col-md-4 mb-2">
												<p>Account Number</p>
												<h6>{data?.organization?.bankdetail?.acct_no}</h6>
											</div>
										</div>
									</div>
								</div>
								<div
									className="tab-pane fade"
									id="comp-tab-pane"
									role="tabpanel"
									aria-labelledby="contact-tab"
									tabIndex="0"
								>
									<Compliance />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Doc
