import axios from 'axios'
import { getLocalStorageItem, decryptData } from '../utlis/helper'

const axiosClient = axios.create({
	baseURL: process.env.REACT_APP_API_URI,
	headers: {
		'Content-Type': 'application/json',
	},
})

axiosClient.interceptors.request.use(async (config) => {
	const encryptedUserInfo = getLocalStorageItem('userInfo')
	let userInfo
	if (encryptedUserInfo) {
		userInfo = decryptData(encryptedUserInfo)
	}
	config.headers.Authorization = userInfo?.userToken
		? `Bearer ${userInfo?.userToken}`
		: ''
	return config
})

export default axiosClient
