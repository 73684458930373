import AdminCreateLink from './dashboardParts/adminCreateLink';
import Adminsidebar from './dashboardParts/adminsidebar';

function AdminAddLink() {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Adminsidebar/>
      <AdminCreateLink/>
    </div>
  )
}

export default AdminAddLink;
