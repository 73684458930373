import React from "react";
import MessagesB from "./dashboardParts/messagesB";
import Sidebar from "./dashboardParts/sideBar";
import { getMessages } from "../handlers/messagesHandler";
import { useMutation, useQuery, useQueryClient } from "react-query";

function Messages() {
  const [currPage, setCurrPage] = React.useState(1);
  const { data: data, isLoading: isDataLoading } = useQuery(
    [`messages`, currPage],
    () => getMessages({ page: currPage })
  );

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Sidebar />
      <MessagesB data={data} />
    </div>
  );
}

export default Messages;
