import Adminsidebar from "./dashboardParts/adminsidebar";
import SendBulk from "./dashboardParts/sendBulk";

function AdminSendBulk() {
  return (
    <div style={{backgroundColor: "#fff"}}>
      <Adminsidebar/>
      <SendBulk/>
    </div>
  );
}

export default AdminSendBulk;
