import React from 'react'
import Adminsidebar from './dashboardParts/adminsidebar'
import ProjectsAdmin from './dashboardParts/projectsAdmin'
import { getAllProjects } from '../handlers/adminHandler'
import { useMutation, useQuery, useQueryClient } from 'react-query'

function AdminProjects() {
	const [currPage, setCurrPage] = React.useState(1)
	const [limit] = React.useState(9)
	const [searchName, setSearchName] = React.useState('')
	const timeout = React.useRef()
	const { data: projectData, isLoading } = useQuery(
		['admin-projects', currPage, searchName],
		() => getAllProjects({ page: currPage, page_size: limit, name: searchName })
	)
	const handlePaginateChange = async (page) => {
		setCurrPage(page.selected + 1)
	}
	const handleSearch = (e) => {
		const value = e.target.value
		clearTimeout(timeout.current)

		timeout.current = setTimeout(() => {
			setSearchName(value)
		}, 600)
	}

	return (
		<div style={{ backgroundColor: '#fff' }}>
			<Adminsidebar />
			<ProjectsAdmin
				projectData={projectData}
				isLoading={isLoading}
				handlePaginateChange={handlePaginateChange}
				currPage={currPage}
				handleSearch={handleSearch}
				limit={limit}
			/>
		</div>
	)
}

export default AdminProjects
