import React from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { Oval } from "react-loader-spinner";

function ViewFeeds({ data, id, isLoading }) {
  const { userInfo } = React.useContext(AuthContext);
  return (
    <section>
      <div className="content overview">
        <div className="row justify-content-between">
          <div className="col-md-6 mt-3 back">
            <Link to="/mis/feeds">
              <i className="bi bi-arrow-left me-2"></i> Back to News Feeds
            </Link>
          </div>
          <div className="col-md-4">
            {userInfo?.organization?.id == data?.organization_id && (
              <div className="d-flex flex-row">
                <Link to="" className="btn btn-success w-100 sbm-btn mb-3 mx-3">
                  Delete
                </Link>
                <Link
                  to={`/mis/feeds/edit/${id}`}
                  className="btn btn-success w-100 login-btn mb-3"
                >
                  Edit News Item
                </Link>
              </div>
            )}
          </div>
        </div>
        <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
          <hr />
        </div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
            }}
          >
            <Oval wrapperClass="ml-2" height={40} width={40} />
          </div>
        ) : (
          <div className="row mt-5 justify-content-center">
            <div className="col-md-8 mb-3">
              <div className="n-feeds">
                {data?.image && (
                  <img
                    src={data?.image}
                    alt="news"
                    className="img-fluid w-100"
                  />
                )}
                <div className="p-4">
                  <h5>{data?.title}</h5>
                  <h6 className="my-3">By {data?.author}</h6>
                  <p>{dayjs(data?.created_at).format("MMMM DD, YYYY")}22</p>
                  <h4>{data?.content}</h4>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default ViewFeeds;
