import MessagesE from "./dashboardParts/messagesE";
import Sidebar from "./dashboardParts/sideBar";

function MessagesEmpty() {
  return (
    <div style={{backgroundColor: "#fff"}}>
      <Sidebar/>
      <MessagesE/>
    </div>
  );
}

export default MessagesEmpty;
