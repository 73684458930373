import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Link } from 'react-router-dom';

function MISLogin() {
  return (
    <section className="register-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5">
            <h2 className='text-center'>Super Admin Login</h2>
            <h6 className="mt-3 text-center">Welcome back. Please enter your details</h6>
            <Form className='mt-5'>
              <Form.Label>Email</Form.Label>
              <InputGroup className='mb-3'>
                <InputGroup.Text><i className="bi bi-envelope"></i></InputGroup.Text>
                <Form.Control
                  id="inlineFormInputGroupUsername"
                  type='email'
                />
              </InputGroup>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control type='password' />
                <Form.Text className="text-muted">
                  Must contain 8+ characters with at least 1 number
                </Form.Text>
              </Form.Group>
              <div>
                <div className="form-check mt-3">
                  <input className="form-check-input" type="checkbox" />
                  <label className="form-check-label agree" >
                    Remember information
                  </label>
                </div>
                <Link to="/forgot-password" className='float-end fp' style={{marginTop: "-24px"}}>Forgot Password</Link>
              </div>
              <Link to="/mis/overview" className="btn btn-success w-100 login-btn mt-5">Sign in</Link>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MISLogin;
