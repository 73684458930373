import React from "react";
import { Link } from "react-router-dom";
import adduser from "../../img/adduser.svg";
import add from "../../img/uas.svg";
import { Form as FormikForm, Formik } from "formik";
import { toast } from "../../component/toast";
import { useMutation, useQueryClient } from "react-query";
import Button from "../../component/Button";
import { createAdmin, updateAdmin } from "../../handlers/adminHandler";
import ReactPaginate from "react-paginate";
import dayjs from "dayjs";
import PasswordManagement from "./PasswordManagement";

function SettingsAdmin({ data, page, handlePaginateChange }) {
  const closeCreateModalRef = React.useRef();
  const closeUpdateModalRef = React.useRef();
  const queryClient = useQueryClient();
  const [selectedAdmin, setSelectedAdmin] = React.useState("");

  const { mutate: createAdminMutation, isLoading: isLoading } = useMutation(
    createAdmin,
    {
      onSuccess: async (res) => {
        toast.success("Admin created successfully");
        await queryClient.invalidateQueries(["all-admins"]);
        closeCreateModalRef.current.click();
      },
      onError: (e) => {
        toast.error(e);
      },
    }
  );

  const { mutate: updateAdminMutation, isLoading: isUpdateLoading } =
    useMutation(updateAdmin, {
      onSuccess: async (res) => {
        toast.success("Admin updated successfully");
        await queryClient.invalidateQueries(["all-admins"]);
        closeUpdateModalRef.current.click();
        setSelectedAdmin("");
      },
      onError: (e) => {
        toast.error(e);
      },
    });

  const handleCreateAdmin = async (values) => {
    createAdminMutation(values);
  };

  const handleUpdateAdmin = async (values) => {
    updateAdminMutation({ id: selectedAdmin.id, data: values });
  };

  return (
    <section>
      <div className="content overview">
        <div className="row">
          <div className="col-md-9 mb-2">
            <h4>Settings</h4>
            <p>Manage Your Password</p>
          </div>
        </div>
        <div className="row listings-tog mt-3">
          <div className="col-md-8">
            <ul className="nav nav-tabs tog" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active tog-btn"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected="true"
                >
                  Administrators
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="contact-tab-pane"
                  aria-selected="true"
                >
                  Password Management
                </button>
              </li>
            </ul>
          </div>
          <div className="col-md-4 text-end ">
            <Link
              to=""
              className="btn btn-success w-50 login-btn py-2"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#add-user"
            >
              <i className="bi bi-plus me-3 fs-5"></i> Add New User
            </Link>
          </div>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home-tab-pane"
              role="tabpanel"
              aria-labelledby="home-tab"
              tabIndex="0"
            >
              <div className="row mt-5 mb-4">
                <div className="col">
                  <h6 className="fw-bold">Account Admin Users</h6>
                  <p>
                    Manage your existing users and change roles/permissions{" "}
                  </p>
                </div>
              </div>
              <div className="bg-white group-cards no-head">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email Address</th>
                        <th scope="col">Designation</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date added</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.map((item, idx) => (
                        <tr key={item.id}>
                          <td className="w-25">{item?.name}</td>
                          <td className="w-25">{item?.email}</td>
                          <td>Admin </td>
                          <td>
                            <span className="badgers">
                              <i className="bi bi-circle-fill me-2"></i>Active
                            </span>{" "}
                          </td>
                          <td>
                            {" "}
                            {dayjs(item.created_at).format("MMMM DD, YYYY")}
                          </td>
                          <td>
                            <div className="dropdown">
                              <i
                                className="bi bi-three-dots-vertical actions"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              ></i>
                              <ul className="dropdown-menu listing-drop">
                                <li>
                                  <Link
                                    className="dropdown-item listing-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit-user"
                                    onClick={() => setSelectedAdmin(item)}
                                  >
                                    <i className="bi bi-pencil me-2"></i>Edit
                                    Details
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item listing-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#update-status"
                                  >
                                    <i className="bi bi-arrow-repeat me-2"></i>Update
                                    Status
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item listing-item text-danger">
                                    <i className="bi bi-archive me-2"></i>{" "}
                                    Delete User
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {data?.data?.length > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={data?.total / 10 || 1}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={3}
                  onPageChange={handlePaginateChange}
                  containerClassName={"paginate"}
                  activeClassName={"active"}
                  forcePage={page - 1}
                />
              )}
            </div>

            <div
              className="tab-pane fade"
              id="contact-tab-pane"
              role="tabpanel"
              aria-labelledby="contact-tab"
              tabIndex="0"
            >
              <PasswordManagement />
            </div>
          </div>
        </div>
      </div>
      {/* Modal for Add User */}
      <div
        className="modal fade"
        id="add-user"
        tabIndex="-1"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                  }}
                  onSubmit={(values) => {
                    handleCreateAdmin(values);
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                    errors,
                    handleReset,
                  }) => (
                    <FormikForm onSubmit={handleSubmit}>
                      <div className="col" style={{ marginTop: "-30px" }}>
                        <img
                          src={adduser}
                          alt="update"
                          className="img-fluid mb-3"
                        />
                        <h4>Add New Admin User</h4>
                        <p>
                          Add and invite new team members to collaborate with
                          you
                        </p>
                        <div className="add-user">
                          <div className="mb-3">
                            <label className="form-label">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required={true}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Email Address</label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required={true}
                            />
                          </div>

                          <div className="d-flex flex-row">
                            <button
                              type="button"
                              className="btn btn-success bg-white w-100 cancel-btn mt-3 mb-3 me-3"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={handleReset}
                              ref={closeCreateModalRef}
                            >
                              Cancel
                            </button>
                            <Button
                              type="submit"
                              className="btn btn-success w-100 login-btn mt-3"
                              loading={isLoading}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      </div>
                    </FormikForm>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal for Update User */}
      <div
        className="modal fade"
        id="edit-user"
        tabIndex="-1"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <Formik
                  initialValues={{
                    name: selectedAdmin?.name,
                    email: selectedAdmin?.email,
                  }}
                  onSubmit={(values) => {
                    handleUpdateAdmin(values);
                  }}
                  enableReinitialize={true}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                    errors,
                    handleReset,
                  }) => (
                    <FormikForm onSubmit={handleSubmit}>
                      <div className="col" style={{ marginTop: "-30px" }}>
                        <img
                          src={adduser}
                          alt="update"
                          className="img-fluid mb-3"
                        />
                        <h4>Edit Admin User</h4>
                        <p>Edit and update team members information</p>
                        <div className="add-user">
                          <div className="mb-3">
                            <label className="form-label">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required={true}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Email Address</label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required={true}
                            />
                          </div>

                          <div className="d-flex flex-row">
                            <button
                              type="button"
                              className="btn btn-success bg-white w-100 cancel-btn mt-3 mb-3 me-3"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={handleReset}
                              ref={closeUpdateModalRef}
                            >
                              Cancel
                            </button>
                            <Button
                              type="submit"
                              className="btn btn-success w-100 login-btn mt-3"
                              loading={isUpdateLoading}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      </div>
                    </FormikForm>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal for Update Status */}
      <div
        className="modal fade"
        id="update-status"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <div className="col">
                  <img
                    src={add}
                    alt="update"
                    className="img-fluid mb-3"
                    style={{ marginTop: "-30px" }}
                  />
                  <h4>Update Admin Status </h4>
                  <div className="update-card mt-3">
                    <h4>Olaoluwa Samuel</h4>
                    <p>abioyesamuel@arcasolutions.com</p>
                  </div>
                  <div className="form-check my-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      for="flexRadioDefault1"
                    >
                      Active
                    </label>
                  </div>
                  <div className="form-check mb-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                    />
                    <label
                      className="form-check-label"
                      for="flexRadioDefault2"
                    >
                      Inactive
                    </label>
                  </div>
                  <div className="d-flex flex-row">
                    <Button
                      type={"button"}
                      className="btn btn-success border-g bg-white w-100 cancel-btn mt-3 me-3"
                    >
                      Cancel
                    </Button>
                    <Button
                      type={"button"}
                      className="btn btn-success w-100 login-btn mt-3"
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SettingsAdmin;
