import AdminSingleMessage from "./dashboardParts/adminSingleMessage";
import Adminsidebar from "./dashboardParts/adminsidebar";
import { getSingleDirectory, getSingleAdmin } from "../handlers/publicHandler";
import { getMessagesToUser } from "../handlers/messagesHandler";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

function MessageSingle() {
  const { id } = useParams();
  const { data: data, isLoading: isDataLoading } = useQuery(
    `directory-${id}`,
    () => getSingleDirectory(id)
  );

  const { data: adminData } = useQuery(`admin-${id}`, () => getSingleAdmin(id));

  const { data: userMessage, isLoading } = useQuery(`message-${id}`, () =>
    getMessagesToUser(id)
  );

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Adminsidebar />
      <AdminSingleMessage
        data={data}
        adminData={adminData}
        userId={id}
        userMessage={userMessage}
      />
    </div>
  );
}

export default MessageSingle;
