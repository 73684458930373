
function PrivacyContent() {
  return (
    <section className="privacy-section">
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <h2>DATA PROTECTION POLICY</h2>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-10">
            <h4 className="mb-3">Policy brief & purpose</h4>
            <p>This Data Protection Policy refers to our commitment to treat information of from subscribers to this site, stakeholders and other interested parties with the utmost care and confidentiality. With this policy, we ensure that we gather, store and handle data fairly, transparently and with respect towards individual rights.	</p>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-10">
            <h4 className="mb-3">Scope</h4>
            <p>This policy refers to all parties and entities who provide any amount of information to us.</p>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-10">
            <h4 className="mb-3">Who is covered under the Data Protection Policy?</h4>
            <p>Subscribers who comprise civil society organisations, donors, contractors, consultants, partners, and any other external entity are also covered. Generally, our policy refers to anyone we collaborate with or acts on our behalf and may need occasional access to data.</p>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-10">
            <h4 className="mb-3">Policy elements</h4>
            <p>As part of our operations, we need to obtain and process information. This information includes any offline or online data that makes a person identifiable such as names, addresses, usernames and passwords, location, addresses, digital footprints, photographs, social security numbers, financial data etc. Our site collects this information in a transparent way and only with the full cooperation and knowledge of interested parties. Once this information is available to us, the following rules apply.	</p>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-10">
            <h4 className="mb-3">Data will be:</h4>
            <div className="list-privacy">
              <ul>
                <li>Accurate and kept up to date.</li>
                <li>Collected fairly and for lawful purposes only.</li>
                <li>Processed within legal and moral boundaries.</li>
                <li>Protected against any unauthorized or illegal access by internal or external parties.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-10">
            <h4 className="mb-3">Data will not be:</h4>
            <div className="list-privacy mb-5">
              <ul>
                <li>Communicated informally.</li>
                <li>Stored for more than a specified amount of time.</li>
                <li>Transferred to organizations, states or countries that do not have adequate data protection policies. </li>
                <li>Distributed to any party other than the ones agreed upon by the data’s owner (exempting legitimate requests from law enforcement authorities).</li>
              </ul>
            </div>
            <p>In addition to ways of handling the data, we have direct obligations towards people to whom the data belongs. Specifically, we must:	</p>
            <div className="list-privacy mb-5">
              <ul>
                <li>Let people know which of their data is collected. </li>
                <li>Inform people about how we’ll process their data.</li>
                <li>Inform people about who has access to their information.</li>
                <li>Have provisions in cases of lost, corrupted, or compromised data.</li>
                <li>Allow people to request that we modify, erase, reduce or correct data contained in our databases. </li>
              </ul>
            </div>
            <hr />
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-10">
            <h4 className="mb-3">Actions</h4>
            <p>To exercise data protection, we’re committed to:</p>
            <div className="list-privacy">
              <ul>
                <li>Restricting and monitoring access to sensitive data.</li>
                <li>Developing transparent data collection procedures.	</li>
                <li>Training the team directly working with data at the back end, in online privacy and security measures.</li>
                <li>Building secure networks to protect online data from cyberattacks.</li>
                <li>Establishing clear procedures for reporting privacy breaches or data misuse.</li>
                <li>Including contract clauses or communicate statements on how we handle data.</li>
                <li>Establishing data protection practices (document shredding, secure locks, data encryption, frequent backups, access authorization etc.). </li>
                <li>Ensuring that data protection provisions will appear on our website.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-10">
            <h4 className="mb-3">Disciplinary Consequences</h4>
            <p>All principles described in this policy must be strictly followed. A breach of data protection guideline will invoke disciplinary and possibly legal action.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PrivacyContent;