import CreateNews from './dashboardParts/createNews';
import Sidebar from './dashboardParts/sideBar'

function AddNews() {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Sidebar/>
      <CreateNews/>
    </div>
  )
}

export default AddNews;
