import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPEG", "PNG", "JPG"];

function CreateCampaign() {
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };
  return (
    <section className="">
    <div className="content overview">
      <div className="row">
        <div className="col-md-8 mb-2 back">
          <Link to="/cso/campaign"><i className="bi bi-arrow-left me-2"></i> Back</Link>
        </div>
        <div className="col-md-4 text-end">
          <div className="d-flex flex-row">
            <Link to="" className="btn btn-success w-100 login-btn me-3">Cancel</Link>
            <Link to="" data-bs-toggle="modal" data-bs-target="#approve" className="btn btn-success bg-white w-100 login-btn lin">Publish Campaign</Link>
          </div>
        </div>
      </div>
      <div style={{marginLeft: "-50px", marginRight:"-58px"}}><hr/></div>
      <div className="row">
        <div className="col">
          <h4>Start a New Donation Campaign</h4>
        </div>
      </div>
      <form className="row mt-5">
        <div className="col-md-8 mb-3">
          <>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name of Campaign</Form.Label>
              <Form.Control type="text" placeholder="What is the title of your Donation Campaign?" />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Upload Featured Image</Form.Label>
              <FileUploader
                multiple={true}
                handleChange={handleChange}
                name="file"
                types={fileTypes}
                label="Drag and drop document here or Browse"
              />
              <p>{file ? `File name: ${file[0].name}` : "No file(s) uploaded yet"}</p>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Upload Additional Images</Form.Label>
              <FileUploader
                multiple={true}
                handleChange={handleChange}
                name="file"
                types={fileTypes}
                label="Drag and drop document here or Browse"
              />
              <p>{file ? `File name: ${file[0].name}` : "No file(s) uploaded yet"}</p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Short Description of your Campaign</Form.Label>
              <Form.Control as="textarea" rows={10} placeholder="Enter a description...."/>
              <Form.Text id="passwordHelpBlock" muted className="float-end">
                0 / 500 Words
              </Form.Text>
            </Form.Group>
          </>
        </div>
        <div className="col-md-4">
          <Form.Label>Campaign Goal</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">NGN</InputGroup.Text>
            <Form.Control
              placeholder="Location of project"
              aria-label="Location"
              aria-describedby="basic"
              type="number"
            />
          </InputGroup>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Campaign Start Date</Form.Label>
            <Form.Control type="date" />
          </Form.Group>
          <Form.Group className="mb-5" controlId="formBasicEmail">
            <Form.Label>Campaign Completion Date</Form.Label>
            <Form.Control type="date" />
          </Form.Group>
          <div className="row mb-3">
            <div className="col">
             <div className="separator">
              <div className="lines"></div>
              <h1>Bank Account Details </h1>
              <div className="lines"></div>
             </div>
            </div>
          </div>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Bank Name</Form.Label>
            <Form.Control type="text" placeholder="Enter Bank Name" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Beneficiary Name</Form.Label>
            <Form.Control type="text" placeholder="Enter Beneficiary Name on Account" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Enter Account Number</Form.Label>
            <Form.Control type="text" placeholder="Enter Account Number" />
          </Form.Group>
        </div>
        <div className="row justify-content-end mt-5 ">
          <div className="col-md-4">
            <div className="d-flex flex-row">
              <Link to="" className="btn btn-success login-btn me-3 w-100">Cancel</Link>
              <Link to="" data-bs-toggle="modal" data-bs-target="#approve" className="btn btn-success bg-white login-btn lin w-100">Publish Campaign</Link>
            </div>
          </div>
        </div>
        
      </form>
    </div>
  </section>
  )
}

export default CreateCampaign;