// import avi from "../img/mg.svg";
import carousel from '../img/carousel.svg'

function ProjectDesc({ data }) {
	return (
		<section className="project-desc">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-8">
						<hr />
						<h2>Description</h2>
						<p>{data?.project?.description}</p>
						{/* <img
							src={carousel}
							alt="post img"
							className="img-fluid my-2 w-100"
						/> */}
					</div>
				</div>
			</div>
		</section>
	)
}

export default ProjectDesc
