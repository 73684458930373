import React from "react";
import { Link, useParams } from "react-router-dom";
import add from "../../img/added.svg";
import upd from "../../img/uas.svg";
import { Oval } from "react-loader-spinner";
import dayjs from "dayjs";
import downloadIcon from "../../img/download-icon.svg";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { approveComplianceAnswer } from "../../handlers/complianceHandlers";
import { toast } from "../../component/toast";
import { Form, Formik } from "formik";
import Button from "../../component/Button";

function ViewSubmission({ data, isLoading }) {
  const { id } = useParams();
  const closeUpdateRef = React.useRef();
  const queryClient = useQueryClient();
  const [submission, setSubmission] = React.useState(null);
  const { mutate: appproveComplianceMutation, isLoading: isUpdateLoading } =
    useMutation(approveComplianceAnswer, {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries([`admin-compliance-${id}`]);
        closeUpdateRef.current.click();
        setSubmission(null);
        toast.success("Updated successfully");
      },
      onError: (e) => {
        toast.error(e);
      },
    });

  const handleUpdate = (values) => {
    appproveComplianceMutation({
      id: submission.id,
      data: values,
    });
  };

  return (
    <section>
      <div className="content overview">
        <div className="row justify-content-between">
          <div className="col-md-8 mt-3 mb-3 sbm">
            <Link to="/admin/compliance">
              {" "}
              <i className="bi bi-arrow-left me-2"></i>Back
            </Link>
          </div>
          <div className="col-md-4 text-end">
            <button
              className="btn btn-success login-btn py-2 px-4 w-50"
              data-bs-toggle="modal"
              data-bs-target="#edit-comp"
            >
              {" "}
              Edit Question
            </button>
          </div>
        </div>

        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
            }}
          >
            <Oval wrapperClass="ml-2" height={40} width={40} />
          </div>
        ) : (
          <>
            <div className="mt-5">
              <div className="comp-card">
                <div className="row">
                  <div className="col-sm-8 v-c">
                    <Link to="/admin/view-question">
                      {data ? data[0]?.question : "-"}
                    </Link>
                    <div className="v-c">
                      <h6>
                        {data ? data[0]?.compliance_answers?.length : "-"}{" "}
                        Submissions
                      </h6>
                      <p>
                        {data
                          ? dayjs(data[0]?.created_at).format("MMMM DD, YYYY")
                          : "-"}
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-4 text-end">
                    <span className="tan">
                      <i className="bi bi-fonts me-2"></i>
                      {data ? data[0]?.question_type : "-"} Answer
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="bg-white group-cards no-head">
                <div className="row mt-4 mb-4 mx-4">
                  <div className="col-sm-12 ps-0">
                    <h3>Submissions</h3>
                  </div>
                </div>
                <hr />
                <div className="table-responsive">
                  <table className="table table-striped custom-class">
                    <thead>
                      <tr>
                        <th scope="col">Date Published</th>
                        <th scope="col">Organization Name</th>
                        <th scope="col">Submissions</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data[0]?.compliance_answers?.map((item, itx) => (
                          <tr key={itx}>
                            <td>
                              {dayjs(item?.created_at).format("YYYY/MM/DD")}
                            </td>
                            <td>{item?.organization?.organization}</td>
                            <td>
                              {data[0]?.question_type === "text" ? (
                                item?.answer
                              ) : (
                                <a
                                  style={{ color: "#039855" }}
                                  href={item?.answer}
                                  target="_blank"
                                  download
                                >
                                  Download Here
                                  <img
                                    src={downloadIcon}
                                    alt="answered"
                                    className="img-fluid"
                                    style={{ marginLeft: "5px" }}
                                    width={15}
                                  />
                                </a>
                              )}
                            </td>
                            <td>
                              {item?.status === "pending" ? (
                                <span className="pendg">
                                  <i className="bi bi-circle-fill me-2"></i>
                                  Pending
                                </span>
                              ) : item?.status === "approved" ? (
                                <span className="badgers">
                                  <i className="bi bi-check me-2"></i>Approved
                                </span>
                              ) : (
                                <span className="rejectedbg">
                                  <i className="bi bi-circle-fill me-2"></i>
                                  Rejected
                                </span>
                              )}
                            </td>
                            <td>
                              <div className="dropdown">
                                <i
                                  className="bi bi-three-dots-vertical actions"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                ></i>
                                <ul className="dropdown-menu listing-drop">
                                  <li>
                                    <a
                                      className="dropdown-item listing-item"
                                      data-bs-toggle="modal"
                                      data-bs-target="#update-comp"
                                      onClick={() => setSubmission(item)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i className="bi bi-arrow-repeat me-2"></i>
                                      Update Status
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Update Status */}

      <div
        className="modal fade"
        id="update-comp"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeUpdateRef}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <Formik
                  initialValues={{
                    status: submission?.status || "",
                  }}
                  enableReinitialize={true}
                  onSubmit={(values) => handleUpdate(values)}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                    errors,
                    handleReset,
                    resetForm,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="col">
                        <img
                          src={upd}
                          alt="update"
                          className="img-fluid mb-3"
                          style={{ marginTop: "-30px" }}
                        />
                        <h4>Update Answer Status</h4>
                        <div className="form-check my-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            value="pending"
                            id="flexRadioDefault1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autocomplete="off"
                            checked={values.status === "pending"}
                            // required={true}
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault1"
                          >
                            Pending
                          </label>
                        </div>
                        <div className="form-check mb-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            id="flexRadioDefault2"
                            value="rejected"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autocomplete="off"
                            checked={values.status === "rejected"}
                            // required={true}
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault2"
                          >
                            Rejected
                          </label>
                        </div>
                        <div className="form-check mb-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            id="flexRadioDefault3"
                            value="approved"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autocomplete="off"
                            checked={values.status === "approved"}
                            // required={true}
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault3"
                          >
                            Approved
                          </label>
                        </div>
                        <div className="d-flex flex-row">
                          <Button
                            type={"reset"}
                            className="btn btn-success border-g bg-white w-100 cancel-btn mt-3 me-3"
                            onClick={() => {
                              closeUpdateRef.current.click();
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="btn btn-success w-100 login-btn mt-3"
                            type="submit"
                            loading={isUpdateLoading}
                          >
                            Confirm
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Compliance Question  */}
      <div
        className="modal fade"
        id="edit-comp"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row listing-modal">
                <div className="col">
                  <img
                    src={add}
                    alt="update"
                    className="img-fluid mb-3"
                    style={{ marginTop: "-30px" }}
                  />
                  <h4>Edit Compliance Question</h4>
                  <p>
                    Edit a compliance question to be answered by organizations.
                  </p>
                  <div className="mb-3">
                    <label className="form-label">Question</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="What is the title of your Question?"
                    />
                  </div>
                  <div className="mb-3 creat-comp">
                    <label className="form-label">Question Type</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option value="1">Text Answer</option>
                      <option value="2">File Answer</option>
                    </select>
                  </div>
                  <div className="d-flex flex-row">
                    <Button
                      type={"button"}
                      className="btn btn-success border-g bg-white w-100 cancel-btn mt-3 me-3"
                    >
                      Cancel
                    </Button>
                    <Button
                      type={"button"}
                      className="btn btn-success w-100 login-btn mt-3"
                    >
                      Update Question
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ViewSubmission;
