import React from "react";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { sendBulkMessages } from "../../handlers/messagesHandler";
import { toast } from "../../component/toast";
import Button from "../../component/Button";
import { Form as FormikForm, Formik } from "formik";

function SendBulk() {
  const queryClient = useQueryClient();
  const [area, setArea] = React.useState("");
  const [message, setMessage] = React.useState("");
  const { mutate: sendBulkMessagesMutation, isLoading: isLoading } =
    useMutation(sendBulkMessages, {
      onSuccess: async (res) => {
        setMessage("");
        await queryClient.invalidateQueries([`messages`]);
        toast.success("Message sent successfully");
      },
      onError: (e) => {
        toast.error(e);
      },
    });

  const handleSubmit = (values) => {
    if (values.type === "area") {
      const data = {
        thematic_area: values.thematic_area,
        body: values.message,
      };
      sendBulkMessagesMutation(data);
    }
    if (values.type === "status") {
      const data = {
        status: values.status,
        body: values.message,
      };
      sendBulkMessagesMutation(data);
    }
  };

  return (
    <section>
      <Formik
        initialValues={{
          type: "area",
          thematic_area: "",
          status: "",
          message: "",
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          errors,
          handleReset,
        }) => (
          <FormikForm onSubmit={handleSubmit}>
            <div className="content overview">
              <div className="row">
                <div className="col-md-6 mt-4 sbm">
                  <Link to="/admin/messages">
                    <i className="bi bi-arrow-left"></i> Back
                  </Link>
                </div>
                <div className="col-md-6 text-end mt-3">
                  <div className="d-flex flex-row">
                    <Link
                      to="/admin/messages"
                      className="btn btn-success w-50 sbm-btn mb-3 mx-3"
                    >
                      Cancel
                    </Link>
                    <Button
                      to=""
                      className="btn btn-success w-50 login-btn mb-3"
                      type="submit"
                      loading={isLoading}
                      // onClick={handleSendMessage}
                    >
                      Send Bulk Message
                    </Button>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
                <hr />
              </div>
              <div className="row mt-5">
                <div className="col">
                  <h3>Send Bulk Message</h3>
                </div>
                <div className="sendbulk p-5">
                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      <div className="them">
                        <h3>Choose Criteria for Bulk Messaging</h3>
                        <div className="form-check form-check-inline mt-3 mb-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="type"
                            id="inlineRadio1"
                            value="area"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required={true}
                          />
                          <label
                            className="form-check-label"
                            for="inlineRadio1"
                          >
                            By Thematic Area
                          </label>
                        </div>
                        <div className="form-check form-check-inline ms-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="type"
                            id="inlineRadio2"
                            value="status"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required={true}
                          />
                          <label
                            className="form-check-label"
                            for="inlineRadio2"
                          >
                            By Organization Status
                          </label>
                        </div>
                        {values.type === "area" ? (
                          <div className="mb-3">
                            <label className="form-label">
                              Select Thematic Area to Message
                            </label>
                            <select
                              className="form-select"
                              name="thematic_area"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.thematic_area}
                              required={true}
                            >
                              <option value="">Select Area</option>
                              <option value="Education">Education</option>
                              <option value="Disability">Disability</option>
                              <option value="Poverty">Poverty</option>
                              <option value="Health">Health</option>
                              <option value="Economic/Community Development">
                                Economic/Community Development
                              </option>
                              <option value="Democracy and Good Governance">
                                Democracy and Good Governance
                              </option>
                              <option value="Orphanages and Shelters">
                                Orphanages and Shelters
                              </option>
                              <option value="Civil Rights and Social Action">
                                Civil Rights and Social Action
                              </option>
                              <option value="Peace and Conflict Resolution">
                                Peace and Conflict Resolution
                              </option>
                              <option value="Corporative and Microfinance">
                                Corporative and Microfinance
                              </option>
                              <option value="Corporative and Microfinance">
                                Corporative and Microfinance
                              </option>
                              <option value="Crime and Law Related">
                                Crime and Law Related
                              </option>
                              <option value="Youth and Youth Development">
                                Youth and Youth Development
                              </option>
                              <option value="Information Communication and Technology">
                                Information Communication and Technology
                              </option>
                              <option value="Trade">Trade</option>
                              <option value="Migration">Migration</option>
                              <option value="Gender and Social Inclusion">
                                Gender and Social Inclusion
                              </option>
                              <option value="Philanthropy">Philanthropy</option>
                              <option value="Research and Development">
                                Research and Development
                              </option>
                              <option value="Environment">Environment</option>
                              <option value="Water, Sanitation and Hygiene.">
                                Water, Sanitation and Hygiene.
                              </option>
                              <option value="Religion Related">
                                Religion Related
                              </option>
                            </select>
                          </div>
                        ) : (
                          <div className="mb-3">
                            <label className="form-label">
                              Select Organization Status to Message
                            </label>
                            <select
                              className="form-select"
                              name="status"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.status}
                              required={true}
                            >
                              <option value="">
                                Select Organization Status
                              </option>
                              <option value="rejected">Rejected</option>
                              <option value="approved">Approved</option>
                              <option value="awaiting">Awaiting</option>
                              <option value="pending">Pending</option>
                            </select>
                          </div>
                        )}

                        <div className="mb-3">
                          <label className="form-label">Message Content</label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="7"
                            placeholder="Enter message content...."
                            name="message"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.message}
                            required={true}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FormikForm>
        )}
      </Formik>
    </section>
  );
}

export default SendBulk;
