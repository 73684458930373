import ContactBody from '../component/contactBody'
import Footer from '../component/footer'
import NavB from '../component/header'



function Contact() {
  return (
    <div>
      <NavB />
      <ContactBody/>
      <Footer />
    </div>
  )
}

export default Contact
