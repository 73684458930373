import Adminsidebar from "./dashboardParts/adminsidebar";
import MessageAdmin from "./dashboardParts/messageAdmin";

function AdminMessageAdmin() {
  return (
    <div style={{backgroundColor: "#fff"}}>
      <Adminsidebar/>
      <MessageAdmin/>
    </div>
  );
}

export default AdminMessageAdmin;
