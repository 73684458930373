import axiosClient from '../utlis/axiosClient'

export const getAllFeed = async ({ page = 1, page_size = 20, name = '' }) => {
	try {
		const response = await axiosClient.get(
			`/v1/news?page=${page}&page_size=${page_size}&news_search=${name}`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		return response.data.data
	} catch (error) {
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const getUserFeed = async ({ page = 1, page_size = 20, name = '' }) => {
	try {
		const response = await axiosClient.get(
			`/v1/news/fetch-news-by-user?page=${page}&page_size=${page_size}&news_search=${name}`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		return response.data.data
	} catch (error) {
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const getSingleFeed = async (id) => {
	try {
		const response = await axiosClient.get(`/v1/news/${id}/fetch-news`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return response.data.data
	} catch (error) {
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const createFeed = async (data) => {
	try {
		const response = await axiosClient.post(`/v1/news`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return response.data.data
	} catch (error) {
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}

export const updateFeed = async ({ id, data }) => {
	try {
		const response = await axiosClient.post(`/v1/news/${id}`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return response.data.data
	} catch (error) {
		if (error?.response?.data?.message) {
			throw error?.response?.data?.message
		}
		throw 'An error occured. Please try again.'
	}
}
