import { Fragment, useContext } from 'react'
import medal from '../../img/medallion.svg'
import SiverMedal from '../../img/silver-medal.svg'
import BronzeMedal from '../../img/bronze-medal.svg'
import DiamondMedal from '../../img/diamond-medal.svg'
import review from '../../img/review.svg'
import list from '../../img/shared.svg'
import view from '../../img/item.svg'
import ngs from '../../img/ngs.svg'
import { Icon } from '@iconify/react'
import avatar from '../../img/user-img.svg'
import state from '../../img/state.svg'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import HeaderDropdownMenu from '../../component/HeaderDropdownMenu'
import { Tooltip } from 'flowbite-react'
import avi from '../../img/av.svg'
import law from '../../img/law.svg'
import dayjs from 'dayjs'
// import { removeLocalStorageItem } from '../../utlis/helper'

function OverviewCSO({ data, thematicOrganization, feedData }) {
  const navigate = useNavigate()
  const { userInfo } = useContext(AuthContext)

  // const { userInfo, handleUpdateInfo, handleUpdateAuthenticated } =
  // const handleLogout = () => {
  //   handleUpdateAuthenticated(false)
  //   handleUpdateInfo(null)
  //   removeLocalStorageItem('userInfo')
  //   navigate('/login')
  // }

  // console.log({ feedData })

  return (
    <section>
      <div className='content overview'>
        <div className='row justify-content-between'>
          <div className='col-md-5 mb-2'>
            <div className='form-group has-search'>
              <span className='bi bi-search form-control-feedback'></span>
              <input
                type='text'
                className='form-control'
                placeholder='Search'
              />
            </div>
          </div>
          <div className='col-md-3 text-end'>
            <div className='d-flex justify-content-end'>
              <p className='bg-lt me-5 mt-1'>
                <i className='bi bi-circle-fill fs-6 me-2'></i> Directory
              </p>
              <div className='dropdown'>
                <Link
                  to='#'
                  className='dropdown-toggle'
                  role='button'
                  data-bs-toggle='dropdown'>
                  {userInfo?.organization?.orgfile?.org_logo ? (
                    <img
                      src={userInfo?.organization?.orgfile?.org_logo}
                      alt='avatar'
                      className='profile-topbar-avi'
                    />
                  ) : (
                    <img src={avatar} alt='avi' className='img-fluid' />
                  )}
                </Link>
                <HeaderDropdownMenu />
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
          <hr />
        </div>
        <div className='row justify-content-between'>
          <div className='col-md-6'>
            <h4>{userInfo?.organization?.organization || '-'}</h4>
            <p>Welcome back. An overview of your organization&apos;s account</p>
          </div>
          <div className='col-md-3'>
            <div className='bg-rankings' id='app-title'>
              <h3>
                <Tooltip
                  className='bg-light'
                  content={
                    <div style={{ height: '100px' }}>
                      <b>Information</b>
                      <p
                        style={{
                          maxWidth: '30ch',
                          marginBlockEnd: '0',
                          paddingBlockEnd: '0',
                          paddingBlockStart: '0.5rem',
                          color: '#A0A0AB',
                        }}>
                        You are on the
                        {userInfo?.organization?.profile_ranking} rank! To reach
                        Diamond rank, aim to have more than 12 entries for each
                        activity (news, projects, resources) on the platform
                      </p>
                    </div>
                  }
                  placement='left-start'
                  trigger='hover'>
                  <Icon
                    icon='uil:info-circle'
                    className='me-3 fs-3'
                    style={{ marginTop: '-2px' }}
                  />{' '}
                  Your account ranking
                </Tooltip>
              </h3>
              {userInfo?.organization?.profile_ranking === 'diamond' ? (
                <h2 className='mt-4'>
                  Diamond{' '}
                  <img
                    src={DiamondMedal}
                    alt='medal'
                    className='img-fluid ms-3'
                    width={40}
                  />
                </h2>
              ) : userInfo?.organization?.profile_ranking === 'gold' ? (
                <h2 className='mt-4'>
                  Gold{' '}
                  <img
                    src={medal}
                    alt='medal'
                    className='img-fluid ms-3'
                    width={40}
                  />
                </h2>
              ) : userInfo?.organization?.profile_ranking === 'silver' ? (
                <h2 className='mt-4'>
                  Silver{' '}
                  <img
                    src={SiverMedal}
                    alt='medal'
                    className='img-fluid ms-3'
                    width={40}
                  />
                </h2>
              ) : (
                <h2 className='mt-4'>
                  Bronze{' '}
                  <img
                    src={BronzeMedal}
                    alt='medal'
                    className='img-fluid ms-3'
                    width={40}
                  />
                </h2>
              )}
            </div>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-md-3 mb-3'>
            <div
              className='bg-listed co'
              style={{ backgroundColor: '#FCF9EE' }}>
              <div className='d-flex flex-row'>
                <div>
                  <img src={review} alt='review' className='img-fluid' />
                </div>
                <div className='cso-o ms-3'>
                  <h6 className='mb-2'>Number of Projects</h6>
                  <h5>{data?.projects || '-'}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3 mb-3'>
            <div
              className='bg-listed co'
              style={{ backgroundColor: '#EEF8FC' }}>
              <div className='d-flex flex-row'>
                <div>
                  <img src={ngs} alt='review' className='img-fluid' />
                </div>
                <div className='cso-o ms-3'>
                  <h6 className='mb-2'>No of Grants</h6>
                  <h5>{data?.grants || '-'}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3 mb-3'>
            <div className='bg-listed co' style={{ background: '#EEFCF2' }}>
              <div className='d-flex flex-row'>
                <div>
                  <img src={list} alt='review' className='img-fluid' />
                </div>
                <div className='cso-o ms-3'>
                  <h6 className='mb-2'>No of Resources</h6>
                  <h5>{data?.resources || '-'}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3 mb-3'>
            <div className='bg-listed co' style={{ background: '#F6F7FE' }}>
              <div className='d-flex flex-row'>
                <div>
                  <img src={view} alt='review' className='img-fluid' />
                </div>
                <div className='cso-o ms-3'>
                  <h6 className='mb-2'>No of News Feed</h6>
                  <h5>{feedData?.data?.length || '-'}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-md-6 mb-3'>
            {thematicOrganization?.length > 0 ? (
              <div className='group-cards py-4'>
                <h5 className='px-4'>
                  Organizations Working in your Thematic Area
                </h5>
                <hr />
                <div className='news-feed'>
                  {thematicOrganization?.map((item, index) => (
                    <Fragment key={index}>
                      <div className='row px-3'>
                        <div className='col-md-8 mb-2'>
                          <div className='d-flex flex-row items-center p-3 msg'>
                            <img
                              src={item?.orgfile?.org_logo || avi}
                              alt='user'
                              className='img-fluid me-2'
                              style={{
                                height: '50px',
                                width: '50px',
                                objectFit: 'cover',
                                borderRadius: '100%',
                              }}
                            />
                            <p>{item?.organization}</p>
                          </div>
                        </div>
                        <div className='col-md-4 text-end'>
                          <button
                            type='submit'
                            className='btn btn-primary bg-white text-muted btn-msg px-3'
                            onClick={() =>
                              navigate(`/mis/messages/${item?.id}`)
                            }>
                            Message
                          </button>
                        </div>
                      </div>
                      <hr className='m-0' />
                    </Fragment>
                  ))}
                </div>
              </div>
            ) : (
              <div
                className='news-feed text-center'
                style={{ marginLeft: 10, marginRight: 10 }}>
                <img src={state} alt='empty state' className='img-fluid mt-5' />
                <h4 className='my-4'>No Organization</h4>
              </div>
            )}
          </div>
          <div className='col-md-6 mb-3'>
            <div className='group-cards py-4 '>
              <h5 className='px-4'>News Feed</h5>
              <hr />
              <div
                className='news-feed text-center'
                style={{ marginLeft: 10, marginRight: 10 }}>
                {feedData?.data?.length > 0 ? (
                  <>
                    {feedData?.data?.map((item, index) => (
                      <Link to={`/mis/feeds/${item?.id}`} key={index}>
                        <div className='d-flex flex-row items-center feeds'>
                          <img
                            src={item?.image || law}
                            alt=''
                            className='img-fluid me-3'
                            style={{
                              height: '70px',
                              width: '70px',
                              objectFit: 'cover',
                              borderRadius: '10px',
                            }}
                          />
                          <div className='msg'>
                            <p className='mb-1' style={{ textAlign: 'left' }}>
                              {item?.content && item?.content?.length > 100
                                ? `${item?.content?.substring(0, 100)}...`
                                : item?.content}
                            </p>
                            <p style={{ textAlign: 'left' }}>
                              {dayjs(item?.created_at).format('MMMM DD, YYYY')}
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </>
                ) : (
                  <div>
                    <img
                      src={state}
                      alt='empty state'
                      className='img-fluid mt-3'
                    />
                    <h4 className='my-4'>No updates yet</h4>
                    <p className='mb-4'>
                      Be the first to share a story so others can see updates in
                      their news feed.
                    </p>
                    <Link to='' className='btn btn-success w-25 login-btn mb-3'>
                      {' '}
                      Add News
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="bg-white p-4 group-cards mt-4">
					<div className="mb-4">
						<h5>Recent Activity</h5>
						<button
							type="button"
							className="btn btn-outline-dark float-end px-4"
							style={{ marginTop: '-42px' }}
						>
							View all
						</button>
					</div>
					<div style={{ marginLeft: '-24px', marginRight: '-24px' }}>
						<hr />
					</div>
					<div className="row mt-5">
						<div className="col-md-6 border-end mb-3">
							<div className="d-flex flex-row recent-act">
								<img src={user} alt="user" className="img-fluid me-3" />
								<div>
									<h4>
										Iyioluwa Abosede{' '}
										<span className="ms-2 time">3 hours ago</span>
									</h4>
									<h4>
										Approved the listing{' '}
										<span style={{ color: '#25815E' }}>
											“African Center for Genetics”
										</span>
									</h4>
								</div>
							</div>
							<div className="d-flex flex-row recent-act mt-5">
								<img src={user} alt="user" className="img-fluid me-3" />
								<div>
									<h4>
										Iyioluwa Abosede{' '}
										<span className="ms-2 time">3 hours ago</span>
									</h4>
									<h4>
										Approved the listing{' '}
										<span style={{ color: '#25815E' }}>
											“African Center for Genetics”
										</span>
									</h4>
								</div>
							</div>
							<div className="d-flex flex-row recent-act mt-5">
								<img src={user} alt="user" className="img-fluid me-3" />
								<div>
									<h4>
										Iyioluwa Abosede{' '}
										<span className="ms-2 time">3 hours ago</span>
									</h4>
									<h4>
										Approved the listing{' '}
										<span style={{ color: '#25815E' }}>
											“African Center for Genetics”
										</span>
									</h4>
								</div>
							</div>
							<div className="d-flex flex-row recent-act mt-5">
								<img src={user} alt="user" className="img-fluid me-3" />
								<div>
									<h4>
										Iyioluwa Abosede{' '}
										<span className="ms-2 time">3 hours ago</span>
									</h4>
									<h4>
										Approved the listing{' '}
										<span style={{ color: '#25815E' }}>
											“African Center for Genetics”
										</span>
									</h4>
								</div>
							</div>
							<div className="d-flex flex-row recent-act mt-5">
								<img src={user} alt="user" className="img-fluid me-3" />
								<div>
									<h4>
										Iyioluwa Abosede{' '}
										<span className="ms-2 time">3 hours ago</span>
									</h4>
									<h4>
										Approved the listing{' '}
										<span style={{ color: '#25815E' }}>
											“African Center for Genetics”
										</span>
									</h4>
								</div>
							</div>
						</div>
						<div className="col-md-6 mb-3 left-act">
							<div className="d-flex flex-row recent-act">
								<img src={user} alt="user" className="img-fluid me-3" />
								<div>
									<h4>
										Iyioluwa Abosede{' '}
										<span className="ms-2 time">3 hours ago</span>
									</h4>
									<h4>
										Approved the listing{' '}
										<span style={{ color: '#25815E' }}>
											“African Center for Genetics”
										</span>
									</h4>
								</div>
							</div>
							<div className="d-flex flex-row recent-act mt-5">
								<img src={user} alt="user" className="img-fluid me-3" />
								<div>
									<h4>
										Iyioluwa Abosede{' '}
										<span className="ms-2 time">3 hours ago</span>
									</h4>
									<h4>
										Approved the listing{' '}
										<span style={{ color: '#25815E' }}>
											“African Center for Genetics”
										</span>
									</h4>
								</div>
							</div>
							<div className="d-flex flex-row recent-act mt-5">
								<img src={user} alt="user" className="img-fluid me-3" />
								<div>
									<h4>
										Iyioluwa Abosede{' '}
										<span className="ms-2 time">3 hours ago</span>
									</h4>
									<h4>
										Approved the listing{' '}
										<span style={{ color: '#25815E' }}>
											“African Center for Genetics”
										</span>
									</h4>
								</div>
							</div>
							<div className="d-flex flex-row recent-act mt-5">
								<img src={user} alt="user" className="img-fluid me-3" />
								<div>
									<h4>
										Iyioluwa Abosede{' '}
										<span className="ms-2 time">3 hours ago</span>
									</h4>
									<h4>
										Approved the listing{' '}
										<span style={{ color: '#25815E' }}>
											“African Center for Genetics”
										</span>
									</h4>
								</div>
							</div>
							<div className="d-flex flex-row recent-act mt-5">
								<img src={user} alt="user" className="img-fluid me-3" />
								<div>
									<h4>
										Iyioluwa Abosede{' '}
										<span className="ms-2 time">3 hours ago</span>
									</h4>
									<h4>
										Approved the listing{' '}
										<span style={{ color: '#25815E' }}>
											“African Center for Genetics”
										</span>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div> */}
      </div>
    </section>
  )
}

export default OverviewCSO
