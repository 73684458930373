import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FileUploader } from "react-drag-drop-files";
import { Link } from "react-router-dom";
import Button from "../Button";
import { Form as FormikForm, Formik } from "formik";
import { businessDocValidationSchema } from "../../utlis/validation";
import Select from "../Select";
import Input from "../Input";
import { Oval } from "react-loader-spinner";
import { s3Config } from "../../utlis/s3config";
import ReactS3Client from "react-aws-s3-typescript";
import { toast } from "../../component/toast";
import { useMutation } from "react-query";
import { addBusinessDocumentation } from "../../handlers/onboardingHandler";

const fileTypes = ["JPEG", "PNG", "JPG", "PDF"];

const BusinessDocumentation = ({ handleChangeTab }) => {
  const [file, setFile] = useState(null);

  const [CACFileObj, setCACFileObj] = React.useState({
    name: "",
    loading: false,
  });
  const [articleAssoFileObj, setArticleAssoFileObj] = React.useState({
    name: "",
    loading: false,
  });
  const [regCertFileObj, setRegCertFileObj] = React.useState({
    name: "",
    loading: false,
  });
  const [CACImgUrl, setCACImgUrl] = React.useState("");
  const [articleAssoImgUrl, setArticleAssoImgUrl] = React.useState("");
  const [regCertImgUrl, setRegCertImgUrl] = React.useState("");

  const handleFileUpload = async (file) => {
    const s3 = new ReactS3Client({ ...s3Config });
    try {
      const res = await s3.uploadFile(file);
      return res.location;
    } catch (exception) {
      console.log(exception);
    }
  };

  const handleCACUpload = async (file) => {
    setCACFileObj({
      name: file?.name,
      loading: true,
    });
    const loc = await handleFileUpload(file);

    setCACImgUrl(loc);
    setCACFileObj((e) => ({ ...e, loading: false }));
  };

  const handleArticleAssoUpload = async (file) => {
    setArticleAssoFileObj({
      name: file?.name,
      loading: true,
    });
    const loc = await handleFileUpload(file);

    setArticleAssoImgUrl(loc);
    setArticleAssoFileObj((e) => ({ ...e, loading: false }));
  };

  const handleRegCertUpload = async (file) => {
    setRegCertFileObj({
      name: file?.name,
      loading: true,
    });
    const loc = await handleFileUpload(file);

    setRegCertImgUrl(loc);
    setRegCertFileObj((e) => ({ ...e, loading: false }));
  };

  const { mutate: addBusinessDocumentationMutation, isLoading: isLoading } =
    useMutation(addBusinessDocumentation, {
      onSuccess: async (res) => {
        toast.success("Details saved successfully");
        handleChangeTab("third");
      },
      onError: (e) => {
        toast.error(e);
      },
    });

  const handleSubmit = (values) => {
    if (values.reg_cac === "Yes") {
      const data = {
        ...values,
        cac: CACImgUrl,
        article_of_asso: articleAssoImgUrl,
        registeration_certificate: null,
      };
      addBusinessDocumentationMutation(data);
    }
    if (values.reg_cac === "No") {
      const data = {
        ...values,
        cac: null,
        article_of_asso: null,
        registeration_certificate: regCertImgUrl,
      };
      addBusinessDocumentationMutation(data);
    }
  };

  return (
    <div>
      <h2>Organization’s Business Documentation</h2>
      <h6>
        Please provide additional business information and upload business
        documents.
      </h6>
      <Formik
        initialValues={{
          reg_cac: "",
          rc_number: "",
          tax_id_no: "",
          name_of_registeration: "",
          registeration_with: "",
          registeration_no: "",
          registeration_name: "",
        }}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={businessDocValidationSchema}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          errors,
        }) => (
          <FormikForm onSubmit={handleSubmit}>
            <Form.Group className="mb-3 mt-5">
              <Select
                label="Is your Organization CAC (Corporate Affairs Commission)
								Registered"
                onChange={handleChange}
                onBlur={handleBlur}
                name="reg_cac"
                value={values.reg_cac}
                options={[
                  { value: "Yes", label: "Yes" },
                  { value: "No", label: "No" },
                ]}
              />
            </Form.Group>
            {values.reg_cac === "Yes" && (
              <>
                <Form.Group className="mb-3">
                  <Input
                    type="text"
                    value={values?.rc_number}
                    name="rc_number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="RC Number"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Input
                    type="text"
                    name="tax_id_no"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.tax_id_no}
                    label="Tax Identification Number"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Upload a Copy of your Organization’s CAC Certificate
                  </Form.Label>
                  <FileUploader
                    handleChange={handleCACUpload}
                    name="file"
                    types={fileTypes}
                    label="Drag and drop Image here or Browse"
                    multiple={false}
                  />
                  <div className="uploader-loading-block">
                    <p style={{ margin: 0 }}>
                      {CACFileObj.loading
                        ? `${CACFileObj.name} Uploading`
                        : CACFileObj.name
                        ? CACFileObj.name
                        : "No file(s) uploaded yet"}
                    </p>
                    {CACFileObj.loading && (
                      <Oval wrapperClass="ml-2" height={20} width={20} />
                    )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Upload Copy of your Organization’s Article of Association
                  </Form.Label>
                  <FileUploader
                    multiple={false}
                    handleChange={handleArticleAssoUpload}
                    name="file"
                    types={fileTypes}
                    label="Drag and drop Image here or Browse"
                  />
                  <div className="uploader-loading-block">
                    <p style={{ margin: 0 }}>
                      {articleAssoFileObj.loading
                        ? `${articleAssoFileObj.name} Uploading`
                        : articleAssoFileObj.name
                        ? articleAssoFileObj.name
                        : "No file(s) uploaded yet"}
                    </p>
                    {articleAssoFileObj.loading && (
                      <Oval wrapperClass="ml-2" height={20} width={20} />
                    )}
                  </div>
                </Form.Group>
              </>
            )}
            {values.reg_cac === "No" && (
              <>
                <Form.Group className="mb-3 ">
                  <Select
                    label="Registered with"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="registeration_with"
                    value={values.registeration_with}
                    options={[
                      { value: "federal ministry", label: "Federal Ministry" },
                      { value: "state ministry", label: "State Ministry" },
                      { value: "local ministry", label: "Local Government" },
                      { value: "cooperatives", label: "Cooperatives" },
                    ]}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Input
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="registeration_name"
                    value={values.registeration_name}
                    label="Name of Registration"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Input
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="registeration_no"
                    value={values.registeration_no}
                    label="Registration Number"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Attach a Copy of your Registration Certificate
                  </Form.Label>
                  <FileUploader
                    multiple={false}
                    handleChange={handleRegCertUpload}
                    name="file"
                    types={fileTypes}
                    label="Drag and drop document here or Browse"
                  />
                  <div className="uploader-loading-block">
                    <p style={{ margin: 0 }}>
                      {regCertFileObj.loading
                        ? `${regCertFileObj.name} Uploading`
                        : regCertFileObj.name
                        ? regCertFileObj.name
                        : "No file(s) uploaded yet"}
                    </p>
                    {regCertFileObj.loading && (
                      <Oval wrapperClass="ml-2" height={20} width={20} />
                    )}
                  </div>
                </Form.Group>
              </>
            )}
            <Button
              type="submit"
              className="btn btn-success register-btn mt-5 login-btn px-5"
              loading={isLoading}
              disabled={
                CACFileObj?.loading ||
                articleAssoFileObj?.loading ||
                regCertFileObj?.loading
              }
            >
              Save and Continue
            </Button>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default BusinessDocumentation;
