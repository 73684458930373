import GrantCreate from "./dashboardParts/grantCreate";
import Sidebar from "./dashboardParts/sideBar";

function CreateGrant() {
  return (
    <div style={{backgroundColor: "#fff"}}>
      <Sidebar/>
      <GrantCreate/>
    </div>
  );
}

export default CreateGrant;
