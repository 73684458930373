import AdminCreateNews from './dashboardParts/adminCreateNews';
import Adminsidebar from './dashboardParts/adminsidebar';

function AdminAddNews() {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Adminsidebar/>
      <AdminCreateNews/>
    </div>
  )
}

export default AdminAddNews;
