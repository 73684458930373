import React from 'react'
import avatar from '../../img/user-img.svg'
// import p from '../../img/public.svg'
// import manage from '../../img/manage.svg'
// import log from '../../img/logout.svg'
// import sr from '../../img/sr.svg'
// import mis from '../../img/repeat.svg'
import header from '../../img/boi.svg'
import state from '../../img/state.svg'
import archived from '../../img/archived.svg'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
	getAllProject,
	archiveProject,
	fetchArchiveProject,
} from '../../handlers/projectHandler'
import { Oval } from 'react-loader-spinner'
// import Table from '../../component/Table'
// import dayjs from 'dayjs'
import HeaderDropdownMenu from '../../component/HeaderDropdownMenu'
import DraftProjects from './DraftProjects'
import { toast } from '../../component/toast'
// import { Button } from 'react-bootstrap'
import { AuthContext } from '../../context/AuthContext'
import AppButton from '../../component/Button'
import ReactPaginate from 'react-paginate'

function Projects() {
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { userInfo } = React.useContext(AuthContext)
	const [currPage, setCurrPage] = React.useState(1)
	const [searchName, setSearchName] = React.useState('')
	const timeout = React.useRef()

	const { data: projectData, isLoading } = useQuery(
		['cso-projects', currPage, searchName],
		() => getAllProject({ page: currPage, name: searchName })
	)

	const {
		data: archiveProjectData,
		isLoading: isArchiveLoading,
	} = useQuery('cso-archive', () => fetchArchiveProject())

	const { mutate: archiveProjectMutation } = useMutation(archiveProject, {
		onSuccess: async (res) => {
			toast.success('Project archived successfully')
			await queryClient.invalidateQueries(['cso-projects'])
		},
		onError: (e) => {
			toast.error(e)
		},
	})

	const handleArchiveProject = (id) => {
		archiveProjectMutation({
			id,
			data: {
				publish_status: 'archived',
			},
		})
	}

	const handleAddProject = () => {
		if (userInfo?.organization?.status !== 'approved') {
			return toast.error("Your application hasn't been approved")
		}
		return navigate('/cso/add-project')
	}

	const handlePaginateChange = async (page) => {
		setCurrPage(page.selected + 1)
	}

	const handleSearch = (e) => {
		const value = e.target.value
		clearTimeout(timeout.current)

		timeout.current = setTimeout(() => {
			setSearchName(value)
		}, 600)
	}

	return (
		<section>
			<div className="content overview">
				<div className="row justify-content-between">
					<div className="col-md-5 mb-2">
						<div className="form-group has-search">
							<span className="bi bi-search form-control-feedback"></span>
							<input
								type="text"
								className="form-control"
								placeholder="Search"
								onChange={handleSearch}
							/>
						</div>
					</div>
					<div className="col-md-3 text-end">
						<div className="d-flex justify-content-end">
							<p className="bg-lt me-5 mt-1">
								<i className="bi bi-circle-fill fs-6 me-2"></i> Directory
							</p>
							<div className="dropdown">
								<Link
									to="#"
									className="dropdown-toggle"
									role="button"
									data-bs-toggle="dropdown"
								>
									{userInfo?.organization?.orgfile?.org_logo ? (
										<img
											src={userInfo?.organization?.orgfile?.org_logo}
											alt="avatar"
											className="profile-topbar-avi"
										/>
									) : (
										<img src={avatar} alt="avi" className="img-fluid" />
									)}
								</Link>
								<HeaderDropdownMenu />
							</div>
						</div>
					</div>
				</div>
				<div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
					<hr />
				</div>
				<div className="row">
					<div className="col-md-7">
						<h4>Projects</h4>
						<p>
							View and share your organization's ongoing and completed projects
						</p>
					</div>
					<div
						className="col-md-5 text-end mt-3"
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<AppButton
							// to="/cso/add-project"
							className="btn btn-success w-50 login-btn mb-3"
							type="button"
							onClick={handleAddProject}
						>
							<i className="bi bi-plus me-3 fs-5"></i> Add New Projects
						</AppButton>
					</div>
				</div>
				<div className="row listings-tog mt-3">
					<div className="col">
						<ul className="nav nav-tabs tog" id="myTab" role="tablist">
							<li className="nav-item" role="presentation">
								<button
									className="nav-link active tog-btn"
									id="home-tab"
									data-bs-toggle="tab"
									data-bs-target="#home-tab-pane"
									type="button"
									role="tab"
									aria-controls="home-tab-pane"
									aria-selected="true"
								>
									All Projects
								</button>
							</li>
							{/* <li className="nav-item" role="presentation">
								<button
									className="nav-link"
									id="profile-tab"
									data-bs-toggle="tab"
									data-bs-target="#profile-tab-pane"
									type="button"
									role="tab"
									aria-controls="profile-tab-pane"
									aria-selected="false"
								>
									Draft Projects
								</button>
							</li> */}
							{/* <li className="nav-item" role="presentation">
								<button
									className="nav-link"
									id="contact-tab"
									data-bs-toggle="tab"
									data-bs-target="#contact-tab-pane"
									type="button"
									role="tab"
									aria-controls="contact-tab-pane"
									aria-selected="false"
								>
									Archived Projects
								</button>
							</li> */}
						</ul>
						{isLoading ? (
							<div className="loading-container">
								<Oval wrapperClass="ml-2" height={50} width={50} />
							</div>
						) : (
							<div className="tab-content" id="myTabContent">
								<div
									className="tab-pane fade show active"
									id="home-tab-pane"
									role="tabpanel"
									aria-labelledby="home-tab"
									tabIndex="0"
								>
									{projectData?.data?.length > 0 ? (
										<div className="row mt-5">
											{projectData?.data?.map((item, idx) => (
												<div className="col-md-4 mb-3" key={idx}>
													<div className="act-pro p-3">
														<img
															src={item?.pictures || header}
															alt="project"
															className="img-fluid w-100 pro-img"
														/>
														<h3 className="my-3">{item?.name}</h3>
														<p>
															{item?.description?.length > 100
																? `${item?.description?.substring(0, 100)}...`
																: item?.description}
														</p>
														<div className="row">
															{/* <div className="col-md-10">
																<div className="d-flex flex-row">
																	<img
																		src={sr}
																		alt="rating"
																		className="img-fluid me-2"
																	/>
																	<h6 className="mt-1">386 reviews</h6>
																</div>
															</div> */}
															<div className="col text-end">
																<div className="dropdown">
																	<i
																		className="bi bi-three-dots actions"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																	></i>
																	<ul className="dropdown-menu listing-drop">
																		<li>
																			<Link
																				className="dropdown-item listing-item"
																				to={`/cso/view-project/${item?.id}`}
																			>
																				<i className="bi bi-eye me-2"></i> View
																				Details
																			</Link>
																		</li>
																		<li>
																			<Link
																				className="dropdown-item listing-item"
																				to={`/cso/view-project/${item?.id}/edit`}
																			>
																				<i className="bi bi-pencil-square me-2"></i>{' '}
																				Edit Project
																			</Link>
																		</li>
																		{/* <li>
																			<Button
																				className="dropdown-item listing-item"
																				onClick={() =>
																					handleArchiveProject(item?.id)
																				}
																				// data-bs-toggle="modal"
																				// data-bs-target="#"
																				// to=""
																			>
																				<i className="bi bi-archive me-2"></i>{' '}
																				Move to Archive
																			</Button>
																		</li> */}
																	</ul>
																</div>
															</div>
														</div>
													</div>
												</div>
											))}
										</div>
									) : (
										<div
											className="news-feed text-center"
											style={{ marginLeft: 10, marginRight: 10 }}
										>
											<img
												src={state}
												alt="empty state"
												className="img-fluid mt-5"
											/>
											<h4 className="my-4">No Projects</h4>
											<p className="mb-4">Create a project to view</p>
										</div>
									)}
									<ReactPaginate
										previousLabel={'Previous'}
										nextLabel={'Next'}
										breakLabel={'...'}
										breakClassName={'break-me'}
										pageCount={projectData?.total / 9 || 1}
										marginPagesDisplayed={1}
										pageRangeDisplayed={3}
										onPageChange={handlePaginateChange}
										containerClassName={'paginate'}
										activeClassName={'active'}
										forcePage={currPage - 1}
									/>
								</div>
								<div
									className="tab-pane fade"
									id="profile-tab-pane"
									role="tabpanel"
									aria-labelledby="profile-tab"
									tabIndex="0"
								>
									<div className="bg-white group-cards mt-5">
										<div style={{ padding: '20px' }}>
											<h5>
												Listing(s){' '}
												<span className="ms-3 tab-lists">{0} Listing(s)</span>
											</h5>
											<h1 className="float-end" style={{ marginTop: '-28px' }}>
												Bulk Actions{' '}
												<i className="ms-3 bi bi-three-dots-vertical"></i>
											</h1>
										</div>
										<DraftProjects data={[]} />
									</div>
								</div>
								<div
									className="tab-pane fade"
									id="contact-tab-pane"
									role="tabpanel"
									aria-labelledby="contact-tab"
									tabIndex="0"
								>
									{archiveProjectData?.length > 0 ? (
										<div className="row mt-5">
											{archiveProjectData?.map((item, idx) => (
												<div className="col-md-4 mb-3" key={idx}>
													<div className="act-pro p-3">
														<img
															src={item?.pictures || archived}
															alt="project"
															className="img-fluid w-100 pro-img"
														/>
														<h3 className="my-3">{item?.name}</h3>
														<p>
															{item?.description?.length > 100
																? `${item?.description?.substring(0, 100)}...`
																: item?.description}
														</p>
														<div className="row">
															{/* <div className="col-md-10">
																<div className="d-flex flex-row">
																	<img
																		src={sr}
																		alt="rating"
																		className="img-fluid me-2"
																	/>
																	<h6 className="mt-1">386 reviews</h6>
																</div>
															</div> */}
															<div className="col text-end">
																<div className="dropdown">
																	<i
																		className="bi bi-three-dots actions"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																	></i>
																	<ul className="dropdown-menu listing-drop">
																		<li>
																			<Link
																				className="dropdown-item listing-item"
																				to=""
																			>
																				<i className="bi bi-eye me-2"></i> View
																				Details
																			</Link>
																		</li>
																		<li>
																			<Link
																				className="dropdown-item listing-item"
																				data-bs-toggle="modal"
																				data-bs-target="#"
																				to=""
																			>
																				<i className="bi bi-arrow-repeat me-2"></i>{' '}
																				Restore Project
																			</Link>
																		</li>
																		<li>
																			<Link
																				className="dropdown-item listing-item text-danger"
																				data-bs-toggle="modal"
																				data-bs-target="#"
																				to=""
																			>
																				<i className="bi bi-archive me-2"></i>{' '}
																				Delete Permanently
																			</Link>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
													</div>
												</div>
											))}
										</div>
									) : (
										<div
											className="news-feed text-center"
											style={{ marginLeft: 10, marginRight: 10 }}
										>
											<img
												src={state}
												alt="empty state"
												className="img-fluid mt-5"
											/>
											<h4 className="my-4">No Archived Projects</h4>
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	)
}

export default Projects
