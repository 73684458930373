import React from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

function ResourceSingle({ data }) {
	return (
		<section>
			<div className="content overview">
				<div className="row justify-content-between">
					<div className="col-md-8 mt-4 sbm">
						<Link to="/admin/resources">
							<i className="bi bi-arrow-left me-2"></i> Back to Resource Center
						</Link>
					</div>
					{/* <div className="col-md-3 text-end mt-3">
						<Link
							to="/admin/edit-resource"
							className="btn btn-success w-50 login-btn mb-3"
						>
							Edit Resource
						</Link>
					</div> */}
				</div>
				<div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
					<hr />
				</div>
				<div className="row mt-5 justify-content-center">
					<div className="col-sm-7">
						<div className="single-tex">
							<h2>{data?.title}</h2>
							<div className="d-flex my-2">
								{data?.type === 'text' ? (
									<span className="badgers me-3">
										<i className="bi bi-fonts me-2"></i>Text
									</span>
								) : data?.type === 'link' ? (
									<span className="me-3 badgers">
										<i className="bi bi-link-45deg me-2"></i>Links
									</span>
								) : (
									<span className="me-3 badgers">
										<i className="bi bi-folder me-2"></i>Files
									</span>
								)}
								{data?.status === 'approved' ? (
									<span className="mx-3 badgers">
										<i className="bi bi-check me-2"></i>Approved
									</span>
								) : data?.status === 'pending' ? (
									<span className="mx-3 pendg">
										<i className="bi bi-circle-fill me-2"></i>
										Pending
									</span>
								) : null}
								<h6 className="mt-1">By {data?.author}</h6>
							</div>
							<h3>{dayjs(data?.created_at).format('MMMM DD, YYYY')}</h3>
							{data?.type === 'link' ? (
								<a
									href={data?.link}
									target="_blank"
									className="btn btn-views float-end px-4"
								>
									View
								</a>
							) : data?.type === 'file' ? (
								<a
									href={data?.file}
									target="_blank"
									className="btn btn-success px-4 login-btn float-end"
									download
								>
									Download
								</a>
							) : (
								<p className="mt-4">{data?.content || '-'}</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ResourceSingle
