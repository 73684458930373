import CSOSidebar from './dashboardParts/csoSidebar'
import ViewProject from './dashboardParts/ViewProject'

function ProjectView() {
	return (
		<div style={{ backgroundColor: '#fff' }}>
			<CSOSidebar />
			<ViewProject />
		</div>
	)
}

export default ProjectView
