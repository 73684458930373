import { Link } from 'react-router-dom'
import emailsent from '../img/create.svg'

function AccountCreate() {
	return (
		<section className="register-section">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-6 text-center">
						<img src={emailsent} alt="envelope" className="img-fluid" />
						<h2 className="mt-4 mb-3">We are reviewing your application</h2>
						<h6 className="mb-5">
							We are currently reviewing your application, and we appreciate
							your patience during this process. We will be in touch with you
							through mail as soon as the process is completed. This usually
							takes 24 hours. If you have any questions or concerns, please do
							not hesitate to contact us.
						</h6>
						<Link
							to="/cso/overview"
							className="btn btn-success w-50 login-btn mb-3"
						>
							Go to Dashboard
						</Link>
					</div>
				</div>
			</div>
		</section>
	)
}

export default AccountCreate
