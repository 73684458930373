import React from "react";
import { useNavigate, Link } from "react-router-dom";
import logo from "../../img/logo-white.png";
import avatar from "../../img/user-img.svg";
import { AuthContext } from "../../context/AuthContext";
import {
  removeLocalStorageItem,
  getLocalStorageItem,
} from "../../utlis/helper";
import { toast } from "../../component/toast";

function Sidebar() {
  const navigate = useNavigate();
  const {
    isAuthenticated,
    userInfo,
    handleUpdateInfo,
    handleUpdateAuthenticated,
  } = React.useContext(AuthContext);
  const shouldClick = React.useRef(true);

  React.useEffect(() => {
    if (shouldClick.current) {
      shouldClick.current = false;
      const encryptedUserInfo = getLocalStorageItem("userInfo");
      if (!encryptedUserInfo) {
        navigate("/login");
        toast.error("You are not authorized to view this page, kindly login!");
      }
    }
  }, [navigate]);
  const handleLogout = () => {
    handleUpdateAuthenticated(false);
    handleUpdateInfo(null);
    removeLocalStorageItem("userInfo");
    navigate("/login");
  };
  return (
    <section>
      <div className="sidebar p-4 flex-sm-column">
        <div className="text-center">
          <img
            src={logo}
            alt="logo"
            className="img-fluid"
            style={{ width: 120, height: "auto" }}
          />
        </div>
        <button
          className="navbar-toggler mobile"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="bi bi-caret-down fs-1 text-white"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div>
            <Link to="/mis/feeds">
              <i className="bi bi-newspaper me-2 fs-5"></i> News Feed
            </Link>
            <Link to="/mis/resources">
              <i className="bi bi-box me-2 fs-5"></i> Resource Center
            </Link>
            <Link to="/mis/opportunities">
              <i className="bi bi-lightbulb me-2 fs-5"></i> Grants{" "}
              <span className="badge nots ms-4">-</span>
            </Link>
            <Link to="/mis/messages">
              <i className="bi bi-envelope me-2 fs-5"></i> Messages{" "}
              <span className="badge nots ms-4">-</span>
            </Link>
            <Link to="/mis/elearning">
              <i className="bi bi-book me-2 fs-5"></i> E-learning
            </Link>
          </div>
          <div className="py-sm-4 mt-sm-auto ms-auto ms-sm-0">
            <Link to="/cso/settings">
              <i className="bi bi-gear me-2 fs-5"></i>Account Settings
            </Link>
            <Link to="/cso/overview" className="switch-mis">
              <i className="bi bi-arrow-left-right me-2 fs-5"></i>Switch to
              Directory
            </Link>
            <hr className="underline" />
            <div className="d-flex flex-row">
              <img src={avatar} alt="avi" className="img-fluid" />
              <div className="mx-3 mt-2" style={{ wordBreak: "break-word" }}>
                <h5 className="text-white">{`${userInfo?.firstname} ${userInfo?.lastname}`}</h5>
                <p className="text-white">{userInfo?.email}</p>
              </div>
              <span
                style={{ cursor: "pointer", marginTop: "20px" }}
                onClick={handleLogout}
              >
                <i className="bi bi-box-arrow-right mt-3 fs-5 text-white"></i>
              </span>
            </div>
          </div>
        </div>
        <div className="desktop mt-5">
          <Link to="/mis/feeds">
            <i className="bi bi-newspaper me-2 fs-5"></i> News Feed
          </Link>
          <Link to="/mis/resources">
            <i className="bi bi-box me-2 fs-5"></i> Resource Center
          </Link>
          <Link to="/mis/opportunities">
            <i className="bi bi-lightbulb me-2 fs-5"></i> Grants{" "}
            <span className="badge nots ms-4">-</span>
          </Link>
          <Link to="/mis/messages">
            <i className="bi bi-envelope me-2 fs-5"></i> Messages{" "}
            <span className="badge nots ms-4">-</span>
          </Link>
          <Link to="/mis/elearning">
            <i className="bi bi-book me-2 fs-5"></i> E-learning
          </Link>
        </div>
        <div className="py-sm-4 mt-sm-auto ms-auto ms-sm-0 desktop">
          <Link to="/cso/settings">
            <i className="bi bi-gear me-2 fs-5"></i>Account Settings
          </Link>
          <Link to="/cso/overview" className="switch-mis">
            <i className="bi bi-arrow-left-right me-2 fs-5"></i>Switch to
            Directory
          </Link>
          <hr className="underline" />
          <div className="d-flex flex-row">
            {userInfo?.organization?.orgfile?.org_logo ? (
              <img
                src={userInfo?.organization?.orgfile?.org_logo}
                alt="avatar"
                className="profile-sidebar-avi"
              />
            ) : (
              <img src={avatar} alt="avi" className="img-fluid" />
            )}
            <div className="mx-3 mt-2" style={{ wordBreak: "break-word" }}>
              <h5 className="text-white">{`${userInfo?.firstname} ${userInfo?.lastname}`}</h5>
              <p className="text-white">{userInfo?.email}</p>
            </div>
            <span
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={handleLogout}
            >
              <i className="bi bi-box-arrow-right mt-3 fs-5 text-white"></i>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sidebar;
