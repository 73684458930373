import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import logo from '../img/atlaslogo.png'

function HeaderLogin() {
	return (
		<Navbar bg="light" expand="lg" className="bg-white p-4">
			<Container>
				<Navbar.Brand href="/">
					<img src={logo} alt="logo" className="img-fluid" width="135px" />
				</Navbar.Brand>
			</Container>
		</Navbar>
	)
}

export default HeaderLogin
