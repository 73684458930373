import React from "react";
// import dt from '../../img/ns.png'
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import ReactPaginate from "react-paginate";

function AdminResourcesCenter({
  resources,
  resourcesCount,
  adminResources,
  handlePaginateChange,
  currPage,
  handleUserPaginateChange,
  userCurrPage,
  allTotal,
  userTotal,
  allType,
  handleAllTypeChange,
  handleAllClear,
  handleAllSearch,
  searchInputRef,
  adminType,
  handleAdminSearch,
  handleAdminTypeChange,
  adminSearchInputRef,
}) {
  const calculateTotalResources = () => {
    return (
      Number(resourcesCount?.texts || 0) +
      Number(resourcesCount?.files || 0) +
      Number(resourcesCount?.links || 0)
    );
  };

  return (
    <section>
      <div className="content overview">
        <div className="row justify-content-between">
          <div className="col-md-6 mt-3">
            <h4>Resource Center</h4>
            <p>Manage resource items uploaded by organizations</p>
          </div>
          <div className="col-md-4 text-end mt-3">
            <div className="dropdown">
              <button
                to=""
                className="btn btn-success w-50 login-btn mb-3 dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Add Resources
              </button>
              <ul className="dropdown-menu">
                <li>
                  <Link
                    className="dropdown-item"
                    to="/admin/resources/add-text"
                  >
                    Text
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/admin/resources/add-file"
                  >
                    File
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/admin/resources/add-link"
                  >
                    Link
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-3 mb-3">
            <div className="bg-listed">
              <h6>Number of Text Based Resources</h6>
              <h5>{resourcesCount?.texts}</h5>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="bg-listed" style={{ backgroundColor: "#FCF9EE" }}>
              <h6>Number of Files Uploaded</h6>
              <h5>{resourcesCount?.files}</h5>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="bg-listed" style={{ background: "#EEFCF2" }}>
              <h6>Number of Links Uploaded</h6>
              <h5>{resourcesCount?.links}</h5>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="bg-listed" style={{ background: "#F6F7FE" }}>
              <h6>Total No of Resource</h6>
              <h5>{calculateTotalResources()}</h5>
            </div>
          </div>
        </div>
        <div className="row listings-tog mt-3">
          <div className="col">
            <ul className="nav nav-tabs tog" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active tog-btn"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected="true"
                >
                  Resource Center
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="post-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#post-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="post-tab-pane"
                  aria-selected="false"
                >
                  My Resources
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home-tab-pane"
                role="tabpanel"
                aria-labelledby="home-tab"
                tabIndex="0"
              >
                <div className="p-4 group-cards gray mt-4">
                  <form className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Search Resources</label>
                        <div className="search-message">
                          <i className="bi bi-search"></i>
                          <input
                            type="text"
                            className="form-control form-input"
                            placeholder="Search Resources"
                            onChange={handleAllSearch}
                            ref={searchInputRef}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Resource Type</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={allType}
                          onChange={handleAllTypeChange}
                        >
                          <option value="">All</option>
                          <option value="file">File</option>
                          <option value="text">Text</option>
                          <option value="link">Link</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3 text-end align-content-center">
                      <button
                        type="button"
                        className="btn btn-primary bg-white text-muted btn-claer px-3"
                        onClick={handleAllClear}
                      >
                        Clear all
                      </button>
                    </div>
                  </form>
                </div>
                <div className="mt-4">
                  <div className="bg-white group-cards no-head mt-4">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Date Published</th>
                            <th scope="col">Resource Title</th>
                            <th scope="col">Type of Resource</th>
                            <th scope="col">Resource Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {resources?.map((resource, idx) => (
                            <tr key={idx}>
                              <td>
                                Published{" "}
                                {dayjs(resource.created_at).format(
                                  "YYYY/MM/DD"
                                )}{" "}
                                at{" "}
                                {dayjs(resource.created_at).format("hh:mm A")}
                              </td>
                              <td className="w-25">{resource.title}</td>
                              <td className="w-25 resource-type">
                                {resource.type}
                              </td>
                              <td>
                                {resource.status === "approved" ? (
                                  <span className="mx-3 badgers">
                                    <i className="bi bi-check me-2"></i>Approved
                                  </span>
                                ) : resource.status === "pending" ? (
                                  <span className="mx-3 pendg">
                                    <i className="bi bi-circle-fill me-2"></i>
                                    Pending
                                  </span>
                                ) : (
                                  <span className="mx-3 rejectedbg">
                                    <i className="bi bi-circle-fill me-2"></i>
                                    Rejected
                                  </span>
                                )}
                              </td>
                              <td>
                                <div className="dropdown">
                                  <i
                                    className="bi bi-three-dots-vertical actions"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  ></i>
                                  <ul className="dropdown-menu listing-drop">
                                    <li>
                                      <Link
                                        className="dropdown-item listing-item"
                                        to={`/admin/resources/${resource.id}`}
                                      >
                                        <i className="bi bi-eye me-2"></i> View
                                        Details
                                      </Link>
                                      <Link
                                        className="dropdown-item listing-item"
                                        to={`/admin/resources/${resource?.id}/update-status`}
                                      >
                                        <i className="bi bi-pencil me-2"></i>{" "}
                                        Update Status
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {resources?.length > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={allTotal / 10 || 1}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handlePaginateChange}
                    containerClassName={"paginate"}
                    activeClassName={"active"}
                    forcePage={currPage - 1}
                  />
                )}
              </div>

              <div
                className="tab-pane fade"
                id="post-tab-pane"
                role="tabpanel"
                aria-labelledby="post-tab"
                tabIndex="0"
              >
                <div className="p-4 group-cards gray mt-4">
                  <form className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Search Resources</label>
                        <div className="search-message">
                          <i className="bi bi-search"></i>
                          <input
                            type="text"
                            className="form-control form-input"
                            placeholder="Search Resources"
                            ref={adminSearchInputRef}
                            onChange={handleAdminSearch}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Resource Type</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={adminType}
                          onChange={handleAdminTypeChange}
                        >
                          <option value="">All</option>
                          <option value="file">File</option>
                          <option value="text">Text</option>
                          <option value="link">Link</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3 text-end align-content-center">
                      <button
                        type="button"
                        className="btn btn-primary bg-white text-muted btn-claer px-3"
                        onClick={handleAllClear}
                      >
                        Clear all
                      </button>
                    </div>
                  </form>
                </div>
                <div className="mt-4">
                  <div className="bg-white group-cards no-head mt-4">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Date Published</th>
                            <th scope="col">Resource Title</th>
                            <th scope="col">Type of Resource</th>
                            <th scope="col">Resource Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {adminResources?.map((resource, idx) => (
                            <tr key={idx}>
                              <td>
                                Published{" "}
                                {dayjs(resource.created_at).format(
                                  "YYYY/MM/DD"
                                )}{" "}
                                at{" "}
                                {dayjs(resource.created_at).format("hh:mm A")}
                              </td>
                              <td className="w-25">{resource.title}</td>
                              <td className="w-25 resource-type">
                                {resource.type}
                              </td>
                              <td>
                                {resource.status === "approved" ? (
                                  <span className="mx-3 badgers">
                                    <i className="bi bi-check me-2"></i>Approved
                                  </span>
                                ) : resource.status === "pending" ? (
                                  <span className="mx-3 pendg">
                                    <i className="bi bi-circle-fill me-2"></i>
                                    Pending
                                  </span>
                                ) : (
                                  <span className="mx-3 rejectedbg">
                                    <i className="bi bi-circle-fill me-2"></i>
                                    Rejected
                                  </span>
                                )}
                              </td>
                              <td>
                                <div className="dropdown">
                                  <i
                                    className="bi bi-three-dots-vertical actions"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  ></i>
                                  <ul className="dropdown-menu listing-drop">
                                    <li>
                                      <Link
                                        className="dropdown-item listing-item"
                                        to={`/admin/resources/${resource.id}`}
                                      >
                                        <i className="bi bi-eye me-2"></i> View
                                        Resource
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="dropdown-item listing-item"
                                        to={`/admin/resources/${resource.id}/edit`}
                                      >
                                        <i className="bi bi-pencil me-2"></i>
                                        Edit
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {adminResources?.length > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={userTotal / 10 || 1}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handleUserPaginateChange}
                    containerClassName={"paginate"}
                    activeClassName={"active"}
                    forcePage={userCurrPage - 1}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdminResourcesCenter;
