import React from "react";
import avi from "../../img/msg-avi.svg";
import dp from "../../img/dp.svg";

import { Link } from "react-router-dom";

function MessageAdmin() {
  return (
    <section>
      <div className="content content-msg overview">
        <div className="container-fluid">
          <div className="row" style={{ marginTop: "-30px" }}>
            <div
              className="col-md-8"
              style={{ paddingTop: "40px", paddingBottom: "40px" }}
            >
              <Link to="/admin/messages">
                <i className="bi bi-arrow-left"></i> All Messages
              </Link>
              <h4 className="mt-3">Damilola Shopade - Admin</h4>
            </div>
            <div className="col-md-4 bg-message"></div>
          </div>
        </div>
        <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
          <hr />
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 mb-4">
              <div className="container py-5 px-3 h-75"></div>
              <div className="hr-divider">
                <hr />
              </div>
              <div className="type-msg">
                <form>
                  <div className="mb-3">
                    <textarea
                      className="form-control"
                      id=""
                      rows="2"
                      placeholder="Enter a message"
                    ></textarea>
                    <button
                      to=""
                      className="btn btn-success login-btn py-2 px-4 float-end"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div
              className="col-md-4 border-start py-4 px-5"
              style={{ height: "100vh" }}
            >
              <img
                src={avi}
                alt="avatar"
                className="img-fluid"
                style={{ marginTop: "-76px" }}
              />
              <h4>Damilola Shopade - Admin</h4>
              <div className="my-4">
                <p>
                  <i className="bi bi-envelope-at me-2"></i>support@csoatlas.com
                </p>
                <p>
                  <i className="bi bi-geo-alt me-2"></i>P.O. BOX 7338,
                  Alandinma, Owerri, Imo state
                </p>
                <p>
                  <i className="bi bi bi-telephone me-2"></i>+2348025689710 |
                  +2348000256485
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MessageAdmin;
