import React from 'react'
import Form from 'react-bootstrap/Form'
import { Link, useNavigate } from 'react-router-dom'
import { Form as FormikForm, Formik } from 'formik'
import { useMutation } from 'react-query'
import { loginUser } from '../handlers/authhandler'
import { loginValidationSchema } from '../utlis/validation'
import Input from './Input'
import { toast } from './toast'
import AppButton from './Button'
import { saveToLocalStorage, encryptData } from '../utlis/helper'
import { AuthContext } from '../context/AuthContext'

function Login() {
	const navigate = useNavigate()
	const { handleUpdateAuthenticated, handleUpdateInfo } = React.useContext(
		AuthContext
	)

	const { mutate: loginUserMutation, isLoading: isLoading } = useMutation(
		loginUser,
		{
			onSuccess: async (res) => {
				handleUpdateInfo(res)
				const encryptedUserInfo = encryptData(res)
				saveToLocalStorage('userInfo', encryptedUserInfo)
				if (res?.is_admin) {
					navigate('/admin/overview')
				}

				if (res?.organization_profile && res?.business_document) {
					handleUpdateAuthenticated(true)
					navigate('/cso/overview')
				}

				if (res?.email_verified_at && !res?.organization_profile) {
					handleUpdateAuthenticated(true)
					navigate('/onboarding')
				}

				if (res?.organization_profile && !res?.business_document) {
					handleUpdateAuthenticated(true)
					navigate('/onboarding')
				}

				if (!res?.email_verified_at && !res?.is_admin) {
					navigate('/otp')
				}
			},
			onError: (e) => {
				toast.error(e)
			},
		}
	)

	const handleSubmit = (values) => {
		loginUserMutation(values)
	}

	return (
		<section className="register-section">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-5">
						<h2 className="text-center">Log in to your account</h2>
						<h6 className="mt-3 text-center">
							Welcome back. Please enter your details
						</h6>
						<Formik
							initialValues={{
								email: '',
								password: '',
							}}
							onSubmit={(values) => handleSubmit(values)}
							validationSchema={loginValidationSchema}
						>
							{({ values, handleChange, handleSubmit, handleBlur }) => (
								<Form className="mt-5" onSubmit={handleSubmit}>
									<Form.Group className="mb-3">
										<Form.Label>Email</Form.Label>
										<Input
											type="login-email"
											name="email"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.email}
										/>
									</Form.Group>
									<Form.Group className="mb-3">
										<Input
											type="password"
											name="password"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.password}
											label="Create a Password"
											bottomText="Must contain 8+ characters with at least 1 number"
										/>
									</Form.Group>
									<div>
										<div className="form-check my-3">
											<input className="form-check-input" type="checkbox" />
											<label className="form-check-label agree">
												Remember information
											</label>
										</div>
										<Link to="/forgot-password" className="float-end fp">
											Forgot Password
										</Link>
									</div>

									<AppButton
										className="w-100"
										type="submit"
										loading={isLoading}
									>
										Sign in
									</AppButton>

									<div className="text-center">
										<small className="agree">
											If you do not have an account yet, you can{' '}
											<Link to="/signup" style={{ color: '#2EA275' }}>
												Signup here
											</Link>
										</small>
									</div>
								</Form>
							)}
						</Formik>
						{/* <div className="separator mt-4">
							<div className="line"></div>
							<h6>OR</h6>
							<div className="line"></div>
						</div>
						<div className="d-flex flex-row mt-4">
							<img src={google} alt="google" className="img-fluid" />
							<Button variant="primary" className="w-100 google-sign">
								Login with Google <i className="bi bi-arrow-right ms-3"></i>
							</Button>
						</div> */}
					</div>
				</div>
			</div>
		</section>
	)
}

export default Login
