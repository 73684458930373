import React from 'react'
import { Link } from 'react-router-dom'
import pix from '../../img/px.svg'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getSingleProject } from '../../handlers/projectHandler'
import dayjs from 'dayjs'
import { format } from 'crypto-js'
import { Oval } from 'react-loader-spinner'

function ViewProject() {
	const { id } = useParams()
	const { data: projectData, isLoading } = useQuery(`project-${id}`, () =>
		getSingleProject(id)
	)

	return (
		<section className="">
			<div className="content overview">
				<div className="row">
					<div className="col-md-8 mb-2 back">
						<Link to="/cso/projects">
							<i className="bi bi-arrow-left me-2"></i> Back to Projects
						</Link>
					</div>
					<div className="col-md-4 text-end">
						<Link
							to={`/cso/view-project/${id}/edit`}
							className="btn btn-success w-50 login-btn me-3"
						>
							Edit Project
						</Link>
					</div>
				</div>
				<div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
					<hr />
				</div>
				{isLoading ? (
					<div className="loading-container">
						<Oval wrapperClass="ml-2" height={50} width={50} />
					</div>
				) : (
					<div className="mt-4">
						<div className="row">
							<div className="col-md-8 mb-3">
								<div className="p-4 bg-white group-cards">
									<div className="project-view">
										<h6>Project Title</h6>
										<h4>{projectData?.name}</h4>
										<h6>Pictures of the Project</h6>
										<img
											src={projectData?.pictures}
											alt="profile"
											className="img-fluid w-100 mb-3"
											style={{ height: '600px', objectFit: 'cover' }}
										/>
										<h6>Description of the project</h6>
										<p>{projectData?.description}</p>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="p-4 bg-white group-cards">
									<div className="row listings-tog">
										<div className="col">
											<ul
												className="nav nav-tabs tog"
												id="myTab"
												role="tablist"
											>
												<li className="nav-item" role="presentation">
													<button
														className="nav-link active review-btn"
														id="home-tab"
														data-bs-toggle="tab"
														data-bs-target="#home-tab-pane"
														type="button"
														role="tab"
														aria-controls="home-tab-pane"
														aria-selected="true"
													>
														Project Details
													</button>
												</li>
												{/* <li className="nav-item" role="presentation">
													<button
														className="nav-link review-btn"
														id="profile-tab"
														data-bs-toggle="tab"
														data-bs-target="#profile-tab-pane"
														type="button"
														role="tab"
														aria-controls="profile-tab-pane"
														aria-selected="false"
													>
														Ratings & Reviews
													</button>
												</li> */}
											</ul>
											<div className="tab-content" id="myTabContent">
												<div
													className="tab-pane fade show active"
													id="home-tab-pane"
													role="tabpanel"
													aria-labelledby="home-tab"
													tabIndex="0"
												>
													<div className="row mt-3">
														<div className="col p-d">
															<div>
																<h3>Project Overview</h3>
																<h2>
																	Project Location:{' '}
																	<span className="sm">
																		{projectData?.location}
																	</span>
																</h2>
															</div>
															<h2>
																Start Date:{' '}
																<span className="sm">
																	{dayjs(projectData?.start_date).format(
																		'DD, MMMM YYYY'
																	)}
																</span>
															</h2>
															<h2>
																Project Status:{' '}
																<span className="sm">
																	{projectData?.project_status}
																</span>
															</h2>
															<h2>
																Publish Status:{' '}
																<span className="sm">
																	{projectData?.publish_status}
																</span>
															</h2>
														</div>
													</div>
												</div>
												{/* <div
													className="tab-pane fade"
													id="profile-tab-pane"
													role="tabpanel"
													aria-labelledby="profile-tab"
													tabIndex="0"
												>
													<div className="row mt-3">
														<div className="col p-d">
														<div>
															<h3>Project Overview</h3>
															<h2>
																Project Location:{' '}
																<span className="sm">Lagos, Nigeria</span>
															</h2>
														</div>
														<h2>
															Start Date:{' '}
															<span className="sm">28, September 2022</span>
														</h2>
														<h2>
															Project Status:{' '}
															<span className="sm">Ongoing</span>
														</h2>
														<h2>
															Project Ratings: <span className="sm">4.8</span>
														</h2>
														<h2>
															Project Reviews: <span className="sm">320</span>
														</h2>
														<h2>
															Publish Status: <span className="sm">Active</span>
														</h2>
														<h2>
															No of Views: <span className="sm">350</span>
														</h2>
													</div>
													</div>
												</div> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</section>
	)
}

export default ViewProject
