
import { Link } from "react-router-dom";
import emailsent from "../img/verified.svg";

function Verified() {
  return (
    <section className="register-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5 text-center">
            <img src={emailsent} alt="envelope" className="img-fluid" />
            <h2 className="mt-4 mb-3">Email Verified</h2>
            <h6 className="mb-5">Your email has been verified. You can go ahead to setup your account now</h6>
            <Link to="/onboarding" className="btn btn-success w-75 login-btn mb-3">Continue</Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Verified;
