import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import flag from '../../img/flag.png'
import update from '../../img/update.svg'
import dump from '../../img/dump.svg'
import dayjs from 'dayjs'
import { toast } from '../../component/toast'
import { useMutation, useQueryClient } from 'react-query'
import { updateGrantStatus } from '../../handlers/grantHandler'
import Button from '../../component/Button'

function UpdateGrant({ id, data }) {
	const navigate = useNavigate()
	const closeApproveButtonRef = React.useRef()
	const closeRejectedButtonRef = React.useRef()
	const queryClient = useQueryClient()
	const [message, setMessage] = React.useState('')
	const [withMessage, setWithMessage] = React.useState(false)
	const {
		mutate: updateGrantStatusMutation,
		isLoading: isLoading,
	} = useMutation(updateGrantStatus, {
		onSuccess: async (res) => {
			closeApproveButtonRef?.current?.click()
			closeRejectedButtonRef?.current?.click()
			toast.success('Grants updated successfully')
			await queryClient.invalidateQueries(['admin-all-grants'])
			navigate('/admin/opportunities')
		},
		onError: (e) => {
			toast.error(e)
		},
	})

	const handleApprove = () => {
		updateGrantStatusMutation({
			id: id,
			data: {
				status: 'approved',
				with_message: false,
			},
		})
	}

	const handleReject = () => {
		updateGrantStatusMutation({
			id: id,
			data: {
				status: 'rejected',
				message: message,
				with_message: withMessage,
			},
		})
	}

	return (
		<section>
			<div className="content overview">
				<div className="row justify-content-between">
					<div className="col-md-8 mt-4 sbm">
						<Link to="/admin/opportunities">
							<i className="bi bi-arrow-left me-2"></i> Back to Grants
						</Link>
					</div>
					<div className="col-md-4 text-end mt-3">
						<div className="d-flex flex-row">
							<Button
								type="button"
								data-bs-toggle="modal"
								data-bs-target="#reject-grant"
								className="btn btn-success w-50 sbm-btn mb-3 mx-3"
							>
								Reject Grant
							</Button>
							<Button
								className="btn btn-success w-50 login-btn mb-3"
								type="button"
								data-bs-toggle="modal"
								data-bs-target="#approve-grant"
							>
								Accept Grant
							</Button>
						</div>
					</div>
				</div>
				<div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
					<hr />
				</div>
				<div className="row mt-5">
					<div className="col-sm-7">
						<div className="single-tex">
							<h2>{data?.title}</h2>
							<img
								src={data?.image}
								alt=""
								className="img-fluid w-100"
								style={{
									borderRadius: 20,
									height: 200,
									width: '100%',
									objectFit: 'cover',
								}}
							/>
							<div className="d-flex my-2">
								{data?.status === 'approved' ? (
									<span className="mx-3 badgers">
										<i className="bi bi-check me-2"></i>Approved
									</span>
								) : data?.status === 'pending' ? (
									<span className="mx-3 pendg">
										<i className="bi bi-circle-fill me-2"></i>
										Pending
									</span>
								) : (
									<span className="mx-3 rejectedbg">
										<i className="bi bi-circle-fill me-2"></i>
										Rejected
									</span>
								)}
								<h6 className="mt-1">By {data?.author}</h6>
							</div>
							<p className="mt-4">{data?.description}</p>
							<div>
								<h6 className="mb-2 fw-bold">Donor Name</h6>
								<p>{data?.donor_name}</p>
								<h6 className="mb-2 mt-3 fw-bold">Grant Size</h6>
								<p>${data?.size}</p>
							</div>
						</div>
					</div>
					<div className="col-sm-5">
						<div className="single-tex">
							<h5 className="mb-4">Grant Overview</h5>
							<p>
								<i className="bi bi-calendar-date me-3 mb-3"></i>Publication
								Date: {dayjs(data?.publication_date).format('MMMM DD, YYYY')}
							</p>
							<p>
								<i className="bi bi-clock me-3 mb-3"></i>Expiration Date:
								{dayjs(data?.closing_date).format('MMMM DD, YYYY')}
							</p>
							<p>
								<i className="bi bi-eye me-3 mb-3"></i>No of Views: -
							</p>
							<p>
								<i className="bi bi-cursor me-3 mb-3"></i>No of Applications: -
							</p>
							<p>
								<i className="bi bi-check-circle me-3 mb-3"></i>No of Grant
								Recipients: {data?.total_granted || '-'}
							</p>
						</div>
					</div>
				</div>
			</div>

			{/* Approve Grant */}
			<div
				className="modal fade"
				id="approve-grant"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content px-3">
						<div className="modal-header">
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								ref={closeApproveButtonRef}
							></button>
						</div>
						<div className="modal-body">
							<div className="row listing-modal">
								<div className="col">
									<img
										src={update}
										alt="update"
										className="img-fluid mb-3"
										style={{ marginTop: '-30px' }}
									/>
									<h4>Approve Grant </h4>
									<p>Are you sure you want to approve this grant?</p>
									<div className="d-flex flex-row">
										<Button
											type="button"
											data-bs-dismiss="modal"
											aria-label="Close"
											className="btn btn-success bg-white w-100 cancel-btn mt-3 me-3"
										>
											Cancel
										</Button>
										<Button
											type={'button'}
											className="btn btn-success w-100 login-btn mt-3"
											onClick={handleApprove}
											loading={isLoading}
										>
											Confirm
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Reject Resource */}
			<div
				className="modal fade"
				id="reject-grant"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content px-3">
						<div className="modal-header">
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								ref={closeRejectedButtonRef}
							></button>
						</div>
						<div className="modal-body">
							<div className="row listing-modal">
								<div className="col">
									<img
										src={dump}
										alt="update"
										className="img-fluid mb-3"
										style={{ marginTop: '-30px' }}
									/>
									<h4>Reject Grant </h4>
									<p>Are you sure you want to reject this grant?</p>
									<div className="mb-3">
										<label className="form-label">Send a Message</label>
										<textarea
											className="form-control"
											placeholder="Enter a message"
											rows="7"
											name="message"
											value={message}
											onChange={(e) => setMessage(e.target.value)}
										></textarea>
									</div>
									<div className="mb-3">
										<div className="form-check">
											<input
												className="form-check-input"
												value={withMessage}
												onChange={(e) => setWithMessage(e.target.checked)}
												type="checkbox"
											/>
											<label
												className="form-check-label"
												htmlFor="flexCheckDefault"
											>
												Send notification email
											</label>
										</div>
									</div>
									<div className="d-flex flex-row">
										<Button
											type={'button'}
											className="btn btn-success border-g bg-white w-100 cancel-btn mt-3 me-3"
											onClick={() => closeRejectedButtonRef.current.click()}
										>
											Cancel
										</Button>
										<Button
											type={'button'}
											className="btn btn-success w-100 del-btn mt-3"
											onClick={handleReject}
											loading={isLoading}
										>
											Confirm
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default UpdateGrant
