import EnterOtp from '../component/EnterOtp'
import HeaderLogin from '../component/navbarLogin'

function OTP() {
	return (
		<div>
			<HeaderLogin />
			<EnterOtp />
		</div>
	)
}

export default OTP
