import Adminsidebar from "../adminDashboard/dashboardParts/adminsidebar";
import ListingView from "./dashboardParts/listingView";

function ViewListing() {
  return (
    <div style={{backgroundColor: "#FAFAFA"}}>
      <Adminsidebar/>
      <ListingView/>
    </div>
  );
}

export default ViewListing;
