import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import mail from "../img/envelope.svg"

function ContactBody() {
  return (
    <section className="contact-section">
      <div className="container">
        <div className="row">
          <div className='col-md-6'>
            <h2>Contact us</h2>
            <h6 className='my-4'>Reach out to us and we'll get back to you as soon as possible.</h6>
            <div className='d-flex flex-row'>
              <img src={mail} alt="" className='img-fluid me-3' />
              <p>support@csoatlas.com</p>
            </div>
          </div>
          <div className="col-md-6">
            <Form className=''>
              <Form.Group className="mb-4" controlId="">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text"/>
              </Form.Group>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" />
              </Form.Group>
              <Form.Group className="mb-4" controlId="">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={6} placeholder="Enter a description...." />
              </Form.Group>
              <div className="form-check mb-5">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                <label className="form-check-label" for="flexCheckDefault">
                  I accept the Terms
                </label>
              </div>
              <Link
                to=""
                className="btn btn-success w-25 login-btn mb-3"
              >
                {' '}
                Submit
              </Link>
            </Form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactBody;