import React from "react";
import { Link } from "react-router-dom";

function SendBulkMIS() {
  return (
    <section>
      <div className="content overview">
        <div className="row">
          <div className="col-md-8 mt-4 sbm">
            <Link to="/mis/messages">
              <i className="bi bi-arrow-left"></i> Back
            </Link>
          </div>
          <div className="col-md-4 text-end mt-3">
            <div className="d-flex flex-row">
              <Link to="" className="btn btn-success w-50 sbm-btn mb-3 mx-3">
                Cancel
              </Link>
              <Link to="" className="btn btn-success w-50 login-btn mb-3">
                Send Bulk Message
              </Link>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "-50px", marginRight: "-58px" }}>
          <hr />
        </div>
        <div className="row mt-5">
          <div className="col">
            <h3>Send Bulk Message</h3>
          </div>
          <div className="sendbulk p-5">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <form>
                  <div className="mb-3">
                    <label className="form-label">
                      Select Thematic Area(s) to Message
                    </label>
                    <select className="form-select">
                      <option selected>Select Thematic Area</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Message Content</label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="5"
                      placeholder="Enter message content...."
                    ></textarea>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SendBulkMIS;
