import React from 'react'
import avatar from '../../img/user-img.svg'
import p from '../../img/public.svg'
import manage from '../../img/manage.svg'
import log from '../../img/logout.svg'
// import sr from "../../img/sr.svg";
import header from '../../img/camp.svg'
import { Link } from 'react-router-dom'

function StartCampaign() {
	return (
		<section>
			<div className="content overview">
				<div className="row">
					<div className="col-md-5 mb-2">
						<div className="form-group has-search">
							<span className="bi bi-search form-control-feedback"></span>
							<input
								type="text"
								className="form-control"
								placeholder="Search"
							/>
						</div>
					</div>
					<div className="col-md-7 text-end">
						<div className="dropdown">
							<Link
								to="#"
								className="dropdown-toggle"
								role="button"
								data-bs-toggle="dropdown"
							>
								<img src={avatar} alt="status" className="img-fluid" />
							</Link>
							<ul className="dropdown-menu userdrop">
								<Link className="d-flex flex-row">
									<img src={avatar} alt="status" className="img-fluid me-3" />
									<h6>African Society of Human Genetics</h6>
								</Link>
								<hr />
								<Link>
									<h5>
										<img src={p} alt="public" className="img-fluid me-3" /> View
										public profile
									</h5>
								</Link>
								<hr />
								<Link className="d-flex flex-row">
									<img src={manage} alt="status" className="img-fluid me-3" />
									<div>
										<h5>Manage Account</h5>
										<p>Control settings related to your account.</p>
									</div>
								</Link>
								<hr />
								<Link>
									<h5>
										<img src={log} alt="public" className="img-fluid me-3" />{' '}
										Log Out
									</h5>
								</Link>
							</ul>
						</div>
					</div>
				</div>
				<div style={{ marginLeft: '-50px', marginRight: '-58px' }}>
					<hr />
				</div>
				<div className="row">
					<div className="col-md-7">
						<h4>Donation Campaigns</h4>
						<p>
							View, create, and manage your organization's ongoing and completed
							campaigns
						</p>
					</div>
					<div className="col-md-5 text-end mt-3">
						<Link
							to="/cso/create-campaign"
							className="btn btn-success w-50 login-btn mb-3"
						>
							<i className="bi bi-plus me-3 fs-5"></i> Start a Campaign
						</Link>
					</div>
				</div>
				<div className="row listings-tog mt-3">
					<div className="col">
						<ul className="nav nav-tabs tog" id="myTab" role="tablist">
							<li className="nav-item" role="presentation">
								<button
									className="nav-link active tog-btn"
									id="home-tab"
									data-bs-toggle="tab"
									data-bs-target="#home-tab-pane"
									type="button"
									role="tab"
									aria-controls="home-tab-pane"
									aria-selected="true"
								>
									Active Campaigns
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className="nav-link"
									id="profile-tab"
									data-bs-toggle="tab"
									data-bs-target="#profile-tab-pane"
									type="button"
									role="tab"
									aria-controls="profile-tab-pane"
									aria-selected="false"
								>
									Completed Campaigns
								</button>
							</li>
							{/* <li className="nav-item" role="presentation">
                <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Archived Projects</button>
              </li> */}
						</ul>
						<div className="tab-content" id="myTabContent">
							<div
								className="tab-pane fade show active"
								id="home-tab-pane"
								role="tabpanel"
								aria-labelledby="home-tab"
								tabIndex="0"
							>
								<div className="row mt-5">
									<div className="col-md-4 mb-3">
										<div className="act-pro p-3">
											<img
												src={header}
												alt="project"
												className="img-fluid w-100"
											/>
											<Link to="/cso/single-donation" className="my-3">
												Fundraiser for the Human Rights Peace Walk on World
												Human Rights Day
											</Link>
											<p>
												Help us build a community center for underprivileged
												children in our neighborhood. Your donation will go
												towards construction costs and funding programs for
												education, arts, and sports.
											</p>
											<div
												className="progress"
												role="progressbar"
												aria-label="Basic example"
												aria-valuenow="75"
												aria-valuemin="0"
												aria-valuemax="100"
											>
												<div className="progress-bar w-75"></div>
											</div>
											<span>₦500,000 raised of ₦1,200,000</span>
											<div className="row mt-3">
												<div className="col-md-10">
													<p>700 Received Donations</p>
												</div>
												<div className="col-md-2 text-end">
													<div className="dropdown">
														<i
															className="bi bi-three-dots actions"
															data-bs-toggle="dropdown"
															aria-expanded="false"
														></i>
														{/* <ul className="dropdown-menu listing-drop">
                              <li><Link className="dropdown-item listing-item" to="/cso/view-project"><i className="bi bi-eye me-2"></i> View Details</Link></li>
                              <li><Link className="dropdown-item listing-item" data-bs-toggle="modal" data-bs-target="#" to=""><i className="bi bi-pencil-square me-2"></i> Edit Project</Link></li>
                              <li><Link className="dropdown-item listing-item" data-bs-toggle="modal" data-bs-target="#" to=""><i className="bi bi-archive me-2"></i> Move to Archive</Link></li>
                            </ul> */}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-4 mb-3">
										<div className="act-pro p-3">
											<img
												src={header}
												alt="project"
												className="img-fluid w-100"
											/>
											<Link to="/cso/single-donation" className="my-3">
												Fundraiser for the Human Rights Peace Walk on World
												Human Rights Day
											</Link>
											<p>
												Help us build a community center for underprivileged
												children in our neighborhood. Your donation will go
												towards construction costs and funding programs for
												education, arts, and sports.
											</p>
											<div
												className="progress"
												role="progressbar"
												aria-label="Basic example"
												aria-valuenow="75"
												aria-valuemin="0"
												aria-valuemax="100"
											>
												<div className="progress-bar w-75"></div>
											</div>
											<span>₦500,000 raised of ₦1,200,000</span>
											<div className="row mt-3">
												<div className="col-md-10">
													<p>700 Received Donations</p>
												</div>
												<div className="col-md-2 text-end">
													<div className="dropdown">
														<i
															className="bi bi-three-dots actions"
															data-bs-toggle="dropdown"
															aria-expanded="false"
														></i>
														{/* <ul className="dropdown-menu listing-drop">
                              <li><Link className="dropdown-item listing-item" to="/cso/view-project"><i className="bi bi-eye me-2"></i> View Details</Link></li>
                              <li><Link className="dropdown-item listing-item" data-bs-toggle="modal" data-bs-target="#" to=""><i className="bi bi-pencil-square me-2"></i> Edit Project</Link></li>
                              <li><Link className="dropdown-item listing-item" data-bs-toggle="modal" data-bs-target="#" to=""><i className="bi bi-archive me-2"></i> Move to Archive</Link></li>
                            </ul> */}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-4 mb-3">
										<div className="act-pro p-3">
											<img
												src={header}
												alt="project"
												className="img-fluid w-100"
											/>
											<Link to="/cso/single-donation" className="my-3">
												Fundraiser for the Human Rights Peace Walk on World
												Human Rights Day
											</Link>
											<p>
												Help us build a community center for underprivileged
												children in our neighborhood. Your donation will go
												towards construction costs and funding programs for
												education, arts, and sports.
											</p>
											<div
												className="progress"
												role="progressbar"
												aria-label="Basic example"
												aria-valuenow="75"
												aria-valuemin="0"
												aria-valuemax="100"
											>
												<div className="progress-bar w-75"></div>
											</div>
											<span>₦500,000 raised of ₦1,200,000</span>
											<div className="row mt-3">
												<div className="col-md-10">
													<p>700 Received Donations</p>
												</div>
												<div className="col-md-2 text-end">
													<div className="dropdown">
														<i
															className="bi bi-three-dots actions"
															data-bs-toggle="dropdown"
															aria-expanded="false"
														></i>
														{/* <ul className="dropdown-menu listing-drop">
                              <li><Link className="dropdown-item listing-item" to="/cso/view-project"><i className="bi bi-eye me-2"></i> View Details</Link></li>
                              <li><Link className="dropdown-item listing-item" data-bs-toggle="modal" data-bs-target="#" to=""><i className="bi bi-pencil-square me-2"></i> Edit Project</Link></li>
                              <li><Link className="dropdown-item listing-item" data-bs-toggle="modal" data-bs-target="#" to=""><i className="bi bi-archive me-2"></i> Move to Archive</Link></li>
                            </ul> */}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								className="tab-pane fade"
								id="profile-tab-pane"
								role="tabpanel"
								aria-labelledby="profile-tab"
								tabIndex="0"
							>
								<div className="row mt-5">
									<div className="col-md-4 mb-3">
										<div className="act-pro p-3">
											<img
												src={header}
												alt="project"
												className="img-fluid w-100"
											/>
											<Link to="/cso/single-donation" className="my-3">
												Fundraiser for the Human Rights Peace Walk on World
												Human Rights Day
											</Link>
											<p>
												Help us build a community center for underprivileged
												children in our neighborhood. Your donation will go
												towards construction costs and funding programs for
												education, arts, and sports.
											</p>
											<div
												className="progress"
												role="progressbar"
												aria-label="Basic example"
												aria-valuenow="75"
												aria-valuemin="0"
												aria-valuemax="100"
											>
												<div className="progress-bar w-100"></div>
											</div>
											<span>₦1,200,000 raised of ₦1,200,000</span>
											<div className="row mt-3">
												<div className="col-md-10">
													<p>700 Received Donations</p>
												</div>
												<div className="col-md-2 text-end">
													<div className="dropdown">
														<i
															className="bi bi-three-dots actions"
															data-bs-toggle="dropdown"
															aria-expanded="false"
														></i>
														{/* <ul className="dropdown-menu listing-drop">
                              <li><Link className="dropdown-item listing-item" to="/cso/view-project"><i className="bi bi-eye me-2"></i> View Details</Link></li>
                              <li><Link className="dropdown-item listing-item" data-bs-toggle="modal" data-bs-target="#" to=""><i className="bi bi-pencil-square me-2"></i> Edit Project</Link></li>
                              <li><Link className="dropdown-item listing-item" data-bs-toggle="modal" data-bs-target="#" to=""><i className="bi bi-archive me-2"></i> Move to Archive</Link></li>
                            </ul> */}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-4 mb-3">
										<div className="act-pro p-3">
											<img
												src={header}
												alt="project"
												className="img-fluid w-100"
											/>
											<Link to="/cso/single-donation" className="my-3">
												Fundraiser for the Human Rights Peace Walk on World
												Human Rights Day
											</Link>
											<p>
												Help us build a community center for underprivileged
												children in our neighborhood. Your donation will go
												towards construction costs and funding programs for
												education, arts, and sports.
											</p>
											<div
												className="progress"
												role="progressbar"
												aria-label="Basic example"
												aria-valuenow="75"
												aria-valuemin="0"
												aria-valuemax="100"
											>
												<div className="progress-bar w-75"></div>
											</div>
											<span>₦500,000 raised of ₦1,200,000</span>
											<div className="row mt-3">
												<div className="col-md-10">
													<p>700 Received Donations</p>
												</div>
												<div className="col-md-2 text-end">
													<div className="dropdown">
														<i
															className="bi bi-three-dots actions"
															data-bs-toggle="dropdown"
															aria-expanded="false"
														></i>
														{/* <ul className="dropdown-menu listing-drop">
                              <li><Link className="dropdown-item listing-item" to="/cso/view-project"><i className="bi bi-eye me-2"></i> View Details</Link></li>
                              <li><Link className="dropdown-item listing-item" data-bs-toggle="modal" data-bs-target="#" to=""><i className="bi bi-pencil-square me-2"></i> Edit Project</Link></li>
                              <li><Link className="dropdown-item listing-item" data-bs-toggle="modal" data-bs-target="#" to=""><i className="bi bi-archive me-2"></i> Move to Archive</Link></li>
                            </ul> */}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-4 mb-3">
										<div className="act-pro p-3">
											<img
												src={header}
												alt="project"
												className="img-fluid w-100"
											/>
											<Link to="/cso/single-donation" className="my-3">
												Fundraiser for the Human Rights Peace Walk on World
												Human Rights Day
											</Link>
											<p>
												Help us build a community center for underprivileged
												children in our neighborhood. Your donation will go
												towards construction costs and funding programs for
												education, arts, and sports.
											</p>
											<div
												className="progress"
												role="progressbar"
												aria-label="Basic example"
												aria-valuenow="75"
												aria-valuemin="0"
												aria-valuemax="100"
											>
												<div className="progress-bar w-75"></div>
											</div>
											<span>₦500,000 raised of ₦1,200,000</span>
											<div className="row mt-3">
												<div className="col-md-10">
													<p>700 Received Donations</p>
												</div>
												<div className="col-md-2 text-end">
													<div className="dropdown">
														<i
															className="bi bi-three-dots actions"
															data-bs-toggle="dropdown"
															aria-expanded="false"
														></i>
														{/* <ul className="dropdown-menu listing-drop">
                              <li><Link className="dropdown-item listing-item" to="/cso/view-project"><i className="bi bi-eye me-2"></i> View Details</Link></li>
                              <li><Link className="dropdown-item listing-item" data-bs-toggle="modal" data-bs-target="#" to=""><i className="bi bi-pencil-square me-2"></i> Edit Project</Link></li>
                              <li><Link className="dropdown-item listing-item" data-bs-toggle="modal" data-bs-target="#" to=""><i className="bi bi-archive me-2"></i> Move to Archive</Link></li>
                            </ul> */}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default StartCampaign
